export interface IFile {
    name: string;
    lastModified: number;
    lastModifiedDate: Date;
    size: number;
    type: string;
    data: any;
    title: string;
    conclusion: string;
    uploaded: boolean;
}

export const prettyByte = (sizeInByte: number) => {
    if (sizeInByte < 1024) {
        const sizeInByteFixed = sizeInByte.toFixed(2);
        return sizeInByteFixed.toString() + ' Byte';
    } else if (sizeInByte >= 1024 && sizeInByte < 1048576) {
        const sizeInKB = sizeInByte / 1024;
        const sizeInKBFixed = sizeInKB.toFixed(2);
        return sizeInKBFixed.toString() + ' KB';
    } else {
        const sizeInMB = sizeInByte / 1024 / 1024;
        const sizeInMBFixed = sizeInMB.toFixed(2);
        return sizeInMBFixed.toString() + ' MB';
    }
};

export const isFileTypeAccepted = (fileName: string, acceptedTypes: string) => {
    if (acceptedTypes && acceptedTypes.length > 0) {
        const allAcceptedTypes = acceptedTypes.split(',');
        let accept = false;
        allAcceptedTypes.forEach((e) => {
            const fileType = e.trim();
            if (fileName.endsWith(fileType)) {
                accept = true;
            }
        });

        return accept;
    }

    return true;
};
