export const setRuleProperties = (hashParams: any, executeruledispatch: any) => {
    if (hashParams && hashParams.type === 'ExecuteRule' && hashParams.ruleName) {
        executeruledispatch({
            type: 'SETRULEPROPERTIES',
            ruleName: hashParams.ruleName ? hashParams.ruleName : '',
            ruleParam: hashParams.ruleParam ? hashParams.ruleParam : '',
            persInfo: hashParams.persInfo ? hashParams.persInfo : '',
        });
    }
};
