import { StyledBadge } from '../../components/Badges/StyledBadge';
import { appointmentStatus } from '../../globals/global';
import { IAppointment } from '../../utils/appointmentUtils';
import { translateStatus } from '../../utils/pcpUtils';
import CompleteAppointmentBadge from './CompleteAppointment';

const AppointmentStatus = (props: IAppointment) => {
    if (props.status === appointmentStatus.enteredInError) {
        return <CompleteAppointmentBadge {...props} />;
    } else {
        return (
            <StyledBadge pill status={props.status} borderwidth={'0'}>
                {translateStatus(props.status)}
            </StyledBadge>
        );
    }
};

export default AppointmentStatus;
