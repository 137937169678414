import { Step, Stepper } from '@material-ui/core';
import StepLabel from '@material-ui/core/StepLabel';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useContext, useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Lottie from 'react-lottie';
import { Link, useNavigate } from 'react-router-dom';
import { Paths } from '../../Routes';
import AppointmentPersonalData from '../../components/AppointmentPersonalData/AppointmentPersonalData';
import StyledButton from '../../components/Buttons/StyledButton';
import StyledCol from '../../components/Cols/StyledCol';
import { LeftDiv } from '../../components/Div/StyledDiv';
import PersonalDataSummary from '../../container/PersonalDataSummary/PersonalDataSummary';
import AlertContext, { AlertOnHide, AlertType } from '../../context/alertContext';
import AppPropsContext from '../../context/appPropsContext';
import LoginContext from '../../context/loginContext';
import TerminierungContext from '../../context/terminierungContext';
import { useAppProperties } from '../../hooks/useAppProperties';
import { usePersonalData } from '../../hooks/usePersonalData';
import useScreenResolution from '../../hooks/useScreenResolution';
import { IRegisterData, registerUserDirect } from '../../services/RestServices';
import {
    changePatientResource,
    getPatientResource,
    modifyPatientResource,
} from '../../services/fhir/FHIRPatientResource';
import { showAlertMessage } from '../../utils/alertHandling';
import { lottieCheck } from '../../utils/lottieUtils';
import { isMobile, isTablet } from '../../utils/screenResolution';
import FullScreenImpressum from '../Mainscreen/FullScreenImpressum';
import { INeuerAccountWL } from './NeuerAccountWL';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        iconContainer: {
            transform: 'scale(1.5)',
        },
    }),
);

function getSteps() {
    return ['Formular ausfüllen', 'Daten überprüfen'];
}

function getDivWidth(screenWidth: number) {
    if (isTablet(screenWidth)) {
        return '70%';
    } else if (isMobile(screenWidth)) {
        return '100%';
    } else {
        return '60%';
    }
}

const NeuerAccount = (props: INeuerAccountWL) => {
    const navigate = useNavigate();
    const { state } = useContext(LoginContext);
    const { alertdispatch } = useContext(AlertContext);
    const { tmstate, tmdispatch } = useContext(TerminierungContext);
    const { apstate } = useContext(AppPropsContext);
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set<number>());
    const steps = getSteps();
    const [success, setSuccess] = useState(false);
    const [response, setResponse] = useState<any>();
    const [reason, setReason] = useState('');

    const screenSize = useScreenResolution();
    const divWidth = getDivWidth(screenSize.width);

    const { getAndSetPersonalData } = usePersonalData();
    const { getAndSetAppProperties, getImpressum } = useAppProperties();

    const classes = useStyles();

    useEffect(() => {
        if (props.type === 'NewAccount') {
            if (props.reason) {
                setReason(props.reason);
            }
            getAndSetAppProperties();
        }
    }, []);

    function getStepContent(step: number, handleNext: any, handleBack: any, handleCancel: any) {
        switch (step) {
            case 0:
                return personalDataForm(handleNext, handleBack, handleCancel);
            case 1:
                return personalDataSummary();
            default:
                return 'Unknown step';
        }
    }

    const SuccessLottieMessage = () => {
        if (tmstate.changePersonalData) {
            return (
                <>
                    <p>Ihre Profildaten wurden erfolgreich geändert!</p>
                </>
            );
        } else {
            return (
                <>
                    <p>
                        Willkommen{' '}
                        <strong>
                            {tmstate.personalData.firstName} {tmstate.personalData.lastName}
                        </strong>
                    </p>
                    <p>Ihr Account wurde erfolgreich eingerichtet!</p>
                    <p>Die Login-Daten wurden an folgende E-Mail Adresse gesendet:</p>
                    <p>
                        <strong>{tmstate.personalData.email}</strong>
                    </p>
                </>
            );
        }
    };

    const personalDataForm = (handleNext: any, handleBack: any, handleCancel: any) => {
        return (
            <AppointmentPersonalData
                handleNext={handleNext}
                handleBack={handleBack}
                handleCancel={handleCancel}
                directRegistration
            />
        );
    };

    const personalDataSummary = () => {
        let finalButton = 'Account erstellen';
        if (tmstate.changePersonalData) {
            finalButton = 'Daten ändern';
        }

        return (
            <div>
                <Row>
                    <StyledCol>
                        <div style={{ width: divWidth, margin: 'auto' }}>
                            <PersonalDataSummary />
                        </div>
                    </StyledCol>
                </Row>
                <Row>
                    <StyledCol textAlign="left">
                        <StyledButton variant="secondary" onClick={() => handleCancel()}>
                            Abbrechen
                        </StyledButton>
                    </StyledCol>
                    <div style={{ float: 'right' }}>
                        <StyledCol>
                            <StyledButton onClick={handleBack}>Zurück</StyledButton>
                        </StyledCol>
                    </div>
                    <div style={{ float: 'left' }}>
                        <StyledCol>
                            <StyledButton onClick={handleNext}>
                                {activeStep === steps.length - 1 ? finalButton : 'Bestätigen'}
                            </StyledButton>
                        </StyledCol>
                    </div>
                </Row>
            </div>
        );
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);

        if (activeStep === steps.length - 1) {
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        const registerData: IRegisterData = {
            insuranceNr: tmstate.personalData.insuranceNr,
            nameLast: tmstate.personalData.lastName,
            nameFirst: tmstate.personalData.firstName,
            birthdate: tmstate.personalData.birthday,
            zip: tmstate.personalData.zip,
            city: tmstate.personalData.city,
            street: tmstate.personalData.street,
            streetNr: tmstate.personalData.housenr,
            tel: tmstate.personalData.telephone,
            email: tmstate.personalData.email,
            gender: tmstate.personalData.gender,
            country: tmstate.personalData.country,
            mobilePhone: tmstate.personalData.mobilePhone,
            academicTitle: tmstate.personalData.academicTitle,
            reason: reason,
            plannedStudies: tmstate.personalData.plannedStudies,
            insuranceName: tmstate.personalData.insuranceName,
            language: tmstate.personalData.language,
        };

        let response;
        if (tmstate.changePersonalData) {
            const patdataOrignal = await getPatientResource(state.sessionId, state.activeUserId);
            const modifiedPatientResource = modifyPatientResource(patdataOrignal, registerData);
            response = await changePatientResource(state, modifiedPatientResource);
        } else {
            response = await registerUserDirect(registerData);
        }

        if (response.error) {
            let alertTitleCst = 'Neuer Account';
            if (tmstate.changePersonalData) {
                alertTitleCst = 'Persönliche Daten ändern';
            }

            showAlertMessage({
                alertTitle: alertTitleCst,
                alertTxt: response.error,
                alertType: AlertType.error,
                onHide: AlertOnHide.goToHome,
                alertdispatch: alertdispatch,
            });
        } else {
            setSuccess(true);
            setResponse(response);

            if (tmstate.changePersonalData) {
                getAndSetPersonalData(state.sessionId, state.activeUserId, state.practitionerRoleId);
            }
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleCancel = async () => {
        if (props.handleFinishClick) {
            props.handleFinishClick(response);
        } else if (tmstate.changePersonalData) {
            tmdispatch({
                type: 'CHANGEPERSONALDATA',
                changePersonalData: true,
            });
            navigate(Paths.PROFIL_INFO);
        } else {
            tmdispatch({
                type: 'RESETALL',
            });
            navigate(Paths.HOME);
        }
    };

    let stepContent;

    if (activeStep === 0) {
        stepContent = <Container>{getStepContent(activeStep, handleNext, handleBack, handleCancel)}</Container>;
    }

    if (activeStep === 1) {
        stepContent = <Container>{getStepContent(activeStep, handleNext, handleBack, handleCancel)}</Container>;
    }

    if (activeStep === steps.length && success) {
        stepContent = (
            <div>
                <Container>
                    <Row>
                        <StyledCol>
                            <Lottie options={lottieCheck} height={100} width={100} />
                        </StyledCol>
                    </Row>
                    <Row>
                        <StyledCol>
                            <div style={{ margin: 'auto', width: divWidth }}>
                                <Alert variant="success">
                                    <SuccessLottieMessage />
                                </Alert>
                            </div>
                        </StyledCol>
                    </Row>
                    <Row>
                        <StyledCol>
                            <div style={{ float: 'right' }}>
                                <StyledButton onClick={() => handleCancel()}>OK</StyledButton>
                            </div>
                        </StyledCol>
                    </Row>
                </Container>
            </div>
        );
    }

    let description;

    if (activeStep === 0) {
        description = <p>Bitte erfassen Sie Ihre persönlichen Informationen:</p>;
    }

    if (activeStep === 1) {
        description = <p>Bitte überprüfen Sie Ihre persönlichen Informationen:</p>;
    }

    const brandingSrc = 'data:image/jpg;base64,' + apstate.brandingBytes;

    const impressum = getImpressum();

    return (
        <FullScreenImpressum impressum={impressum}>
            <LeftDiv>
                <Nav.Link as={Link} to={Paths.HOME}>
                    <img src={brandingSrc} width="130" className="d-inline-block align-top" alt="Principa Logo" />
                </Nav.Link>
                <Container>
                    <br />
                    <h3>{tmstate.changePersonalData ? 'Accountdaten ändern' : 'Neuen Account erstellen'}</h3>
                    <div>{description}</div>
                    <br />
                </Container>
                <Container>
                    <div>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                                const stepProps: { completed?: boolean } = {};
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel classes={{ iconContainer: classes.iconContainer }}>
                                            {label}
                                        </StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        <div>{stepContent}</div>
                    </div>
                </Container>
            </LeftDiv>
        </FullScreenImpressum>
    );
};

export default NeuerAccount;
