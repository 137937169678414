import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import useScreenResolution from '../../../hooks/useScreenResolution';
import StyledButton from '../../Buttons/StyledButton';
import TableProvider, { ITableData } from '../../TableProvider/TableProvider';

export const ModalCst = styled(Modal)`
    .modal-dialog {
        max-width: 90%;
        max-height: 100%;
        margin: 1.75rem auto;
    }
    .modal-content {
        border-radius: ${(prop) => (prop.borderRadius ? prop.borderRadius : prop.theme.modal.borderRadius)};
        box-shadow: ${(prop) => (prop.boxShadow ? prop.boxShadow : prop.theme.modal.boxShadow)};
    }
`;

export interface ITableModalShow {
    show: boolean;
    modalTitle: string;
    tableData: ITableData;
}
export interface ITableModal extends ITableModalShow {
    onHide(): void;
}

const TableModal = (props: ITableModal) => {
    const screenSize = useScreenResolution();

    const onHideClick = () => {
        props.onHide();
    };

    const getBodyHeight = () => {
        let bodyHeight = screenSize.height;
        bodyHeight = bodyHeight * 0.8;
        return bodyHeight.toString() + 'px';
    };

    return (
        <>
            <ModalCst show={props.show}>
                <Modal.Header>
                    <Modal.Title>{props.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: getBodyHeight() }}>
                    <TableProvider maxHeight="100%" tableData={props.tableData} />
                </Modal.Body>
                <Modal.Footer>
                    <StyledButton style={{ marginLeft: '40px' }} onClick={() => onHideClick()}>
                        Schließen
                    </StyledButton>
                </Modal.Footer>
            </ModalCst>
        </>
    );
};

export default TableModal;
