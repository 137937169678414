import { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { CenterDiv, VerticalCenterDiv } from '../../components/Div/StyledDiv';
import { IURLToCallResponse } from '../../container/Splashscreen/CheckInSplashscreen';
import loadingSplashWait from '../../lotties/splashscreenWait.json';
import { Paths } from '../../Routes';
import { getURLToCall } from '../../services/RestServices';
const CheckInSplashscreenWaiting = () => {
    const navigate = useNavigate();
    const navtype = useNavigationType();
    const [intervalTimer, setIntervalTimer] = useState<any>();

    useEffect(() => {
        startListening();

        return () => {
            clearInterval(intervalTimer);
        };
    }, []);

    useEffect(() => {
        /* avoid browser back button click */
        return () => {
            if (navtype === 'POP') {
                navigate(Paths.SPLASHSCREEN_CHECKIN);
            }
        };
    }, [history]);

    const lottieSpinnerOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingSplashWait,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const LottieSplashWait = () => {
        return <Lottie options={lottieSpinnerOptions} height={200} width={200} />;
    };

    const LoadingSpinner = () => {
        return (
            <VerticalCenterDiv>
                <CenterDiv>
                    <h1>CheckIn Terminal</h1>
                    <LottieSplashWait />
                    <h2>Bitte stecken Sie Ihre Karte</h2>
                </CenterDiv>
            </VerticalCenterDiv>
        );
    };

    const startListening = () => {
        const timer = setInterval(() => listeningWorkflow(), 1000);
        setIntervalTimer(timer);
    };

    const listeningWorkflow = async () => {
        // console.log('do something...');
        const wpName = 'HSRUW';
        const response: IURLToCallResponse = await getURLToCall(wpName);
        if (response && response.state === 'active') {
            window.open(response.url, '_self');
        }
    };

    return (
        <div>
            <LoadingSpinner />
        </div>
    );
};

export default CheckInSplashscreenWaiting;
