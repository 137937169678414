import { Image } from 'react-bootstrap';
import { AiOutlineUser } from 'react-icons/ai';

type IPhyscianSelectionPhoto = {
    showPicture?: boolean;
    pict?: string;
};

const PhysicianSelectionPhoto = (props: IPhyscianSelectionPhoto) => {
    const physicianPhoto = () => {
        if (props.showPicture && props.pict) {
            return <Image src={'data:image/jpg;base64,' + props.pict} rounded width={150} />;
        } else if (props.showPicture && !props.pict) {
            return <AiOutlineUser size={80} />;
        } else {
            return null;
        }
    };

    return physicianPhoto();
};

export default PhysicianSelectionPhoto;
