export interface IPhoto {
    name: string;
    lastModifiedDate: Date;
    size: number;
    type: string;
    data: string;
}

export interface IPhotoRep {
    resource: {
        id: string;
        presentedForm: IPhotoItem[];
        effectiveDateTime: string;
    };
}

export interface IPhotoItem {
    contentType: string;
    data: string;
    title: string;
}

export function fullSrc(dataSrc: string) {
    return 'data:image/jpg;base64,' + dataSrc;
}
