import { useCallback, useContext } from 'react';
import LoginContext from '../context/loginContext';
import { PROFILE_HOME, securityItemNames } from '../globals/global';

export const useProfileSwitch = () => {
    const { state, dispatch } = useContext(LoginContext);

    const getActiveStudyEntry = (profileName: string) => {
        const entry = state.activeStudies.find((e) => e.studyName === profileName);
        return entry;
    };

    const getSecurityItems = (profileName: string) => {
        let securityItems = {};

        if (profileName === PROFILE_HOME) {
            securityItems = state.securityItems;
        } else {
            const entry: any = getActiveStudyEntry(profileName);
            if (entry) {
                securityItems = entry?.SecurityItems;
            }
        }

        return securityItems;
    };

    const isSecurityItemEnabled = useCallback(
        (profileName: string, securityItem: string) => {
            let securityItemsFound = {};

            securityItemsFound = getSecurityItems(profileName);
            if (securityItemsFound && securityItemsFound[securityItem] !== undefined) {
                return securityItemsFound[securityItem];
            }
            return false;
        },
        [state.activeUserId, state.activeStudies],
    );

    const getUserId = useCallback(
        (profileName: string) => {
            let newActiveUserId = '';
            if (profileName === PROFILE_HOME) {
                newActiveUserId = state.userId;
            } else {
                state.activeStudies.forEach((study: any) => {
                    if (study.studyName === profileName) {
                        newActiveUserId = study.studyPatId;
                    }
                });
            }

            return newActiveUserId;
        },
        [state.activeUserId, state.activeStudies],
    );

    const setActiveUserId = useCallback(
        (profileName: string) => {
            const activeUserId = getUserId(profileName);

            dispatch({
                type: 'SETACTIVEUSERID',
                activeUserId: activeUserId,
            });
        },
        [state.activeUserId, state.activeStudies],
    );

    const setSecurityItems = useCallback(
        async (profileName: string) => {
            let securityItemsFound = {};

            securityItemsFound = getSecurityItems(profileName);

            if (securityItemsFound) {
                if (profileName !== PROFILE_HOME) {
                    dispatch({
                        type: 'SETSECURITYITEMSSTUDY',
                        securityItemsStudy: {
                            CHARTS:
                                securityItemsFound[securityItemNames.CHARTS] !== undefined &&
                                (securityItemsFound[securityItemNames.CHARTS] === 'true' ||
                                    securityItemsFound[securityItemNames.CHARTS] === true)
                                    ? true
                                    : false,
                            CHARTS_WEIGHT:
                                securityItemsFound[securityItemNames.CHARTS_WEIGHT] !== undefined &&
                                (securityItemsFound[securityItemNames.CHARTS_WEIGHT] === 'true' ||
                                    securityItemsFound[securityItemNames.CHARTS_WEIGHT] === true)
                                    ? true
                                    : false,
                            CHARTS_FATMASS:
                                securityItemsFound[securityItemNames.CHARTS_FATMASS] !== undefined &&
                                (securityItemsFound[securityItemNames.CHARTS_FATMASS] === 'true' ||
                                    securityItemsFound[securityItemNames.CHARTS_FATMASS] === true)
                                    ? true
                                    : false,
                            CHARTS_BLOODSUGAR:
                                securityItemsFound[securityItemNames.CHARTS_BLOODSUGAR] !== undefined &&
                                (securityItemsFound[securityItemNames.CHARTS_BLOODSUGAR] === 'true' ||
                                    securityItemsFound[securityItemNames.CHARTS_BLOODSUGAR] === true)
                                    ? true
                                    : false,
                            CHARTS_FLI:
                                securityItemsFound[securityItemNames.CHARTS_FLI] !== undefined &&
                                (securityItemsFound[securityItemNames.CHARTS_FLI] === 'true' ||
                                    securityItemsFound[securityItemNames.CHARTS_FLI] === true)
                                    ? true
                                    : false,
                            CHARTS_SAM:
                                securityItemsFound[securityItemNames.CHARTS_SAM] !== undefined &&
                                (securityItemsFound[securityItemNames.CHARTS_SAM] === 'true' ||
                                    securityItemsFound[securityItemNames.CHARTS_SAM] === true)
                                    ? true
                                    : false,
                            CHARTS_ACTIVITY:
                                securityItemsFound[securityItemNames.CHARTS_ACTIVITY] !== undefined &&
                                (securityItemsFound[securityItemNames.CHARTS_ACTIVITY] === 'true' ||
                                    securityItemsFound[securityItemNames.CHARTS_ACTIVITY] === true)
                                    ? true
                                    : false,
                            DOKUMENT:
                                securityItemsFound[securityItemNames.DOKUMENT] !== undefined &&
                                (securityItemsFound[securityItemNames.DOKUMENT] === 'true' ||
                                    securityItemsFound[securityItemNames.DOKUMENT] === true)
                                    ? true
                                    : false,
                            FOTOS:
                                securityItemsFound[securityItemNames.FOTOS] !== undefined &&
                                (securityItemsFound[securityItemNames.FOTOS] === 'true' ||
                                    securityItemsFound[securityItemNames.FOTOS] === true)
                                    ? true
                                    : false,
                            FRAGEBOGEN:
                                securityItemsFound[securityItemNames.FRAGEBOGEN] !== undefined &&
                                (securityItemsFound[securityItemNames.FRAGEBOGEN] === 'true' ||
                                    securityItemsFound[securityItemNames.FRAGEBOGEN] === true)
                                    ? true
                                    : false,
                            MEDIKATION:
                                securityItemsFound[securityItemNames.MEDIKATION] !== undefined &&
                                (securityItemsFound[securityItemNames.MEDIKATION] === 'true' ||
                                    securityItemsFound[securityItemNames.MEDIKATION] === true)
                                    ? true
                                    : false,
                            TAGESPLAN:
                                securityItemsFound[securityItemNames.TAGESPLAN] !== undefined &&
                                (securityItemsFound[securityItemNames.TAGESPLAN] === 'true' ||
                                    securityItemsFound[securityItemNames.TAGESPLAN] === true)
                                    ? true
                                    : false,
                            TERMINE:
                                securityItemsFound[securityItemNames.TERMINE] !== undefined &&
                                (securityItemsFound[securityItemNames.TERMINE] === 'true' ||
                                    securityItemsFound[securityItemNames.TERMINE] === true)
                                    ? true
                                    : false,
                            VIDEO:
                                securityItemsFound[securityItemNames.VIDEO] !== undefined &&
                                (securityItemsFound[securityItemNames.VIDEO] === 'true' ||
                                    securityItemsFound[securityItemNames.VIDEO] === true)
                                    ? true
                                    : false,
                            JOURNEY:
                                securityItemsFound[securityItemNames.JOURNEY] !== undefined &&
                                (securityItemsFound[securityItemNames.JOURNEY] === 'true' ||
                                    securityItemsFound[securityItemNames.JOURNEY] === true)
                                    ? true
                                    : false,
                            TODO:
                                securityItemsFound[securityItemNames.TODO] !== undefined
                                    ? securityItemsFound[securityItemNames.TODO]
                                    : [],
                        },
                    });
                }
            }
        },
        [state.activeUserId, state.activeStudies],
    );

    return {
        setSecurityItems,
        isSecurityItemEnabled,
        getUserId,
        setActiveUserId,
    };
};
