import { useContext } from 'react';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import MyTooltip from '../../components/Tooltip/MyTooltip';
import AlertContext, { AlertOnHide, AlertType } from '../../context/alertContext';
import AppPropsContext from '../../context/appPropsContext';
import LoginContext, { ILink } from '../../context/loginContext';
import { callRestMethod } from '../../services/RestServices';
import { showAlertMessage } from '../../utils/alertHandling';

const ExtraLinks = () => {
    const { state } = useContext(LoginContext);
    const { alertdispatch } = useContext(AlertContext);
    const { apstate } = useContext(AppPropsContext);

    const url = async (linkObj: ILink) => {
        if (linkObj.href && linkObj.target) {
            window.open(linkObj.href, linkObj.target);
        } else if (linkObj.restmethod) {
            const tokenRes = await callRestMethod(state, alertdispatch, linkObj.restmethod);
            if (tokenRes) {
                if (tokenRes.startsWith('{')) {
                    const trj = JSON.parse(tokenRes);
                    const turl = trj.url;
                    if (turl) {
                        window.open(turl, linkObj.target, 'noopener,noreferrer');
                    } else {
                        const error = trj.error;
                        if (error) {
                            showAlertMessage({
                                alertTitle: 'Webshop',
                                alertTxt: error,
                                alertType: AlertType.error,
                                onHide: AlertOnHide.onlyClose,
                                alertdispatch: alertdispatch,
                            });
                        } else {
                            const warning = trj.warning;
                            showAlertMessage({
                                alertTitle: 'Webshop',
                                alertTxt: warning,
                                alertType: AlertType.info,
                                onHide: AlertOnHide.onlyClose,
                                alertdispatch: alertdispatch,
                                doHTML: true,
                            });
                        }
                    }
                } else {
                    const tempUrl = tokenRes.slice(2, -2); // remove " and brackets
                    const url = tempUrl.split('"').pop(); // get url after last "
                    window.open(url, linkObj.target, 'noopener,noreferrer');
                }
            }
        } else {
            console.log('restmethod not recognized');
        }
    };

    const myIcon = (linkObj: ILink) => {
        let displayLink = 'link';
        if (linkObj.innerHTML) {
            const image = linkObj.innerHTML;
            return (
                <div>
                    <Image src={`data:image/svg+xml;utf8,${encodeURIComponent(image)}`} fluid />
                </div>
            );
        } else if (linkObj.prefix && linkObj) {
            displayLink = linkObj.prefix + linkObj.linkText;
            return <p style={{ paddingTop: '15px' }}>{displayLink}</p>;
        } else {
            return <></>;
        }
    };

    const linkList = (extraLinks: any) => {
        const allLinks: any[] = [];

        if (extraLinks && Array.isArray(extraLinks)) {
            /* (multiple) extraLinks as array */
            extraLinks.forEach((linkItem: ILink, id: number) => {
                allLinks.push(
                    <div key={id}>
                        <Nav.Item>
                            <Nav.Link onClick={() => url(linkItem)}>
                                <MyTooltip
                                    id={String(id)}
                                    text={linkItem.linkText}
                                    iconDiv={false}
                                    divWidth="35px"
                                    marginLeft="0"
                                    marginRight="auto"
                                    icon={myIcon(linkItem)}
                                ></MyTooltip>
                            </Nav.Link>
                        </Nav.Item>
                    </div>,
                );
            });
            return allLinks;
        } else if (extraLinks) {
            /* single extraLink as object */
            const myExtraLink: ILink = extraLinks;
            return (
                <Nav.Item>
                    <Nav.Link onClick={() => url(myExtraLink)}>
                        <MyTooltip
                            id="ExtraLinkTooltop"
                            text={myExtraLink.linkText}
                            iconDiv={false}
                            divWidth="35px"
                            marginLeft="0"
                            marginRight="auto"
                            icon={myIcon(myExtraLink)}
                        ></MyTooltip>
                    </Nav.Link>
                </Nav.Item>
            );
        } else {
            return null;
        }
    };

    return (
        <>
            {apstate.extraLinks &&
            state.extraLinks &&
            state.extraLinks.length < 1 /* extraLinks from appProperties only before Login */ ? (
                <>{linkList(apstate.extraLinks)}</>
            ) : null}
            {state.extraLinks /* extraLinks from authorize after Login */ ? <>{linkList(state.extraLinks)}</> : null}
        </>
    );
};

export default ExtraLinks;
