import moment from 'moment';
import { useState } from 'react';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { Bar, Line } from 'react-chartjs-2';
import useScreenResolution from '../../../hooks/useScreenResolution';
import {
    chartSettingsForDevice,
    getChartOptionsData,
    getDatasetData,
    handleDisplayControllers,
    IChartOptions,
    IChartProps,
} from '../../../utils/chartUtils';
import { ToggleButtonGroupFlex } from '../../Buttons/ToggleButtonGroupCst';
import { ChartInnerDiv, ChartOuterDiv } from '../../Div/StyledDiv';

const OneYearView = (props: IChartProps) => {
    const [xMin, setXMin] = useState(
        moment(props.chartData.data[props.chartData.data.length - 1].t)
            .clone()
            .startOf('year'),
    );
    const [xMax, setXMax] = useState(
        moment(props.chartData.data[props.chartData.data.length - 1].t)
            .clone()
            .endOf('year'),
    );

    const screenSize = useScreenResolution();

    const chartSettings = chartSettingsForDevice('oneYear', screenSize.width, screenSize.height);

    // get year of first and last weight data entry
    const firstYear = props.chartData.data[0].t.year();
    const lastYear = props.chartData.data[props.chartData.data.length - 1].t.year();

    // create a list of objects with the years and an index for the buttons
    const yearList: Array<{ name: number; value: number }> = [];
    let index = 0;
    for (let year = firstYear; year <= lastYear; year++) {
        yearList.push({ name: year, value: index });
        index++;
    }

    const datasetProps = {
        chartProps: props,
        barPercentage: 2,
    };

    const [datasets, labelsToDisplay] = getDatasetData(datasetProps);
    const data = {
        labels: labelsToDisplay, // labels to be displayed by the datalabels.formatter
        datasets: datasets,
    };

    const chartOptionsProps: IChartOptions = {
        chartProps: props,
        ticksXMin: xMin,
        ticksXMax: xMax,
        ticksLabelOffset: 0,
        chartSettings: chartSettings,
        updateLimits: (e) => updateLimits(e),
    };

    const options = getChartOptionsData(chartOptionsProps);

    function updateLimits(context: any) {
        if (!props.mobileProps?.sessionId) {
            setXMin(context.chart.scales['x-axis-0'].min);
            setXMax(context.chart.scales['x-axis-0'].max);
        }
    }

    const [radioValue, setRadioValue] = useState(yearList[yearList.length - 1].value);

    const handleYearChange = (selectedYearVal: React.SetStateAction<number>) => {
        setRadioValue(selectedYearVal);
        if (yearList) {
            const yearName = yearList.find((i) => i.value === selectedYearVal)?.name;
            if (yearName) {
                setXMin(moment([yearName]).clone().startOf('year'));
                setXMax(moment([yearName]).clone().endOf('year'));
            }
        }
    };

    const YearOptions = () => {
        const allYearOptions: any[] = [];
        {
            yearList.map((year, idx) => {
                allYearOptions.push(
                    <React.Fragment key={idx}>
                        <ToggleButton
                            id={'mob_year_' + year.name}
                            key={idx}
                            type="radio"
                            variant="light"
                            name="radio"
                            value={year.value}
                            checked={radioValue === year.value}
                            onChange={() => handleYearChange(year.value)}
                        >
                            {year.name}
                        </ToggleButton>
                    </React.Fragment>,
                );
            });
        }

        return (
            <ToggleButtonGroupFlex id="mob_yearController" type="radio" name="yearOption" defaultValue={radioValue}>
                {allYearOptions}
            </ToggleButtonGroupFlex>
        );
    };

    const MyLine = () => {
        if (props.mobileProps?.height !== undefined) {
            return (
                <ChartOuterDiv style={{ height: props.mobileProps?.height }}>
                    <ChartInnerDiv>
                        <Line
                            redraw={true}
                            data={data}
                            options={options}
                            width={props.mobileProps?.width}
                            height={props.mobileProps?.height}
                        />
                    </ChartInnerDiv>
                </ChartOuterDiv>
            );
        } else {
            return (
                <ChartOuterDiv>
                    <ChartInnerDiv>
                        <Line redraw={true} data={data} options={options} height={chartSettings.height} />
                    </ChartInnerDiv>
                </ChartOuterDiv>
            );
        }
    };

    const MyBar = () => {
        if (props.mobileProps?.height !== undefined) {
            return (
                <ChartOuterDiv style={{ height: props.mobileProps?.height }}>
                    <ChartInnerDiv>
                        <Bar
                            redraw={true}
                            data={data}
                            options={options}
                            width={props.mobileProps?.width}
                            height={props.mobileProps?.height}
                        />
                    </ChartInnerDiv>
                </ChartOuterDiv>
            );
        } else {
            return (
                <ChartOuterDiv>
                    <ChartInnerDiv>
                        <Bar redraw={true} data={data} options={options} height={chartSettings.height} />
                    </ChartInnerDiv>
                </ChartOuterDiv>
            );
        }
    };

    return (
        <div>
            <Row style={{ display: props?.mobileProps && handleDisplayControllers(props.mobileProps) }}>
                <Col style={{ paddingBottom: '5px' }}>
                    <YearOptions />
                </Col>
            </Row>
            {props.displayOption === 1 ? <MyLine /> : <MyBar />}
        </div>
    );
};

export default OneYearView;
