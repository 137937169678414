import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

interface IModalCst {
    fullScreen?: boolean;
}

export const ModalCst = styled(Modal)<IModalCst>`
    .modal-dialog {
        max-width: ${(props) => (props.fullScreen ? '100%' : '90%')};
        max-height: ${(props) => (props.fullScreen ? '100%' : '93%')};
        margin: ${(props) => (props.fullScreen ? '0' : '1.75rem auto')};
        height: ${(props) => (props.fullScreen ? '100%' : '93%')};
        width: ${(props) => (props.fullScreen ? '100%' : null)};
    }
    .modal-content {
        min-height: ${(props) => (props.fullScreen ? '100%' : '93%')};
        border-radius: ${(props) => props.theme.modal.borderRadius};
        box-shadow: ${(props) => props.theme.modal.boxShadow};
    }

    .modal-body {
        /* min-height: ${(props) => (props.fullScreen ? '100%' : '93%')}; */
        ms-overflow-style: none;
        scrollbar-width: none;
    }
`;

export interface IEmbeddedPDFInModal {
    show: boolean;
    modalTitle: string;
    onHide(): void;
    fullScreen?: boolean;
    children: React.ReactNode;
}

const EmbeddedPDFInModal = (props: IEmbeddedPDFInModal) => {
    return (
        <>
            <ModalCst show={props.show} fullScreen={props.fullScreen === false ? false : true} scrollable>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: '1rem' }}>{props.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body id="embeddedPdfInModalBody">{props.children}</Modal.Body>
            </ModalCst>
        </>
    );
};

export default EmbeddedPDFInModal;
