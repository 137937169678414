import { useContext, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { ThemeContext } from 'styled-components';
import StyledButton from '../../components/Buttons/StyledButton';
import { RightDiv } from '../../components/Div/StyledDiv';
import { StyledRowNoMargin } from '../../components/Rows/StyledRow';
import {
    StyledFormGroupMaxWidth,
    StyledFormLabelForInput,
    StyledFormRowNoMargin,
} from '../../components/StyledForm/SyledForm';
import { IHints, IHintsAnswers, IHintsFragen } from '../../context/terminierungContext';
import useScreenResolution from '../../hooks/useScreenResolution';
import { isBootstrap_md } from '../../utils/screenResolution';

interface IAppointmentCategoryReasonHints {
    hints: IHints;
    onHintsSubmit: (answers: IHintsAnswers[]) => void;
}

interface IHintTypeEleBoolean {
    question: IHintsFragen;
    setHintAnswerBoolean: (linkId: string, answer: boolean) => void;
    getHintAnswerBoolean: (linkId: string) => boolean;
}

interface IHintTypeEleText {
    question: IHintsFragen;
    setHintAnswerText: (linkId: string, answer: string) => void;
    getHintAnswerText: (linkId: string) => string | undefined;
}

interface IHintTypeEleNumeric {
    question: IHintsFragen;
    setHintAnswerNumeric: (linkId: string, answer: string) => void;
    getHintAnswerNumeric: (linkId: string) => string | undefined;
}

const label = (mandatory: boolean, labelName: string) => {
    return (
        <div>
            {mandatory ? (
                <div>
                    <StyledFormLabelForInput>{labelName}*</StyledFormLabelForInput>
                </div>
            ) : (
                <div>
                    <StyledFormLabelForInput>{labelName}</StyledFormLabelForInput>
                </div>
            )}
        </div>
    );
};

const getNumericRegex = (precision?: number) => {
    if (precision && precision > 0) {
        return '^\\d{1,99}([.,]{1}\\d{' + precision + ',' + precision + '}){1}?$';
    } else {
        return '^\\d{1,99}?$';
    }
};

const CheckboxTypeEle = (props: IHintTypeEleBoolean) => {
    return (
        <>
            <br />
            <StyledFormRowNoMargin>
                <StyledFormGroupMaxWidth controlId={props.question.linkId}>
                    <Form.Check>
                        <Form.Check.Input
                            required={props.question.mandatory ? props.question.mandatory : false}
                            type={'checkbox'}
                            id={`${props.question.linkId}`}
                            checked={props.getHintAnswerBoolean(props.question.linkId)}
                            onChange={() =>
                                props.setHintAnswerBoolean(
                                    props.question.linkId,
                                    !props.getHintAnswerBoolean(props.question.linkId),
                                )
                            }
                        />
                        <Form.Check.Label>
                            {label(props.question.mandatory ? props.question.mandatory : false, props.question.label)}
                        </Form.Check.Label>
                        <Form.Control.Feedback type="invalid">Pflichtfeld</Form.Control.Feedback>
                    </Form.Check>
                </StyledFormGroupMaxWidth>
            </StyledFormRowNoMargin>
        </>
    );
};

const TextTypeEle = (props: IHintTypeEleText) => {
    return (
        <>
            <br />
            <StyledFormRowNoMargin>
                <StyledFormGroupMaxWidth controlId={props.question.linkId}>
                    <Form.Label>
                        {label(props.question.mandatory ? props.question.mandatory : false, props.question.label)}
                    </Form.Label>
                    <Form.Control
                        required={props.question.mandatory ? props.question.mandatory : false}
                        type="text"
                        placeholder=""
                        value={props.getHintAnswerText(props.question.linkId) || ''}
                        onChange={(e) => props.setHintAnswerText(props.question.linkId, e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">Pflichtfeld</Form.Control.Feedback>
                </StyledFormGroupMaxWidth>
            </StyledFormRowNoMargin>
        </>
    );
};

const NumericTypeEle = (props: IHintTypeEleNumeric) => {
    let regex = '';
    let feedbackMsg = '';

    if (props.question.mandatory) {
        feedbackMsg = 'Pflichtfeld';
    }

    if (props.question.precision && props.question.precision > 0) {
        regex = getNumericRegex(props.question.precision);
        let preFeedbackMsg = '';
        if (props.question.mandatory) {
            preFeedbackMsg = 'Pflichtfeld: ';
        }
        feedbackMsg =
            preFeedbackMsg + 'Bitte geben Sie eine Zahl mit ' + props.question.precision + ' Nachkommastellen ein.';
    } else {
        let preFeedbackMsg = '';
        if (props.question.mandatory) {
            preFeedbackMsg = 'Pflichtfeld: ';
        }
        regex = getNumericRegex();
        feedbackMsg = preFeedbackMsg + 'Bitte geben Sie eine ganzzahlige Zahl ein.';
    }

    return (
        <>
            <br />
            <StyledFormRowNoMargin>
                <StyledFormGroupMaxWidth controlId={props.question.linkId}>
                    <Form.Label>
                        {label(props.question.mandatory ? props.question.mandatory : false, props.question.label)}
                    </Form.Label>
                    <Form.Control
                        required={props.question.mandatory ? props.question.mandatory : false}
                        type="text"
                        pattern={regex}
                        placeholder=""
                        value={props.getHintAnswerNumeric(props.question.linkId) || ''}
                        onChange={(e) => props.setHintAnswerNumeric(props.question.linkId, e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">{feedbackMsg}</Form.Control.Feedback>
                </StyledFormGroupMaxWidth>
            </StyledFormRowNoMargin>
        </>
    );
};

const AppointmentCategoryReasonHints = (props: IAppointmentCategoryReasonHints) => {
    const [validated, setValidated] = useState(false);
    const [answers, setAnswers] = useState<IHintsAnswers[]>([]);
    const themeContext = useContext(ThemeContext);
    const screenSize = useScreenResolution();

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setValidated(true);

        if (validate()) {
            props.onHintsSubmit(answers);
        }
    };

    const validate = () => {
        if (props.hints.Fragen && props.hints.Fragen.length > 0) {
            for (let i = 0; i < props.hints.Fragen.length; i++) {
                const frage = props.hints.Fragen[i];
                if (frage.type === 'checkbox') {
                    const booleanVal = getHintAnswerBoolean(frage.linkId);
                    if (frage.mandatory) {
                        if (booleanVal === undefined || booleanVal === false) {
                            return false;
                        }
                    }
                } else if (frage.type === 'text') {
                    const text = getHintAnswerText(frage.linkId);
                    if (frage.mandatory) {
                        if (text === undefined || text.length <= 0) {
                            return false;
                        }
                    }
                } else if (frage.type === 'numeric') {
                    const numeric = getHintAnswerNumeric(frage.linkId);
                    if (frage.mandatory) {
                        if (numeric === undefined || numeric.length <= 0) {
                            return false;
                        }
                    }

                    if (numeric) {
                        if (frage.precision && frage.precision > 0) {
                            const regex = new RegExp(getNumericRegex(frage.precision));
                            if (!regex.test(numeric)) {
                                return false;
                            }
                        } else {
                            const regex = new RegExp(getNumericRegex());
                            if (!regex.test(numeric)) {
                                return false;
                            }
                        }
                    }
                }
            }
        }

        return true;
    };

    const showHinweis = () => {
        if (props.hints.Hinweis && props.hints.Hinweis.length > 0) {
            return true;
        }

        return false;
    };

    const setHintAnswerBoolean = (linkId: string, answer: boolean) => {
        const foundAnswer = answers?.find((a: IHintsAnswers) => {
            if (a.linkId === linkId) {
                return true;
            }
        });
        if (foundAnswer) {
            /* update valueBoolean */
            const newAnswers = answers.map((a: IHintsAnswers) => {
                if (a.linkId === linkId) {
                    return { ...a, valueBoolean: answer };
                }
                return a;
            });
            setAnswers(newAnswers);
        } else {
            /* make new entry*/
            setAnswers([...answers, { linkId: linkId, valueBoolean: answer }]);
        }
    };

    const getHintAnswerBoolean = (linkId: string): boolean => {
        const foundAnswer = answers?.find((a: IHintsAnswers) => {
            if (a.linkId === linkId) {
                return true;
            }
        });
        if (foundAnswer !== undefined) {
            if (foundAnswer.valueBoolean !== undefined) {
                return foundAnswer.valueBoolean;
            }
        }

        return false;
    };

    const setHintAnswerText = (linkId: string, answer: string) => {
        const foundAnswer = answers?.find((a: IHintsAnswers) => {
            if (a.linkId === linkId) {
                return true;
            }
        });
        if (foundAnswer) {
            /* update valueString */
            const newAnswers = answers.map((a: IHintsAnswers) => {
                if (a.linkId === linkId) {
                    return { ...a, valueString: answer };
                }
                return a;
            });
            setAnswers(newAnswers);
        } else {
            /* make new entry*/
            setAnswers([...answers, { linkId: linkId, valueString: answer }]);
        }
    };

    const getHintAnswerText = (linkId: string): string | undefined => {
        const foundAnswer = answers?.find((a: IHintsAnswers) => {
            if (a.linkId === linkId) {
                return true;
            }
        });
        if (foundAnswer !== undefined) {
            if (foundAnswer.valueString !== undefined) {
                return foundAnswer.valueString;
            }
        }
    };

    const setHintAnswerNumeric = (linkId: string, answer: string) => {
        const foundAnswer = answers?.find((a: IHintsAnswers) => {
            if (a.linkId === linkId) {
                return true;
            }
        });
        if (foundAnswer) {
            /* update valueString */
            const newAnswers = answers.map((a: IHintsAnswers) => {
                if (a.linkId === linkId) {
                    return { ...a, valueNumber: answer };
                }
                return a;
            });
            setAnswers(newAnswers);
        } else {
            /* make new entry*/
            setAnswers([...answers, { linkId: linkId, valueNumber: answer }]);
        }
    };

    const getHintAnswerNumeric = (linkId: string): string | undefined => {
        const foundAnswer = answers?.find((a: IHintsAnswers) => {
            if (a.linkId === linkId) {
                return true;
            }
        });
        if (foundAnswer !== undefined) {
            if (foundAnswer.valueNumber !== undefined) {
                return foundAnswer.valueNumber;
            }
        }
    };

    const showQuestions = () => {
        const allQuestions: any[] = [];
        if (props.hints.Fragen && props.hints.Fragen.length > 0) {
            props.hints.Fragen.forEach((q: IHintsFragen) => {
                if (q.type === 'checkbox') {
                    allQuestions.push(
                        <CheckboxTypeEle
                            question={q}
                            getHintAnswerBoolean={(linkId: string) => getHintAnswerBoolean(linkId)}
                            setHintAnswerBoolean={setHintAnswerBoolean}
                        />,
                    );
                } else if (q.type === 'text') {
                    allQuestions.push(
                        <TextTypeEle
                            question={q}
                            getHintAnswerText={(linkId: string) => getHintAnswerText(linkId)}
                            setHintAnswerText={setHintAnswerText}
                        />,
                    );
                } else if (q.type === 'numeric') {
                    allQuestions.push(
                        <NumericTypeEle
                            question={q}
                            getHintAnswerNumeric={(linkId: string) => getHintAnswerNumeric(linkId)}
                            setHintAnswerNumeric={setHintAnswerNumeric}
                        />,
                    );
                }
            });
        }

        return allQuestions;
    };

    return (
        <>
            <br />
            <br />
            {showHinweis() && (
                <>
                    <StyledRowNoMargin>
                        <Col xs={12} sm={12} md={6} style={{ backgroundColor: themeContext.hint.backgroundColor }}>
                            <Form.Row>
                                <Form.Label>
                                    <b>Hinweis</b>
                                </Form.Label>
                            </Form.Row>
                            <Form.Row>
                                <p>{props.hints.Hinweis}</p>
                            </Form.Row>
                        </Col>
                    </StyledRowNoMargin>
                </>
            )}
            <Row style={{ marginBottom: '40px' }}>
                <Col xs={12} sm={12} md={6} style={{ paddingRight: isBootstrap_md(screenSize.width) ? 0 : 15 }}>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        {showQuestions()}
                        <RightDiv>
                            <StyledButton variant="primary" type="submit">
                                Weiter
                            </StyledButton>
                        </RightDiv>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default AppointmentCategoryReasonHints;
