import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import SAMCharts from '../../../components/Charts/SAM/SAMCharts';
import { containerHeader } from '../../../components/Header/Header';
import Mainscreen from '../../Mainscreen/Mainscreen';

const ChartsSAMContent = () => {
    return (
        <Container fluid>
            <SAMCharts />
        </Container>
    );
};

const ChartsSAM = () => {
    return (
        <Mainscreen>
            <Container fluid>
                <Row>
                    <Col>
                        {containerHeader('SAM')}
                        <ChartsSAMContent />
                    </Col>
                </Row>
            </Container>
        </Mainscreen>
    );
};

export default ChartsSAM;
