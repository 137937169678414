import { useEffect } from 'react';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { CenterDiv, VerticalCenterDiv } from '../../components/Div/StyledDiv';
import { Paths } from '../../Routes';

const LoggedOutSplashscreen = () => {
    const navigation = useNavigate();
    const navtype = useNavigationType();
    /* avoid browser back button click */
    useEffect(() => {
        return () => {
            if (navtype === 'POP') {
                navigation(Paths.SPLASHSCREEN_LOGGEDOUT);
            }
        };
    }, [history]);

    return (
        <div>
            <VerticalCenterDiv>
                <CenterDiv>
                    <h1>Sie wurden abgemeldet</h1>
                </CenterDiv>
            </VerticalCenterDiv>
        </div>
    );
};

export default LoggedOutSplashscreen;
