import { Container } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';
import LoginContext from '../../context/loginContext';
import { getQRCodeConfig } from '../../globals/global';
import { getBarcodeForApp } from '../../services/RestServices';
import { StyledMainCardTitle, StyledProfileCard } from '../Cards/StyledCard';

const QRCodeForApp = () => {
    const { state } = useContext(LoginContext);

    const [qrCode, setQrCode] = useState('');
    const [applinksURL, setAppLinksURL] = useState('');

    useEffect(() => {
        const getQRCodes4App = async () => {
            const response = await getBarcodeForApp(state.sessionId);

            const qrCodeConfig = getQRCodeConfig();
            const qrCodeData = response[qrCodeConfig];
            // let applinksURL = '';

            if (qrCodeConfig === 'pngSSG') {
                setAppLinksURL(response['applinksURLSSG']);
            } else if (qrCodeConfig === 'pngPOI') {
                setAppLinksURL(response['applinksURLPOI']);
            }

            if (qrCodeData) {
                setQrCode(qrCodeData);
            } else {
                console.log('cannot find qrcode with config: ', qrCodeConfig);
            }
        };
        getQRCodes4App();
    }, []);

    const qrCodeSrc = 'data:image/jpg;base64,' + qrCode;

    const handleQRClick = () => {
        window.open(applinksURL, '_self');
    };

    return (
        <Row style={{ height: '100%' }}>
            <Col style={{ display: 'flex' }}>
                <StyledProfileCard>
                    <Card.Body>
                        <StyledMainCardTitle>Die App für Ihr Handy</StyledMainCardTitle>
                        <Container style={{ marginTop: '35px' }}>
                            <Row xs={1} style={{ paddingTop: 10 }}>
                                <Col style={{ paddingTop: 20 }} onClick={handleQRClick}>
                                    <div>
                                        <OverlayTrigger
                                            placement="bottom"
                                            transition={false}
                                            overlay={
                                                <Tooltip id={`qrCode-tooltip`}>
                                                    Öffnen Sie die <strong>Kamera-App</strong> Ihres Gerätes und richten
                                                    Sie den Sucher auf diesen QR-Code.
                                                </Tooltip>
                                            }
                                        >
                                            <Image
                                                src={qrCodeSrc}
                                                thumbnail
                                                style={{
                                                    height: 150,
                                                    width: 150,
                                                    display: 'block',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto',
                                                }}
                                            />
                                        </OverlayTrigger>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </StyledProfileCard>
            </Col>
        </Row>
    );
};

export default QRCodeForApp;
