import { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import LoginContext from '../../context/loginContext';
import { PROFILE_HOME } from '../../globals/global';
import { useProfileSwitch } from '../../hooks/useProfileSwitch';
import { setActivePatId } from '../../services/RestServices';
import StyledButton from '../Buttons/StyledButton';

const ProfileSwitch = () => {
    const [allProfiles, setAllProfiles] = useState<any>();

    const { state, dispatch } = useContext(LoginContext);

    const { setSecurityItems, getUserId } = useProfileSwitch();

    useEffect(() => {
        getDropdownItems();
    }, []);

    const handleProfileSwitch = async (profileName: string) => {
        const activeUserId = getUserId(profileName);

        dispatch({
            type: 'SETACTIVEUSERID',
            activeUserId: activeUserId,
        });

        dispatch({
            type: 'SETACTIVEPROFILE',
            activeProfile: profileName,
        });
        dispatch({
            type: 'SETOFINITPAGEDONE',
            setOfInitPageDone: false,
        });

        setSecurityItems(profileName);
        const changeRes = await setActivePatId(state.sessionId, activeUserId);
        if (!changeRes.SUCCESS) {
            // console.log('profile change NOT successful');
        }
    };

    const getDropdownItems = () => {
        const allItems: any[] = [];

        if (Array.isArray(state.activeStudies) && state.activeStudies.length) {
            state.activeStudies.forEach((study: any) => {
                allItems.push(
                    <Dropdown.Item key={study.studyName} onClick={() => handleProfileSwitch(study.studyName)}>
                        {study.studyName}
                    </Dropdown.Item>,
                );
            });

            allItems.push(
                <Dropdown.Item key={PROFILE_HOME} onClick={() => handleProfileSwitch(PROFILE_HOME)}>
                    {PROFILE_HOME}
                </Dropdown.Item>,
            );
        } else {
            allItems.push(
                <Dropdown.Item key={PROFILE_HOME} onClick={() => handleProfileSwitch(PROFILE_HOME)}>
                    {PROFILE_HOME}
                </Dropdown.Item>,
            );
        }

        setAllProfiles(allItems);
    };

    return (
        <Dropdown>
            <Dropdown.Toggle as={StyledButton} variant="secondary" margintop="1px" id="dropdown-basic">
                {state.activeProfile}
            </Dropdown.Toggle>
            <Dropdown.Menu>{allProfiles}</Dropdown.Menu>
        </Dropdown>
    );
};

export default ProfileSwitch;
