import { Placement } from 'react-bootstrap/esm/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { IconDiv } from '../Div/StyledDiv';

interface ITooltip {
    id: string;
    text: string;
    iconDiv: boolean;
    divWidth?: string;
    marginLeft?: string;
    marginRight?: string;
    // icon: IconBaseProps;
    icon: any;
    placement?: Placement;
}

const MyTooltip = (props: ITooltip) => {
    let placement: Placement = 'bottom';
    if (props.placement && placement.length > 0) {
        placement = props.placement;
    }

    if (props.iconDiv) {
        return (
            <OverlayTrigger key="bottom" placement={placement} overlay={<Tooltip id={props.id}>{props.text}</Tooltip>}>
                <IconDiv
                    id="icondiv"
                    divWidth={props.divWidth}
                    marginLeft={props.marginLeft}
                    marginRight={props.marginRight}
                >
                    {props.icon}
                </IconDiv>
            </OverlayTrigger>
        );
    } else {
        return (
            <OverlayTrigger key="bottom" placement={placement} overlay={<Tooltip id={props.id}>{props.text}</Tooltip>}>
                {props.icon}
            </OverlayTrigger>
        );
    }
};

export default MyTooltip;
