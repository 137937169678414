type IPhyscianSelectionDescriptionList = {
    description?: string[];
    firstFreeDateElement?: JSX.Element;
};

const PhysicianSelectionDescriptionList = (props: IPhyscianSelectionDescriptionList) => {
    const descriptionList = () => {
        if (props.description && props.description.length > 0) {
            const allDescrItems: any[] = [];
            props.description?.forEach((e) => {
                // allDescrItems.push(<li>{e}</li>);
                allDescrItems.push(<p dangerouslySetInnerHTML={{ __html: e }}></p>);
            });

            if (props.firstFreeDateElement) {
                allDescrItems.push(props.firstFreeDateElement);
            }

            return allDescrItems;
        }

        return null;
    };

    return <>{descriptionList()}</>;
};

export default PhysicianSelectionDescriptionList;
