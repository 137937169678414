import Toast from 'react-bootstrap/Toast';
import Lottie from 'react-lottie';
import { lottieCheck } from '../../utils/lottieUtils';

export interface IToast {
    show: boolean;
    onHide: () => void;
    text: string;
    delay: number;
    width?: string;
    height?: string;
}

const ConfirmationToast = (props: IToast) => {
    return (
        <Toast
            style={{
                backgroundColor: '#D4ECDC',
                borderRadius: 10,
                width: props.width ? props.width : '250px',
                height: props.height ? props.height : '110px',
            }}
            onClose={props.onHide}
            show={props.show}
            delay={props.delay}
            autohide
        >
            <Toast.Body style={{ color: '#367D85', fontSize: 18, width: props.width }}>
                <Lottie options={lottieCheck} height={40} width={40} />
                <div>{props.text}</div>
            </Toast.Body>
        </Toast>
    );
};

export default ConfirmationToast;
