import { Formik } from 'formik';
import produce from 'immer';
import moment from 'moment';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import * as yup from 'yup';
import { StyledFormLabelForInput } from '../../components/StyledForm/SyledForm';
import AppPropsContext, { IRegistrationFields } from '../../context/appPropsContext';
import CheckInContext from '../../context/checkInContext';
import LoginContext, { IPossibleStudies } from '../../context/loginContext';
import TerminierungContext from '../../context/terminierungContext';
import { useGoogleAnalytics } from '../../hooks/useGoogleAnalytics';
import { usePersonalData } from '../../hooks/usePersonalData';
import { IPlannedStudies } from '../../services/fhir/FHIRPatientResource';
import { getGenderDE, pattern } from '../../utils/pcpUtils';
import { Default, Mobile } from '../../utils/screenResolution';
import StyledButton from '../Buttons/StyledButton';
import StyledCol from '../Cols/StyledCol';
import { LeftDiv } from '../Div/StyledDiv';
import { StyledRowPersonalDataBottom } from '../Rows/StyledRow';
import './AppointmentPersonalData.css';
import { useAppProperties } from '../../hooks/useAppProperties';

export interface IAppointmentPersonalData {
    handleNext?: any;
    handleNextWithActiveStep?(currentActiveStep: number): void;
    handleBack?: any;
    handleCancel?: any;
    directRegistration?: boolean;
    currentActiveStep?: number;
    showCancelButton?: boolean;
    showBackButton?: boolean;
    showRegistrationCbx?: boolean;
    nextButtonEnabled?: boolean;
    showNextButton?: boolean;
    isPhysPat?: boolean;
    showPrivacyPolicyCbx?: boolean;
}

const AppointmentPersonalData = (props: IAppointmentPersonalData) => {
    const { state } = useContext(LoginContext);
    const { tmstate, tmdispatch } = useContext(TerminierungContext);
    const { checkinstate } = useContext(CheckInContext);
    const { apstate } = useContext(AppPropsContext);
    const [allCurrentStudies, setAllCurrentStudies] = useState<IPlannedStudies[]>();
    const [mandFields, setMandFields] = useState<IRegistrationFields>({
        birthDate: apstate.RegistrationMandFields.birthDate,
        city: apstate.RegistrationMandFields.city,
        country: apstate.RegistrationMandFields.country,
        email: apstate.RegistrationMandFields.email,
        firstName: apstate.RegistrationMandFields.firstName,
        gender: apstate.RegistrationMandFields.gender,
        lastName: apstate.RegistrationMandFields.lastName,
        phone: apstate.RegistrationMandFields.phone,
        street: apstate.RegistrationMandFields.street,
        zip: apstate.RegistrationMandFields.zip,
        insuranceNr: apstate.RegistrationMandFields.insuranceNr,
        mobilePhone: apstate.RegistrationMandFields.mobilePhone,
        academicTitle: apstate.RegistrationMandFields.academicTitle,
        insuranceName: apstate.RegistrationMandFields.insuranceName,
        language: apstate.RegistrationMandFields.language,
        privacyTerms: apstate.RegistrationMandFields.language,
    });

    const [cbxRegistrationSelected, setCbxRegistrationSelected] = useState(false);
    const [cbxPrivacyPolicySelected, setCbxPrivacyPolicySelected] = useState(false);

    const { getPlannedStudyParticipation } = usePersonalData();
    const { sendGoogleAnalyticsPageView } = useGoogleAnalytics();
    const { getPossibleCountries } = useAppProperties();

    useEffect(() => {
        sendGoogleAnalyticsPageView({ page: '/AppointmentPersonalData', title: 'Personendaten eingeben' });

        const allStudies: IPlannedStudies[] = [];

        state.possibleStudies.forEach((study) => {
            const code = study.code;
            const display = study.display;
            const participated = getPlannedStudyParticipation(code);
            allStudies.push({
                code: code,
                display: display,
                participate: participated,
            });
        });

        /* get the inital values for the checkboxes */
        setAllCurrentStudies(allStudies);

        /* override mand fields */
        if (props.isPhysPat) {
            setMandFields({
                ...mandFields,
                email: false,
            });
        }
    }, []);

    const setInsuranceNr = (value: string) => {
        if (props.isPhysPat) {
            tmdispatch({
                type: 'SETPERSONALDATA_PHYSPAT_INSURANCENR',
                personalDataPhysPat: {
                    insuranceNr: value,
                },
            });
        } else {
            tmdispatch({
                type: 'SETPERSONALDATA_INSURANCENR',
                personalData: {
                    insuranceNr: value,
                },
            });
        }
    };

    const setInsuranceName = (value: string) => {
        if (props.isPhysPat) {
            tmdispatch({
                type: 'SETPERSONALDATA_PHYSPAT_INSURANCENAME',
                personalDataPhysPat: {
                    insuranceName: value,
                },
            });
        } else {
            tmdispatch({
                type: 'SETPERSONALDATA_INSURANCENAME',
                personalData: {
                    insuranceName: value,
                },
            });
        }
    };

    const setLanguage = (value: string) => {
        if (props.isPhysPat) {
            tmdispatch({
                type: 'SETPERSONALDATA_PHYSPAT_LANGUAGE',
                personalDataPhysPat: {
                    language: value,
                },
            });
        } else {
            tmdispatch({
                type: 'SETPERSONALDATA_LANGUAGE',
                personalData: {
                    language: value,
                },
            });
        }
    };

    const setMobilePhone = (value: string) => {
        if (props.isPhysPat) {
            tmdispatch({
                type: 'SETPERSONALDATA_PHYSPAT_MOBILEPHONE',
                personalDataPhysPat: {
                    mobilePhone: value,
                },
            });
        } else {
            tmdispatch({
                type: 'SETPERSONALDATA_MOBILEPHONE',
                personalData: {
                    mobilePhone: value,
                },
            });
        }
    };

    const setAcademicTitle = (value: string) => {
        if (props.isPhysPat) {
            tmdispatch({
                type: 'SETPERSONALDATA_PHYSPAT_ACADEMICTITLE',
                personalDataPhysPat: {
                    academicTitle: value,
                },
            });
        } else {
            tmdispatch({
                type: 'SETPERSONALDATA_ACADEMICTITLE',
                personalData: {
                    academicTitle: value,
                },
            });
        }
    };

    const setFirstName = (value: string) => {
        if (props.isPhysPat) {
            tmdispatch({
                type: 'SETPERSONALDATA_PHYSPAT_FIRSTNAME',
                personalDataPhysPat: {
                    firstName: value,
                },
            });
        } else {
            tmdispatch({
                type: 'SETPERSONALDATA_FIRSTNAME',
                personalData: {
                    firstName: value,
                },
            });
        }
    };

    const setLastName = (value: string) => {
        if (props.isPhysPat) {
            tmdispatch({
                type: 'SETPERSONALDATA_PHYSPAT_LASTNAME',
                personalDataPhysPat: {
                    lastName: value,
                },
            });
        } else {
            tmdispatch({
                type: 'SETPERSONALDATA_LASTNAME',
                personalData: {
                    lastName: value,
                },
            });
        }
    };

    const setBirthday = (value: string) => {
        let dateFormated = '';

        if (value) {
            dateFormated = moment(value).format('DD.MM.yyyy');
        }

        if (props.isPhysPat) {
            tmdispatch({
                type: 'SETPERSONALDATA_PHYSPAT_BIRTHDAY',
                personalDataPhysPat: {
                    birthday: dateFormated,
                },
            });
        } else {
            tmdispatch({
                type: 'SETPERSONALDATA_BIRTHDAY',
                personalData: {
                    birthday: dateFormated,
                },
            });
        }
    };

    const formatBirthdayForFormik = (birthday: string) => {
        if (moment(birthday, 'dd.MM.yyyy', true).isValid()) {
            const dateFormated = moment(birthday).format('YYYY-DD-MM');
            console.log('dateFormated: ' + dateFormated);
            return dateFormated;
        } else {
            return birthday;
        }
    };

    const setGender = (value: string) => {
        if (props.isPhysPat) {
            tmdispatch({
                type: 'SETPERSONALDATA_PHYSPAT_GENDER',
                personalDataPhysPat: {
                    gender: value,
                },
            });
        } else {
            tmdispatch({
                type: 'SETPERSONALDATA_GENDER',
                personalData: {
                    gender: value,
                },
            });
        }
    };

    const setEmail = (value: string) => {
        if (props.isPhysPat) {
            tmdispatch({
                type: 'SETPERSONALDATA_PHYSPAT_EMAIL',
                personalDataPhysPat: {
                    email: value,
                },
            });
        } else {
            tmdispatch({
                type: 'SETPERSONALDATA_EMAIL',
                personalData: {
                    email: value,
                },
            });
        }
    };

    const setTelephone = (value: string) => {
        if (props.isPhysPat) {
            tmdispatch({
                type: 'SETPERSONALDATA_PHYSPAT_TELEPHONE',
                personalDataPhysPat: {
                    telephone: value,
                },
            });
        } else {
            tmdispatch({
                type: 'SETPERSONALDATA_TELEPHONE',
                personalData: {
                    telephone: value,
                },
            });
        }
    };

    const setStreet = (value: string) => {
        if (props.isPhysPat) {
            tmdispatch({
                type: 'SETPERSONALDATA_PHYSPAT_STREET',
                personalDataPhysPat: {
                    street: value,
                },
            });
        } else {
            tmdispatch({
                type: 'SETPERSONALDATA_STREET',
                personalData: {
                    street: value,
                },
            });
        }
    };

    const setHouseNr = (value: string) => {
        if (props.isPhysPat) {
            tmdispatch({
                type: 'SETPERSONALDATA_PHYSPAT_HOUSENR',
                personalDataPhysPat: {
                    housenr: value,
                },
            });
        } else {
            tmdispatch({
                type: 'SETPERSONALDATA_HOUSENR',
                personalData: {
                    housenr: value,
                },
            });
        }
    };

    const setCity = (value: string) => {
        if (props.isPhysPat) {
            tmdispatch({
                type: 'SETPERSONALDATA_PHYSPAT_CITY',
                personalDataPhysPat: {
                    city: value,
                },
            });
        } else {
            tmdispatch({
                type: 'SETPERSONALDATA_CITY',
                personalData: {
                    city: value,
                },
            });
        }
    };

    const setZip = (value: string) => {
        if (props.isPhysPat) {
            tmdispatch({
                type: 'SETPERSONALDATA_PHYSPAT_ZIP',
                personalDataPhysPat: {
                    zip: value,
                },
            });
        } else {
            tmdispatch({
                type: 'SETPERSONALDATA_ZIP',
                personalData: {
                    zip: value,
                },
            });
        }
    };

    const setCountry = (value: string) => {
        if (props.isPhysPat) {
            tmdispatch({
                type: 'SETPERSONALDATA_PHYSPAT_COUNTRY',
                personalDataPhysPat: {
                    country: value,
                },
            });
        } else {
            tmdispatch({
                type: 'SETPERSONALDATA_COUNTRY',
                personalData: {
                    country: value,
                },
            });
        }
    };

    const setUsername = (value: string) => {
        if (props.isPhysPat) {
            if (cbxRegistrationSelected) {
                tmdispatch({
                    type: 'SETPERSONALDATA_PHYSPAT_USERNAME',
                    personalDataPhysPat: {
                        username: value,
                    },
                });
            } else {
                tmdispatch({
                    type: 'SETPERSONALDATA_PHYSPAT_USERNAME',
                    personalDataPhysPat: {
                        username: '',
                    },
                });
            }
        } else {
            if (cbxRegistrationSelected) {
                tmdispatch({
                    type: 'SETPERSONALDATA_USERNAME',
                    personalData: {
                        username: value,
                    },
                });
            } else {
                tmdispatch({
                    type: 'SETPERSONALDATA_USERNAME',
                    personalData: {
                        username: '',
                    },
                });
            }
        }
    };

    const setPassword = (value: string) => {
        if (props.isPhysPat) {
            if (cbxRegistrationSelected) {
                tmdispatch({
                    type: 'SETPERSONALDATA_PHYSPAT_PASSWORD',
                    personalDataPhysPat: {
                        password: value,
                    },
                });
            } else {
                tmdispatch({
                    type: 'SETPERSONALDATA_PHYSPAT_PASSWORD',
                    personalDataPhysPat: {
                        password: '',
                    },
                });
            }
        } else {
            if (cbxRegistrationSelected) {
                tmdispatch({
                    type: 'SETPERSONALDATA_PASSWORD',
                    personalData: {
                        password: value,
                    },
                });
            } else {
                tmdispatch({
                    type: 'SETPERSONALDATA_PASSWORD',
                    personalData: {
                        password: '',
                    },
                });
            }
        }
    };

    const setPlannedStudies = () => {
        if (props.isPhysPat) {
            tmdispatch({
                type: 'SETPERSONALDATA_PHYSPAT_PLANNEDSTUDIES',
                personalDataPhysPat: {
                    plannedStudies: allCurrentStudies,
                },
            });
        } else {
            tmdispatch({
                type: 'SETPERSONALDATA_PLANNEDSTUDIES',
                personalData: {
                    plannedStudies: allCurrentStudies,
                },
            });
        }
    };

    const handleSubmit = (event: any) => {
        setInsuranceNr(event.insuranceNr);
        setInsuranceName(event.insuranceName);
        setLanguage(event.language);
        setFirstName(event.firstName);
        setLastName(event.lastName);
        setBirthday(event.birthday);
        setEmail(event.email);
        setGender(event.gender);
        setTelephone(event.phone);
        setStreet(event.street);
        setHouseNr(event.houseNumber);
        setZip(event.zip);
        setCity(event.city);
        setCountry(event.country);
        setMobilePhone(event.mobilePhone);
        setAcademicTitle(event.academicTitle);
        setUsername(event.username);
        setPassword(event.password);
        setPlannedStudies();
        if (props.handleNextWithActiveStep) {
            props.handleNextWithActiveStep(props.currentActiveStep ? props.currentActiveStep : 0);
        } else {
            props.handleNext();
        }
    };

    const label = (value: boolean, labelName: string) => {
        return (
            <div>
                {value ? (
                    <div>
                        <StyledFormLabelForInput>{labelName}*</StyledFormLabelForInput>
                    </div>
                ) : (
                    <div>
                        <StyledFormLabelForInput>{labelName}</StyledFormLabelForInput>
                    </div>
                )}
            </div>
        );
    };

    function validationSchema() {
        const SignupSchema = yup.object().shape({
            insuranceNr:
                mandFields.insuranceNr && !isFieldReadOnly(apstate.RegistrationReadOnlyFields.insuranceNr)
                    ? yup.string().required('Bitte geben Sie Ihre Versichertennummer an.')
                    : yup.string().notRequired(),
            insuranceName:
                mandFields.insuranceName && !isFieldReadOnly(apstate.RegistrationReadOnlyFields.insuranceName)
                    ? yup.string().required('Bitte geben Sie Ihre Krankenkassse an.')
                    : yup.string().notRequired(),
            language:
                mandFields.language && !isFieldReadOnly(apstate.RegistrationReadOnlyFields.language)
                    ? yup.string().required('Bitte geben Sie Ihre Sprache an.')
                    : yup.string().notRequired(),
            firstName:
                mandFields.firstName && !isFieldReadOnly(apstate.RegistrationReadOnlyFields.firstName)
                    ? yup.string().required('Bitte geben Sie Ihren Vornamen an.')
                    : yup.string().notRequired(),
            lastName:
                mandFields.lastName && !isFieldReadOnly(apstate.RegistrationReadOnlyFields.lastName)
                    ? yup.string().required('Bitte geben Sie Ihren Nachnamen an.')
                    : yup.string().notRequired(),
            birthday:
                mandFields.birthDate && !isFieldReadOnly(apstate.RegistrationReadOnlyFields.birthDate)
                    ? yup.string().required('Bitte geben Sie Ihren Geburtstag an.')
                    : yup.string().notRequired(),
            email:
                mandFields.email &&
                !isFieldReadOnly(apstate.RegistrationReadOnlyFields.email) &&
                checkinstate.wpName.length <= 0
                    ? yup
                          .string()
                          .required('Bitte geben Sie Ihre E-Mail-Adresse an.')
                          .matches(pattern, 'Ungültiges E-Mail-Format')
                    : yup.string().notRequired().matches(pattern, 'Ungültiges E-Mail-Format'),
            phone:
                mandFields.phone && !isFieldReadOnly(apstate.RegistrationReadOnlyFields.phone)
                    ? yup.string().required('Bitte geben Sie Ihre Telefonnummer an.')
                    : yup.string().notRequired(),
            gender:
                mandFields.gender && !isFieldReadOnly(apstate.RegistrationReadOnlyFields.gender)
                    ? yup.string().required('Bitte geben Sie Ihr Geschlecht an.')
                    : yup.string().notRequired(),
            street:
                mandFields.street && !isFieldReadOnly(apstate.RegistrationReadOnlyFields.street)
                    ? yup.string().required('Bitte geben Sie Ihre Straße an.')
                    : yup.string().notRequired(),
            houseNumber:
                mandFields.street && !isFieldReadOnly(apstate.RegistrationReadOnlyFields.street)
                    ? yup.string().required('Bitte geben Sie Ihre Hausnummer an.')
                    : yup.string().notRequired(),
            zip:
                mandFields.zip && !isFieldReadOnly(apstate.RegistrationReadOnlyFields.zip)
                    ? yup.string().required('Bitte geben Sie Ihre Postleitzahl an.')
                    : yup.string().notRequired(),
            city:
                mandFields.city && !isFieldReadOnly(apstate.RegistrationReadOnlyFields.city)
                    ? yup.string().required('Bitte geben Sie Ihren Ort an.')
                    : yup.string().notRequired(),
            country:
                mandFields.country && !isFieldReadOnly(apstate.RegistrationReadOnlyFields.country)
                    ? yup.string().required('Bitte geben Sie Ihr Land an.')
                    : yup.string().notRequired(),
            mobilePhone:
                mandFields.mobilePhone && !isFieldReadOnly(apstate.RegistrationReadOnlyFields.mobilePhone)
                    ? yup.string().required('Bitte geben Sie Ihre Mobil-Telefonnummer an.')
                    : yup.string().notRequired(),
            academicTitle:
                mandFields.academicTitle && !isFieldReadOnly(apstate.RegistrationReadOnlyFields.academicTitle)
                    ? yup.string().required('Bitte geben Sie Ihren Titel an.')
                    : yup.string().notRequired(),
            username: cbxRegistrationSelected
                ? yup.string().required('Bitte geben Sie einen Benutzernamen an.')
                : yup.string().notRequired(),
            password: cbxRegistrationSelected
                ? yup.string().required('Bitte geben Sie ein Passwort an.')
                : yup.string().notRequired(),
        });

        return SignupSchema;
    }

    /**
     * The field can only be readOnly if the value is set and if we are in changePersonalData.
     * The field is always writable when creating a new account
     *
     * @param registrationReadOnlyField
     *      the field to check
     * @returns
     *      true, if field is readOnly, otherwise false
     */
    const isFieldReadOnly = (registrationReadOnlyField: boolean) => {
        if (tmstate.changePersonalData) {
            return registrationReadOnlyField;
        }
        return false;
    };

    const CancelButton = () => {
        if (props.showCancelButton !== undefined && props.showCancelButton === false) {
            return null;
        } else {
            return (
                <StyledButton variant="secondary" onClick={props.handleCancel}>
                    Abbrechen
                </StyledButton>
            );
        }
    };

    const BackButton = () => {
        if (props.showBackButton !== undefined && props.showBackButton === false) {
            return null;
        } else {
            return <StyledButton onClick={props.handleBack}>Zurück</StyledButton>;
        }
    };

    const NextButton = () => {
        if (props.showNextButton !== undefined && props.showNextButton === false) {
            return null;
        }
        let buttonEnabled = true;
        if (props.showPrivacyPolicyCbx && apstate.RegistrationVisibleFields.privacyTerms) {
            if (cbxPrivacyPolicySelected === false && apstate.RegistrationMandFields.privacyTerms) {
                buttonEnabled = false;
            }
        } else if (props.nextButtonEnabled) {
            buttonEnabled = props.nextButtonEnabled;
        } else if (
            checkinstate.wpName !== undefined &&
            checkinstate?.wpName?.length > 0 &&
            (tmstate.personalData.eGK === undefined || tmstate?.personalData?.eGK?.length <= 0) &&
            (tmstate.personalData.kvBarcode === undefined || tmstate?.personalData?.kvBarcode.length <= 0)
        ) {
            buttonEnabled = false;
        }
        return (
            <StyledButton disabled={!buttonEnabled} type="submit">
                Weiter
            </StyledButton>
        );
    };

    const SubmitButton = () => {
        return <StyledButton type="submit">Bestätigen</StyledButton>;
    };

    interface IFormGroup {
        values: any;
        handleChange: any;
        errors: any;
    }

    const FormGroupInsuranceNr = (propsFG: IFormGroup) => {
        if (apstate.RegistrationVisibleFields.insuranceNr === true) {
            return (
                <Form.Group as={Col} md={6} xl={4} controlId="formGridInsuranceNr">
                    {label(mandFields.insuranceNr, 'Versichertennummer')}
                    <Form.Control
                        name="insuranceNr"
                        type="text"
                        value={propsFG.values.insuranceNr}
                        onChange={propsFG.handleChange}
                        isInvalid={!!propsFG.errors.insuranceNr}
                        readOnly={isFieldReadOnly(apstate.RegistrationReadOnlyFields.insuranceNr)}
                    />
                    <Form.Control.Feedback type="invalid">{propsFG.errors.insuranceNr}</Form.Control.Feedback>
                </Form.Group>
            );
        } else {
            return null;
        }
    };

    const FormGroupInsuranceName = (propsFG: IFormGroup) => {
        if (apstate.RegistrationVisibleFields.insuranceName === true) {
            return (
                <Form.Group as={Col} md={6} xl={4} controlId="formGridInsuranceName">
                    {label(mandFields.insuranceName, 'Krankenkasse')}
                    <Form.Control
                        name="insuranceName"
                        type="text"
                        value={propsFG.values.insuranceName}
                        onChange={propsFG.handleChange}
                        isInvalid={!!propsFG.errors.insuranceName}
                        readOnly={isFieldReadOnly(apstate.RegistrationReadOnlyFields.insuranceName)}
                    />
                    <Form.Control.Feedback type="invalid">{propsFG.errors.insuranceName}</Form.Control.Feedback>
                </Form.Group>
            );
        } else {
            return null;
        }
    };

    const FormGroupLastName = (propsFG: IFormGroup) => {
        if (apstate.RegistrationVisibleFields.lastName === true) {
            return (
                <Form.Group as={Col} md={5} controlId="formGridLastName">
                    {label(mandFields.lastName, 'Nachname')}
                    <Form.Control
                        name="lastName"
                        type="text"
                        value={propsFG.values.lastName}
                        onChange={propsFG.handleChange}
                        isInvalid={!!propsFG.errors.lastName}
                        readOnly={isFieldReadOnly(apstate.RegistrationReadOnlyFields.lastName)}
                    />
                    <Form.Control.Feedback type="invalid">{propsFG.errors.lastName}</Form.Control.Feedback>
                </Form.Group>
            );
        } else {
            return null;
        }
    };

    const FormGroupFirstName = (propsFG: IFormGroup) => {
        if (apstate.RegistrationVisibleFields.firstName === true) {
            return (
                <Form.Group as={Col} md={5} controlId="formGridFirstName">
                    {label(mandFields.firstName, 'Vorname')}
                    <Form.Control
                        type="text"
                        name="firstName"
                        value={propsFG.values.firstName}
                        onChange={propsFG.handleChange}
                        isInvalid={!!propsFG.errors.firstName}
                        readOnly={isFieldReadOnly(apstate.RegistrationReadOnlyFields.firstName)}
                    />
                    <Form.Control.Feedback type="invalid">{propsFG.errors.firstName}</Form.Control.Feedback>
                </Form.Group>
            );
        } else {
            return null;
        }
    };

    const FormGroupBirthday = (propsFG: IFormGroup) => {
        if (apstate.RegistrationVisibleFields.birthDate === true) {
            return (
                <Form.Group as={Col} md={6} xl={3} controlId="formGridBirthday">
                    {label(mandFields.birthDate, 'Geburtstag')}
                    <Form.Control
                        type="date"
                        name="birthday"
                        min="1910-01-01"
                        max={moment().format('YYYY-MM-DD')}
                        value={propsFG.values.birthday}
                        onChange={(e) => {
                            const currentValues = e.target.value;
                            const currentNumberValues = currentValues.length;
                            if (currentNumberValues < 11) {
                                propsFG.handleChange(e);
                            }
                        }}
                        isInvalid={!!propsFG.errors.birthday}
                        readOnly={isFieldReadOnly(apstate.RegistrationReadOnlyFields.birthDate)}
                    />
                    <Form.Control.Feedback type="invalid">{propsFG.errors.birthday}</Form.Control.Feedback>
                </Form.Group>
            );
        } else {
            return null;
        }
    };

    const FormGroupGender = (propsFG: IFormGroup) => {
        if (apstate.RegistrationVisibleFields.gender === true) {
            return (
                <Form.Group as={Col} md={6} xl={3} controlId="formGridGender">
                    {label(mandFields.gender, 'Geschlecht')}
                    <Form.Control
                        as="select"
                        name="gender"
                        value={getGenderDE(propsFG.values.gender)}
                        onChange={propsFG.handleChange}
                        isInvalid={!!propsFG.errors.gender}
                    >
                        <option></option>
                        <option>Weiblich</option>
                        <option>Männlich</option>
                        <option>Divers</option>
                        <option>Unbekannt</option>
                        readOnly={isFieldReadOnly(apstate.RegistrationReadOnlyFields.gender)}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{propsFG.errors.gender}</Form.Control.Feedback>
                </Form.Group>
            );
        } else {
            return null;
        }
    };

    const FormGroupLanguage = (propsFG: IFormGroup) => {
        if (apstate.RegistrationVisibleFields.language === true) {
            return (
                <Form.Group as={Col} md={6} xl={3} controlId="formGridLanguage">
                    {label(mandFields.language, 'Sprache')}
                    <Form.Control
                        as="select"
                        name="language"
                        value={propsFG.values.language}
                        onChange={propsFG.handleChange}
                        isInvalid={!!propsFG.errors.language}
                    >
                        <option></option>
                        <option>Deutsch</option>
                        <option>Englisch</option>
                        readOnly={isFieldReadOnly(apstate.RegistrationReadOnlyFields.language)}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{propsFG.errors.language}</Form.Control.Feedback>
                </Form.Group>
            );
        } else {
            return null;
        }
    };

    const FormGroupTelephone = (propsFG: IFormGroup) => {
        if (apstate.RegistrationVisibleFields.phone === true) {
            return (
                <Form.Group as={Col} md={6} xl={4} controlId="formGridTelephone">
                    {label(mandFields.phone, 'Telefonnummer')}
                    <Form.Control
                        name="phone"
                        type="text"
                        value={propsFG.values.phone}
                        onChange={propsFG.handleChange}
                        isInvalid={!!propsFG.errors.phone}
                        readOnly={isFieldReadOnly(apstate.RegistrationReadOnlyFields.phone)}
                    />
                    <Form.Control.Feedback type="invalid">{propsFG.errors.phone}</Form.Control.Feedback>
                </Form.Group>
            );
        } else {
            return null;
        }
    };

    const FormGroupMobilePhone = (propsFG: IFormGroup) => {
        if (apstate.RegistrationVisibleFields.mobilePhone === true) {
            return (
                <Form.Group as={Col} md={6} xl={4} controlId="formGridMobilePhone">
                    {label(mandFields.mobilePhone, 'Mobil Telefonnummer')}
                    <Form.Control
                        name="mobilePhone"
                        type="text"
                        value={propsFG.values.mobilePhone}
                        onChange={propsFG.handleChange}
                        isInvalid={!!propsFG.errors.mobilePhone}
                        readOnly={isFieldReadOnly(apstate.RegistrationReadOnlyFields.mobilePhone)}
                    />
                    <Form.Control.Feedback type="invalid">{propsFG.errors.mobilePhone}</Form.Control.Feedback>
                </Form.Group>
            );
        } else {
            return null;
        }
    };

    const FormGroupAcademicTitle = (propsFG: IFormGroup) => {
        if (apstate.RegistrationVisibleFields.academicTitle === true) {
            return (
                <Form.Group as={Col} controlId="formGridAcademicTitle">
                    {label(mandFields.academicTitle, 'Titel')}
                    <Form.Control
                        name="academicTitle"
                        type="text"
                        value={propsFG.values.academicTitle}
                        onChange={propsFG.handleChange}
                        isInvalid={!!propsFG.errors.academicTitle}
                        readOnly={isFieldReadOnly(apstate.RegistrationReadOnlyFields.academicTitle)}
                    />
                    <Form.Control.Feedback type="invalid">{propsFG.errors.academicTitle}</Form.Control.Feedback>
                </Form.Group>
            );
        } else {
            return null;
        }
    };

    const FormGroupEmail = (propsFG: IFormGroup) => {
        if (apstate.RegistrationVisibleFields.email === true) {
            return (
                <Form.Group as={Col} md={6} xl={4} controlId="formGridEmail">
                    {label(mandFields.email && checkinstate.wpName.length <= 0, 'E-Mail')}
                    <Form.Control
                        name="email"
                        type="email"
                        value={propsFG.values.email}
                        onChange={propsFG.handleChange}
                        isInvalid={!!propsFG.errors.email}
                        readOnly={isFieldReadOnly(apstate.RegistrationReadOnlyFields.email)}
                    />
                    <Form.Control.Feedback type="invalid">{propsFG.errors.email}</Form.Control.Feedback>
                </Form.Group>
            );
        } else {
            return null;
        }
    };

    const FormGroupZip = (propsFG: IFormGroup) => {
        if (apstate.RegistrationVisibleFields.zip === true) {
            return (
                <Form.Group as={Col} md={6} xl={2} controlId="formGridZip">
                    {label(mandFields.zip, 'Postleitzahl')}
                    <Form.Control
                        type="text"
                        name="zip"
                        value={propsFG.values.zip}
                        onChange={propsFG.handleChange}
                        isInvalid={!!propsFG.errors.zip}
                        readOnly={isFieldReadOnly(apstate.RegistrationReadOnlyFields.zip)}
                    />
                    <Form.Control.Feedback type="invalid">{propsFG.errors.zip}</Form.Control.Feedback>
                </Form.Group>
            );
        } else {
            return null;
        }
    };

    const FormGroupCity = (propsFG: IFormGroup) => {
        if (apstate.RegistrationVisibleFields.city === true) {
            return (
                <Form.Group as={Col} md={6} xl={4} controlId="formGridCity">
                    {label(mandFields.city, 'Ort')}
                    <Form.Control
                        type="text"
                        name="city"
                        value={propsFG.values.city}
                        onChange={propsFG.handleChange}
                        isInvalid={!!propsFG.errors.city}
                        readOnly={isFieldReadOnly(apstate.RegistrationReadOnlyFields.city)}
                    />
                    <Form.Control.Feedback type="invalid">{propsFG.errors.city}</Form.Control.Feedback>
                </Form.Group>
            );
        } else {
            return null;
        }
    };

    const FormGroupStreet = (propsFG: IFormGroup) => {
        if (apstate.RegistrationVisibleFields.street === true) {
            return (
                <Form.Group as={Col} md={6} xl={4} controlId="formGridStreet">
                    {label(mandFields.street, 'Straße')}
                    <Form.Control
                        type="text"
                        name="street"
                        value={propsFG.values.street}
                        onChange={propsFG.handleChange}
                        isInvalid={!!propsFG.errors.street}
                        readOnly={isFieldReadOnly(apstate.RegistrationReadOnlyFields.street)}
                    />
                    <Form.Control.Feedback type="invalid">{propsFG.errors.street}</Form.Control.Feedback>
                </Form.Group>
            );
        } else {
            return null;
        }
    };

    const FormGroupHouseNr = (propsFG: IFormGroup) => {
        if (apstate.RegistrationVisibleFields.street === true) {
            return (
                <Form.Group as={Col} md={6} xl={2} controlId="formGridHouseNr">
                    {label(mandFields.street, 'Hausnummer')}
                    <Form.Control
                        type="text"
                        name="houseNumber"
                        value={propsFG.values.houseNumber}
                        onChange={propsFG.handleChange}
                        isInvalid={!!propsFG.errors.houseNumber}
                        readOnly={isFieldReadOnly(apstate.RegistrationReadOnlyFields.street)}
                    />
                    <Form.Control.Feedback type="invalid">{propsFG.errors.houseNumber}</Form.Control.Feedback>
                </Form.Group>
            );
        } else {
            return null;
        }
    };

    const handleCbxRegistrationSelected = () => {
        setCbxRegistrationSelected(!cbxRegistrationSelected);
    };

    const handleCbxPrivacyPolicySelected = () => {
        setCbxPrivacyPolicySelected(!cbxPrivacyPolicySelected);
    };

    const FormGroupRegistrationCbx = () => {
        if (props.showRegistrationCbx) {
            return (
                <Form.Group
                    as={Col}
                    className="mb-3"
                    controlId="registrationCbx"
                    style={{ fontSize: '0.85rem', color: 'rgb(116, 118, 130)' }}
                >
                    <Form.Check
                        name="registrationCbx"
                        checked={cbxRegistrationSelected}
                        onChange={handleCbxRegistrationSelected}
                        type="checkbox"
                        label="Account erstellen"
                    />
                </Form.Group>
            );
        } else {
            return null;
        }
    };

    const FormGroupPrivacyPolicyRegistrationCbx = () => {
        if (props.showPrivacyPolicyCbx && apstate.RegistrationVisibleFields.privacyTerms) {
            return (
                <div key="privacyPolicyCbx" className="mt-3 mb-3" style={{ marginLeft: '5px' }}>
                    <Form.Check type="checkbox" id="privacyPolicyCbx-check-1">
                        <Form.Check.Input
                            type="checkbox"
                            checked={cbxPrivacyPolicySelected}
                            onChange={handleCbxPrivacyPolicySelected}
                        />
                        <Form.Check.Label>
                            <div>
                                <span>
                                    <a href={apstate.impressum.datenschutzLink} target="_blank" rel="noreferrer">
                                        Datenschutzerklärung
                                    </a>{' '}
                                    gelesen und akzeptiert
                                </span>
                            </div>
                        </Form.Check.Label>
                    </Form.Check>
                </div>
            );
        } else {
            return null;
        }
    };

    const FormGroupRegistrationUsername = (propsFG: IFormGroup) => {
        if (props.showRegistrationCbx && cbxRegistrationSelected) {
            return (
                <Form.Group as={Col} md={6} xl={3} controlId="formGridRegistrationUser">
                    {label(true, 'Benutzername')}
                    <Form.Control
                        type="text"
                        name="username"
                        value={propsFG.values.username}
                        onChange={propsFG.handleChange}
                        isInvalid={!!propsFG.errors.username}
                    />
                    <Form.Control.Feedback type="invalid">{propsFG.errors.username}</Form.Control.Feedback>
                </Form.Group>
            );
        } else {
            return null;
        }
    };

    const FormGroupRegistrationPassword = (propsFG: IFormGroup) => {
        if (props.showRegistrationCbx && cbxRegistrationSelected) {
            return (
                <Form.Group as={Col} md={6} xl={3} controlId="formGridRegistrationPwd">
                    {label(true, 'Passwort')}
                    <Form.Control
                        type="password"
                        name="password"
                        value={propsFG.values.password}
                        onChange={propsFG.handleChange}
                        isInvalid={!!propsFG.errors.password}
                    />
                    <Form.Control.Feedback type="invalid">{propsFG.errors.password}</Form.Control.Feedback>
                </Form.Group>
            );
        } else {
            return null;
        }
    };

    const makeOption = (country: string) => {
        return <option>{country}</option>;
    };

    const FormGroupCountry = (propsFG: IFormGroup) => {
        if (apstate.RegistrationVisibleFields.country === true) {
            const countries = ['Deutschland', 'Österreich', 'Schweiz', 'Italien'];
            return (
                <Form.Group as={Col} md={6} xl={4} controlId="formGridHouseCountry">
                    {label(mandFields.country, 'Land')}
                    <Form.Control
                        as="select"
                        name="country"
                        value={propsFG.values.country}
                        onChange={propsFG.handleChange}
                        isInvalid={!!propsFG.errors.country}
                    >
                        <option value="" disabled hidden>
                            Bitte wählen...
                        </option>
                        {getPossibleCountries().map(makeOption)}
                        readOnly={isFieldReadOnly(apstate.RegistrationReadOnlyFields.country)}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{propsFG.errors.country}</Form.Control.Feedback>
                </Form.Group>
            );
        } else {
            return null;
        }
    };

    const studyChange = (e: React.ChangeEvent<HTMLInputElement>, studyCode: string) => {
        console.log(studyCode + ':' + e.target.checked);

        const modifiedAllCurrentStudies = produce(allCurrentStudies, (draft) => {
            if (draft && draft.length > 0) {
                for (let i = 0; i < draft.length; i++) {
                    if (draft[i].code === studyCode) {
                        draft[i].participate = e.target.checked;
                    }
                }
            }
        });

        setAllCurrentStudies(modifiedAllCurrentStudies);
    };

    const FormGroupStudies = () => {
        const allStudies: any[] = [];

        state.possibleStudies.forEach((study: IPossibleStudies) => {
            const cbxId = 'check-formGroupStudies-' + study.code;
            let isParticipated = false;
            if (allCurrentStudies) {
                const foundStudy = allCurrentStudies.find((p: IPlannedStudies) => p.code === study.code);
                if (foundStudy?.participate !== undefined) {
                    isParticipated = foundStudy.participate;

                    allStudies.push(
                        <Form.Check type="checkbox" id={cbxId}>
                            <Form.Check.Input
                                type="checkbox"
                                isValid
                                checked={isParticipated}
                                onChange={(e) => studyChange(e, study.code)}
                            />
                            <Form.Check.Label>
                                <div style={{ color: 'black' }}>{study.display}</div>
                            </Form.Check.Label>
                        </Form.Check>,
                    );
                }
            }
        });

        if (tmstate.changePersonalData && state.possibleStudies?.length > 0) {
            return (
                <Form.Group as={Col} md={8} controlId="formGridPlannedStudies">
                    {label(false, 'Gewünschte Studienteilnahmen')}
                    {allStudies}
                </Form.Group>
            );
        } else {
            return null;
        }
    };

    const getInitialFirstName = () => {
        let firstName = '';
        if (props.isPhysPat) {
            firstName = tmstate.personalDataPhysPat.firstName;
        } else if (checkinstate.urlToCallResponse.firstName && checkinstate.urlToCallResponse.firstName.length > 0) {
            firstName = checkinstate.urlToCallResponse.firstName;
        } else {
            firstName = tmstate.personalData.firstName;
        }
        return firstName;
    };

    const getInitialLastName = () => {
        let lastName = '';
        if (props.isPhysPat) {
            lastName = tmstate.personalDataPhysPat.lastName;
        } else if (checkinstate.urlToCallResponse.nameLast && checkinstate.urlToCallResponse.nameLast.length > 0) {
            lastName = checkinstate.urlToCallResponse.nameLast;
        } else {
            lastName = tmstate.personalData.lastName;
        }
        return lastName;
    };

    const getInitialInsuranceNr = () => {
        let insuranceNr = '';
        if (props.isPhysPat) {
            insuranceNr = tmstate.personalDataPhysPat.insuranceNr;
        } else if (
            checkinstate.urlToCallResponse.insuranceNr &&
            checkinstate.urlToCallResponse.insuranceNr.length > 0
        ) {
            insuranceNr = checkinstate.urlToCallResponse.insuranceNr;
        } else {
            insuranceNr = tmstate.personalData.insuranceNr;
        }
        return insuranceNr;
    };

    const getInitialInsuranceName = () => {
        let insuranceName = '';
        if (props.isPhysPat) {
            insuranceName = tmstate.personalDataPhysPat.insuranceName;
        } else if (
            checkinstate.urlToCallResponse.insuranceName &&
            checkinstate.urlToCallResponse.insuranceName.length > 0
        ) {
            insuranceName = checkinstate.urlToCallResponse.insuranceName;
        } else {
            insuranceName = tmstate.personalData.insuranceName;
        }
        return insuranceName;
    };

    const getLanguage = () => {
        let language = '';
        if (props.isPhysPat) {
            language = tmstate.personalDataPhysPat.language;
        } else if (checkinstate.urlToCallResponse.language && checkinstate.urlToCallResponse.language.length > 0) {
            language = checkinstate.urlToCallResponse.language;
        } else {
            language = tmstate.personalData.language;
        }
        return language;
    };

    const getInitialBirthday = () => {
        let birthday = '';
        if (props.isPhysPat) {
            birthday = formatBirthdayForFormik(tmstate.personalDataPhysPat.birthday);
        } else if (checkinstate.urlToCallResponse.birthday && checkinstate.urlToCallResponse.birthday.length > 0) {
            birthday = formatBirthdayForFormik(checkinstate.urlToCallResponse.birthday);
        } else {
            birthday = formatBirthdayForFormik(tmstate.personalData.birthday);
        }
        return birthday;
    };

    const getInitialEmail = () => {
        let email = '';
        if (props.isPhysPat) {
            email = tmstate.personalDataPhysPat.email;
        } else {
            email = tmstate.personalData.email;
        }
        return email;
    };

    const getInitialPhone = () => {
        let phone = '';
        if (props.isPhysPat) {
            phone = tmstate.personalDataPhysPat.telephone;
        } else {
            phone = tmstate.personalData.telephone;
        }

        return phone;
    };

    const getInitialMobilePhone = () => {
        let mobilePhone = '';
        if (props.isPhysPat) {
            mobilePhone = tmstate.personalDataPhysPat.mobilePhone;
        } else {
            mobilePhone = tmstate.personalData.mobilePhone;
        }

        return mobilePhone;
    };

    const getInitialAcademicTitle = () => {
        let academicTitle = '';
        if (props.isPhysPat) {
            academicTitle = tmstate.personalDataPhysPat.academicTitle;
        } else {
            academicTitle = tmstate.personalData.academicTitle;
        }

        return academicTitle;
    };

    const getInitialGender = () => {
        let gender = '';
        if (props.isPhysPat) {
            gender = tmstate.personalDataPhysPat.gender;
        } else if (checkinstate.urlToCallResponse.gender && checkinstate.urlToCallResponse.gender.length > 0) {
            gender = checkinstate.urlToCallResponse.gender;
        } else {
            gender = tmstate.personalData.gender;
        }
        return gender;
    };

    const getInitialStreet = () => {
        let street = '';
        if (props.isPhysPat) {
            street = tmstate.personalDataPhysPat.street;
        } else if (checkinstate.urlToCallResponse.street && checkinstate.urlToCallResponse.street.length > 0) {
            street = checkinstate.urlToCallResponse.street;
        } else {
            street = tmstate.personalData.street;
        }
        return street;
    };

    const getInitialHouseNr = () => {
        let houseNr = '';
        if (props.isPhysPat) {
            houseNr = tmstate.personalDataPhysPat.housenr;
        } else if (
            checkinstate.urlToCallResponse.houseNumber &&
            checkinstate.urlToCallResponse.houseNumber.length > 0
        ) {
            houseNr = checkinstate.urlToCallResponse.houseNumber;
        } else {
            houseNr = tmstate.personalData.housenr;
        }
        return houseNr;
    };

    const getInitialZip = () => {
        let zip = '';
        if (props.isPhysPat) {
            zip = tmstate.personalDataPhysPat.zip;
        } else if (checkinstate.urlToCallResponse.zip && checkinstate.urlToCallResponse.zip.length > 0) {
            zip = checkinstate.urlToCallResponse.zip;
        } else {
            zip = tmstate.personalData.zip;
        }
        return zip;
    };

    const getInitialCity = () => {
        let city = '';
        if (props.isPhysPat) {
            city = tmstate.personalDataPhysPat.city;
        } else if (checkinstate.urlToCallResponse.city && checkinstate.urlToCallResponse.city.length > 0) {
            city = checkinstate.urlToCallResponse.city;
        } else {
            city = tmstate.personalData.city;
        }
        return city;
    };

    const getInitialCountry = () => {
        let country = '';
        if (props.isPhysPat) {
            country = tmstate.personalDataPhysPat.country;
        } else if (checkinstate.urlToCallResponse.country && checkinstate.urlToCallResponse.country.length > 0) {
            country = checkinstate.urlToCallResponse.country;
        } else {
            country = tmstate.personalData.country;
        }
        return country;
    };

    const getInitialPlannedStudies = () => {
        if (props.isPhysPat) {
            return tmstate.personalDataPhysPat.plannedStudies;
        } else {
            return tmstate.personalData.plannedStudies;
        }
    };

    return (
        <LeftDiv>
            <Formik
                validationSchema={validationSchema()}
                onSubmit={handleSubmit}
                initialValues={{
                    insuranceNr: getInitialInsuranceNr(),
                    insuranceName: getInitialInsuranceName(),
                    language: getLanguage(),
                    firstName: getInitialFirstName(),
                    lastName: getInitialLastName(),
                    birthday: getInitialBirthday(),
                    email: getInitialEmail(),
                    phone: getInitialPhone(),
                    gender: getInitialGender(),
                    street: getInitialStreet(),
                    houseNumber: getInitialHouseNr(),
                    zip: getInitialZip(),
                    city: getInitialCity(),
                    country: getInitialCountry(),
                    mobilePhone: getInitialMobilePhone(),
                    academicTitle: getInitialAcademicTitle(),
                    plannedStudies: getInitialPlannedStudies(),
                }}
            >
                {({ handleSubmit, handleChange, values, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Row>
                            <FormGroupFirstName values={values} handleChange={handleChange} errors={errors} />
                            <FormGroupLastName values={values} handleChange={handleChange} errors={errors} />
                            <FormGroupAcademicTitle values={values} handleChange={handleChange} errors={errors} />
                        </Form.Row>
                        <Form.Row>
                            <FormGroupBirthday values={values} handleChange={handleChange} errors={errors} />
                            <FormGroupGender values={values} handleChange={handleChange} errors={errors} />
                        </Form.Row>
                        <Form.Row>
                            <FormGroupTelephone values={values} handleChange={handleChange} errors={errors} />
                            <FormGroupMobilePhone values={values} handleChange={handleChange} errors={errors} />
                            <FormGroupEmail values={values} handleChange={handleChange} errors={errors} />
                        </Form.Row>
                        <Form.Row>
                            <FormGroupZip values={values} handleChange={handleChange} errors={errors} />
                            <FormGroupCity values={values} handleChange={handleChange} errors={errors} />
                            <FormGroupStreet values={values} handleChange={handleChange} errors={errors} />
                            <FormGroupHouseNr values={values} handleChange={handleChange} errors={errors} />
                        </Form.Row>
                        <Form.Row>
                            <FormGroupCountry values={values} handleChange={handleChange} errors={errors} />
                            <FormGroupInsuranceNr values={values} handleChange={handleChange} errors={errors} />
                            <FormGroupInsuranceName values={values} handleChange={handleChange} errors={errors} />
                        </Form.Row>
                        <Form.Row>
                            <FormGroupLanguage values={values} handleChange={handleChange} errors={errors} />
                        </Form.Row>
                        <Form.Row>
                            <FormGroupStudies />
                        </Form.Row>
                        {!props.isPhysPat && (
                            <>
                                <Form.Row>
                                    <FormGroupRegistrationCbx />
                                </Form.Row>
                                <Form.Row>
                                    <FormGroupRegistrationUsername
                                        values={values}
                                        handleChange={handleChange}
                                        errors={errors}
                                    />
                                    <FormGroupRegistrationPassword
                                        values={values}
                                        handleChange={handleChange}
                                        errors={errors}
                                    />
                                </Form.Row>
                            </>
                        )}
                        {props.showPrivacyPolicyCbx && (
                            <Form.Row>
                                <FormGroupPrivacyPolicyRegistrationCbx />
                            </Form.Row>
                        )}
                        <div style={{ marginTop: '30px' }}>
                            {props.directRegistration ? (
                                <StyledRowPersonalDataBottom>
                                    <StyledCol paddingLeft="0px" textAlign="left">
                                        <CancelButton />
                                    </StyledCol>

                                    <StyledCol paddingRight="0px" textAlign="right">
                                        <SubmitButton />
                                    </StyledCol>
                                </StyledRowPersonalDataBottom>
                            ) : (
                                <StyledRowPersonalDataBottom>
                                    <Default>
                                        <StyledCol paddingLeft="0px" textAlign="left">
                                            <CancelButton />
                                        </StyledCol>
                                        <StyledCol sm={{ offset: 6 }} paddingLeft="0px" textAlign="right">
                                            <BackButton />
                                        </StyledCol>
                                        <StyledCol paddingLeft="0px" paddingRight="0px" textAlign="right">
                                            <NextButton />
                                        </StyledCol>
                                    </Default>
                                    <Mobile>
                                        <StyledCol paddingLeft="0px" textAlign="left">
                                            <CancelButton />
                                        </StyledCol>
                                        <StyledCol paddingRight="0px" textAlign="right">
                                            <BackButton />
                                        </StyledCol>
                                        <StyledCol paddingRight="0px" textAlign="right">
                                            <NextButton />
                                        </StyledCol>
                                    </Mobile>
                                </StyledRowPersonalDataBottom>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </LeftDiv>
    );
};

export default AppointmentPersonalData;
