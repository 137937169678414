import { getBaseURL } from '../../globals/global';

export const getFHIRConfig = (sessionId: string, baseURL?: string) => {
    let baseurl = getBaseURL() + '/fhir';
    if (baseURL) {
        baseurl = baseURL;
    }

    return {
        baseUrl: baseurl,
        headers: {
            Authorization: sessionId,
        },
    };
};
