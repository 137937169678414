import * as React from 'react';
import { useContext, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import LoginContext from '../../../context/loginContext';
import TerminierungContext from '../../../context/terminierungContext';
import { PROFILE_HOME, securityItemNames } from '../../../globals/global';
import { usePersonalData } from '../../../hooks/usePersonalData';
import { Paths } from '../../../Routes';
import { getPatientProfilePictureWL } from '../../../services/fhir/FHIRPatientResource';
import { authorizeAsync, IAuthorizeAsync } from '../../../services/RestServices';
import StyledButton from '../../Buttons/StyledButton';
import StyledCol from '../../Cols/StyledCol';
import { StyledFormLabelForInput } from '../../StyledForm/SyledForm';
import { StyledModal } from '../StyledModal/StyledModal';
interface I2FAModal {
    show: boolean;
    onHide: () => void;
    username: string;
    password: string;
}

const TwoFactorAuthModal = (props: I2FAModal) => {
    const navigate = useNavigate();
    const { dispatch } = useContext(LoginContext);
    const { tmdispatch } = useContext(TerminierungContext);

    const [token, setToken] = useState('');

    const [validated, setValidated] = useState(false);
    const [invalid, setInvalid] = useState(false);

    const [errorMessage, setErroMessage] = useState('');

    const { getAndSetPersonalData } = usePersonalData();

    // function getEnabledSecurityItems(securityItems: Object): Array<string> {
    //     const enabledItems: Array<string> = [];
    //     const entries = Object.entries(securityItems);
    //     for (const [item, enabled] of entries) {
    //         if (enabled === 'true') {
    //             enabledItems.push(item);
    //         }
    //     }
    //     return enabledItems;
    // }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>, token: string) => {
        event.preventDefault();

        const authorizeData: IAuthorizeAsync = {
            username: props.username,
            password: props.password,
            token: token,
        };

        const twoFALogin = await authorizeAsync(authorizeData);

        if (twoFALogin.error) {
            setErroMessage('Bitte geben Sie ein gültiges Token an.');
            setInvalid(true);
            setToken('');
        } else if (!twoFALogin) {
            setErroMessage('Kann keine Verbindung zum Server herstellen.');
            setInvalid(true);
        } else {
            // let enabledSecurityItems = getEnabledSecurityItems(twoFALogin.SecurityItems);
            const activeStudy =
                twoFALogin.activeStudies !== undefined
                    ? twoFALogin.activeStudies.find(
                          (element: { studyPatId: any }) => element.studyPatId === twoFALogin.activePatId,
                      )
                    : undefined;

            const activeUserId = twoFALogin.userId; //now default is HOME
            const practitionerRoleId = twoFALogin.practitionerRoleId;

            dispatch({
                type: 'LOGIN',
                userId: twoFALogin.userId,
                practitionerRoleId: twoFALogin.practitionerRoleId,
                sessionId: twoFALogin.sessionId,
                securityItems: {
                    CHARTS: twoFALogin.SecurityItems[securityItemNames.CHARTS] === 'true' ? true : false,
                    CHARTS_WEIGHT: twoFALogin.SecurityItems[securityItemNames.CHARTS_WEIGHT] === 'true' ? true : false,
                    CHARTS_FATMASS:
                        twoFALogin.SecurityItems[securityItemNames.CHARTS_FATMASS] === 'true' ? true : false,
                    CHARTS_BLOODSUGAR:
                        twoFALogin.SecurityItems[securityItemNames.CHARTS_BLOODSUGAR] === 'true' ? true : false,
                    CHARTS_FLI: twoFALogin.SecurityItems[securityItemNames.CHARTS_FLI] === 'true' ? true : false,
                    CHARTS_SAM: twoFALogin.SecurityItems[securityItemNames.CHARTS_SAM] === 'true' ? true : false,
                    CHARTS_ACTIVITY:
                        twoFALogin.SecurityItems[securityItemNames.CHARTS_ACTIVITY] === 'true' ? true : false,
                    TABLE_VAL: twoFALogin.SecurityItems[securityItemNames.TABLE_VAL] === 'true' ? true : false,
                    DOKUMENT: twoFALogin.SecurityItems[securityItemNames.DOKUMENT] === 'true' ? true : false,
                    FOTOS: twoFALogin.SecurityItems[securityItemNames.FOTOS] === 'true' ? true : false,
                    FRAGEBOGEN: twoFALogin.SecurityItems[securityItemNames.FRAGEBOGEN] === 'true' ? true : false,
                    MEDIKATION: twoFALogin.SecurityItems[securityItemNames.MEDIKATION] === 'true' ? true : false,
                    TAGESPLAN: twoFALogin.SecurityItems[securityItemNames.TAGESPLAN] === 'true' ? true : false,
                    TERMINE: twoFALogin.SecurityItems[securityItemNames.TERMINE] === 'true' ? true : false,
                    VIDEO: twoFALogin.SecurityItems[securityItemNames.VIDEO] === 'true' ? true : false,
                    JOURNEY: twoFALogin.SecurityItems[securityItemNames.JOURNEY] === 'true' ? true : false,
                    TODO: twoFALogin.SecurityItems[securityItemNames.TODO]
                        ? twoFALogin.SecurityItems[securityItemNames.TODO]
                        : [],
                    TAB_ORDER: twoFALogin.SecurityItems[securityItemNames.TAB_ORDER]
                        ? twoFALogin.SecurityItems[securityItemNames.TAB_ORDER]
                        : '',
                },
                iceServers: twoFALogin.iceServers,
                possibleMacros: twoFALogin.PossibleMacros,
                activeStudies: twoFALogin.activeStudies,
                activeProfile: activeStudy === undefined || activeStudy === '' ? PROFILE_HOME : activeStudy.studyName,
                activeUserId: activeUserId,
                extraLinks: twoFALogin.extraLinks,
                possibleStudies: twoFALogin.possibleStudies ? twoFALogin.possibleStudies : [],
                addConsignationsToAsk: twoFALogin.addConsignationsToAsk ? twoFALogin.addConsignationsToAsk : [],
            });

            getAndSetPersonalData(twoFALogin.sessionId, activeUserId, practitionerRoleId);

            const photoRes = await getPatientProfilePictureWL(twoFALogin.sessionId, activeUserId);

            if (photoRes.data.entry) {
                tmdispatch({
                    type: 'SETPERSONALDATA_PROFILEPHOTO',
                    personalData: {
                        profilePhoto: photoRes.data.entry[0].resource.photo
                            ? photoRes.data.entry[0].resource.photo[0].data
                            : '',
                    },
                });
            }

            tmdispatch({
                type: 'SETWITHLOGIN',
                withLogin: true,
            });
            setValidated(true);
            navigate(Paths.DOKUMENTE);
        }
    };

    return (
        <StyledModal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Code eingeben</Modal.Title>
            </Modal.Header>
            <Form
                noValidate
                validated={validated}
                className="m-3"
                onSubmit={(event: React.FormEvent<HTMLFormElement>) => handleSubmit(event, token)}
            >
                <Modal.Body>
                    <Row>
                        <Col>
                            Bitte geben Sie den Sicherheits-Code von Ihrer Authenticator-Extension hier ein:
                            <Form.Group controlId="qrCode">
                                <StyledFormLabelForInput>2-Faktor Auth.</StyledFormLabelForInput>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Sicherheits-Token"
                                    isInvalid={invalid}
                                    value={token}
                                    onChange={(e) => setToken(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <div style={{ height: 200, width: 300, margin: 'auto' }}>
                                <Image
                                    src="https://lh3.googleusercontent.com/jkjveDyiIs9vMJ_CG2wqRPQh9At58C3CPzfKIU8eiVTfzar8xmXh6XoRUa2Q7y3NaUmpmx6v2Aw=w640-h400-e365-rj-sc0x00ffffff"
                                    thumbnail
                                />
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <div style={{ float: 'left' }}>
                            <StyledCol textAlign="left">
                                <StyledButton variant="secondary" onClick={props.onHide}>
                                    Abbrechen
                                </StyledButton>
                            </StyledCol>
                        </div>
                        <div style={{ float: 'right' }}>
                            <StyledCol paddingLeft="30px">
                                <StyledButton variant="primary" type="submit" disabled={token.length < 1}>
                                    Anmelden
                                </StyledButton>
                            </StyledCol>
                        </div>
                    </Row>
                </Modal.Footer>
            </Form>
        </StyledModal>
    );
};

export default TwoFactorAuthModal;
