import { createContext } from 'react';

export interface ISignaturePadDevice {
    deviceName: string;
    serialNumber: string;
    firmwareVersion: string;
    supportsRSA: boolean;
    canStoreEncryptKey: boolean;
    padConnectionType: string;
    padIndex: number;
    displayWidth: number;
    displayHeight: number;
    xResolution: number;
    yResolution: number;
    scaleFactorX: number;
    scaleFactorY: number;
    sampleRate: number;
}

export interface ISigData {
    pictureData: string;
    certId: string;
    rsaSignature: string;
    signData: string;
    signingCert: string;
}

export interface ISignotecDeviceActions {
    sendClicked: boolean;
    cancelClicked: boolean;
}

export type InitialSigPadType = {
    deviceData: ISignaturePadDevice;
    sigData: ISigData;
    deviceActions: ISignotecDeviceActions; //action that are clicked direct on the Signature Device
    canvasRef: any;
};

export const initialSigPadState = {
    deviceData: {
        deviceName: '',
        canStoreEncryptKey: false,
        firmwareVersion: '',
        serialNumber: '',
        supportsRSA: false,
        padConnectionType: '',
        padIndex: 0,
        displayHeight: 0,
        displayWidth: 0,
        sampleRate: 0,
        scaleFactorX: 0,
        scaleFactorY: 0,
        xResolution: 0,
        yResolution: 0,
    },
    sigData: {
        certId: '',
        pictureData: '',
        rsaSignature: '',
        signData: '',
        signingCert: '',
    },
    deviceActions: {
        sendClicked: false,
        cancelClicked: false,
    },
    canvasRef: null,
};

const SigPadContext = createContext<{
    sigpadstate: InitialSigPadType;
    sigpaddispatch: React.Dispatch<any>;
}>({
    sigpadstate: initialSigPadState,
    sigpaddispatch: () => null,
});

export default SigPadContext;
