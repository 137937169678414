import { useContext, useEffect } from 'react';
import CheckInContext from '../../context/checkInContext';
import { usePersonalData } from '../../hooks/usePersonalData';
import StyledButton from '../Buttons/StyledButton';
import AppointmentPersonalData, { IAppointmentPersonalData } from './AppointmentPersonalData';
import ScanUtils, { BarcodeScanCallback } from '../../hooks/barcodeKeyHandler';
import { showAlertMessage } from '../../utils/alertHandling';
import AlertContext, { AlertOnHide, AlertType } from '../../context/alertContext';

interface IAppointmentPersonalDataWithCardRead extends IAppointmentPersonalData {
    showCardReadButton: boolean;
    onCardReadClick?(): void;
}

const AppointmentPersonalDataWithCardRead = (props: IAppointmentPersonalDataWithCardRead) => {
    const { readCardDataAndSetPersonalData, readKVDataAndSetPersonalData } = usePersonalData();
    const { checkinstate } = useContext(CheckInContext);
    const { alertdispatch } = useContext(AlertContext);

    const readEGKCard = async () => {
        readCardDataAndSetPersonalData(checkinstate.wpName);
    };

    const handleCardReadClick = (e: any) => {
        if (e) {
            e.currentTarget.blur();
        }

        readEGKCard();
        if (props.onCardReadClick) {
            props.onCardReadClick();
        }
    };

    const barcodeScannerCallback: BarcodeScanCallback = (txt) => {
        if (txt.startsWith('06\t')) {
            readKVDataAndSetPersonalData(txt);
            if (props.onCardReadClick) {
                props.onCardReadClick();
            }
        } else {
            showAlertMessage({
                alertTitle: 'Unbekannter Barcode',
                alertTxt: 'An dieser Stelle sind nur Überweisungen erlaubt.',
                alertType: AlertType.error,
                onHide: AlertOnHide.onlyClose,
                alertdispatch: alertdispatch,
            });
        }
    };
    useEffect(() => {
        const bc = ScanUtils();
        if (bc) {
            bc.startup(document.getElementById('MainPatData'), '', barcodeScannerCallback);
        }
        return () => {
            if (bc) {
                bc.removeListeners(document.getElementById('MainPatData'));
            }
        };
    }, []);

    return (
        <div id="MainPatData">
            <div>
                {props.showCardReadButton && (
                    <p>
                        <StyledButton onClick={(e) => handleCardReadClick(e)}>Karte lesen</StyledButton>
                    </p>
                )}
            </div>
            <AppointmentPersonalData {...props} />
        </div>
    );
};

export default AppointmentPersonalDataWithCardRead;
