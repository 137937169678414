import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import { IconBaseProps } from 'react-icons/lib';
import { Link, useNavigate } from 'react-router-dom';
import useScreenResolution from '../../hooks/useScreenResolution';
import { isMobile, isTablet } from '../../utils/screenResolution';
import { StyledMainCard } from '../Cards/StyledCard';

interface ICardContent {
    type: string;
    route: string;
    title: string;
    text?: string;
    icon?: IconBaseProps;
}

function getCardWidth(screenWidth: number) {
    if (isTablet(screenWidth)) {
        return '380px';
    } else if (isMobile(screenWidth)) {
        return '300px';
    } else {
        return '380px';
    }
}

const StyledCardContent = (props: ICardContent) => {
    const navigate = useNavigate();
    const screenSize = useScreenResolution();
    const cardWidth = getCardWidth(screenSize.width);

    if (props.type === 'link' && props.icon) {
        return (
            <Nav.Item>
                <Nav.Link as={Link} to={props.route}>
                    <Col>
                        <StyledMainCard cardwidth={cardWidth}>
                            <Card.Body>
                                <Card.Title>{props.title}</Card.Title>
                                <Card.Text style={{ paddingTop: 10 }}>{props.icon}</Card.Text>
                            </Card.Body>
                        </StyledMainCard>
                    </Col>
                </Nav.Link>
            </Nav.Item>
        );
    }
    if (props.type === 'link' && props.text) {
        return (
            <Nav.Item>
                <Nav.Link as={Link} to={props.route}>
                    <Col>
                        <StyledMainCard cardwidth={cardWidth}>
                            <Card.Body>
                                <Card.Title>{props.title}</Card.Title>
                                <Card.Text style={{ fontSize: 60 }}>{props.text}</Card.Text>
                            </Card.Body>
                        </StyledMainCard>
                    </Col>
                </Nav.Link>
            </Nav.Item>
        );
    }
    if (props.type === 'clickOpen') {
        const url: string = props.route;
        return (
            <Nav.Item>
                <Nav.Link onClick={() => window.open(url, '_blank')}>
                    <Col>
                        <StyledMainCard cardwidth={cardWidth}>
                            <Card.Body>
                                <Card.Title>{props.title}</Card.Title>
                                <Card.Text style={{ paddingTop: 10 }}>{props.icon}</Card.Text>
                            </Card.Body>
                        </StyledMainCard>
                    </Col>
                </Nav.Link>
            </Nav.Item>
        );
    }
    if (props.type === 'clickPush') {
        return (
            <Nav.Item>
                <Nav.Link onClick={() => navigate(props.route)}>
                    <Col>
                        <StyledMainCard cardwidth={cardWidth}>
                            <Card.Body>
                                <Card.Title>{props.title}</Card.Title>
                                <Card.Text style={{ paddingTop: 10 }}>{props.icon}</Card.Text>
                            </Card.Body>
                        </StyledMainCard>
                    </Col>
                </Nav.Link>
            </Nav.Item>
        );
    } else {
        return <p>Card content cannot be loaded (props.type does not match)</p>;
    }
};

export default StyledCardContent;
