import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { CenterDiv, StickyStepperWithNavbar } from '../../components/Div/StyledDiv';
import StepperCheckIn, {
    IStepperCheckInStep,
    StepTyp,
    StepperLabelOrientation,
    StepperOrientation,
} from '../../components/Stepper/StepperCheckIn';
import { useAppProperties } from '../../hooks/useAppProperties';
import FullScreenImpressum from '../Mainscreen/FullScreenImpressum';
import NavbarCheckInWL from '../Navbar/NavbarCheckInWL';
import FragebogenDetail from './FragebogenDetail';
import { IQuestionnaireStepData, IQuestionnaireWL } from './QuestionnaireWL';

export interface IMultiFragebogen extends IQuestionnaireWL {
    formNames: any;
}

interface IChangeStep {
    id: string;
    completed: boolean;
    draft: boolean;
    active: boolean;
    clickable: boolean;
}

interface IReloadPageData {
    reload: boolean;
    nextActiveStep: number;
    newStepValues?: IChangeStep[];
}

const MultiFragebogen = (props: IMultiFragebogen) => {
    const [allSteps, setAllSteps] = useState<IStepperCheckInStep[]>([]);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [activeContent, setActiveContent] = useState<JSX.Element>(<></>);
    const [reloadPageData, setReloadPageData] = useState<IReloadPageData>({
        reload: false,
        nextActiveStep: 0,
        newStepValues: [
            {
                id: '',
                active: false,
                clickable: false,
                completed: false,
                draft: false,
            },
        ],
    });

    const { getImpressum, getAndSetAppProperties } = useAppProperties();
    const impressum = getImpressum();

    useEffect(() => {
        getAndSetAppProperties();
        getInitialSteps();
    }, []);

    useEffect(() => {
        if (reloadPageData.reload) {
            getInitialSteps();
            let nextStep = 0;
            if (reloadPageData.reload) {
                nextStep = reloadPageData.nextActiveStep;
            }
            const con = getContent(nextStep);
            if (con) {
                setActiveContent(con);
            }
            setReloadPageData({
                reload: false,
                nextActiveStep: 0,
                newStepValues: [
                    {
                        id: '',
                        active: false,
                        clickable: false,
                        completed: false,
                        draft: false,
                    },
                ],
            });
        }
    }, [reloadPageData]);

    useEffect(() => {
        setActiveContent(getContent(activeStep));
    }, [allSteps, activeStep]);

    const handleStepClick = (stepNumberClicked: number) => {
        setActiveStep(stepNumberClicked);
        // setStepClicked(true);
        // handlePossibleChangeOfNextSteps(stepNumberClicked);
    };

    const handleQuestFinished = (currentActiveStep) => {
        const reloadData: IReloadPageData = {
            reload: true,
            nextActiveStep: getNextUnfinishedStepNumber(currentActiveStep),
            newStepValues: [
                {
                    id: allSteps[currentActiveStep].id,
                    active: false,
                    clickable: true,
                    completed: true,
                    draft: false,
                },
                {
                    id: allSteps[getNextUnfinishedStepNumber(currentActiveStep)].id,
                    active: true,
                    clickable: true,
                    completed: false,
                    draft: false,
                },
            ],
        };
        setReloadPageData(reloadData);
    };

    const handleQuestSave = () => {
        // setClickedQuestSave(true);
    };

    const handleQuestPDFClose = (currentActiveStep: number) => {
        const reloadData: IReloadPageData = {
            reload: true,
            nextActiveStep: getNextUnfinishedStepNumber(currentActiveStep),
        };
        setReloadPageData(reloadData);
    };

    const getNextUnfinishedStepNumber = (currentStepNumber: number) => {
        let endReached = false;
        for (let i = currentStepNumber; i < allSteps.length; i++) {
            const nextStep = i + 1;
            if (nextStep >= allSteps.length) {
                endReached = true;
                break;
            } else {
                if (!allSteps[nextStep].completed) {
                    return nextStep;
                }
            }
        }

        if (endReached) {
            for (let i = 0; i < currentStepNumber; i++) {
                if (!allSteps[i].completed) {
                    return i;
                }
            }
        }

        return 0;
    };

    const changeStep = (
        currentSteps: IStepperCheckInStep[],
        newValues: IChangeStep,
        setAllOtherStepsInactive: boolean,
    ) => {
        if (currentSteps.length > 0) {
            const allStepsCopy = [...currentSteps];
            for (let i = 0; i < allStepsCopy.length; i++) {
                if (allStepsCopy[i].id === newValues.id) {
                    if (newValues.completed) {
                        allStepsCopy[i].completed = newValues.completed;
                    }
                    if (newValues.draft) {
                        allStepsCopy[i].draft = newValues.draft;
                    }
                    if (newValues.active) {
                        allStepsCopy[i].active = newValues.active;
                    }
                    if (newValues.clickable) {
                        allStepsCopy[i].clickable = newValues.clickable;
                    }
                } else {
                    if (setAllOtherStepsInactive) {
                        allStepsCopy[i].active = false;
                    }
                }
            }

            return allStepsCopy;
        }
    };

    const getInitialSteps = () => {
        let steps: IStepperCheckInStep[] = [];

        const nameArr = props.name?.split(',');

        /* handle possible change of step */
        let newStepsToSet = false;
        if (reloadPageData.newStepValues && reloadPageData.newStepValues.length > 0) {
            reloadPageData.newStepValues.forEach((newStep) => {
                if (newStep.id?.length > 0) {
                    const newSteps = changeStep(steps.length > 0 ? steps : allSteps, newStep, true);
                    if (newSteps) {
                        steps = newSteps;
                        newStepsToSet = true;
                    }
                    setActiveStep(reloadPageData.nextActiveStep);
                }
            });
        }

        if (!newStepsToSet) {
            if (nameArr !== undefined && nameArr?.length > 0) {
                for (let i = 0; i < nameArr.length; i++) {
                    const name = nameArr[i];

                    const newQuestionnaireStep: IQuestionnaireWL = {
                        ...props,
                        name: name,
                    };

                    let formName = name;
                    if (props.formNames && props.formNames[name] !== undefined) {
                        formName = props.formNames[name];
                    }

                    steps.push({
                        id: name,
                        name: formName,
                        typ: StepTyp.Journey,
                        completed: false,
                        draft: false,
                        active: i === 0 ? true : false,
                        clickable: true,
                        hashParams: newQuestionnaireStep,
                    });
                }
            }
        }

        setAllSteps(steps);
    };

    const getContent = (step?: number): JSX.Element => {
        let stepContent = activeStep;
        if (step !== undefined) {
            stepContent = step;
        }

        if (allSteps && allSteps.length > 0) {
            const hashParams = allSteps[stepContent].hashParams !== undefined ? allSteps[stepContent].hashParams : {};
            if (hashParams) {
                const stepData: IQuestionnaireStepData = {
                    activeStepNumber: stepContent,
                    handleQuestionnaireFinished: handleQuestFinished,
                    handleQuestionnairePDFClose: handleQuestPDFClose,
                    handleQuestionnaireSave: handleQuestSave,
                };

                hashParams.stepData = stepData;

                return (
                    <div>
                        <FragebogenDetail {...hashParams} key={hashParams.name} />
                    </div>
                );
            }
        }

        return <p>Content nicht gefunden</p>;
    };

    const contentToShow = () => {
        return <div>{getContent(activeStep)}</div>;
    };

    const handleLogout = () => {
        window.location.reload();
    };

    return (
        <FullScreenImpressum show={true} impressum={impressum}>
            <Container fluid>
                <StickyStepperWithNavbar backgroundColor="white" zIndex={30}>
                    <NavbarCheckInWL onLogout={handleLogout} showAvatarIcon={false} showLoginIcon={false} />
                    <CenterDiv id="steppercheckinparent">
                        <StepperCheckIn
                            steps={allSteps}
                            backgroundColor={'white'}
                            handleStepClick={handleStepClick}
                            stepperOrientation={StepperOrientation.Horizontal}
                            labelOrientation={StepperLabelOrientation.Bottom}
                            paddingTop="5px"
                            paddingBottom="30px"
                        />
                    </CenterDiv>
                </StickyStepperWithNavbar>
                {contentToShow()}
            </Container>
        </FullScreenImpressum>
    );
};

export default MultiFragebogen;
