import ToggleButton from 'react-bootstrap/esm/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import styled from 'styled-components';

interface IToggleButtonProps {
    width: string;
    marginRight?: string;
    marginBottom?: string;
    marginTop?: string;
    requiredmissing: string;
}

export const ToggleButtonGroupFlex = styled(ToggleButtonGroup)`
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;
`;

export const StyledToggleButton = styled(ToggleButton)<IToggleButtonProps>`
    width: ${(props) => (props.width ? props.width : '200px')};
    color: ${(props) => (props.requiredmissing === 'true' ? '#7B001D' : 'rgb(36, 94, 173)')};
    background-color: ${(props) => (props.requiredmissing === 'true' ? '#FED3D9' : 'rgb(221, 232, 247)')};
    border-color: rgb(236, 242, 250);
    margin-right: ${(props) => (props.marginRight ? props.marginRight : '3px')};
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '3px')};
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '3px')};
    word-wrap: break-word;
    padding-right: 5px;
    padding-left: 5px;
`;

export const StyledResetButton = styled(ToggleButton)`
    margin-right: 3px;
    margin-bottom: 3px;
    margin-top: 3px;
    padding-left: 28px;
`;
