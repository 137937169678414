import { useContext, useState } from 'react';
import * as React from 'react';
import Nav from 'react-bootstrap/Nav';
import { AiOutlineMinusCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import AlertContext, { AlertOnHide, AlertType } from '../../context/alertContext';
import LoginContext from '../../context/loginContext';
import TerminierungContext from '../../context/terminierungContext';
import { Paths } from '../../Routes';
import { cancelAppointment } from '../../services/fhir/FHIRAppointment';
import { cancelEnrollment } from '../../services/RestServices';
import { showAlertMessage } from '../../utils/alertHandling';
import { IAppointment } from '../../utils/appointmentUtils';
import CancelAppointmentModal from '../Modals/CancelAppointmentModal/CancelAppointmentModal';
import MyTooltip from '../Tooltip/MyTooltip';

const CancelAppointment = (props: IAppointment) => {
    const navigate = useNavigate();
    const themeContext = useContext(ThemeContext);
    const { alertdispatch } = useContext(AlertContext);
    const { state } = useContext(LoginContext);
    const { tmdispatch } = useContext(TerminierungContext);
    const [cancelModalShow, setCancelModalShow] = React.useState(false);
    const [reason, setReason] = useState('');

    const handleSubmit = async () => {
        /* appointment without login */
        if (props.enrollCode) {
            const cancelRes: any = await cancelEnrollment(props.enrollCode, alertdispatch, reason);
            setCancelModalShow(false);
            if (cancelRes.info === 'Die Anmeldung ist erfolgreich abgesagt worden.') {
                showAlertMessage({
                    alertTitle: 'Terminabsage',
                    alertTxt: 'Der Termin wurde erfolgreich abgesagt',
                    alertType: AlertType.info,
                    onHide: AlertOnHide.custom,
                    onHideCustom: onHideAlertModal,
                    alertdispatch: alertdispatch,
                });
            } else {
                showAlertMessage({
                    alertTitle: 'Terminabsage',
                    alertTxt: 'Es ist ein Fehler bei der Terminabsage aufgetreten.',
                    alertType: AlertType.error,
                    onHide: AlertOnHide.custom,
                    onHideCustom: onHideAlertModal,
                    alertdispatch: alertdispatch,
                });
            }

            /* appointment with login */
        } else {
            const cancelRes = await cancelAppointment(state.sessionId, props.id, reason, alertdispatch);
            // console.log('cancelRes: ', cancelRes);
            if (cancelRes) {
                if (cancelRes.ERROR) {
                    showAlertMessage({
                        alertTitle: 'Terminabsage',
                        alertTxt: cancelRes.ERROR,
                        alertType: AlertType.error,
                        onHide: AlertOnHide.custom,
                        onHideCustom: onHideAlertModal,
                        alertdispatch: alertdispatch,
                    });
                } else {
                    setCancelModalShow(false);
                    setReason('');
                    tmdispatch({
                        type: 'CANCELEDAPPOINTMENT',
                        canceledAppointment: true,
                    });

                    /* ask for making alternative date */
                    if (cancelRes.data.contained) {
                        tmdispatch({
                            type: 'SETCANCELNEWAPPOINTMENTDATA',
                            cancelNewAppointmentData: {
                                showModal: true,
                                data: cancelRes.data,
                                reason: reason,
                            },
                        });
                    }
                }
            }
        }
    };

    const onHideAlertModal = () => {
        if (props.enrollCode) {
            navigate(Paths.HOME);
        }
    };

    return (
        <div>
            <Nav.Link data-name="cancelappointment" onClick={() => setCancelModalShow(true)}>
                <MyTooltip
                    id="MakeAppointmentTooltip"
                    text="Termin stornieren"
                    iconDiv={true}
                    divWidth="25px"
                    marginLeft="auto"
                    marginRight="0"
                    icon={<AiOutlineMinusCircle size={25} color={themeContext.icon.neg.color} />}
                ></MyTooltip>
            </Nav.Link>
            <CancelAppointmentModal
                show={cancelModalShow}
                onHide={() => setCancelModalShow(false)}
                onInput={(reason: string) => setReason(reason)}
                onSubmit={handleSubmit}
                commentPossible={true} //comment only with login appointments
            />
        </div>
    );
};

export default CancelAppointment;
