import { useContext } from 'react';
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled, { ThemeContext } from 'styled-components';
import TerminierungContext from '../../context/terminierungContext';

interface IStyledPDiv {
    backgroundColor?: string;
}

export const StyledPDiv = styled.div<IStyledPDiv>`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: ${(props) => (props.backgroundColor ? props.backgroundColor : props.theme.mainColor)};
    font-size: 20px;
    color: #fff;
    text-align: center;
    padding-top: 2px;

    &:hover {
        box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.2);
    }
`;

export const StyledP = styled.p`
    text-align: center;
    vertical-align: middle;
`;

const Avatar = () => {
    const { tmstate } = useContext(TerminierungContext);
    const themeContext = useContext(ThemeContext);
    const photoSrc = 'data:image/jpg;base64,' + tmstate.personalData.profilePhoto;

    const hasProfilePhoto = () => {
        if (tmstate.personalData.profilePhoto === '' || tmstate.personalData.profilePhoto === undefined) {
            return false;
        }
        return true;
    };

    return (
        <OverlayTrigger key="bottom" placement="bottom" overlay={<Tooltip id="ProfileTooltip">Profil</Tooltip>}>
            <StyledPDiv
                backgroundColor={hasProfilePhoto() ? themeContext.navbar.backgroundColor : themeContext.mainColor}
            >
                {!hasProfilePhoto() ? (
                    <StyledP>
                        {tmstate.personalData.firstName.charAt(0)}
                        {tmstate.personalData.lastName.charAt(0)}
                    </StyledP>
                ) : (
                    <Image src={photoSrc} style={{ width: '35px', height: '35px', borderRadius: '50%' }} />
                )}
            </StyledPDiv>
        </OverlayTrigger>
    );
};

export default Avatar;
