import { ListGroup } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';

interface ICard {
    width?: string;
    backgroundcolor?: string;
    marginbottom?: string;
}

export const StyledAppointmentCard = styled(Card)<ICard>`
    width: ${(prop) => (prop.width ? prop.width : '35rem')};
    align-self: center;
    border-radius: ${(props) => props.theme.appointmentCard.borderRadius};
    border-color: #e0e1e1;
    overflow: hidden;
    text-align: left;
    box-shadow: 0 2px 4px 0 #e0e1e1, 0 3px 10px 0 #e0e1e1;
    margin-bottom: 10px;
    margin-top: 10px;
`;

export const StyledAppointmentCardHeader = styled(Card.Header)`
    background-color: ${(props) => (props.type === 'upcoming' ? '#c9d2df' : '#e0e1e1')};
`;

export const StyledMainCardTitle = styled(Card.Title)`
    text-align: center;
    color: ${(props) => props.theme.icon.passive};
`;

export const StyledAppointmentCardBody = styled(Card.Body)`
    background-color: white;
    border-top-style: solid;
    border-top-width: thin;
    border-top-color: black;
`;

export const StyledAppointmentCardFooter = styled(Card.Footer)`
    color: ${(props) => (props.status === 'cancelled' ? 'red' : 'black')};
    background-color: white;
`;

export const StyledMainCard = styled(Card)`
    color: ${(props) => props.theme.mainColor};
    background-color: ${(props) => props.theme.passiveColor};
    border-color: #e0e1e1;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    width: ${(prop) => (prop.cardwidth ? prop.cardwidth : '380px')};
    height: 190px;
    box-shadow: 0 2px 4px 0 #e0e1e1, 0 3px 10px 0 #e0e1e1;
    text-align: center;
    align-self: center;
    &:hover {
        background-color: white;
        color: ${(props) => props.theme.font.mainColorHover};
        box-shadow: 0 4px 8px 0 #e0e1e1, 0 6px 20px 0 #e0e1e1;
    }
`;

export const StyledProfileCard = styled(Card)`
    width: 600px;
    border-radius: 10px;
    border-color: #e0e1e1;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 #e0e1e1, 0 3px 10px 0 #e0e1e1;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 10px;
`;

export const StyledPhysicianSelectionCard = styled(Card)`
    width: 100%;
    border-radius: 10px;
    border-color: #e0e1e1;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 #e0e1e1, 0 3px 10px 0 #e0e1e1;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 0px;
    margin-left: 0px;
    background-color: white;
    color: black;
`;

export const StyledNewRegistrationCard = styled(Card)`
    height: 400px;
    width: 90%;
    border-radius: 10px;
    border-color: #e0e1e1;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 #e0e1e1, 0 3px 10px 0 #e0e1e1;
    margin: auto;
    margin-bottom: 10px;
    align-self: center;
    text-align: left;
`;

export const StyledNewRegistrationCardBody = styled(Card.Body)`
    background-color: rgb(36, 94, 173);
    border-top-style: solid;
    border-top-width: thin;
    border-top-color: black;
    color: white;
`;

export const StyledNewRegistrationCardImg = styled(Card.Img)`
    width: 60%;
    margin: 20px 20px;
`;

export const StyledPhotoCard = styled(Card)`
    color: ${(props) => props.theme.mainColor};
    background-color: ${(props) => props.theme.passiveColor};
    border-color: #e0e1e1;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 25px;
    width: ${(prop) => (prop.cardwidth ? prop.cardwidth : '300px')};
    height: ${(prop) => (prop.cardwidth ? prop.cardwidth : '300px')};
    box-shadow: 0 2px 4px 0 #e0e1e1, 0 3px 10px 0 #e0e1e1;
    text-align: center;
    align-self: center;
    &:hover {
        background-color: white;
        color: ${(props) => props.theme.font.mainColorHover};
        box-shadow: 0 4px 8px 0 #e0e1e1, 0 6px 20px 0 #e0e1e1;
    }
`;

export const StyledAppoinmentCategoryCard = styled(Card)<ICard>`
    background-color: ${(prop) => (prop.backgroundcolor ? prop.backgroundcolor : 'white')};
    margin-bottom: ${(prop) => (prop.marginbottom ? prop.marginbottom : null)};
`;

export const ListGroupCst = styled(ListGroup)`
    font-size: 0.85rem;
`;

export const ListGroupItemCst = styled(ListGroup.Item)`
    padding: 0.2rem 1.25rem;
`;

export const StyledWaitingCard = styled(Card)`
    color: ${(props) => props.theme.mainColor};
    background-color: ${(props) => props.theme.passiveColor};
    border-color: #e0e1e1;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    width: ${(prop) => (prop.cardwidth ? prop.cardwidth : '380px')};
    height: 190px;
    box-shadow: 0 2px 4px 0 #e0e1e1, 0 3px 10px 0 #e0e1e1;
    text-align: center;
    align-self: center;
`;

export const StyledWaitingCardBody = styled(Card.Body)`
    /* border-top-style: solid; */
    /* border-top-width: thin; */
`;

export const StyledPhysicianSelectionCardTitle = styled(Card.Title)`
    font-weight: bold;
    color: rgb(36, 94, 173);
`;
