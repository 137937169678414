import styled from 'styled-components';

interface IParagraph {
    margin?: string;
    marginBottom?: string;
    marginTop?: string;
    marginLeft?: string;
    marginRight?: string;
    fontSize?: ParagraphFontSize | string;
    display?: string;
    color?: string;
    textAlign?: string;
}

export enum ParagraphFontSize {
    ExtraSmall = '0.70rem',
    Small = '0.85rem',
    Medium = '1rem',
    Big = '1.7rem',
    ExtraBig = '2.5rem',
}

export const StyledParagraph = styled.p<IParagraph>`
    margin-bottom: ${(prop) => (prop.marginBottom ? prop.marginBottom : '1rem')};
    margin-top: ${(prop) => (prop.marginTop ? prop.marginTop : '1rem')};
    margin-left: ${(prop) => (prop.marginLeft ? prop.marginLeft : '1rem')};
    margin-right: ${(prop) => (prop.marginRight ? prop.marginRight : '1rem')};
    font-size: ${(prop) => (prop.fontSize ? prop.fontSize : ParagraphFontSize.Medium)};
    display: ${(prop) => (prop.display ? prop.display : 'block')};
    color: ${(prop) => (prop.color ? prop.color : 'black')};
    text-align: ${(prop) => (prop.textAlign ? prop.textAlign : null)};
`;

export const StyledParagraphHorizontallyCenter = styled.p<IParagraph>`
    margin-bottom: ${(prop) => (prop.marginBottom ? prop.marginBottom : '1rem')};
    margin-left: ${(prop) => (prop.marginLeft ? prop.marginLeft : '0rem')};
    font-size: ${(prop) => (prop.fontSize ? prop.fontSize : ParagraphFontSize.Medium)};
    display: ${(prop) => (prop.display ? prop.display : 'block')};
    color: ${(prop) => (prop.color ? prop.color : 'black')};
    text-align: ${(prop) => (prop.textAlign ? prop.textAlign : 'center')};
`;

export const StyledParagraphVerticallyCenter = styled.p<IParagraph>`
    margin: ${(prop) => (prop.margin ? prop.margin : 'auto')};
    font-size: ${(prop) => (prop.fontSize ? prop.fontSize : ParagraphFontSize.Medium)};
    display: ${(prop) => (prop.display ? prop.display : 'block')};
    color: ${(prop) => (prop.color ? prop.color : 'black')};
`;

export const StyledParagraphWithoutMargin = styled.p<IParagraph>`
    margin-bottom: 0;
    font-size: ${(prop) => (prop.fontSize ? prop.fontSize : ParagraphFontSize.Medium)};
`;

export const StyledParagraphGrayText = styled.p<IParagraph>`
    color: rgb(116, 118, 130);
`;

export const StyledParagraphGrayTextItalic = styled.p<IParagraph>`
    color: rgb(116, 118, 130);
    font-style: italic;
`;

export const QuestionnaireStepperTitle = styled.p<IParagraph>`
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1.7rem;
    display: block;
    color: black;

    @media screen and (max-width: 900px) {
        font-size: 1.1rem;
    }
`;
