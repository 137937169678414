import { ContentWithoutImpressum } from '../../components/Div/StyledDiv';
import Impressum, { IImpressumElement } from '../../components/Impressum/Impressum';

interface IFullScreenImpressum {
    show?: boolean;
    impressum?: IImpressumElement;
    children: React.ReactNode;
}

const FullScreenImpressum = (props: IFullScreenImpressum) => {
    const showImpressum = props.show !== undefined ? props.show : true;

    if (showImpressum && props.impressum) {
        return (
            <div>
                <ContentWithoutImpressum>{props.children}</ContentWithoutImpressum>
                <Impressum {...props.impressum} />
            </div>
        );
    } else {
        return <>{props.children}</>;
    }
};

export default FullScreenImpressum;
