import { Line } from 'react-chartjs-2';
import { VerticalCenterDiv } from '../../Div/StyledDiv';
import { IAppCharts } from '../AppCharts/AppCharts';

const EmptyView = (props: IAppCharts) => {
    const data = {
        labels: [
            'Januar',
            'Februar',
            'März',
            'April',
            'Mai',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Dezember',
        ],
        datasets: [
            {
                label: 'Keine Daten',
            },
        ],
    };

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        min: 30,
                        max: 100,
                    },
                },
            ],
        },
    };

    const EmptyViewLine = () => {
        return (
            <div>
                <Line redraw={true} data={data} options={options} />
            </div>
        );
    };

    return props.type ? (
        /* if props.type then it is called by mobile and we net to center the chart */
        <VerticalCenterDiv>
            <EmptyViewLine />
        </VerticalCenterDiv>
    ) : (
        <EmptyViewLine />
    );
};

export default EmptyView;
