import 'chartjs-plugin-annotation';
import moment, { Moment } from 'moment';
import { ITableData } from '../components/TableProvider/TableProvider';
import { IAppCharts } from './../components/Charts/AppCharts/AppCharts';
import { isMobile, isTablet } from './screenResolution';

export interface IChartProps {
    chartName?: string;
    chartType?: string;
    mobileProps?: IAppCharts;
    displayOption: number;
    chartOptions: IOptions;
    chartData: IDatasetProps;
    targetChart1?: IDatasetProps;
    targetChart2?: IDatasetProps;
    targetChart3?: IDatasetProps;
    targetBarrier?: IDatasetProps;
    targetArea?: IDatasetProps;
    targetCeiling?: IDatasetProps;
    multiLine?: IDatasetProps;
    chartViewLimits: ILimits;
    labels: any[];
    labelOption: boolean;
    tooltipPrecisionVal: number;
    // labelButton: number[];
    // setLabelOpt: (opt: boolean, val: React.SetStateAction<number[]>) => void;
}

export interface IDatasetProps {
    AXIS: string;
    COLOR: string;
    DECDIGIT: string;
    OPACITY: string;
    SHOWHD: string;
    TITLELONG: string;
    TYPE: string;
    YMIN: number;
    YMAX: number;
    data: any[];
    label: string;
}
export interface IOptions {
    animation: {
        duration: number;
    };
    scales: {
        xAxes: {
            bounds: string;
            distribution: string;
            offset: boolean;
            time: { unit: string };
            type: string;
            min: number;
            max: number;
        };
        yAxes: {
            min: number;
            max: number;
        };
        axisLeft: {
            unit: string;
        };
        axisRight: {
            unit: string;
            factorFromLeft: number;
        };
    };
    extraTables?: [
        {
            name: string;
            id: string;
        },
    ];
}

export interface ILimits {
    fiveYears: Moment;
    oneYear: Moment;
    oneMonth: Moment;
    oneWeek: Moment;
}

export interface IGetChartData {
    chartLoaded: boolean;
    foundChartData: boolean;
    chartData: IChartProps;
}

export interface IGetTableData {
    tableTitle: string;
    tableData: ITableData;
}

export interface IChartDataset {
    chartProps: IChartProps;
    barPercentage?: number;
}

export interface IChartOptions {
    chartProps: IChartProps;
    chartSettings: IChartSettings;
    ticksXMin: Moment;
    ticksXMax: Moment;
    ticksMinRotation?: number;
    ticksMaxRotation?: number;
    ticksLabelOffset?: number;
    scalesOffset?: boolean;
    updateLimits: (e: any) => void;
}

export interface IChartSettings {
    isMobile: boolean;
    height: number;
    padding: number;
    rotation: number;
}

export const initalChartData = {
    displayOption: 1,
    chartOptions: {
        animation: { duration: 0 },
        scales: {
            xAxes: { bounds: '', distribution: '', offset: false, time: { unit: '' }, type: '', min: 0, max: 0 },
            yAxes: { min: 0, max: 0 },
            axisLeft: {
                unit: '',
            },
            axisRight: {
                unit: '',
                factorFromLeft: 0,
            },
        },
    },
    chartData: {
        AXIS: '',
        COLOR: '',
        DECDIGIT: '',
        OPACITY: '',
        SHOWHD: '',
        TITLELONG: '',
        TYPE: '',
        YMIN: 0,
        YMAX: 0,
        data: [{ t: '', y: null }],
        label: '',
    },
    targetChart1: {
        AXIS: '',
        COLOR: '',
        DECDIGIT: '',
        OPACITY: '',
        SHOWHD: '',
        TITLELONG: '',
        TYPE: '',
        YMIN: 0,
        YMAX: 0,
        data: [{ t: '', y: null }],
        label: '',
    },
    targetChart2: {
        AXIS: '',
        COLOR: '',
        DECDIGIT: '',
        OPACITY: '',
        SHOWHD: '',
        TITLELONG: '',
        TYPE: '',
        YMIN: 0,
        YMAX: 0,
        data: [{ t: '', y: null }],
        label: '',
    },
    targetChart3: {
        AXIS: '',
        COLOR: '',
        DECDIGIT: '',
        OPACITY: '',
        SHOWHD: '',
        TITLELONG: '',
        TYPE: '',
        YMIN: 0,
        YMAX: 0,
        data: [{ t: '', y: null }],
        label: '',
    },
    chartViewLimits: {
        fiveYears: moment(),
        oneYear: moment(),
        oneMonth: moment(),
        oneWeek: moment(),
    },
    labels: [],
    labelOption: false,
    tooltipPrecisionVal: 1,
};

export const chartViews = {
    ONE_WEEK_VIEW: 0,
    ONE_MONTH_VIEW: 1,
    ONE_YEAR_VIEW: 2,
    FIVE_YEAR_VIEW: 3,
    ALL_VIEW: 4,
};

export const charts = {
    ACTIVITY: {
        name: 'Aktivität',
        id: 'CHARTS:ACTIVITY',
        requestId: 'CHART:ACTIVITY',
        appTypeName: 'ACTIVITY',
    },
    FATMASS: {
        name: 'Fettmasse',
        id: 'CHARTS:FATMASS',
        requestId: 'CHART:FATMASS',
        appTypeName: 'FATMASS',
    },
    FLI: {
        name: 'FLI',
        id: 'CHARTS:FLI',
        requestId: 'CHART:FLI',
        appTypeName: 'FLI',
    },
    SAM: {
        name: 'SAM',
        id: 'CHARTS:SAM',
        requestId: 'CHART:SAM',
        appTypeName: 'SAM',
    },
    WEIGHT: {
        name: 'Gewicht',
        id: 'CHARTS:WEIGHT',
        requestId: 'CHART:WEIGHT',
        appTypeName: 'WEIGHT',
    },
    BLOODSUGAR: {
        name: 'Blutzucker',
        id: 'CHARTS:BLOODSUGAR',
        requestId: 'CHART:BLOODSUGAR',
        appTypeName: 'BLOODSUGAR',
    },
};

export const tables = {
    VALTBL: {
        name: 'Rohdaten',
        id: 'CHARTS:VALTBL',
        requestId: 'CHART:VALTBL',
        appTypeName: 'VALTBL',
    },
};

/**
 *
 * @param jsonRes returns the data chronologically sorted by moment objects
 */
export function transformAndSortDates(jsonRes: { data: { datasets: string | any[] } }) {
    // convert dates (string) into moment objects
    for (let i = 0; i < jsonRes.data.datasets.length; i++) {
        for (let j = 0; j < jsonRes.data.datasets[i].data.length; j++) {
            const dateAsMoment = moment(jsonRes.data.datasets[i].data[j].t, 'ddd MMM DD LTS YYYY', 'en');
            jsonRes.data.datasets[i].data[j].t = dateAsMoment;
        }
    }

    // sort data by moment
    for (let i = 0; i < jsonRes.data.datasets.length; i++) {
        jsonRes.data.datasets[i].data.sort((a: { t: Moment; y: string }, b: { t: Moment; y: string }) => a.t.diff(b.t));
    }
    return jsonRes;
}

export function chartSettingsForDevice(view: string, screenWidth: number, screenHeight: number): IChartSettings {
    switch (view) {
        case 'max':
            if (isTablet(screenWidth)) {
                return { isMobile: false, height: screenHeight * 0.28, padding: 20, rotation: 0 };
            } else if (isMobile(screenWidth)) {
                return { isMobile: true, height: 350, padding: 30, rotation: 0 };
            } else {
                return { isMobile: false, height: 130, padding: 180, rotation: 0 };
            }
        case 'fiveYears':
            if (isTablet(screenWidth)) {
                return { isMobile: false, height: screenHeight * 0.28, padding: 30, rotation: 0 };
            } else if (isMobile(screenWidth)) {
                return { isMobile: true, height: 350, padding: 30, rotation: 0 };
            } else {
                return { isMobile: false, height: 130, padding: 100, rotation: 0 };
            }
        case 'oneYear':
            if (isTablet(screenWidth)) {
                return { isMobile: false, height: screenHeight * 0.28, padding: 30, rotation: 0 };
            } else if (isMobile(screenWidth)) {
                return { isMobile: true, height: 350, padding: 30, rotation: 0 };
            } else {
                return { isMobile: false, height: 120, padding: 60, rotation: 0 };
            }
        case 'oneMonth':
            if (isTablet(screenWidth)) {
                return { isMobile: false, height: screenHeight * 0.28, padding: 20, rotation: 0 };
            } else if (isMobile(screenWidth)) {
                return { isMobile: true, height: 300, padding: 500, rotation: 0 };
            } else {
                return { isMobile: false, height: 110, padding: 20, rotation: 0 };
            }
        case 'oneWeek':
            if (isTablet(screenWidth)) {
                return { isMobile: false, height: screenHeight * 0.28, padding: 20, rotation: 0 };
            } else if (isMobile(screenWidth)) {
                return { isMobile: true, height: 250, padding: 20, rotation: 0 };
            } else {
                return { isMobile: false, height: 100, padding: 100, rotation: 0 };
            }
        default:
            return { isMobile: false, height: 130, padding: 180, rotation: 0 };
    }
}

export const handleDisplayControllers = (props: IAppCharts | undefined): any => {
    if (props && props.showControllers !== undefined) {
        if (props.showControllers) {
            return 'block';
        } else {
            return 'none';
        }
    } else {
        return 'block';
    }
};

export const getUnitFromLabel = (props: IChartProps) => {
    const unitFromLabel = props.chartData.label.split(' ');
    const unit = unitFromLabel[unitFromLabel.length - 1];
    return unit;
};

export const getDatasetData = (props: IChartDataset) => {
    let labelsToDisplay = props.chartProps.labels;

    const unit = getUnitFromLabel(props.chartProps);

    const datasets: any[] = [];

    /* special datasets for MultiLine (Blutzucker) */
    if (props.chartProps.chartName === charts.BLOODSUGAR.id) {
        /* separate data and fill in "gaps" when date changes */
        const mmol_l: any[] = [];
        const mg_dl: any[] = [];
        const labels: any[] = [];
        const chartData = props.chartProps.chartData;
        let day = chartData.data[0].t;

        /* before a new day begins we fill in a ',' to enable gaps in the line */
        for (let i = 0; i < chartData.data.length; i++) {
            if (!chartData.data[i].t.isSame(day, 'day')) {
                day = chartData.data[i].t;
                mmol_l.push(',');
                mg_dl.push(',');
                labels.push(chartData.data[i].y);
            }
            if (chartData.data[i].y) {
                mmol_l.push(chartData.data[i]);
            }
            if (chartData.data[i].z) {
                // mg_dl.push(chartData.data[i].z);
                // mg_dl.push(chartData.data[i]);
            }

            labels.push(chartData.data[i].y);
        }
        mmol_l.push(','); /* fill in ',' at the end for the next step */
        mg_dl.push(','); /* fill in ',' at the end for the next step */
        labels.push(chartData.data[chartData.data.length - 1].y);

        /* build the dataset that spans the min and max values of every day */
        const minSpanData: any[] = [];
        const maxSpanData: any[] = [];
        let localMin: any;
        let localMax: any;
        let dailyData: any[] = [];
        let firstDailyDataMax: any;
        let firstDailyDataMin: any;
        let foundFirstData = false;
        for (let i = 0; i < mmol_l.length; i++) {
            /* build up one array per day */
            if (mmol_l[i] !== ',') {
                // dailyData.push(Number(mmol_l[i]));
                dailyData.push(mmol_l[i]);
                if (!foundFirstData) {
                    firstDailyDataMax = { ...mmol_l[i] };
                    firstDailyDataMin = { ...mmol_l[i] };
                    foundFirstData = true;
                }
            } else {
                /* get min and max values from one day */
                foundFirstData = false;
                let min = +Infinity;
                let max = -Infinity;
                for (let m = 0; m < dailyData.length; m++) {
                    const yNumber = Number(dailyData[m].y);
                    if (yNumber < min) {
                        min = yNumber;
                        localMin = dailyData[m];
                    }
                    if (yNumber > max) {
                        max = yNumber;
                        localMax = dailyData[m];
                    }
                }

                /* add data points with min and max values to create the spanning data */
                const lastDailyDataMax = { ...dailyData[dailyData.length - 1] };
                lastDailyDataMax.y = localMax;
                firstDailyDataMax.y = localMax;

                const lastDailyDataMin = { ...dailyData[dailyData.length - 1] };
                lastDailyDataMin.y = localMin;
                firstDailyDataMin.y = localMin;

                maxSpanData.push(firstDailyDataMax);
                maxSpanData.push(localMax);
                maxSpanData.push(lastDailyDataMax);

                // lastDailyData.y = localMin;
                // firstDailyData.y = localMin;

                minSpanData.push(firstDailyDataMin);
                minSpanData.push(localMin);
                minSpanData.push(lastDailyDataMin);
                /* fill in gaps after every day for correct label mapping of the data */
                minSpanData.push(',');
                maxSpanData.push(',');
                dailyData = [];
            }
        }

        labelsToDisplay = labels;

        datasets.push(
            {
                label: 'Blutzucker',
                data: mmol_l,
                // yAxisID: 'y-axis-1',
                borderColor: '#ed90d5',
                backgroundColor: '#ed90d5',
                fill: false,
                lineTension: 0,
                barPercentage: props.barPercentage ? props.barPercentage : 5,
                datalabels: {
                    display: props.chartProps.labelOption, // if labels are enabled, tooltips are disabled and vice versa
                    align: '-45',
                    anchor: 'end',
                    offset: 5,
                    borderRadius: 4,
                    backgroundColor: 'rgb(255,255,216)',
                    opacity: 0.7,
                    color: 'black',
                    font: {
                        weight: 'bold',
                    },
                    formatter: function (
                        value: any,
                        context: { chart: { data: { labels: { [x: string]: any } } }; dataIndex: string | number },
                    ) {
                        const valLabel = Number(context.chart.data.labels[context.dataIndex]).toFixed(
                            props.chartProps.tooltipPrecisionVal,
                        );
                        return valLabel + ' ' + props.chartProps.chartOptions.scales.axisLeft.unit;
                    },
                },
            },
            {
                label: 'Tagesminimum',
                data: minSpanData,
                borderColor: props.chartProps.chartData.COLOR,
                backgroundColor: 'rgba(255,192,203, 0.2)',
                fill: '+1',
                spanGaps: true,
                lineTension: 0,
                pointRadius: 0, //don't show point in span
                barPercentage: props.barPercentage ? props.barPercentage : 5,
                datalabels: {
                    display: false, // if labels are enabled, tooltips are disabled and vice versa
                    align: '-45',
                    anchor: 'end',
                    offset: 5,
                    borderRadius: 4,
                    backgroundColor: 'rgb(255,255,216)',
                    opacity: 0.7,
                    color: 'black',
                    font: {
                        weight: 'bold',
                    },
                    formatter: function (
                        value: any,
                        context: { chart: { data: { labels: { [x: string]: any } } }; dataIndex: string | number },
                    ) {
                        return (
                            context.chart.data.labels[context.dataIndex] +
                            ' ' +
                            props.chartProps.chartOptions.scales.axisLeft.unit
                        );
                    },
                },
            },
            {
                label: 'Tagesmaximum',
                data: maxSpanData,
                borderColor: props.chartProps.chartData.COLOR,
                backgroundColor: 'rgba(255,192,203, 0.2)',
                spanGaps: true,
                fill: '-1',
                lineTension: 0,
                pointRadius: 0, //don't show point in span
                barPercentage: props.barPercentage ? props.barPercentage : 5,
                datalabels: {
                    display: false, // if labels are enabled, tooltips are disabled and vice versa
                    align: '-45',
                    anchor: 'end',
                    offset: 5,
                    borderRadius: 4,
                    backgroundColor: 'rgb(255,255,216)',
                    opacity: 0.7,
                    color: 'black',
                    font: {
                        weight: 'bold',
                    },
                    formatter: function (
                        value: any,
                        context: { chart: { data: { labels: { [x: string]: any } } }; dataIndex: string | number },
                    ) {
                        return (
                            context.chart.data.labels[context.dataIndex] +
                            ' ' +
                            props.chartProps.chartOptions.scales.axisLeft.unit
                        );
                    },
                },
            },
        );
        /* all other Charts */
    } else {
        /* add datapoints */
        datasets.push({
            label: props.chartProps.chartData.label,
            data: props.chartProps.chartData.data,
            borderColor: props.chartProps.chartData.COLOR,
            backgroundColor:
                props.chartProps.chartName === charts.ACTIVITY.id
                    ? 'rgba(170, 206, 41, 1)'
                    : props.chartProps.chartData.COLOR,
            fill: false,
            lineTension: 0,
            barPercentage: props.barPercentage ? props.barPercentage : 5,
            datalabels: {
                display: props.chartProps.labelOption, // if labels are enabled, tooltips are disabled and vice versa
                align: '-45',
                anchor: 'end',
                offset: 5,
                borderRadius: 4,
                backgroundColor: 'rgb(255,255,216)',
                opacity: 0.7,
                color: 'black',
                font: {
                    weight: 'bold',
                },
                formatter: function (
                    value: any,
                    context: { chart: { data: { labels: { [x: string]: any } } }; dataIndex: string | number },
                ) {
                    return context.chart.data.labels[context.dataIndex] + ' ' + unit; // labels with unit from dataset (e.g. "kg", "%")
                },
            },
        });

        /* add targetChart1 if not empty */
        if (props.chartProps.targetChart1 && props.chartProps.targetChart1.label.length > 0) {
            datasets.push({
                label: props.chartProps.targetChart1.label,
                data: props.chartProps.targetChart1.data,
                fill: unit === 'kg' ? 'end' : '+1', // fill to the top of the chart (='end') for weightChart and to dataset 2 (='+1') for bodyfatChart
                borderColor: props.chartProps.targetChart1.COLOR,
                backgroundColor: unit === 'kg' ? 'rgba(255, 99, 132, 0.2)' : 'rgb(193, 213, 249, 0.4)',
                borderWidth: 1,
                steppedLine: 'before',
                radius: 0,
                datalabels: {
                    labels: {
                        title: null,
                    },
                },
            });
        }

        /* add targetChart2 if not empty */
        if (props.chartProps.targetChart2 && props.chartProps.targetChart2.label.length > 0) {
            datasets.push({
                label: props.chartProps.targetChart2.label,
                data: props.chartProps.targetChart2.data,
                fill: unit === 'kg' ? 1 : '+2', // fill to dataset 1 = targetWeight1 for weightChart and to dataset 4 (='+2') for bodyfatChart (skip hidden dataset)
                borderColor: props.chartProps.targetChart2.COLOR,
                backgroundColor: unit === 'kg' ? 'rgba(255, 206, 86, 0.2)' : 'rgb(123, 165, 243, 0.4)',
                borderWidth: 1,
                steppedLine: 'before',
                radius: 0,
                datalabels: {
                    labels: {
                        title: null,
                    },
                },
            });
        }

        /* add hidden dataset */
        datasets.push({
            label: 'hiddenDataset',
            data: props.chartProps.targetChart2 && props.chartProps.targetChart2.data,
            fill: unit === 'kg' ? '+1' : false, // fill to dataset +1 = targetWeight3 for weightChart and don't fill for bodyfatChart
            borderColor: props.chartProps.targetChart2 && props.chartProps.targetChart2.COLOR,
            backgroundColor: 'rgba(255, 206, 86, 0.2)',
            borderWidth: 1,
            steppedLine: 'before',
            radius: 0,
            datalabels: {
                labels: {
                    title: null,
                },
            },
        });

        /* add targetChart3 if not empty */
        if (props.chartProps.targetChart3 && props.chartProps.targetChart3.label.length > 0) {
            datasets.push({
                label: props.chartProps.targetChart3.label,
                data: props.chartProps.targetChart3.data,
                fill: true, // "fill area to the bottom of the chart"
                borderColor: props.chartProps.targetChart3.COLOR,
                backgroundColor: unit === 'kg' ? 'rgba(75, 192, 192, 0.2)' : 'rgb(53, 117, 237, 0.4)',
                borderWidth: 1,
                steppedLine: 'before',
                radius: 0,
                datalabels: {
                    labels: {
                        title: null,
                    },
                },
            });
        }
    }

    const data: [any[], any[]] = [datasets, labelsToDisplay];
    return data;
};

export const getChartOptionsData = (props: IChartOptions) => {
    const unit = getUnitFromLabel(props.chartProps);

    let options: any = undefined;
    if (props.chartProps.chartName === charts.BLOODSUGAR.id) {
        options = {
            animation: false,
            scales: {
                xAxes: [
                    {
                        offset: props.scalesOffset ? props.scalesOffset : false,
                        type: 'time',
                        time: {
                            displayFormats: {
                                day: 'DD.MM.YYYY',
                                hour: 'DD.MM.YYYY HH:mm',
                                minute: 'DD.MM.YYYY HH:mm',
                                second: 'DD.MM.YYYY HH:mm',
                                millisecond: 'DD.MM.YYYY HH:mm',
                            },
                            isoWeekday: true,
                        },
                        ticks: {
                            min: props.ticksXMin,
                            max: props.ticksXMax,
                            maxRotation: props.ticksMaxRotation ? props.ticksMaxRotation : props.chartSettings.rotation,
                            minRotation: props.ticksMinRotation ? props.ticksMinRotation : 0,
                            autoSkipPadding: props.chartSettings.padding,
                            labelOffset: props.ticksLabelOffset ? props.ticksLabelOffset : 0,
                            autoSkip: true,
                            maxTicksLimit: props.chartSettings.isMobile ? 2 : 4,
                        },
                    },
                ],
                yAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            fontSize: 14,
                            labelString: props.chartProps.chartOptions.scales.axisLeft.unit,
                        },

                        ticks: {
                            suggestedMin: props.chartProps.chartData.YMIN,
                            suggestedMax: props.chartProps.chartData.YMAX,
                        },
                    },
                    // {
                    //     position: 'right',
                    //     scaleLabel: {
                    //         display: true,
                    //         fontSize: 14,
                    //         labelString: 'mg/dl',
                    //     },

                    //     ticks: {
                    //         suggestedMin: props.chartProps.chartData.YMIN,
                    //         suggestedMax: props.chartProps.chartData.YMAX,
                    //     },
                    // },
                ],
            },
            legend: {
                labels: {
                    filter: function (item: { text: string | string[] }) {
                        if (item.text) {
                            return !item.text.includes('hiddenDataset'); // Don't show a legend for the duplicated dataset
                        }
                    },
                },
            },
            tooltips: {
                intersect: props.chartProps.labelOption, // if labels are displayed tooltips are only shown when mouse position intersects with element (less sensitive), for better experience
                backgroundColor: 'rgb(255,255,216)',
                titleFontColor: 'black',
                callbacks: {
                    label: function (tooltipItem: { yLabel: any }) {
                        if (tooltipItem?.yLabel) {
                            const val = Number(tooltipItem?.yLabel).toFixed(props.chartProps.tooltipPrecisionVal);
                            return val + ' ' + props.chartProps.chartOptions.scales.axisLeft.unit;
                        }
                    },
                    title: function (tooltipItem: any) {
                        if (tooltipItem[0]?.xLabel) {
                            return moment(tooltipItem[0]?.xLabel, 'MMM D, YYYY h:mm:ss A', 'de').format(
                                'DD.MM.YYYY HH:mm',
                            );
                        }
                    },
                    labelTextColor: function () {
                        return 'black';
                    },
                },
                filter: function (tooltipItem: { datasetIndex: number }) {
                    if (tooltipItem.datasetIndex === 0) {
                        return true;
                    } else {
                        return false;
                    }
                    // return tooltipItem.datasetIndex !== 3; // don't show tooltips for duplicated dataset
                },
            },
            zoom: {
                enabled: true,
                mode: 'x',
                // Function called once zooming is completed
                onZoomComplete: props.updateLimits,
            },
            pan: {
                enabled: true,
                mode: 'x',
                // Function called once panning is completed
                onPanComplete: props.updateLimits,
            },
        };
    } else {
        options = {
            animation: false,
            scales: {
                xAxes: [
                    {
                        offset: props.scalesOffset ? props.scalesOffset : false,
                        type: 'time',
                        time: {
                            displayFormats: {
                                day: props.chartProps.chartName === charts.ACTIVITY.id ? 'DD.MM.YYYY' : 'DD.MM.YYYY',
                                hour:
                                    props.chartProps.chartName === charts.ACTIVITY.id
                                        ? 'DD.MM.YYYY'
                                        : 'DD.MM.YYYY HH:mm',
                                minute:
                                    props.chartProps.chartName === charts.ACTIVITY.id
                                        ? 'DD.MM.YYYY'
                                        : 'DD.MM.YYYY HH:mm',
                                second:
                                    props.chartProps.chartName === charts.ACTIVITY.id
                                        ? 'DD.MM.YYYY'
                                        : 'DD.MM.YYYY HH:mm',
                                millisecond:
                                    props.chartProps.chartName === charts.ACTIVITY.id
                                        ? 'DD.MM.YYYY'
                                        : 'DD.MM.YYYY HH:mm',
                            },
                            isoWeekday: true,
                        },
                        ticks: {
                            min: props.ticksXMin,
                            max: props.ticksXMax,
                            maxRotation: props.ticksMaxRotation ? props.ticksMaxRotation : props.chartSettings.rotation,
                            minRotation: props.ticksMinRotation ? props.ticksMinRotation : 0,
                            autoSkipPadding: props.chartSettings.padding,
                            labelOffset: props.ticksLabelOffset ? props.ticksLabelOffset : 0,
                            autoSkip: true,
                            maxTicksLimit: props.chartSettings.isMobile ? 2 : 4,
                        },
                    },
                ],
                yAxes: [
                    {
                        ticks: {
                            suggestedMin: props.chartProps.chartData.YMIN,
                            suggestedMax: props.chartProps.chartData.YMAX,
                        },
                    },
                ],
            },
            legend: {
                labels: {
                    filter: function (item: { text: string | string[] }) {
                        if (item.text) {
                            return !item.text.includes('hiddenDataset'); // Don't show a legend for the duplicated dataset
                        }
                    },
                },
            },
            tooltips: {
                intersect: props.chartProps.labelOption, // if labels are displayed tooltips are only shown when mouse position intersects with element (less sensitive), for better experience
                backgroundColor: 'rgb(255,255,216)',
                titleFontColor: 'black',
                callbacks: {
                    label: function (tooltipItem: { yLabel: any }) {
                        return Number(tooltipItem?.yLabel).toFixed(props.chartProps.tooltipPrecisionVal) + ' ' + unit;
                    },
                    title: function (tooltipItem: any) {
                        return moment(tooltipItem[0]?.xLabel, 'MMM D, YYYY h:mm:ss A', 'de').format(
                            props.chartProps.chartName === charts.ACTIVITY.id ? 'DD.MM.YYYY' : 'DD.MM.YYYY HH:mm',
                        );
                    },
                    labelTextColor: function () {
                        return 'black';
                    },
                },
                filter: function (tooltipItem: { datasetIndex: number }) {
                    return tooltipItem.datasetIndex !== 3; // don't show tooltips for duplicated dataset
                },
            },
            zoom: {
                enabled: true,
                mode: 'x',
                // Function called once zooming is completed
                onZoomComplete: props.updateLimits,
            },
            pan: {
                enabled: true,
                mode: 'x',
                // Function called once panning is completed
                onPanComplete: props.updateLimits,
            },
        };

        /* use of chartjs-plugin-annotation - Box Annotations */
        if (props.chartProps.chartName === charts.FLI.id) {
            options.annotation = {
                annotations: [
                    {
                        type: 'box',
                        drawTime: 'beforeDatasetsDraw',
                        yMin: 0,
                        yMax: props.chartProps.targetBarrier?.data[0].y,
                        backgroundColor: 'rgba(184, 216, 62, 0.5)',
                        borderWidth: 0,
                        borderColor: 'transparent',
                        yScaleID: 'y-axis-0',
                    },
                    {
                        type: 'box',
                        drawTime: 'beforeDatasetsDraw',
                        yMin: props.chartProps.targetBarrier?.data[0].y,
                        yMax: props.chartProps.targetArea?.data[0].y,
                        backgroundColor: 'rgba(249, 205, 90, 0.5)',
                        borderWidth: 0,
                        borderColor: 'transparent',
                        yScaleID: 'y-axis-0',
                    },
                    {
                        type: 'box',
                        drawTime: 'beforeDatasetsDraw',
                        yMin: props.chartProps.targetArea?.data[0].y,
                        yMax: 100,
                        backgroundColor: 'rgba(205, 50, 50, 0.5)',
                        borderWidth: 0,
                        borderColor: 'transparent',
                        yScaleID: 'y-axis-0',
                    },
                ],
            };
        }
    }

    return options;
};

export const getTableDataFromDiagnosticReportResponse = (diagnosticReportResponse: any) => {
    let tableTitle = '';
    // let tableData = {} as ITableData;
    let tableData = {} as any;

    if (diagnosticReportResponse) {
        tableTitle = diagnosticReportResponse.data.entry[0].resource.presentedForm[0].title;
        tableData = diagnosticReportResponse.data.entry[0].resource.presentedForm[0].extension[0].valueString;
        const jsonRes = JSON.parse(tableData);
        tableData = jsonRes;
    }

    const tableAllData: [string, ITableData] = [tableTitle, tableData];
    return tableAllData;
};

export const getTableHeight = (screenHeight: number) => {
    const tableHeight = screenHeight - 150;
    return tableHeight.toString() + 'px';
};
