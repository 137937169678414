import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import StyledButton from '../../Buttons/StyledButton';

export const ModalCst = styled(Modal)`
    .modal-dialog {
        max-width: 90%;
        margin: 1.75rem auto;
    }
    .modal-content {
        border-radius: ${(prop) => (prop.borderRadius ? prop.borderRadius : prop.theme.modal.borderRadius)};
        box-shadow: ${(prop) => (prop.boxShadow ? prop.boxShadow : prop.theme.modal.boxShadow)};
    }
`;

export interface IBlockingAppointmentModal {
    show: boolean;
    onHide: any;
}

const BlockingAppointmentModal = (props: IBlockingAppointmentModal) => {
    // useEffect(() => {}, [props.show]);

    return (
        <>
            <ModalCst show={props.show}>
                <Modal.Header>
                    <Modal.Title>Dieser Timeslot wurde in der Zwischenzeit bereits vergeben</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bitte wählen Sie einen neuen Termin aus.</Modal.Body>
                <Modal.Footer>
                    <StyledButton onClick={() => props.onHide()}>OK</StyledButton>
                </Modal.Footer>
            </ModalCst>
        </>
    );
};

export default BlockingAppointmentModal;
