import { useContext, useEffect, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import { FaChartPie, FaWeight } from 'react-icons/fa';
import { GiLiver } from 'react-icons/gi';
import { ImDroplet, ImTable } from 'react-icons/im';
import {
    RiBarChart2Line,
    RiCalendarCheckLine,
    RiCalendarEventLine,
    RiCameraLine,
    RiFileList2Line,
    RiFolder2Line,
    RiFootprintFill,
    RiGitMergeLine,
    RiHospitalLine,
    RiLiveLine,
    RiMenuFill,
    RiTaskLine,
    RiUserSearchFill,
} from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../Routes';
import LoginContext from '../../context/loginContext';
import { securityItemNames } from '../../globals/global';
import useScreenResolution from '../../hooks/useScreenResolution';
import { useSecurityItems } from '../../hooks/useSecurityItems';
import { charts, tables } from '../../utils/chartUtils';
import { isMobile } from '../../utils/screenResolution';
import SidebarItem from '../SidebarItems/SidebarItem';

const SidebarItemList = () => {
    const navigate = useNavigate();
    const { state, dispatch } = useContext(LoginContext);
    const [itemOrderIsSet, setItemOrderIsSet] = useState(false);
    const {
        isPatListEnabled,
        isDokumenteEnabled,
        isTermineEnabled,
        isFotosEnabled,
        isMedikationEnabled,
        isTagesplanEnabled,
        isFragebogenEnabled,
        isVideochatEnabled,
        isChartsEnabled,
        isChartsWeightEnabled,
        isChartsBodyFatEnabled,
        isChartsBloodSugarEnabled,
        isChartsFLIEnabled,
        isChartsSAMEnabled,
        isChartsActivityEnabled,
        isTableVALEnabled,
        isJourneyEnabled,
        isTodoEnabled,
        getTabOrder,
        setActiveSidebarItem,
    } = useSecurityItems();

    const screenSize = useScreenResolution();

    useEffect(() => {
        if (!state.setOfInitPageDone) {
            dispatch({
                type: 'SETOFINITPAGEDONE',
                setOfInitPageDone: true,
            });
            if (isJourneyEnabled()) {
                setActiveSidebarItem(Paths.JOURNEY);
                navigate(Paths.JOURNEY);
            } else {
                const defaultPage = getDefaultPage();
                setActiveSidebarItem(defaultPage);
                navigate(defaultPage);
            }
        }
    }, [state.setOfInitPageDone]);

    const getDefaultPage = () => {
        /* Default Page when no TAB_ORDER from SecurityItems*/
        let route = Paths.DOKUMENTE;
        if (state.practitionerRoleId) {
            route = Paths.PATLIST;
        }
        const tabOrderFromSecurityItems = getTabOrder();
        if (tabOrderFromSecurityItems.length > 0) {
            /* first item from SecurityItems.TAB_ORDER is defaultPage */
            const firstItem = tabOrderFromSecurityItems[0];
            if (firstItem !== undefined && firstItem === securityItemNames.JOURNEY && isJourneyEnabled()) {
                route = Paths.JOURNEY;
            }
            if (firstItem !== undefined && firstItem === securityItemNames.PATLIST) {
                route = Paths.PATLIST;
            }
            if (firstItem !== undefined && firstItem === securityItemNames.DOKUMENT) {
                route = Paths.DOKUMENTE;
            }
            if (firstItem !== undefined && firstItem === securityItemNames.TERMINE) {
                route = Paths.TERMINE;
            }
            if (firstItem !== undefined && firstItem === securityItemNames.FOTOS) {
                route = Paths.FOTOS;
            }
            if (firstItem !== undefined && firstItem === securityItemNames.MEDIKATION) {
                route = Paths.MEDIKATION;
            }
            if (firstItem !== undefined && firstItem === securityItemNames.TAGESPLAN) {
                route = Paths.TAGESPLAN;
            }
            if (firstItem !== undefined && firstItem === securityItemNames.FRAGEBOGEN) {
                route = Paths.FRAGEBOGEN;
            }
            if (firstItem !== undefined && firstItem === securityItemNames.VIDEO) {
                route = Paths.VIDEOCHAT;
            }
            if (firstItem !== undefined && firstItem === securityItemNames.CHARTS) {
                route = Paths.CHARTS;
            }
            if (firstItem !== undefined && firstItem === securityItemNames.CHARTS_WEIGHT) {
                route = Paths.CHARTS_GEWICHT;
            }
            if (firstItem !== undefined && firstItem === securityItemNames.CHARTS_FATMASS) {
                route = Paths.CHARTS_FETTMASSE;
            }
            if (firstItem !== undefined && firstItem === securityItemNames.CHARTS_BLOODSUGAR) {
                route = Paths.CHARTS_BLUTZUCKER;
            }
            if (firstItem !== undefined && firstItem === securityItemNames.CHARTS_FLI) {
                route = Paths.CHARTS_FLI;
            }
            if (firstItem !== undefined && firstItem === securityItemNames.CHARTS_SAM) {
                route = Paths.CHARTS_SAM;
            }
            if (firstItem !== undefined && firstItem === securityItemNames.CHARTS_ACTIVITY) {
                route = Paths.CHARTS_ACTIVITY;
            }
            if (firstItem !== undefined && firstItem === securityItemNames.TABLE_VAL) {
                route = Paths.TABLE_VAL;
            }
            if (firstItem !== undefined && firstItem === securityItemNames.TODO) {
                route = Paths.TODO;
            }
        }

        return route;
    };

    const isPhysicanLoginWithoutSelectedPatient = () => {
        if (state.practitionerRoleId && (state.activePatient === undefined || state.activePatient.length <= 0)) {
            return true;
        }
        return false;
    };

    const showPatList = (forMoreMenu: boolean) => {
        if (isPatListEnabled()) {
            return (
                <SidebarItem
                    key="Patienten"
                    route={Paths.PATLIST}
                    label="Patienten"
                    icon={<RiUserSearchFill />}
                    forMoreMenu={forMoreMenu}
                ></SidebarItem>
            );
        } else {
            return null;
        }
    };

    const showDokumente = (forMoreMenu: boolean) => {
        if (isPhysicanLoginWithoutSelectedPatient()) {
            return null;
        }
        if (isDokumenteEnabled()) {
            return (
                <SidebarItem
                    key="Dokumente"
                    route={Paths.DOKUMENTE}
                    label="Dokumente"
                    icon={<RiFolder2Line />}
                    forMoreMenu={forMoreMenu}
                ></SidebarItem>
            );
        } else {
            return null;
        }
    };

    const showTermine = (forMoreMenu: boolean) => {
        if (isPhysicanLoginWithoutSelectedPatient()) {
            return null;
        }
        if (isTermineEnabled()) {
            return (
                <SidebarItem
                    key="Termine"
                    route={Paths.TERMINE}
                    label="Termine"
                    icon={<RiCalendarEventLine />}
                    forMoreMenu={forMoreMenu}
                ></SidebarItem>
            );
        } else {
            return null;
        }
    };

    const showFotos = (forMoreMenu: boolean) => {
        if (isPhysicanLoginWithoutSelectedPatient()) {
            return null;
        }
        if (isFotosEnabled()) {
            return (
                <SidebarItem
                    key="Fotos"
                    route={Paths.FOTOS}
                    label="Fotos"
                    icon={<RiCameraLine />}
                    forMoreMenu={forMoreMenu}
                ></SidebarItem>
            );
        } else {
            return null;
        }
    };

    const showMedikation = (forMoreMenu: boolean) => {
        if (isPhysicanLoginWithoutSelectedPatient()) {
            return null;
        }
        if (isMedikationEnabled()) {
            return (
                <SidebarItem
                    key="Medikation"
                    route={Paths.MEDIKATION}
                    label="Medikation"
                    icon={<RiHospitalLine />}
                    forMoreMenu={forMoreMenu}
                ></SidebarItem>
            );
        } else {
            return null;
        }
    };

    const showTagesplan = (forMoreMenu: boolean) => {
        if (isPhysicanLoginWithoutSelectedPatient()) {
            return null;
        }
        if (isTagesplanEnabled()) {
            return (
                <SidebarItem
                    key="Tagesplan"
                    route={Paths.TAGESPLAN}
                    label="Tagesplan"
                    icon={<RiCalendarCheckLine />}
                    forMoreMenu={forMoreMenu}
                ></SidebarItem>
            );
        } else {
            return null;
        }
    };

    const showFragebogen = (forMoreMenu: boolean) => {
        if (isPhysicanLoginWithoutSelectedPatient()) {
            return null;
        }
        if (isFragebogenEnabled()) {
            return (
                <SidebarItem
                    key="Fragebogen"
                    route={Paths.FRAGEBOGEN}
                    label="Fragebogen"
                    icon={<RiFileList2Line />}
                    forMoreMenu={forMoreMenu}
                ></SidebarItem>
            );
        } else {
            return null;
        }
    };

    const showVideochat = (forMoreMenu: boolean) => {
        if (isPhysicanLoginWithoutSelectedPatient()) {
            return null;
        }
        if (isVideochatEnabled()) {
            return (
                <SidebarItem
                    key="Videochat"
                    route={Paths.VIDEOCHAT}
                    label="Videochat"
                    icon={<RiLiveLine />}
                    forMoreMenu={forMoreMenu}
                ></SidebarItem>
            );
        } else {
            return null;
        }
    };

    const showJourney = (forMoreMenu: boolean) => {
        if (isPhysicanLoginWithoutSelectedPatient()) {
            return null;
        }
        if (isJourneyEnabled()) {
            return (
                <SidebarItem
                    key="Journey"
                    route={Paths.JOURNEY}
                    label="Journey"
                    icon={<RiGitMergeLine />}
                    forMoreMenu={forMoreMenu}
                ></SidebarItem>
            );
        } else {
            return null;
        }
    };

    const showCharts = (forMoreMenu: boolean) => {
        if (isPhysicanLoginWithoutSelectedPatient()) {
            return null;
        }
        if (isChartsEnabled()) {
            return (
                <SidebarItem
                    key="Charts"
                    route={Paths.CHARTS}
                    label="Charts"
                    icon={<RiBarChart2Line />}
                    forMoreMenu={forMoreMenu}
                ></SidebarItem>
            );
        } else {
            return null;
        }
    };

    const showChartWeight = (forMoreMenu: boolean) => {
        if (isPhysicanLoginWithoutSelectedPatient()) {
            return null;
        }
        if (isChartsWeightEnabled()) {
            return (
                <div key={charts.WEIGHT.name} style={{ paddingLeft: '25px' }}>
                    <SidebarItem
                        route={Paths.CHARTS_GEWICHT}
                        label={charts.WEIGHT.name}
                        icon={<FaWeight />}
                        forMoreMenu={forMoreMenu}
                    ></SidebarItem>
                </div>
            );
        } else {
            return null;
        }
    };

    const showChartBodyFat = (forMoreMenu: boolean) => {
        if (isPhysicanLoginWithoutSelectedPatient()) {
            return null;
        }
        if (isChartsBodyFatEnabled()) {
            return (
                <div key={charts.FATMASS.name} style={{ paddingLeft: '25px' }}>
                    <SidebarItem
                        route={Paths.CHARTS_FETTMASSE}
                        label={charts.FATMASS.name}
                        icon={<FaChartPie />}
                        forMoreMenu={forMoreMenu}
                    ></SidebarItem>
                </div>
            );
        } else {
            return null;
        }
    };

    const showChartBloodSugar = (forMoreMenu: boolean) => {
        if (isPhysicanLoginWithoutSelectedPatient()) {
            return null;
        }
        // if (true) {
        if (isChartsBloodSugarEnabled()) {
            return (
                <div key={charts.BLOODSUGAR.name} style={{ paddingLeft: '25px' }}>
                    <SidebarItem
                        // {...props}

                        route={Paths.CHARTS_BLUTZUCKER}
                        label={charts.BLOODSUGAR.name}
                        icon={<ImDroplet />}
                        forMoreMenu={forMoreMenu}
                    ></SidebarItem>
                </div>
            );
        } else {
            return null;
        }
    };

    const showChartFLI = (forMoreMenu: boolean) => {
        if (isPhysicanLoginWithoutSelectedPatient()) {
            return null;
        }
        // if (true) {
        if (isChartsFLIEnabled()) {
            return (
                <div key={charts.FLI.name} style={{ paddingLeft: '25px' }}>
                    <SidebarItem
                        // {...props}

                        route={Paths.CHARTS_FLI}
                        label={charts.FLI.name}
                        icon={<GiLiver />}
                        forMoreMenu={forMoreMenu}
                    ></SidebarItem>
                </div>
            );
        } else {
            return null;
        }
    };

    const showChartSAM = (forMoreMenu: boolean) => {
        if (isPhysicanLoginWithoutSelectedPatient()) {
            return null;
        }
        // if (true) {
        if (isChartsSAMEnabled()) {
            return (
                <div key={charts.SAM.name} style={{ paddingLeft: '25px' }}>
                    <SidebarItem
                        // {...props}

                        route={Paths.CHARTS_SAM}
                        label={charts.SAM.name}
                        icon={<FaChartPie />}
                        forMoreMenu={forMoreMenu}
                    ></SidebarItem>
                </div>
            );
        } else {
            return null;
        }
    };

    const showChartActivity = (forMoreMenu: boolean) => {
        if (isPhysicanLoginWithoutSelectedPatient()) {
            return null;
        }
        // if (true) {
        if (isChartsActivityEnabled()) {
            return (
                <div key={charts.ACTIVITY.name} style={{ paddingLeft: '25px' }}>
                    <SidebarItem
                        // {...props}

                        route={Paths.CHARTS_ACTIVITY}
                        label={charts.ACTIVITY.name}
                        icon={<RiFootprintFill />}
                        forMoreMenu={forMoreMenu}
                    ></SidebarItem>
                </div>
            );
        } else {
            return null;
        }
    };

    const showTableVAL = (forMoreMenu: boolean) => {
        if (isPhysicanLoginWithoutSelectedPatient()) {
            return null;
        }
        // if (true) {
        if (isTableVALEnabled()) {
            return (
                <div key={tables.VALTBL.name} style={{ paddingLeft: '25px' }}>
                    <SidebarItem
                        // {...props}

                        route={Paths.TABLE_VAL}
                        label={tables.VALTBL.name}
                        icon={<ImTable />}
                        forMoreMenu={forMoreMenu}
                    ></SidebarItem>
                </div>
            );
        } else {
            return null;
        }
    };

    const showTodo = (forMoreMenu: boolean) => {
        if (isPhysicanLoginWithoutSelectedPatient()) {
            return null;
        }
        if (isTodoEnabled()) {
            return (
                <>
                    <SidebarItem
                        key="ToDo"
                        route={Paths.TODO}
                        label="ToDo"
                        icon={<RiTaskLine />}
                        forMoreMenu={forMoreMenu}
                    ></SidebarItem>
                </>
            );
        } else {
            return null;
        }
    };

    interface IOrderSidebarItems {
        securityItemName: string;
        item: any;
        forMoreMenu: boolean;
    }

    const orderSidebarItemsWorkflow = (startItemNr: number, tabOrderList: string[], maxSidebarItems: number) => {
        const allSidebarItems: IOrderSidebarItems[] = [];

        let itemNr = startItemNr;
        tabOrderList.forEach((tab) => {
            let forMoreMenu = false;

            if (maxSidebarItems > 0 && itemNr >= maxSidebarItems - 1) {
                forMoreMenu = true;
            }
            if (tab !== undefined && tab === securityItemNames.JOURNEY) {
                const journey = showJourney(forMoreMenu);
                if (journey) {
                    itemNr++;
                    allSidebarItems.push({
                        securityItemName: securityItemNames.JOURNEY,
                        item: journey,
                        forMoreMenu: forMoreMenu,
                    });
                }
            }
            if (tab !== undefined && tab === securityItemNames.PATLIST) {
                const patList = showPatList(forMoreMenu);
                if (patList) {
                    itemNr++;
                    allSidebarItems.push({
                        securityItemName: securityItemNames.PATLIST,
                        item: patList,
                        forMoreMenu: forMoreMenu,
                    });
                }
            }
            if (tab !== undefined && tab === securityItemNames.DOKUMENT) {
                const dokumente = showDokumente(forMoreMenu);
                if (dokumente) {
                    itemNr++;
                    allSidebarItems.push({
                        securityItemName: securityItemNames.DOKUMENT,
                        item: dokumente,
                        forMoreMenu: forMoreMenu,
                    });
                }
            }
            if (tab !== undefined && tab === securityItemNames.TERMINE) {
                const termine = showTermine(forMoreMenu);
                if (termine) {
                    itemNr++;
                    allSidebarItems.push({
                        securityItemName: securityItemNames.TERMINE,
                        item: termine,
                        forMoreMenu: forMoreMenu,
                    });
                }
            }
            if (tab !== undefined && tab === securityItemNames.FOTOS) {
                const fotos = showFotos(forMoreMenu);
                if (fotos) {
                    itemNr++;
                    allSidebarItems.push({
                        securityItemName: securityItemNames.FOTOS,
                        item: fotos,
                        forMoreMenu: forMoreMenu,
                    });
                }
            }
            if (tab !== undefined && tab === securityItemNames.MEDIKATION) {
                const medikation = showMedikation(forMoreMenu);
                if (medikation) {
                    itemNr++;
                    allSidebarItems.push({
                        securityItemName: securityItemNames.MEDIKATION,
                        item: medikation,
                        forMoreMenu: forMoreMenu,
                    });
                }
            }
            if (tab !== undefined && tab === securityItemNames.TAGESPLAN) {
                const tagesplan = showTagesplan(forMoreMenu);
                if (tagesplan) {
                    itemNr++;
                    allSidebarItems.push({
                        securityItemName: securityItemNames.TAGESPLAN,
                        item: tagesplan,
                        forMoreMenu: forMoreMenu,
                    });
                }
            }
            if (tab !== undefined && tab === securityItemNames.FRAGEBOGEN) {
                const fragebogen = showFragebogen(forMoreMenu);
                if (fragebogen) {
                    itemNr++;
                    allSidebarItems.push({
                        securityItemName: securityItemNames.FRAGEBOGEN,
                        item: fragebogen,
                        forMoreMenu: forMoreMenu,
                    });
                }
            }
            if (tab !== undefined && tab === securityItemNames.VIDEO) {
                const videochat = showVideochat(forMoreMenu);
                if (videochat) {
                    itemNr++;
                    allSidebarItems.push({
                        securityItemName: securityItemNames.VIDEO,
                        item: videochat,
                        forMoreMenu: forMoreMenu,
                    });
                }
            }
            if (tab !== undefined && tab === securityItemNames.CHARTS) {
                const charts = showCharts(forMoreMenu);
                if (charts) {
                    itemNr++;
                    allSidebarItems.push({
                        securityItemName: securityItemNames.CHARTS,
                        item: charts,
                        forMoreMenu: forMoreMenu,
                    });
                }
            }
            if (tab !== undefined && tab === securityItemNames.CHARTS_WEIGHT) {
                const chartWeight = showChartWeight(forMoreMenu);
                if (chartWeight) {
                    itemNr++;
                    allSidebarItems.push({
                        securityItemName: securityItemNames.CHARTS_WEIGHT,
                        item: chartWeight,
                        forMoreMenu: forMoreMenu,
                    });
                }
            }
            if (tab !== undefined && tab === securityItemNames.CHARTS_FATMASS) {
                const chartBodyFat = showChartBodyFat(forMoreMenu);
                if (chartBodyFat) {
                    itemNr++;
                    allSidebarItems.push({
                        securityItemName: securityItemNames.CHARTS_FATMASS,
                        item: chartBodyFat,
                        forMoreMenu: forMoreMenu,
                    });
                }
            }
            if (tab !== undefined && tab === securityItemNames.CHARTS_BLOODSUGAR) {
                const chartBloodSugar = showChartBloodSugar(forMoreMenu);
                if (chartBloodSugar) {
                    itemNr++;
                    allSidebarItems.push({
                        securityItemName: securityItemNames.CHARTS_BLOODSUGAR,
                        item: chartBloodSugar,
                        forMoreMenu: forMoreMenu,
                    });
                }
            }
            if (tab !== undefined && tab === securityItemNames.CHARTS_FLI) {
                const chartFLI = showChartFLI(forMoreMenu);
                if (chartFLI) {
                    itemNr++;
                    allSidebarItems.push({
                        securityItemName: securityItemNames.CHARTS_FLI,
                        item: chartFLI,
                        forMoreMenu: forMoreMenu,
                    });
                }
            }
            if (tab !== undefined && tab === securityItemNames.CHARTS_SAM) {
                const chartSAM = showChartSAM(forMoreMenu);
                if (chartSAM) {
                    itemNr++;
                    allSidebarItems.push({
                        securityItemName: securityItemNames.CHARTS_SAM,
                        item: chartSAM,
                        forMoreMenu: forMoreMenu,
                    });
                }
            }
            if (tab !== undefined && tab === securityItemNames.CHARTS_ACTIVITY) {
                const chartActivity = showChartActivity(forMoreMenu);
                if (chartActivity) {
                    itemNr++;
                    allSidebarItems.push({
                        securityItemName: securityItemNames.CHARTS_ACTIVITY,
                        item: chartActivity,
                        forMoreMenu: forMoreMenu,
                    });
                }
            }
            if (tab !== undefined && tab === securityItemNames.TABLE_VAL) {
                const tableVAL = showTableVAL(forMoreMenu);
                if (tableVAL) {
                    itemNr++;
                    allSidebarItems.push({
                        securityItemName: securityItemNames.TABLE_VAL,
                        item: tableVAL,
                        forMoreMenu: forMoreMenu,
                    });
                }
            }
            if (tab !== undefined && tab === securityItemNames.TODO) {
                const todo = showTodo(forMoreMenu);
                if (todo) {
                    itemNr++;
                    allSidebarItems.push({
                        securityItemName: securityItemNames.TODO,
                        item: todo,
                        forMoreMenu: forMoreMenu,
                    });
                }
            }
        });

        return allSidebarItems;
    };

    const orderedSidebarItems = (maxSidebarItems: number) => {
        /* INFO: the defaultPage (first page after login) is defined in getDefaultPage() */

        const allSidebarItems: any[] = [];
        const allSidebarMoreMenuItems: any[] = [];
        const allSidebarItemNames: string[] = [];

        const defaultTabOrder = [
            securityItemNames.JOURNEY,
            securityItemNames.PATLIST,
            securityItemNames.DOKUMENT,
            securityItemNames.TERMINE,
            securityItemNames.FOTOS,
            securityItemNames.MEDIKATION,
            securityItemNames.TAGESPLAN,
            securityItemNames.FRAGEBOGEN,
            securityItemNames.VIDEO,
            securityItemNames.CHARTS,
            securityItemNames.CHARTS_WEIGHT,
            securityItemNames.CHARTS_FATMASS,
            securityItemNames.CHARTS_BLOODSUGAR,
            securityItemNames.CHARTS_SAM,
            securityItemNames.CHARTS_FLI,
            securityItemNames.CHARTS_ACTIVITY,
            securityItemNames.TABLE_VAL,
            securityItemNames.TODO,
        ];

        const tabOrderFromSecurityItems = getTabOrder();
        let doTabOrderFromSecItems = false;
        if (tabOrderFromSecurityItems.length > 0) {
            /* ORDER FROM SECURITY ITEMS */
            doTabOrderFromSecItems = true;
            tabOrderFromSecurityItems.forEach((tab) => {
                /* delete from the default list */
                delete defaultTabOrder[defaultTabOrder.indexOf(tab)];
            });
        }

        /* ORDER FROM SECURITY ITEMS */
        if (doTabOrderFromSecItems) {
            const orderedSidebarItemsSecItems = orderSidebarItemsWorkflow(
                0,
                tabOrderFromSecurityItems,
                maxSidebarItems,
            );

            orderedSidebarItemsSecItems.forEach((e) => {
                if (e.forMoreMenu) {
                    allSidebarMoreMenuItems.push(e.item);
                } else {
                    allSidebarItems.push(e.item);
                }

                allSidebarItemNames.push(e.securityItemName);
            });

            // allSidebarItems.push(orderSidebarItemsWorkflow(tabOrderFromSecurityItems));
        }

        /* REST FROM DEFAULT LIST */
        const orderedSidebarItemsDefault = orderSidebarItemsWorkflow(
            allSidebarItems.length,
            defaultTabOrder,
            maxSidebarItems,
        );
        orderedSidebarItemsDefault.forEach((e) => {
            if (e.forMoreMenu) {
                allSidebarMoreMenuItems.push(e.item);
            } else {
                allSidebarItems.push(e.item);
            }
            allSidebarItemNames.push(e.securityItemName);
        });
        // allSidebarItems.push(orderSidebarItemsWorkflow(defaultTabOrder));

        if (!itemOrderIsSet) {
            dispatch({
                type: 'SETFINALSECURITYITEMSORDER',
                finalSecurityItemsOrder: allSidebarItemNames,
            });
            setItemOrderIsSet(true);
        }

        if (allSidebarMoreMenuItems.length > 0) {
            const dropdown = (
                <NavDropdown
                    title={<RiMenuFill size={30} />}
                    id="moreMenu-collasible-nav-dropdown"
                    drop="up"
                    alignRight
                >
                    {allSidebarMoreMenuItems}
                </NavDropdown>
            );

            allSidebarItems.push(dropdown);
        }

        return allSidebarItems;
    };

    const handleSidebarItemClick = (eventKey) => {
        setActiveSidebarItem(eventKey);
    };

    const getMaxSidebarItems = () => {
        let maxSidebarItems = 0;

        if (isMobile(screenSize.width)) {
            if (screenSize.width < 295) {
                maxSidebarItems = 3;
            } else if (screenSize.width < 354) {
                maxSidebarItems = 4;
            } else if (screenSize.width < 490) {
                maxSidebarItems = 5;
            } else if (screenSize.width < 620) {
                maxSidebarItems = 6;
            } else if (screenSize.width < 720) {
                maxSidebarItems = 7;
            } else {
                maxSidebarItems = 8;
            }
        }

        return maxSidebarItems;
    };

    return (
        <>
            <div style={{ padding: 0, width: '100%' }}>
                <Nav
                    className={isMobile(screenSize.width) ? 'flex-row' : 'flex-column'}
                    onSelect={handleSidebarItemClick}
                    style={{ justifyContent: 'space-between' }}
                >
                    {/* <SidebarItem {...props} route={Paths.DASHBOARD} label="Home" icon={<RiHome2Line />}></SidebarItem> */}
                    {orderedSidebarItems(getMaxSidebarItems())}
                </Nav>
            </div>
        </>
    );
};

export default SidebarItemList;
