import Row from 'react-bootstrap/Row';
import styled from 'styled-components';

interface IRow {
    height?: string;
    justifyContent?: string;
    marginLeft?: string;
    marginRight?: string;
}

export const StyledRow = styled(Row)<IRow>`
    justify-content: ${(prop) => (prop.justifyContent ? prop.justifyContent : null)};
`;

export const CenterRow = styled(Row)<IRow>`
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const MainHeaderRow = styled(Row)<IRow>`
    height: 100%;
`;

export const AppointmentCardStatusButtonRow = styled(Row)<IRow>`
    height: 100%;
    flex-wrap: inherit;
`;

export const StyledRowNoMargin = styled(Row)<IRow>`
    margin-left: 0px;
    margin-right: 0px;
`;

export const StyledRowPersonalDataBottom = styled(Row)<IRow>`
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
`;

export const StyledVerticalCenterRow = styled(Row).attrs({ className: 'align-items-center' })<IRow>`
    height: 100%;
`;

export const TimeslotRow = styled(Row).attrs({ className: 'align-items-center' })<IRow>`
    /* height: 45px; */
    flex-wrap: nowrap;
`;

export const DateSwitcherRow = styled(Row).attrs({ className: 'align-items-center' })<IRow>`
    height: 45px;
    flex-wrap: nowrap;
`;

export const QuestionnaireSwitcherRow = styled(Row).attrs({ className: 'align-items-center' })<IRow>`
    flex-wrap: nowrap;
`;
