import Col from 'react-bootstrap/Col';
import styled from 'styled-components';

interface ICol {
    borderLeft?: string;
    paddingLeft?: string;
    paddingRight?: string;
    textAlign?: string;
    justifyContent?: string;
}

const StyledCol = styled(Col)<ICol>`
    border-left: ${(prop) => (prop.borderLeft ? prop.borderLeft : 'none')};
    padding-left: ${(prop) => (prop.paddingLeft ? prop.paddingLeft : '15px')};
    padding-right: ${(prop) => (prop.paddingRight ? prop.paddingRight : '15px')};
    text-align: ${(prop) => (prop.textAlign ? prop.textAlign : 'left')};
`;

export const MainHeaderCol = styled(Col)<ICol>`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const ChartsHeaderCol = styled(Col)<ICol>`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const MainHeaderButtonCol = styled(Col)<ICol>`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`;

export const ChartsButtonCol = styled(Col)<ICol>`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: ${(prop) => (prop.justifyContent ? prop.justifyContent : 'flex-end')};
    width: 100%;
`;

export const AppointmentCardHeaderCol = styled(Col)<ICol>`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 100%;
`;

export const AppointmentCardHeaderStatusButtonCol = styled(Col)<ICol>`
    justify-content: flex-end;
`;

export const AppointmentCardDateCol = styled(AppointmentCardHeaderCol)<ICol>`
    justify-content: left;
`;

export const AppointmentCardStatusCol = styled(AppointmentCardHeaderCol)<ICol>`
    padding: 0;
    justify-content: flex-start;
`;

export const AppointmentCardButtonCol = styled(AppointmentCardHeaderCol)<ICol>`
    padding: 0;
    justify-content: flex-end;
`;

export const StyledColNoPadding = styled(Col)<ICol>`
    padding: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
`;

export const DateSwitcherTimeslotCol = styled(Col)<ICol>`
    height: 100%;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;

    @media screen and (max-width: 580px) {
        padding-left: 2px;
        padding-right: 2px;
        padding-top: 5px;
    }
`;

export const DateSwitcherTimeslotColStartEnd = styled(Col)<ICol>`
    height: 100%;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    flex: 0 0 40px;

    @media screen and (max-width: 580px) {
        padding-left: 2px;
        padding-right: 2px;
        padding-top: 5px;
        flex: 0 0 30px;
    }
    @media screen and (max-width: 360px) {
        padding-left: 2px;
        padding-right: 2px;
        padding-top: 5px;
        flex: 0 0 25px;
    }
`;

export const DateCol = styled(Col)<ICol>`
    font-size: small;
    text-align: center;
    @media screen and (max-width: 500px) {
        font-size: 13px;
    }
`;

export const DatePlaceholderCol = styled(Col)<ICol>`
    @media screen and (min-width: 897px) {
        min-width: 277px;
    }
    @media screen and (min-width: 985px) {
        min-width: 230px;
    }
`;

export const QuestionnaireSwitcherColStartEnd = styled(Col)<ICol>`
    flex-grow: 1;
    padding-left: 0px;
    padding-right: 0px;
`;

export const QuestionnaireSwitcherColTitle = styled(Col)<ICol>`
    flex-grow: 1;
    padding-left: 0px;
    padding-right: 0px;
`;

export const ButtonColLeft = styled(Col)<ICol>`
    padding-left: 15px;
    padding-right: 15px;

    @media screen and (max-width: 500px) {
        padding-left: 0px;
        padding-right: 5px;
    }
`;

export const ButtonColMiddle = styled(Col)<ICol>`
    padding-left: 15px;
    padding-right: 15px;

    @media screen and (max-width: 500px) {
        padding-left: 5px;
        padding-right: 5px;
    }
`;

export const ButtonColRight = styled(Col)<ICol>`
    padding-left: 15px;
    padding-right: 15px;

    @media screen and (max-width: 500px) {
        padding-left: 5px;
        padding-right: 0px;
    }
`;

export default StyledCol;
