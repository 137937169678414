import { createContext } from 'react';

export type InitialExecuteRuleType = {
    ruleName: string;
    ruleParam: string;
    persInfo: string;
};

export const initialExecuteRuleState = {
    ruleName: '',
    ruleParam: '',
    persInfo: '',
};

const ExecuteRuleContext = createContext<{
    executerulestate: InitialExecuteRuleType;
    executeruledispatch: React.Dispatch<any>;
}>({
    executerulestate: initialExecuteRuleState,
    executeruledispatch: () => null,
});

export default ExecuteRuleContext;
