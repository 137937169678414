import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import styled from 'styled-components';

const SelectButton = styled(Button)`
    margin: 10px;
    padding: ${(props) => (props.padding ? props.padding : '.375rem .50rem')};
    height: ${(props) => (props.height ? props.height : '80px')};
    width: ${(props) => (props.width ? props.width : '300px')};
    color: rgb(36, 94, 173);
    background-color: ${(props) =>
        props.selected ? props.theme.selectButton.backgroundColorActive : props.theme.selectButton.backgroundColor};
    border-color: rgb(36, 94, 173);
    font-weight: normal;
    font-size: ${(props) => (props.fontSize ? props.fontSize : 'medium')};
    margin-left: 0;
    box-shadow: ${(props) => (props.theme.button.boxShadow ? props.theme.button.boxShadow : 'none')};
    border-radius: ${(props) => (props.theme.button.borderRadius ? props.theme.button.borderRadius : 'none')};

    &:hover {
        background-color: ${(props) => props.theme.selectButton.backgroundColorHover};
        border-color: ${(props) => props.theme.selectButton.backgroundColorHover};
        color: rgb(36, 94, 173);
    }
    &:active {
        background-color: ${(props) => props.theme.selectButton.backgroundColorActive} !important;
        border-color: ${(props) => props.theme.selectButton.backgroundColorActive} !important;
        color: rgb(36, 94, 173);
    }

    &.btn-outline-primary:not(:disabled):not(.disabled).active,
    .btn-outline-primary:not(:disabled):not(.disabled):active,
    .show > .btn-outline-primary.dropdown-toggle {
        color: black;
        background-color: white;
        border-color: lightblue;
    }
`;

export const TimeslotButton = styled(SelectButton)`
    variant: 'outline-primary';
    margin: 0px;
    background-color: rgba(173, 207, 3, 0.5);
    box-shadow: none;
    font-size: small;
    /* font-size: 0.58rem; */
    height: 80%;
    width: 100%;
    display: inline-block;
    border-radius: 2px;
    text-align: center;
    @media screen and (max-width: 520px) {
        /* font-size: 10px; */
        font-size: 0.58rem;
        padding: 4px 2px 4px 2px;
        max-width: 37px;
        overflow: hidden;
        white-space: nowrap;
    }
    @media screen and (max-width: 400px) {
        max-width: 32px;
    }
    @media screen and (max-width: 414px) {
        max-width: 90%;
        font-size: small;
        // word-break: break-all;
    }
`;

export const SelectRadioToggleButton = styled(ToggleButton)`
    width: 180px;
`;

export default SelectButton;
