import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import styled from 'styled-components';
import { LeftDiv, RightDiv } from '../Div/StyledDiv';
import { ParagraphFontSize, StyledParagraph } from '../StyledParagraph/StyledParagraph';
import { IMessage } from './ChatMessages';

const OutgoingMsgDiv = styled(RightDiv)`
    background-color: #a3eaf7;
    border-radius: 10px;
    text-align: left;
    padding: 15px;
`;

const IncomingMsgDiv = styled(LeftDiv)`
    background-color: #57aee7;
    border-radius: 10px;
    text-align: left;
    padding: 15px;
`;

export const OutgoingMessage = (props: IMessage) => {
    return (
        <Row>
            <Col xs={{ span: 6, offset: 6 }}>
                <RightDiv>
                    <OutgoingMsgDiv>
                        <StyledParagraph marginBottom={'0rem'}>{props.txt}</StyledParagraph>
                    </OutgoingMsgDiv>
                    <StyledParagraph fontSize={ParagraphFontSize.ExtraSmall}>{props.time}</StyledParagraph>
                </RightDiv>
            </Col>
        </Row>
    );
};

export const IncomingMessage = (props: IMessage) => {
    return (
        <Row>
            <Col xs={{ span: 6, offset: 0 }}>
                <LeftDiv>
                    <IncomingMsgDiv>
                        <StyledParagraph marginBottom={'0rem'}>{props.txt}</StyledParagraph>
                    </IncomingMsgDiv>
                    <StyledParagraph fontSize={ParagraphFontSize.ExtraSmall}>{props.time}</StyledParagraph>
                </LeftDiv>
            </Col>
        </Row>
    );
};
