import { useCallback, useContext } from 'react';
import LoginContext from '../context/loginContext';
import { PROFILE_HOME } from '../globals/global';

export const useSecurityItems = () => {
    const { state, dispatch } = useContext(LoginContext);

    const isPatListEnabled = useCallback(() => {
        const patListEnabled =
            state.activeProfile === PROFILE_HOME ? state.securityItems.PATLIST : state.securityItemsStudy?.PATLIST;
        return patListEnabled;
    }, [state.activeProfile, state.securityItems, state.securityItemsStudy]);

    const isDokumenteEnabled = useCallback(() => {
        const dokumenteEnabled =
            state.activeProfile === PROFILE_HOME ? state.securityItems.DOKUMENT : state.securityItemsStudy?.DOKUMENT;
        return dokumenteEnabled;
    }, [state.activeProfile, state.securityItems, state.securityItemsStudy]);

    const isTermineEnabled = useCallback(() => {
        const termineEnabled =
            state.activeProfile === PROFILE_HOME ? state.securityItems.TERMINE : state.securityItemsStudy?.TERMINE;
        return termineEnabled;
    }, [state.activeProfile, state.securityItems, state.securityItemsStudy]);

    const isFotosEnabled = useCallback(() => {
        const fotosEnabled =
            state.activeProfile === PROFILE_HOME ? state.securityItems.FOTOS : state.securityItemsStudy?.FOTOS;
        return fotosEnabled;
    }, [state.activeProfile, state.securityItems, state.securityItemsStudy]);

    const isMedikationEnabled = useCallback(() => {
        const medikationEnabled =
            state.activeProfile === PROFILE_HOME
                ? state.securityItems.MEDIKATION
                : state.securityItemsStudy?.MEDIKATION;
        return medikationEnabled;
    }, [state.activeProfile, state.securityItems, state.securityItemsStudy]);

    const isTagesplanEnabled = useCallback(() => {
        const tagesplanEnabled =
            state.activeProfile === PROFILE_HOME ? state.securityItems.TAGESPLAN : state.securityItemsStudy?.TAGESPLAN;
        return tagesplanEnabled;
    }, [state.activeProfile, state.securityItems, state.securityItemsStudy]);

    const isFragebogenEnabled = useCallback(() => {
        const fragebogenEnabled =
            state.activeProfile === PROFILE_HOME
                ? state.securityItems.FRAGEBOGEN
                : state.securityItemsStudy?.FRAGEBOGEN;
        return fragebogenEnabled;
    }, [state.activeProfile, state.securityItems, state.securityItemsStudy]);

    const isVideochatEnabled = useCallback(() => {
        const videochatEnabled =
            state.activeProfile === PROFILE_HOME ? state.securityItems.VIDEO : state.securityItemsStudy?.VIDEO;
        return videochatEnabled;
    }, [state.activeProfile, state.securityItems, state.securityItemsStudy]);

    const isChartsEnabled = useCallback(() => {
        const chartsEnabled =
            state.activeProfile === PROFILE_HOME ? state.securityItems.CHARTS : state.securityItemsStudy?.CHARTS;
        return chartsEnabled;
    }, [state.activeProfile, state.securityItems, state.securityItemsStudy]);

    const isChartsWeightEnabled = useCallback(() => {
        const chartWeightEnabled =
            state.activeProfile === PROFILE_HOME
                ? state.securityItems.CHARTS_WEIGHT
                : state.securityItemsStudy?.CHARTS_WEIGHT;
        return chartWeightEnabled;
    }, [state.activeProfile, state.securityItems, state.securityItemsStudy]);

    const isChartsBodyFatEnabled = useCallback(() => {
        const chartBodyFatEnabled =
            state.activeProfile === PROFILE_HOME
                ? state.securityItems.CHARTS_FATMASS
                : state.securityItemsStudy?.CHARTS_FATMASS;
        return chartBodyFatEnabled;
    }, [state.activeProfile, state.securityItems, state.securityItemsStudy]);

    const isChartsBloodSugarEnabled = useCallback(() => {
        const chartBloodSugarEnabled =
            state.activeProfile === PROFILE_HOME
                ? state.securityItems.CHARTS_BLOODSUGAR
                : state.securityItemsStudy?.CHARTS_BLOODSUGAR;
        return chartBloodSugarEnabled;
    }, [state.activeProfile, state.securityItems, state.securityItemsStudy]);

    const isChartsFLIEnabled = useCallback(() => {
        const chartFLIEnabled =
            state.activeProfile === PROFILE_HOME
                ? state.securityItems.CHARTS_FLI
                : state.securityItemsStudy?.CHARTS_FLI;
        return chartFLIEnabled;
    }, [state.activeProfile, state.securityItems, state.securityItemsStudy]);

    const isChartsSAMEnabled = useCallback(() => {
        const chartSAMEnabled =
            state.activeProfile === PROFILE_HOME
                ? state.securityItems.CHARTS_SAM
                : state.securityItemsStudy?.CHARTS_SAM;
        return chartSAMEnabled;
    }, [state.activeProfile, state.securityItems, state.securityItemsStudy]);

    const isChartsActivityEnabled = useCallback(() => {
        const chartActivityEnabled =
            state.activeProfile === PROFILE_HOME
                ? state.securityItems.CHARTS_ACTIVITY
                : state.securityItemsStudy?.CHARTS_ACTIVITY;
        return chartActivityEnabled;
    }, [state.activeProfile, state.securityItems, state.securityItemsStudy]);

    const isTableVALEnabled = useCallback(() => {
        const tableVALEnabled =
            state.activeProfile === PROFILE_HOME ? state.securityItems.TABLE_VAL : state.securityItemsStudy?.TABLE_VAL;
        return tableVALEnabled;
    }, [state.activeProfile, state.securityItems, state.securityItemsStudy]);

    const isJourneyEnabled = useCallback(() => {
        const journeyEnabled =
            state.activeProfile === PROFILE_HOME ? state.securityItems.JOURNEY : state.securityItemsStudy?.JOURNEY;
        return journeyEnabled;
    }, [state.activeProfile, state.securityItems, state.securityItemsStudy]);

    const isTodoEnabled = useCallback(() => {
        let todoEnabled = false;
        if (state.activeProfile === PROFILE_HOME) {
            if (state.securityItems.TODO && state.securityItems.TODO.length > 0) {
                todoEnabled = true;
            }
        } else {
            if (state.securityItemsStudy && state.securityItemsStudy.TODO && state.securityItemsStudy.TODO.length > 0) {
                todoEnabled = true;
            }
        }
        return todoEnabled;
    }, [state.activeProfile, state.securityItems, state.securityItemsStudy]);

    const getTabOrder = useCallback(() => {
        let tabOrder: string[] = [];
        let tabOrderFromRequest = '';
        if (state.activeProfile === PROFILE_HOME) {
            if (state.securityItems.TAB_ORDER && state.securityItems.TAB_ORDER.length > 0) {
                tabOrderFromRequest = state.securityItems.TAB_ORDER;
            }
        } else {
            if (
                state.securityItemsStudy &&
                state.securityItemsStudy.TAB_ORDER &&
                state.securityItemsStudy.TAB_ORDER.length > 0
            ) {
                tabOrderFromRequest = state.securityItemsStudy.TAB_ORDER;
            }
        }

        if (tabOrderFromRequest.length > 0) {
            tabOrderFromRequest = tabOrderFromRequest.replace(/\s/g, '');
            tabOrder = tabOrderFromRequest.split(',');
        }

        return tabOrder;
    }, [state.activeProfile, state.securityItems, state.securityItemsStudy]);

    const setActiveSidebarItem = useCallback(
        (sidebarItem) => {
            dispatch({
                type: 'SETACTIVESIDEBARITEM',
                activeSidebarItem: sidebarItem,
            });
        },
        [state.activeSidebarItem],
    );

    return {
        isPatListEnabled,
        isDokumenteEnabled,
        isTermineEnabled,
        isFotosEnabled,
        isMedikationEnabled,
        isTagesplanEnabled,
        isFragebogenEnabled,
        isVideochatEnabled,
        isChartsEnabled,
        isChartsWeightEnabled,
        isChartsBodyFatEnabled,
        isChartsBloodSugarEnabled,
        isChartsFLIEnabled,
        isChartsSAMEnabled,
        isChartsActivityEnabled,
        isJourneyEnabled,
        isTodoEnabled,
        isTableVALEnabled,
        getTabOrder,
        setActiveSidebarItem,
    };
};
