import StyledButton from '../../components/Buttons/StyledButton';
import StyledCol from '../../components/Cols/StyledCol';
import { StickyButtonRowBottomDiv } from '../../components/Div/StyledDiv';
import { StyledRowNoMargin } from '../../components/Rows/StyledRow';
import { TermineContent } from './Termine';

interface ITermineCheckIn {
    showNextButton: boolean;
    handleNextWithActiveStep?(currentActiveStep: number): void;
    currentActiveStep: number;
}

interface ITermineButtons {
    handleNext(): void;
}

const TermineButtons = (props: ITermineButtons) => {
    const handleNext = () => {
        props.handleNext();
    };

    return (
        <StickyButtonRowBottomDiv marginTop="30px">
            <StyledRowNoMargin>
                <StyledCol paddingLeft="0px" paddingRight="0px" textAlign="right">
                    <StyledButton onClick={() => handleNext()}>Weiter</StyledButton>
                </StyledCol>
            </StyledRowNoMargin>
        </StickyButtonRowBottomDiv>
    );
};

const TermineCheckIn = (props: ITermineCheckIn) => {
    const handleNextClick = () => {
        if (props.handleNextWithActiveStep) {
            props.handleNextWithActiveStep(props.currentActiveStep);
        }
    };

    return (
        <>
            <TermineContent checkInVariant={true} paddingLeft="15px" paddingRight="15px" />
            {props.showNextButton && <TermineButtons handleNext={handleNextClick} />}
        </>
    );
};

export default TermineCheckIn;
