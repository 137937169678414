import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ActivityCharts from '../../../components/Charts/ActivityCharts/ActivityCharts';
import { containerHeader } from '../../../components/Header/Header';
import { charts } from '../../../utils/chartUtils';
import Mainscreen from '../../Mainscreen/Mainscreen';

const ChartsActivityContent = () => {
    return (
        <Container fluid>
            <ActivityCharts />
        </Container>
    );
};

const ChartsActivity = () => {
    return (
        <Mainscreen>
            <Container fluid>
                <Row>
                    <Col>
                        {containerHeader(charts.ACTIVITY.name)}
                        <ChartsActivityContent />
                    </Col>
                </Row>
            </Container>
        </Mainscreen>
    );
};

export default ChartsActivity;
