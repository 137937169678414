import { Image } from 'react-bootstrap';
import { BsSim } from 'react-icons/bs';
import cardSim from '../../images/CardSim.png';
import { SpaceBetweenDiv, VerticalCenterFlexDiv } from '../Div/StyledDiv';
import StyledButton from './StyledButton';

interface IReadCardButton {
    width?: string;
    textSize?: string;
    disabled: boolean;
    onClick(): void;
}

const ReadCardButton = (props: IReadCardButton) => {
    return (
        <StyledButton
            width={props.width ? props.width : '400px'}
            height="100px"
            borderRadius="100px"
            color="#00305e"
            backgroundColor="white"
            backgroundColorHover="#00305e"
            borderColor="transparent"
            borderColorHover="transparent"
            disabled={props.disabled}
            onClick={() => {
                props.onClick();
            }}
        >
            <SpaceBetweenDiv marginLeft="20px" marginRight="20px">
                <VerticalCenterFlexDiv>
                    <Image src={cardSim} />
                </VerticalCenterFlexDiv>
                <VerticalCenterFlexDiv
                    fontSize={props.textSize ? props.textSize : '1.5rem'}
                    divWidth="100%"
                    style={{ textAlign: 'center', justifyContent: 'center' }}
                >
                    Karte einlesen
                </VerticalCenterFlexDiv>
            </SpaceBetweenDiv>
        </StyledButton>
    );
};

export default ReadCardButton;
BsSim;
