import StepperTerminierungAlternateDate from '../../components/Stepper/StepperTerminierungAlternateDate';
import Mainscreen from '../Mainscreen/Mainscreen';
import { ITermineContent } from '../Termine/Termine';

export const StepperTerminierungAlternateDateContent = (props: ITermineContent) => {
    return (
        <div>
            <StepperTerminierungAlternateDate {...props} />
        </div>
    );
};

const TerminierungAlternateDate = () => {
    return (
        <Mainscreen>
            <StepperTerminierungAlternateDateContent />
        </Mainscreen>
    );
};

export default TerminierungAlternateDate;
