import styled from 'styled-components';

export const StyledH3 = styled.h3`
    color: ${(props) => props.theme.font.highlightColor};
`;

export const StyledMainHeader = styled.h3`
    @media only screen and (max-width: 500px), screen and (max-height: 500px) {
        font-size: 1.25rem;
    }
`;

export const StyledSubHeader = styled.h4`
    @media only screen and (max-width: 500px), screen and (max-height: 500px) {
        font-size: 1.1rem;
    }
`;
