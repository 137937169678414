import * as React from 'react';
import Carousel from 'react-bootstrap/esm/Carousel';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import useScreenResolution from '../../../hooks/useScreenResolution';
import { isMobile, isTablet } from '../../../utils/screenResolution';
import StyledButton from '../../Buttons/StyledButton';
import './PhotoModal.css';

export interface IPhotoModal {
    show: boolean;
    itemMargin: string;
    index: number;
    onHide: () => void;
    modalTitle: string;
    modalDate: string;
    photoData: any[];
    handleSelect: (selectedIndex: React.SetStateAction<number>) => void;
}

interface IPhotoModalFrame {
    width: string;
    height: string;
}

const ModalCst = styled(Modal)<IPhotoModalFrame>`
    .modal-dialog {
        max-width: ${(props) => (props.width ? props.width : '40%')};
        max-height: ${(props) => (props.heigth ? props.height : '50%')};
        margin: 1.75rem auto;
    }
    .modal-content {
        border-radius: ${(prop) => (prop.borderRadius ? prop.borderRadius : prop.theme.modal.borderRadius)};
        box-shadow: ${(prop) => (prop.boxShadow ? prop.boxShadow : prop.theme.modal.boxShadow)};
    }
`;

function getWidth(screenWidth: number) {
    if (isMobile(screenWidth) || isTablet(screenWidth)) {
        return { width: '80%', height: '90%' };
    } else {
        return { width: '40%', height: '50%' };
    }
}

const PhotoModal = (props: IPhotoModal) => {
    const screenSize = useScreenResolution();
    const modalSize = getWidth(screenSize.width);

    return (
        <>
            <ModalCst show={props.show} onHide={() => props.onHide()} width={modalSize.width} height={modalSize.height}>
                <Modal.Header>
                    <Modal.Title>{props.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ marginLeft: props.itemMargin, marginRight: props.itemMargin, marginBottom: 0 }}>
                        <p>{props.modalDate}</p>
                        {props.photoData.length > 1 ? (
                            <Carousel activeIndex={props.index} onSelect={props.handleSelect} interval={null}>
                                {props.photoData}
                            </Carousel>
                        ) : (
                            <Carousel controls={false}>{props.photoData}</Carousel>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <StyledButton onClick={() => props.onHide()}>Schließen</StyledButton>
                </Modal.Footer>
            </ModalCst>
        </>
    );
};

export default PhotoModal;
