import { useCallback, useEffect, useState } from 'react';

const useScreenResolution = () => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0,
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
};

/**
 * get modified sizes of the screen resolution
 *
 * @returns
 *      getResizedHeight
 */
export const useResizedScreenResolution = () => {
    const screenSize = useScreenResolution();

    /**
     * get resized height, modified by an factor
     * e.g.: factor = 75 -> currentScreenHeight * 0.75
     */
    const getResizedHeight = useCallback(
        (factor: number) => {
            if (screenSize.height && screenSize.height > 0) {
                const iFrameHeightSize = (screenSize.height / 100) * factor;
                return iFrameHeightSize;
            }
        },
        [screenSize],
    );

    return [getResizedHeight] as const;
};

export default useScreenResolution;
