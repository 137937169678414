import { useContext, useState } from 'react';
import AlertContext, { AlertOnHide, AlertType } from '../../context/alertContext';
import LoginContext from '../../context/loginContext';
import { setPwd } from '../../services/RestServices';
import { showAlertMessage } from '../../utils/alertHandling';
import NewPWDModal from '../Modals/NewPWDModal/NewPWDModal';

interface IPasswordInit {
    onchange: (newPwd: string) => void;
    oncancel: () => void;
}

const InitialPassword = (props: IPasswordInit) => {
    const { state } = useContext(LoginContext);
    const { alertdispatch } = useContext(AlertContext);
    const [forcePWModalShow, setForcePwModalShow] = useState(true);

    const [passwordCheck, setPasswordCheck] = useState({ identical: false, password: '', confirmedPassword: '' });

    // const [consignationForm, showConsignationForm] = useState(false);

    const handleSubmit = async () => {
        // let pwResponse = await setPwd(state.sessionId, passwordCheck.password);
        const pwResponse = await setPwd(state, alertdispatch, passwordCheck.password);

        if (pwResponse) {
            if (pwResponse.ERROR) {
                showAlertMessage({
                    alertTitle: 'Passwort setzen',
                    alertTxt: pwResponse.ERROR,
                    alertType: AlertType.error,
                    onHide: AlertOnHide.onlyClose,
                    alertdispatch: alertdispatch,
                });
            } else {
                setForcePwModalShow(false);
                {
                    props.onchange(passwordCheck.password);
                }
                // showConsignationForm(true);
            }
        }
    };

    const handleCancel = () => {
        setForcePwModalShow(false);
        {
            props.oncancel();
        }
    };

    function passwordChangeHandler(newPW: string) {
        setPasswordCheck({ ...passwordCheck, identical: newPW === passwordCheck.confirmedPassword, password: newPW });
    }

    function passwordValidationHandler(confirmedPW: string) {
        setPasswordCheck({
            ...passwordCheck,
            identical: confirmedPW === passwordCheck.password,
            confirmedPassword: confirmedPW,
        });
    }

    return (
        <div>
            <NewPWDModal
                show={forcePWModalShow}
                sessionId={state.sessionId}
                userId={state.userId}
                onHide={() => handleCancel()}
                onInput={(newPassword: string) => passwordChangeHandler(newPassword)}
                onRepeat={(repeatedPassword: string) => passwordValidationHandler(repeatedPassword)}
                identicalPW={passwordCheck.identical}
                onSubmit={() => handleSubmit()}
            />
            {/* {consignationForm ? (
                <div><Consignation {...true} /></div>
            ) : (<></>)} */}
        </div>
    );
};

export default InitialPassword;
