import styled, { css } from 'styled-components';
import { MainHeaderCol } from '../Cols/StyledCol';
import { StickyHeaderTitleDiv } from '../Div/StyledDiv';
import { MainHeaderRow } from '../Rows/StyledRow';

const headerBaseStyle = css`
    color: white;
    vertical-align: middle;
    margin: auto;
    padding: 0;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

interface IHeader {
    color?: string;
}

export const MainH1Title = styled.h1<IHeader>`
    ${headerBaseStyle}
    color: ${(prop) => (prop.color ? prop.color : prop.theme.header.main.color)};
`;

export const MainH2Title = styled.h2`
    ${headerBaseStyle}
    color: ${(prop) => (prop.color ? prop.color : prop.theme.header.main.color)};
`;

export const MainH3Title = styled.h3`
    ${headerBaseStyle}
    color: ${(prop) => (prop.color ? prop.color : prop.theme.header.main.color)};
`;

export const MainH4Title = styled.h4`
    ${headerBaseStyle}
    color: ${(prop) => (prop.color ? prop.color : prop.theme.header.main.color)};
`;

export const MainH5Title = styled.h5`
    ${headerBaseStyle}
    color: ${(prop) => (prop.color ? prop.color : prop.theme.header.main.color)};
`;

export const containerHeader = (title: string) => {
    return (
        <StickyHeaderTitleDiv>
            <MainHeaderRow>
                <MainHeaderCol>
                    <MainH1Title>{title}</MainH1Title>
                </MainHeaderCol>
            </MainHeaderRow>
        </StickyHeaderTitleDiv>
    );
};
