import { useContext } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import TerminierungContext from '../../context/terminierungContext';

interface IRemarkInputField {
    groupText?: string;
}

const RemarkInputField = (props: IRemarkInputField) => {
    const { tmstate, tmdispatch } = useContext(TerminierungContext);

    const setRemark = (value: string) => {
        tmdispatch({
            type: 'SETREMARK',
            remark: value,
        });
    };

    return (
        <InputGroup className="mb-3 mt-3">
            <InputGroup.Prepend>
                <InputGroup.Text id="inputGroup-sizing-default">
                    {props.groupText ? props.groupText : 'Grund für Termin'}
                </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                value={tmstate.remark}
                onChange={(e) => setRemark(e.target.value)}
            />
        </InputGroup>
    );
};

export default RemarkInputField;
