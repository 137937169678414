import { useContext, useEffect, useState } from 'react';
import LoginContext from '../../../context/loginContext';
import { useChart } from '../../../hooks/useChart';
import { charts, IChartProps, IGetChartData, initalChartData } from '../../../utils/chartUtils';
import { IAppCharts } from '../AppCharts/AppCharts';
import ViewSwitcher from '../ChartViews/ViewSwitcher';

const BodyFatCharts = (props: IAppCharts) => {
    const { state } = useContext(LoginContext);
    const { getChartData } = useChart({ ...props, chartId: charts.FATMASS.requestId, dataPrecisionVal: 1 });

    const [chartData, setChartData] = useState<IGetChartData>({
        chartLoaded: false,
        foundChartData: false,
        chartData: { ...initalChartData },
    });

    useEffect(() => {
        const getData = async () => {
            const data = await getChartData();
            setChartData(data);
        };

        getData();
    }, [state.activePatient]);

    const getData = () => {
        const data: IChartProps = {
            chartName: charts.FATMASS.id,
            mobileProps: props,
            displayOption: 1,
            chartOptions: chartData.chartData.chartOptions,
            chartData: chartData.chartData.chartData,
            targetChart1: chartData.chartData.targetChart1,
            targetChart2: chartData.chartData.targetChart2,
            targetChart3: chartData.chartData.targetChart3,
            chartViewLimits: chartData.chartData.chartViewLimits,
            labels: chartData.chartData.labels,
            labelOption: false,
            tooltipPrecisionVal: 1,
        };

        return data;
    };

    return (
        <ViewSwitcher chartLoaded={chartData.chartLoaded} foundData={chartData.foundChartData} chartProps={getData()} />
    );
};

export default BodyFatCharts;
