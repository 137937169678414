import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import StyledButton from '../../Buttons/StyledButton';
import StyledCol from '../../Cols/StyledCol';
import { StyledFormLabelForInput } from '../../StyledForm/SyledForm';
import { StyledModal } from '../StyledModal/StyledModal';

interface IPasswordModal {
    show: boolean;
    sessionId: string;
    userId: string;
    onHide: () => void;
    onInput: (password: string) => void;
    onRepeat: (repPassword: string) => void;
    identicalPW: boolean;
    onSubmit: () => void;
}

export const NewPWDModal = (props: IPasswordModal) => {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        {
            props.onSubmit();
        }
    };

    return (
        <StyledModal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Bitte neues Passwort wählen</Modal.Title>
            </Modal.Header>
            <Form className="m-3" noValidate onSubmit={handleSubmit}>
                <Container fluid>
                    <Modal.Body>
                        <Row style={{ padding: 10 }}>
                            <Col>
                                <Form.Group controlId="formGroupPassword">
                                    <StyledFormLabelForInput>Neues Passwort</StyledFormLabelForInput>
                                    <Form.Control
                                        required
                                        type="password"
                                        className="mx-sm-2"
                                        placeholder="Neues Passwort"
                                        onChange={(e) => {
                                            props.onInput(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row style={{ padding: 10 }}>
                            <Col>
                                <Form.Group controlId="formGroupValidatePassword">
                                    <StyledFormLabelForInput>Neues Passwort wiederholen</StyledFormLabelForInput>
                                    <Form.Control
                                        required
                                        type="password"
                                        className="mx-sm-2"
                                        placeholder="Passwort wiederholen"
                                        onChange={(e) => {
                                            props.onRepeat(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container className="mt-4">
                            <Row>
                                <StyledCol paddingLeft="0px" textAlign="left">
                                    <StyledButton variant="secondary" onClick={props.onHide}>
                                        Abbrechen
                                    </StyledButton>
                                </StyledCol>
                                <StyledCol paddingRight="0px" textAlign="right">
                                    <fieldset disabled={!props.identicalPW}>
                                        <StyledButton type="submit">Neues Passwort speichern</StyledButton>
                                    </fieldset>
                                </StyledCol>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Container>
            </Form>
        </StyledModal>
    );
};

export default NewPWDModal;
