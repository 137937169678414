import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertContext, { AlertOnHide, AlertType } from '../../context/alertContext';
import LoginContext from '../../context/loginContext';
import useHandleLogout from '../../hooks/useHandleLogout';
import { Paths } from '../../Routes';
import { consignation } from '../../services/RestServices';
import { showAlertMessage } from '../../utils/alertHandling';
import ConsignationModal from '../Modals/ConsignationModal/ConsignationModal';
import ConsignationWaitForLetterModal from '../Modals/ConsignationModal/ConsignationWaitForLetterModal';
import ConsignationWaitModal from '../Modals/ConsignationModal/ConsignationWaitModal';
import ConsignationAdd from './ConsignationAdd';
import InitialPassword from './InitialPassword';

interface IConsignation {
    ondenial: () => void;
    oncancel: () => void;
    notgiven: boolean;
}

const Consignation = (props: IConsignation) => {
    const navigate = useNavigate();
    const { state } = useContext(LoginContext);
    const { alertdispatch } = useContext(AlertContext);
    const [consigModalShow, setConsigModalShow] = useState(true);
    const [consigWaitModalShow, setConsigWaitModalShow] = useState(false);
    const [consigWaitForLetterModalShow, setConsigWaitForLetterModalShow] = useState(false);
    const [checkConsignation, setCheckConsignation] = useState({ checkbox1: false, checkbox2: false });
    const [consigAddModalShow, setConsigAddModalShow] = useState(false);
    const [passwordModalShow, setPasswordModalShow] = useState(false);
    const { handleLogout } = useHandleLogout();
    const [lastConsigRes, setLastConsigRes] = useState({ waitForConsignation: 'false', waitForLetter: 'false' });

    const handleChange1 = () => {
        setCheckConsignation({ checkbox1: !checkConsignation.checkbox1, checkbox2: checkConsignation.checkbox2 });
    };

    const handleChange2 = () => {
        setCheckConsignation({ checkbox1: checkConsignation.checkbox1, checkbox2: !checkConsignation.checkbox2 });
    };

    const handleSubmit = async () => {
        const consigRes = await consignation(state.userId, state.sessionId, 'given');

        if (consigRes.status === 'success') {
            setConsigModalShow(false);
            if (consigRes.forceNewPWD == 'true') {
                setLastConsigRes({
                    waitForConsignation: consigRes.waitForConsignation,
                    waitForLetter: consigRes.waitForLetter,
                });
                setPasswordModalShow(true);
            } else {
                if (consigRes.waitForConsignation === 'true') {
                    setConsigWaitModalShow(true);
                } else if (consigRes.waitForLetter === 'true') {
                    setConsigWaitForLetterModalShow(true);
                } else {
                    if (state.addConsignationsToAsk && state.addConsignationsToAsk.length > 0) {
                        setConsigAddModalShow(true);
                    } else {
                        navigate(Paths.DOKUMENTE);
                    }
                }
            }
        } else {
            showAlertMessage({
                alertTitle: 'Datenschutzerklärung',
                alertTxt: 'Ein Fehler ist aufgetreten!',
                alertType: AlertType.error,
                onHide: AlertOnHide.onlyClose,
                alertdispatch: alertdispatch,
            });
        }
    };

    const handlePWChange = async () => {
        if (lastConsigRes.waitForConsignation === 'true') {
            setConsigWaitModalShow(true);
        } else if (lastConsigRes.waitForLetter === 'true') {
            setConsigWaitForLetterModalShow(true);
        } else if (state.addConsignationsToAsk && state.addConsignationsToAsk.length > 0) {
            setConsigAddModalShow(true);
        } else {
            navigate(Paths.DOKUMENTE);
        }
    };

    const setInitPassword = async () => {
        navigate(Paths.HOME);
    };

    const handleDenial = async () => {
        const consigDenialRes = await consignation(state.userId, state.sessionId, 'not given');

        if (consigDenialRes.status === 'success') {
            setConsigModalShow(false);
            {
                props.ondenial();
            }
        } else {
            showAlertMessage({
                alertTitle: 'Datenschutzerklärung',
                alertTxt: 'Ein Fehler ist aufgetreten!',
                alertType: AlertType.error,
                onHide: AlertOnHide.onlyClose,
                alertdispatch: alertdispatch,
            });
        }
    };

    const handleCancel = () => {
        setConsigModalShow(false);
        {
            props.oncancel();
        }
    };

    const handleHideConsignationWaitModal = () => {
        setConsigWaitModalShow(false);
        navigate(Paths.DOKUMENTE);
    };

    const handleHideConsignationWaitForLetterModal = () => {
        setConsigWaitForLetterModalShow(false);
        handleLogout();
    };

    const handleConsigAddDenial = () => {
        setConsigAddModalShow(false);
    };

    const showConsignationAddForm = () => {
        setConsigAddModalShow(false);
    };

    return (
        <div>
            <ConsignationModal
                show={consigModalShow}
                onHide={() => handleCancel()}
                onSubmit={() => handleSubmit()}
                onDeny={() => handleDenial()}
                checkbox1={() => handleChange1()}
                checkbox2={() => handleChange2()}
                checkConsignation1={checkConsignation.checkbox1}
                checkConsignation2={checkConsignation.checkbox2}
                sessionId={state.sessionId}
                userId={state.userId}
                notGiven={props.notgiven}
            />
            <ConsignationWaitModal show={consigWaitModalShow} onHide={() => handleHideConsignationWaitModal()} />
            <ConsignationWaitForLetterModal
                show={consigWaitForLetterModalShow}
                onHide={() => handleHideConsignationWaitForLetterModal()}
            />
            {consigAddModalShow && (
                <ConsignationAdd ondenial={() => handleConsigAddDenial()} oncancel={() => showConsignationAddForm()} />
            )}
            {passwordModalShow && (
                <InitialPassword onchange={() => handlePWChange()} oncancel={() => setInitPassword()} />
            )}
        </div>
    );
};

export default Consignation;
