import { useCallback, useRef } from 'react';
import Container from 'react-bootstrap/esm/Container';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import { BiCamera } from 'react-icons/bi';
import Webcam from 'react-webcam';
import useScreenResolution from '../../../hooks/useScreenResolution';
import { isMobile, isTablet } from '../../../utils/screenResolution';
import StyledButton from '../../Buttons/StyledButton';
import StyledCol from '../../Cols/StyledCol';
import { StyledModal } from '../StyledModal/StyledModal';

interface IWebcamModal {
    show: boolean;
    onClose: () => void;
    setPhoto: (photo: string) => void;
}

export function getWebcamDim(screenWidth: number) {
    if (isMobile(screenWidth)) {
        return { width: 300, height: 200 };
    } else if (isTablet(screenWidth)) {
        return { width: 350, height: 250 };
    } else {
        return { width: 550, height: 450 };
    }
}

const WebcamModal = (props: IWebcamModal) => {
    const webcamRef = useRef<null | Webcam>(null);

    const screenSize = useScreenResolution();
    const webcamDim = getWebcamDim(screenSize.width);

    const capture = useCallback(() => {
        if (webcamRef.current) {
            const image = webcamRef.current?.getScreenshot();
            if (image) {
                props.setPhoto(image);
            }
        }
    }, [webcamRef]);

    return (
        <>
            <StyledModal size="lg" show={props.show} onHide={props.onClose}>
                <Modal.Header>
                    <Modal.Title>
                        <BiCamera size={30} /> Kamera
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ textAlign: 'center' }}>
                        <Webcam
                            audio={false}
                            height={webcamDim.height}
                            width={webcamDim.width}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Container className="mt-4">
                        <Row>
                            <StyledCol paddingLeft="0px" textAlign="left">
                                <StyledButton variant="secondary" onClick={props.onClose}>
                                    Abbrechen
                                </StyledButton>
                            </StyledCol>
                            <StyledCol paddingRight="0px" textAlign="right">
                                <StyledButton type="submit" onClick={capture}>
                                    Foto aufnehmen
                                </StyledButton>
                            </StyledCol>
                        </Row>
                    </Container>
                </Modal.Footer>
            </StyledModal>
        </>
    );
};

export default WebcamModal;
