import { useResizedScreenResolution } from '../../hooks/useScreenResolution';

export interface IFramePDF {
    src: string;
}

const IFramePDF = (props: IFramePDF) => {
    const [getResizedHeight] = useResizedScreenResolution();
    // return <iframe src={props.src} title="PDF" width="100%" height={getResizedHeight(75)}></iframe>;
    return (
        <iframe
            src={props.src}
            title="PDF"
            style={{ position: 'absolute', height: '100%', width: '100%', top: 0, left: 0 }}
        ></iframe>
    );
};

export default IFramePDF;
