import moment from 'moment';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Carousel from 'react-bootstrap/esm/Carousel';
import Nav from 'react-bootstrap/esm/Nav';
import { FaRegFilePdf } from 'react-icons/fa';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import { ThemeContext } from 'styled-components';
import { IPDFModalShow } from '../../container/Dokumente/Dokumente';
import AlertContext from '../../context/alertContext';
import LoginContext from '../../context/loginContext';
import useScreenResolution from '../../hooks/useScreenResolution';
import { getDiagnosticReport, getDiagnosticReportsByCategory } from '../../services/fhir/FHIRDiagnosticReports';
import { getPDFDataFromDiagnosticReportResponse } from '../../utils/documentUtils';
import { IPhotoModalShow } from '../../utils/modalUtils';
import { IPhotoItem, IPhotoRep, fullSrc } from '../../utils/photoUtils';
import { isMobile, isTablet } from '../../utils/screenResolution';
import { StyledPhotoCard } from '../Cards/StyledCard';
import IFramePDF from '../IFrame/IFramePDF';
import PDFModal from '../Modals/PDFModal/PDFModal';
import PhotoModal from '../Modals/PhotoModal/PhotoModal';
import './UploadContentCards.css';

function getAlertSize(screenWidth: number) {
    if (isTablet(screenWidth)) {
        return '60%';
    } else if (isMobile(screenWidth)) {
        return '90%';
    } else {
        return '50%';
    }
}

function getCardSize(screenWidth: number) {
    if (isTablet(screenWidth)) {
        return '300px';
    } else if (isMobile(screenWidth)) {
        return '270px';
    } else {
        return '300px';
    }
}

function getMargins(screenWidth: number) {
    if (isTablet(screenWidth)) {
        return '35px';
    } else if (isMobile(screenWidth)) {
        return '20px';
    } else {
        return '40px';
    }
}

interface IUploadContentCards {
    refreshPhotos: boolean;
    resetRefresh: any;
    acceptedFileTypes: string;
}

const UploadContentCards = (props: IUploadContentCards) => {
    const themeContext = useContext(ThemeContext);
    const { alertdispatch } = useContext(AlertContext);
    const { state } = useContext(LoginContext);
    const [photoReports, setFotoReports] = useState([]);
    const [dataFound, setDataFound] = useState(true);
    const [modalIndex, setModalIndex] = useState(0);
    const [photoModalShow, setPhotoModalShow] = useState<IPhotoModalShow>({
        show: false,
        modalTitle: '',
        modalDate: '',
        photoData: '',
    });
    const [pdfModalShow, setPdfModalShow] = useState<IPDFModalShow>({
        show: false,
        modalTitle: '',
        pdfData: '',
        isSignable: false,
        isSigned: false,
        diagnosticReportData: '',
    });

    const screenSize = useScreenResolution();
    const alertWidth = getAlertSize(screenSize.width);
    const cardWidth = getCardSize(screenSize.width);
    const itemMargins = getMargins(screenSize.width);

    const loadPhotos = async () => {
        const res = await getDiagnosticReportsByCategory(state, 'MULTIMEDIA', alertdispatch);
        if (res) {
            const resources = res?.data?.entry;
            if (resources) {
                setFotoReports(resources);
            } else {
                setDataFound(false);
            }
        }
    };

    function photoCarouselItems(photoItems: IPhotoItem[], cardWidth: string) {
        const carouselItems = photoItems.map((photo: IPhotoItem, index: number) => (
            <Carousel.Item key={index}>
                <div style={{ height: cardWidth === '300px' ? '600px' : '300px' }}>
                    <ReactPanZoom key={index} image={fullSrc(photo.data)} />
                    <Carousel.Caption>
                        <h5>{photo.title}</h5>
                        <h4>
                            {index + 1}
                            <span style={{ color: themeContext.font.passiveColor, fontSize: '18px' }}>
                                /{photoItems.length}
                            </span>
                        </h4>
                    </Carousel.Caption>
                </div>
            </Carousel.Item>
        ));
        return carouselItems;
    }

    useEffect(() => {
        const getPhotos = async () => {
            loadPhotos();
        };
        getPhotos();
    }, [state.activePatient]);

    useEffect(() => {
        const getPhotos = async () => {
            loadPhotos();
        };
        if (props.refreshPhotos) {
            getPhotos();
            props.resetRefresh();
        }
    }, [props.refreshPhotos]);

    function photoCard(photoItems: IPhotoItem[]) {
        if (photoItems !== undefined) {
            const itemsToShow = photoItems.slice(0, 3); // get only three first elements for the card preview
            const photoRow = itemsToShow.map((item: IPhotoItem, index: number) => (
                <Col key={index}>
                    {item.contentType === 'application/pdf' ? (
                        <FaRegFilePdf size={65} />
                    ) : (
                        <Image src={fullSrc(item.data)} rounded width={65} height={65} />
                    )}
                </Col>
            ));
            return photoRow;
        } else {
            return null;
        }
    }

    const showPhoto = async (photoId: string, date: string) => {
        const response = await getDiagnosticReport(state.sessionId, photoId);
        const title = getCardTitle(response.data.presentedForm);
        if (response.data.presentedForm[0].contentType === 'application/pdf') {
            const [modalTitle, pdfsrc, isSignable, isSigned, signPosData] = getPDFDataFromDiagnosticReportResponse(
                response,
                title,
                '',
            );

            setPdfModalShow({
                show: true,
                modalTitle: modalTitle,
                isSignable: isSignable === 'true' ? true : false,
                isSigned: isSigned === 'true' ? true : false,
                pdfData: <IFramePDF src={pdfsrc} />,
                diagnosticReportData: response,
            });
        } else {
            setPhotoModalShow({
                show: true,
                modalTitle: title,
                modalDate: moment(date).format('DD. MMMM YYYY HH:mm'),
                photoData: photoCarouselItems(response.data.presentedForm, cardWidth),
            });
        }
    };

    const getCardTitle = (photoItem: IPhotoItem[]) => {
        let title = 'Foto';
        if (photoItem.length > 1) {
            title = 'Fotodokumentation';
        } else {
            if (photoItem[0].title) {
                title = photoItem[0].title;
            }
        }

        return title;
    };

    const getAllCards = () => {
        const allCards: any[] = [];

        photoReports.forEach((photo: IPhotoRep, index: number) => {
            let showCard = true;
            if (props.acceptedFileTypes && props.acceptedFileTypes.length > 0) {
                if (
                    photo.resource.presentedForm[0].contentType === 'application/pdf' &&
                    !props.acceptedFileTypes.includes('pdf')
                ) {
                    showCard = false;
                }
            }

            if (showCard) {
                allCards.push(
                    <div key={index} style={{ padding: 5 }}>
                        <Col>
                            <Nav className="justify-content-center">
                                <Nav.Item>
                                    <Nav.Link
                                        onClick={() => showPhoto(photo.resource.id, photo.resource.effectiveDateTime)}
                                    >
                                        <StyledPhotoCard cardwidth={cardWidth}>
                                            <Card.Body>
                                                <Card.Title>{getCardTitle(photo.resource.presentedForm)}</Card.Title>
                                                <Row style={{ paddingTop: cardWidth === '300px' ? 50 : 10 }}>
                                                    {photoCard(photo.resource.presentedForm)}
                                                </Row>
                                                <Row style={{ paddingTop: cardWidth === '300px' ? 50 : 10 }}>
                                                    <Col>
                                                        <Card.Text>Gesendet am: </Card.Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Card.Text>
                                                            {moment(photo.resource.effectiveDateTime).format(
                                                                'DD. MMMM YYYY HH:mm',
                                                            )}
                                                        </Card.Text>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </StyledPhotoCard>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </div>,
                );
            }
        });

        return allCards;
    };

    function Photos() {
        if (photoReports.length > 0) {
            return <>{getAllCards()}</>;
        } else {
            return (
                <div style={{ margin: 'auto', width: alertWidth }}>
                    <Alert variant="success">Keine Fotodokumentation vorhanden.</Alert>
                </div>
            );
        }
    }

    function handlePhotoChange(selectedIndex: React.SetStateAction<number>) {
        setModalIndex(selectedIndex);
    }

    function handleClose() {
        setPhotoModalShow({ show: false, modalTitle: '', modalDate: '', photoData: '' });
        setModalIndex(0);
    }

    return (
        <div>
            <Container fluid>
                {photoReports.length === 0 && dataFound ? (
                    <Row style={{ paddingTop: 100 }}>
                        <Col>
                            <Spinner animation="border" variant="primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                            <p style={{ color: themeContext.mainColor, paddingTop: 20 }}>
                                Fotodokumentation wird geladen...
                            </p>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ paddingTop: 20 }}>
                        <Photos />
                    </Row>
                )}
                <PhotoModal
                    show={photoModalShow.show}
                    itemMargin={itemMargins}
                    index={modalIndex}
                    onHide={() => handleClose()}
                    modalTitle={photoModalShow.modalTitle}
                    modalDate={photoModalShow.modalDate}
                    photoData={photoModalShow.photoData}
                    handleSelect={(selectedIndex: React.SetStateAction<number>) => handlePhotoChange(selectedIndex)}
                />
                <PDFModal
                    show={pdfModalShow.show}
                    onHide={() =>
                        setPdfModalShow({
                            show: false,
                            modalTitle: '',
                            pdfData: '',
                            isSignable: false,
                            isSigned: false,
                            diagnosticReportData: '',
                        })
                    }
                    modalTitle={pdfModalShow.modalTitle}
                    isSignable={pdfModalShow.isSignable}
                    isSigned={pdfModalShow.isSigned}
                    pdfData={pdfModalShow.pdfData}
                    diagnosticReportData={pdfModalShow.diagnosticReportData}
                />
            </Container>
        </div>
    );
};

export default UploadContentCards;
