import { useLocation } from 'react-router-dom';
import { charts } from '../../../utils/chartUtils';
import { getQueryParams } from '../../../utils/urlUtils';
import ActivityCharts from '../ActivityCharts/ActivityCharts';
import BloodSugarCharts from '../BloodSugar/BloodSugarCharts';
import BodyFatCharts from '../BodyFat/BodyFatCharts';
import FLICharts from '../FLI/FLICharts';
import SAMCharts from '../SAM/SAMCharts';
import WeightCharts from '../Weight/WeightCharts';

export interface IAppCharts {
    type?: string;
    chartType?: string;
    width?: number;
    height?: number;
    sessionId?: string;
    activeUserId?: number;
    showControllers?: boolean;
    dataPrecisionVal?: number;
}

const AppCharts = () => {
    const location = useLocation();
    const hashParams = getQueryParams(location.search);

    if (hashParams.type === 'Charts') {
        if (hashParams.chartType === charts.WEIGHT.appTypeName) {
            return <WeightCharts {...hashParams} />;
        }
        if (hashParams.chartType === charts.FATMASS.appTypeName) {
            return <BodyFatCharts {...hashParams} />;
        }
        if (hashParams.chartType === charts.BLOODSUGAR.appTypeName) {
            return <BloodSugarCharts {...hashParams} />;
        }
        if (hashParams.chartType === charts.FLI.appTypeName) {
            return <FLICharts {...hashParams} />;
        }
        if (hashParams.chartType === charts.ACTIVITY.appTypeName) {
            return <ActivityCharts {...hashParams} />;
        }
        if (hashParams.chartType === charts.SAM.appTypeName) {
            return <SAMCharts {...hashParams} />;
        }
    }

    return null;
};

export default AppCharts;
