import { useEffect, useState } from 'react';

interface ITextSwitch {
    textParts: string[];
    intervalSec: number;
}

const TextSwitch = (props: ITextSwitch) => {
    const [text, setText] = useState(props.textParts.length > 0 ? props.textParts[0] : '');

    const switchText = () => {
        const index = props.textParts.indexOf(text);
        const nextIndex = index + 1 === props.textParts.length ? 0 : index + 1;
        setText(props.textParts[nextIndex]);
    };

    useEffect(() => {
        let intervalId;
        if (props.textParts.length > 0 && props.textParts[0] !== '') {
            intervalId = setInterval(() => {
                switchText();
            }, props.intervalSec * 1000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [text]);

    return <>{text}</>;
};

export default TextSwitch;
