import { useContext } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import { ThemeContext } from 'styled-components';
import NavbarMenu from '../../container/Navbar/Navbar';
import Sidebar from '../../container/Sidebar/Sidebar';
import IFrameContext from '../../context/iframeContext';
import DragNDropArea from '../Div/DragNDropArea';
import { containerHeader } from '../Header/Header';

export interface IIFrame {
    src: string | undefined;
    name?: string;
    callingPage?: string;
    height?: string;
}

const IFrame = () => {
    const themeContext = useContext(ThemeContext);
    const { iframestate } = useContext(IFrameContext);

    return (
        <div>
            <header>
                <NavbarMenu />
            </header>
            <Container fluid>
                <Row>
                    <Col
                        md={3}
                        xl={2}
                        xs={12}
                        className="vh-100 d-flex flex-column"
                        style={{ backgroundColor: themeContext.mainColor }}
                    >
                        <Sidebar paddingTop="80px" />
                    </Col>
                    <DragNDropArea>
                        {containerHeader(iframestate.name ? iframestate.name : '')}
                        {iframestate.src && iframestate.src.length > 0 && (
                            <iframe
                                src={iframestate.src ? iframestate.src : 'about:blank'}
                                width="100%"
                                height="100%"
                                name={iframestate.name ? iframestate.name : ''}
                                style={{ border: 'none' }}
                            ></iframe>
                        )}
                    </DragNDropArea>
                </Row>
            </Container>
        </div>
    );
};

export const IFrameInternal = (props: IIFrame) => {
    return (
        <Container fluid>
            <iframe
                src={props.src ? props.src : 'about:blank'}
                width="100%"
                height={props.height ? props.height : '100%'}
                name={props.name ? props.name : ''}
                style={{ border: 'none' }}
            ></iframe>
        </Container>
    );
};
export default IFrame;
