import { useContext } from 'react';
import SigPadContext, { ISigData, ISignaturePadDevice } from '../context/sigpadContext';

export interface ISignotec {
    wsUri?: string;
}

export const useSignotecConnector = (props: ISignotec) => {
    const { sigpadstate, sigpaddispatch } = useContext(SigPadContext);

    const docHashes = {
        kSha1: 0,
        kSha256: 1,
    };

    const sha1_dochash = 'AAECAwQFBgcICQoLDA0ODxAREhM=';
    const sha256_dochash = 'AAECAwQFBgcICQoLDA0ODxAREhMUFRYXGBkaGxwdHh8=';
    const encryption_cert =
        'MIICqTCCAZGgAwIBAgIBATANBgkqhkiG9w0BAQUFADAYMRYwFAYDVQQKEw1EZW1vIHNpZ25vdGVjMB4XDTE1MTAwNzA5NDc1MFoXDTI1MTAwNDA5NDc1MFowGDEWMBQGA1UEChMNRGVtbyBzaWdub3RlYzCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAOFFpsZexYW28Neznn26Bp9NVCJywFFj1QYXg3DDsaSyr6ubuqXKSC4jkenIGBnom/zKPxwPDtNXuy+nyDYFXYNn87TUdh/51CCr3uk9kR9hvRIzBKwkOx0DGLdCoSGAKDOPHwx1rE0m/SOqYOQh6XFjlybw+KzDZcPvhf2Fq/IFNXHpk8m0YHMAReW8q34CYjk9ZtcIlrcYGTikQherOtYM8CaEUPDd6vdJgosGWEnDeNXDCAIWTFc5ECJm9Hh7a47eF3BG5Pjl1QfOSA8lQBV5eTjQc1n1rWCWULt143nIbN5yCFrn0D8W6+eKJV5urETxWUQ208iqgeU1bIgKSEUCAwEAATANBgkqhkiG9w0BAQUFAAOCAQEAt2ax8iwLFoOmlAOZTQcRQtjxseQAhgOTYL/vEP14rPZhF1/gkI9ZzhESdkqR8mHIIl7FnfBg9A2v9ZccC7YgRb4bCXNzv6TIEyz4EYXNkIq8EaaQpvsX4+A5jKIP0PRNZUaLJaDRcQZudd6FMyHxrHtCUTEvORzrgGtRnhBDhAMiSDmQ958t8RhET6HL8C7EnL7f8XBMMFR5sDC60iCu/HeIUkCnx/a2waZ13QvhEIeUBmTRi9gEjZEsGd1iZmgf8OapTjefZMXlbl7CJBymKPJgXFe5mD9/yEMFKNRy5Xfl3cB2gJka4wct6PSIzcQVPaCts6I0V9NfEikXy1bpSA==';

    const encryption_cert_only_when_empty = 'TRUE';
    const rsa_scheme = STPadServerLibDefault.RsaScheme.PSS;
    let supportsRSA = false;
    let canStoreEncryptKey = false;
    const padConnectionType = 'HID';
    const padIndex = 0;
    const docHash_type = docHashes.kSha256;

    const cancelButton = -1;
    const retryButton = -1;
    const confirmButton = -1;
    // const buttonDiff = 0;
    // const buttonLeft = 0;
    // const buttonTop = 0;
    // const buttonSize = 0;
    // let backgroundImage;
    // const scaleFactorX = 1.0;
    // const scaleFactorY = 1.0;

    let sampleRate;

    const fieldName = 'Signatur';
    const customText = 'Bitte hier signieren!';

    const padStates = {
        closed: 0,
        opened: 1,
    };

    let padState = padStates.closed;

    const padModes = {
        Default: 0,
        API: 1,
    };
    const padMode = padModes.Default;

    const deviceCapabilities = {
        HasColorDisplay: 0x00000001,
        HasBacklight: 0x00000002,
        SupportsVerticalScrolling: 0x00000004,
        SupportsHorizontalScrolling: 0x00000008,
        SupportsPenScrolling: 0x00000010,
        SupportsServiceMenu: 0x00000020,
        SupportsRSA: 0x00000040,
        SupportsContentSigning: 0x00000080,
        SupportsH2ContentSigning: 0x00000100,
        CanGenerateSignKey: 0x00000200,
        CanStoreSignKey: 0x00000400,
        CanStoreEncryptKey: 0x00000800,
        CanSignExternalHash: 0x00001000,
        SupportsRSAPassword: 0x00002000,
        SupportsSecureModePassword: 0x00004000,
        Supports4096BitKeys: 0x00008000,
        HasNFCReader: 0x00010000,
        SupportsKeyPad: 0x00020000,
        SupportsKeyPad32: 0x00040000,
        HasDisplay: 0x00080000,
        SupportsRSASignPassword: 0x00100000,
    };

    const padTypes = {
        sigmaUSB: 1,
        sigmaSerial: 2,
        zetaUSB: 5,
        zetaSerial: 6,
        omegaUSB: 11,
        omegaSerial: 12,
        gammaUSB: 15,
        gammaSerial: 16,
        deltaUSB: 21,
        deltaSerial: 22,
        deltaIP: 23,
        alphaUSB: 31,
        alphaSerial: 32,
        alphaIP: 33,
    };

    const getReadableType = (intTypeNumber: number) => {
        switch (intTypeNumber) {
            case padTypes.sigmaUSB:
                return 'Sigma USB';
            case padTypes.sigmaSerial:
                return 'Sigma serial';
            case padTypes.zetaUSB:
                return 'Zeta USB';
            case padTypes.zetaSerial:
                return 'Zeta serial';
            case padTypes.omegaUSB:
                return 'Omega USB';
            case padTypes.omegaSerial:
                return 'Omega serial';
            case padTypes.gammaUSB:
                return 'Gamma USB';
            case padTypes.gammaSerial:
                return 'Gamma serial';
            case padTypes.deltaUSB:
                return 'Delta USB';
            case padTypes.deltaSerial:
                return 'Delta serial';
            case padTypes.deltaIP:
                return 'Delta IP';
            case padTypes.alphaUSB:
                return 'Alpha USB';
            case padTypes.alphaSerial:
                return 'Alpha serial';
            case padTypes.alphaIP:
                return 'Alpha IP';
            default:
                return 'Unknown';
        }
    };

    const resetSignotecDeviceData = () => {
        sigpaddispatch({
            type: 'RESETALL',
        });
    };

    /* BEGIN extern callable hook functions */

    const loadSignotec = () => {
        let wsUri = 'wss://local.signotecwebsocket.de:49494';
        if (props.wsUri && props.wsUri.length > 0) {
            wsUri = props.wsUri;
        }
        STPadServerLibCommons.handleLogging = logMessage;
        STPadServerLibCommons.createConnection(wsUri, onOpen, onClose, onError);
    };

    const unloadSignotec = () => {
        try {
            default_close_pad();
        } catch (e) {
            logMessage('problems to close pad, maybe its already closed, exception was = ' + e);
        }

        STPadServerLibCommons.destroyConnection();
        resetSignotecDeviceData();
    };

    const clearSignotec = (canvasRef: any) => {
        signature_retry_send(canvasRef);
    };

    const sendSignotec = () => {
        signature_confirm_send();
    };

    /* END extern callable hook functions */

    const onOpen = (evt) => {
        let loadText = '';
        if (evt.target === undefined || evt.target.url === undefined) {
            loadText = 'ActiveX loaded';
        } else {
            loadText = 'Connected to ' + evt.target.url;
        }
        logMessage('loadText: ' + loadText);

        getSignatureDefault(); // hier aufrufen???
    };

    const onClose = (evt) => {
        let unloadText = '';
        if (evt.target === undefined || evt.target.url === undefined) {
            unloadText = 'ActiveX unloaded';
        } else {
            unloadText = 'Disconnected from ' + evt.target.url;
        }
        logMessage('unloadText: ' + unloadText);
    };

    const onError = (evt) => {
        let errorText = '';
        if (evt.target === undefined || evt.target.url === undefined) {
            errorText = 'Communication error';
        } else {
            errorText = 'Communication error ' + evt.target.url;
        }
        logMessage('errorText: ' + errorText);
    };

    const getSignatureDefault = () => {
        // search for pads begin
        const deviceData: ISignaturePadDevice = {
            deviceName: '',
            canStoreEncryptKey: false,
            firmwareVersion: '',
            serialNumber: '',
            supportsRSA: false,
            padConnectionType: '',
            padIndex: 0,
            displayHeight: 0,
            displayWidth: 0,
            sampleRate: 0,
            scaleFactorX: 0,
            scaleFactorY: 0,
            xResolution: 0,
            yResolution: 0,
        };

        const searchForPadsParams = new STPadServerLibDefault.Params.searchForPads();

        searchForPadsParams.setPadSubset(padConnectionType);

        STPadServerLibDefault.searchForPads(searchForPadsParams)
            .then(function (pads: any) {
                if (pads.foundPads.length == 0) {
                    logMessage('No connected pads have been found.');
                    return Promise.reject('No connected pads have been found.');
                }

                deviceData.padIndex = padIndex;
                deviceData.padConnectionType = padConnectionType;

                const padType = pads.foundPads[padIndex].type;

                deviceData.deviceName = getReadableType(padType);
                deviceData.serialNumber = pads.foundPads[padIndex].serialNumber;
                deviceData.firmwareVersion = pads.foundPads[padIndex].firmwareVersion;

                if (pads.foundPads[padIndex].capabilities & deviceCapabilities.SupportsRSA) {
                    supportsRSA = true;
                } else {
                    supportsRSA = false;
                }
                deviceData.supportsRSA = supportsRSA;

                if (pads.foundPads[padIndex].capabilities & deviceCapabilities.CanStoreEncryptKey) {
                    canStoreEncryptKey = true;
                } else {
                    canStoreEncryptKey = false;
                }
                deviceData.canStoreEncryptKey = canStoreEncryptKey;
            }, STPadServerLibCommons.defaultReject)
            // search for pads end

            // open pad begin
            .then(function () {
                const openPadParams = new STPadServerLibDefault.Params.openPad(padIndex);
                return STPadServerLibDefault.openPad(openPadParams);
            }, STPadServerLibCommons.defaultReject)
            .then(function (padInfo) {
                padState = padStates.opened;
                deviceData.displayWidth = padInfo.padInfo.displayWidth;
                deviceData.displayHeight = padInfo.padInfo.displayHeight;

                //get scale factor from signature resolution to canvas
                deviceData.scaleFactorX = padInfo.padInfo.displayWidth / padInfo.padInfo.xResolution;
                deviceData.scaleFactorY = padInfo.padInfo.displayHeight / padInfo.padInfo.yResolution;

                //get sample rate
                deviceData.sampleRate = padInfo.padInfo.samplingRate;

                //start the signature process
                selection_dialog();
            }, STPadServerLibCommons.defaultReject)
            // open pad end

            .then(
                function () {
                    logMessage('getSignatureDefault end');
                    sigpaddispatch({
                        type: 'SETDEVICEDATA',
                        deviceData: {
                            ...deviceData,
                        },
                    });
                },
                function (reason) {
                    error_message(reason);
                    default_close_pad();
                },
            );
    };

    const selection_dialog = () => {
        //Selection Diaglog nur für SignaturPads mit Display
        if (false) {
            // prepare selection dialog
            const checkBoxInformation: any = [];
            const box: any = {};
            box.id = 'Signature 1';
            box.text = 'Bite signieren...';
            box.checked = false;
            box.required = true;
            checkBoxInformation[0] = box;

            const startSelectionDialogParams = new STPadServerLibDefault.Params.startSelectionDialog();
            startSelectionDialogParams.addCheckboxInformation(checkBoxInformation);
            STPadServerLibDefault.startSelectionDialog(startSelectionDialogParams)
                .then(function () {
                    logMessage('Please select and confirm elements on the pad.');
                }, STPadServerLibCommons.defaultReject)
                .then(null, function (reason) {
                    error_message(reason);
                    default_close_pad();
                });
        } else {
            // start signature capture
            signature_start();
        }
    };

    const error_message = (param: any) => {
        if (param.errorCode < 0) {
            logMessage('Signotec errorMessage: Function' + param.command + ' failed. Reason: ' + param.errorMesage);
        }
    };

    const logMessage = (msg) => {
        //TODO: do something with this log
        console.log('Signotec logMessage: ' + msg);
    };

    // close pad begin
    const default_close_pad = () => {
        if (padState == padStates.opened) {
            const closePadParams = new STPadServerLibDefault.Params.closePad(padIndex);
            STPadServerLibDefault.closePad(closePadParams)
                .then(function () {
                    padState = padStates.closed;
                }, STPadServerLibCommons.defaultReject)
                .then(null, function (reason) {
                    error_message(reason);
                    return;
                });
        }
    };

    const api_close_pad = () => {
        if (padState == padStates.opened) {
            const closePadParams = new STPadServerLibApi.Device.Params.close(padIndex);
            STPadServerLibApi.Device.close(closePadParams)
                .then(function () {
                    padState = padStates.closed;
                }, STPadServerLibCommons.defaultReject)
                .then(null, function (reason) {
                    error_message(reason);
                    return;
                });
        }
    };
    // close pad end

    // signature start begin
    const signature_start = () => {
        let dochash;

        switch (docHash_type) {
            case docHashes.kSha1:
                dochash = sha1_dochash;
                break;

            case docHashes.kSha256:
                dochash = sha256_dochash;
                break;

            default:
                logMessage('unknown doc hash');
                return;
        }

        if (supportsRSA) {
            const startSignatureParams = new STPadServerLibDefault.Params.startSignature();
            startSignatureParams.setFieldName(fieldName);
            startSignatureParams.setCustomText(customText);
            if (canStoreEncryptKey) {
                startSignatureParams.enablePadEncryption(dochash, encryption_cert, encryption_cert_only_when_empty);
            } else {
                startSignatureParams.enablePadEncryption(dochash, null);
            }
            STPadServerLibDefault.startSignature(startSignatureParams)
                .then(function () {}, STPadServerLibCommons.defaultReject)
                .then(null, function (reason) {
                    error_message(reason);
                    default_close_pad();
                });
        } else {
            const startSignatureParams = new STPadServerLibDefault.Params.startSignature();
            startSignatureParams.setFieldName(fieldName);
            startSignatureParams.setCustomText(customText);
            STPadServerLibDefault.startSignature(startSignatureParams)
                .then(function () {}, STPadServerLibCommons.defaultReject)
                .then(null, function (reason) {
                    error_message(reason);
                    default_close_pad();
                });
        }
    };
    // signature start end

    /**
     * The send events
     */
    STPadServerLibCommons.handleDisconnect = function (index) {
        disconnect_send(index);
    };

    STPadServerLibCommons.handleNextSignaturePoint = function (x, y, p) {
        signature_point_send(x, y, p);
    };

    STPadServerLibDefault.handleRetrySignature = function () {
        signature_retry_send();
    };

    STPadServerLibDefault.handleConfirmSignature = function () {
        // signature_confirm_send();
        sigpaddispatch({
            type: 'SETDEVICEACTION_SEND',
            deviceActions: {
                sendClicked: true,
            },
        });
    };

    STPadServerLibDefault.handleCancelSignature = function () {
        // signature_cancel_send();
        sigpaddispatch({
            type: 'SETDEVICEACTION_CANCEL',
            deviceActions: {
                cancelClicked: true,
            },
        });
    };

    // STPadServerLibDefault.handleConfirmSelection = function () {
    //     selection_confirm_send();
    // };

    // STPadServerLibDefault.handleSelectionChange = function (fieldId, fieldChecked) {
    //     selection_change_send(fieldId, fieldChecked);
    // };

    // STPadServerLibDefault.handleCancelSelection = function () {
    //     selection_cancel_send();
    // };

    STPadServerLibDefault.handleError = function (error_context, return_code, error_description) {
        error_send(error_context, return_code, error_description);
    };

    STPadServerLibApi.Sensor.handleHotSpotPressed = function (button) {
        api_sensor_hot_spot_pressed_send(button);
    };

    STPadServerLibApi.Sensor.handleDisplayScrollPosChanged = function (xPos, yPos) {
        api_display_scroll_pos_changed_send(xPos, yPos);
    };

    // disconnect send begin
    const disconnect_send = (index) => {
        const msg = 'The pad (index: ' + index + ') has been disconnected.';
        logMessage('disconnect_send: ' + msg);

        padState = padStates.closed;
    };
    // disconnect send end

    // signature point send begin
    const signature_point_send = (x, y, p) => {
        // const sigcanvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById('signotecCanvas');
        if (sigpadstate.canvasRef) {
            const ctx = sigpadstate.canvasRef.current?.getContext('2d');
            if (ctx) {
                ctx.lineWidth = 4.5;
                ctx.lineCap = 'round';
                if (p == 0) {
                    // drawStrokeStartPoint(ctx, x * scaleFactorX, y * scaleFactorY);
                    drawStrokeStartPoint(
                        ctx,
                        x * sigpadstate.deviceData.scaleFactorX,
                        y * sigpadstate.deviceData.scaleFactorY,
                    );
                } else {
                    // drawStrokePoint(ctx, x * scaleFactorX, y * scaleFactorY);
                    drawStrokePoint(
                        ctx,
                        x * sigpadstate.deviceData.scaleFactorX,
                        y * sigpadstate.deviceData.scaleFactorY,
                    );
                }
            }
        }
    };
    // signature point send end

    /**
     * Draws a stroke start point into the canvas
     */
    function drawStrokeStartPoint(canvasContext, softCoordX, softCoordY) {
        // open new stroke's path
        canvasContext.beginPath();
        canvasContext.arc(softCoordX, softCoordY, 0.1, 0, 2 * Math.PI, true);
        canvasContext.fill();
        canvasContext.stroke();
        canvasContext.moveTo(softCoordX, softCoordY);
    }

    /**
     * Draws a stroke point into the canvas
     */
    function drawStrokePoint(canvasContext, softCoordX, softCoordY) {
        // continue after start or not start point
        canvasContext.lineTo(softCoordX, softCoordY);
        canvasContext.stroke();
    }

    // signature retry send begin
    const signature_retry_send = (canvRef?: any) => {
        let canvasRef: any = null;
        if (sigpadstate.canvasRef) {
            canvasRef = sigpadstate.canvasRef;
        } else if (canvRef) {
            canvasRef = canvRef;
        }

        if (canvasRef) {
            if (padMode == padModes.Default) {
                // default mode
                STPadServerLibDefault.retrySignature()
                    .then(function () {
                        const ctx = canvasRef.current?.getContext('2d');
                        if (ctx) {
                            if (canvasRef.current) {
                                ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                            }
                        }
                    }, STPadServerLibCommons.defaultReject)
                    .then(null, function (reason) {
                        error_message(reason);
                        default_close_pad();
                    });
            } else if (padMode == padModes.API) {
                // API mode
                STPadServerLibApi.Signature.retry()
                    .then(function () {
                        const ctx = canvasRef.current?.getContext('2d');
                        if (ctx) {
                            if (canvasRef.current) {
                                ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                            }
                        }
                    }, STPadServerLibCommons.defaultReject)
                    .then(null, function (reason) {
                        error_message(reason);
                        api_close_pad();
                    });
            } else {
                alert('invalid padMode');
                return;
            }
        }
    };
    // signature retry send end

    // signature confirm send begin
    const signature_confirm_send = () => {
        const sigData: ISigData = {
            certId: '',
            pictureData: '',
            rsaSignature: '',
            signData: '',
            signingCert: '',
        };

        if (padMode == padModes.Default) {
            // default mode
            STPadServerLibDefault.confirmSignature()
                .then(function (value) {
                    // check if there are enough points for a valid signature
                    if (value.countedPoints / sampleRate <= 0.2) {
                        alert('The signature is too short. Please sign again!');
                        return STPadServerLibDefault.retrySignature();
                    }
                    if (supportsRSA) {
                        return STPadServerLibDefault.getSigningCert();
                    } else {
                        return value;
                    }
                }, STPadServerLibCommons.defaultReject)
                .then(function (value) {
                    if (value.command == 'TOKEN_CMD_SIGNATURE_RETRY') {
                        const ctx = sigpadstate.canvasRef.current?.getContext('2d');
                        if (ctx) {
                            if (sigpadstate.canvasRef.current?.width && sigpadstate.canvasRef.current?.height) {
                                ctx.clearRect(
                                    0,
                                    0,
                                    sigpadstate.canvasRef.current?.width,
                                    sigpadstate.canvasRef.current?.height,
                                );
                            }
                        }

                        return value;
                    }
                    if (supportsRSA) {
                        if (value.signingCert !== undefined) {
                            sigData.signingCert = value.signingCert; // NEEDED
                        }
                    }
                    const getSignatureImageParams = new STPadServerLibDefault.Params.getSignatureImage();
                    getSignatureImageParams.setFileType(STPadServerLibDefault.FileType.PNG);
                    getSignatureImageParams.setPenWidth(5);
                    return STPadServerLibDefault.getSignatureImage(getSignatureImageParams);
                }, STPadServerLibCommons.defaultReject)
                .then(function (value) {
                    if (value.command == 'TOKEN_CMD_SIGNATURE_RETRY') {
                        // just do nothing but returning original promise object
                        return value;
                    }
                    if (value.file !== undefined) {
                        const pictureData = 'data:image/png;base64,' + value.file; //NEEDED
                        sigData.pictureData = pictureData;
                    }
                    const getSignatureDataParams = new STPadServerLibDefault.Params.getSignatureData();
                    getSignatureDataParams.setRsaScheme(rsa_scheme);
                    return STPadServerLibDefault.getSignatureData(getSignatureDataParams);
                }, STPadServerLibCommons.defaultReject)
                .then(function (value) {
                    if (value.command == 'TOKEN_CMD_SIGNATURE_RETRY') {
                        // just do nothing but returning original promise object
                        return value;
                    }
                    if (supportsRSA) {
                        if (value.certId !== undefined) {
                            const certId = value.certId; //NEEDED
                            sigData.certId = certId;
                        }
                        if (value.rsaSignature !== undefined) {
                            const rsaSignature = value.rsaSignature; //NEEDED
                            sigData.rsaSignature = rsaSignature;
                        }
                    }
                    if (value.signData !== undefined) {
                        const signData = value.signData; //NEEDED
                        sigData.signData = signData;
                    }
                    default_close_pad();

                    sigpaddispatch({
                        type: 'SETSIGDATA',
                        sigData: {
                            ...sigData,
                        },
                    });
                }, STPadServerLibCommons.defaultReject)
                .then(null, function (reason) {
                    error_message(reason);
                    default_close_pad();
                });
        } else if (padMode == padModes.API) {
            // // API mode
            // STPadServerLibApi.Signature.confirm()
            //     .then(function (value) {
            //         // check if there are enough points for a valid signature
            //         if (value.countedPoints / sampleRate <= 0.2) {
            //             alert('The signature is too short. Please sign again!');
            //             return STPadServerLibApi.Signature.retry();
            //         }
            //         const saveAsStreamExParams = new STPadServerLibApi.Signature.Params.saveAsStreamEx(
            //             300,
            //             0,
            //             0,
            //             STPadServerLibApi.FileType.PNG,
            //             5,
            //             document.getElementById('signaturePenColorSelect').value,
            //             0,
            //         );
            //         return STPadServerLibApi.Signature.saveAsStreamEx(saveAsStreamExParams);
            //     }, STPadServerLibCommons.defaultReject)
            //     .then(function (value) {
            //         if (value.command == 'TOKEN_CMD_API_SIGNATURE_RETRY') {
            //             const ctx = sigcanvas.getContext('2d');
            //             ctx.clearRect(0, 0, sigcanvas.width, sigcanvas.height);
            //             return value;
            //         }
            //         if (value.image !== undefined) {
            //             document.getElementById('Signature_0').src = 'data:image/png;base64,' + value.image;
            //         }
            //         return STPadServerLibApi.Signature.getSignData();
            //     }, STPadServerLibCommons.defaultReject)
            //     .then(function (value) {
            //         if (value.command == 'TOKEN_CMD_API_SIGNATURE_RETRY') {
            //             // just do nothing but returning original promise object
            //             return value;
            //         }
            //         if (value.signData !== undefined) {
            //             document.getElementById('SignData_0').value = value.signData;
            //         }
            //         api_close_pad();
            //     }, STPadServerLibCommons.defaultReject)
            //     .then(
            //         function (value) {},
            //         function (reason) {
            //             error_message(reason);
            //             api_close_pad();
            //         },
            //     );
        } else {
            alert('invalid padMode');
            return;
        }
    };
    // signature confirm send end

    // signature cancel send begin
    const signature_cancel_send = () => {
        //TODO: do something with the points...
        //
        //
        // if (padMode == padModes.Default) {
        //     // default mode
        //     STPadServerLibDefault.cancelSignature()
        //         .then(function (value) {
        //             const ctx = sigcanvas.getContext('2d');
        //             ctx.clearRect(0, 0, sigcanvas.width, sigcanvas.height);
        //             default_close_pad();
        //         }, STPadServerLibCommons.defaultReject)
        //         .then(null, function (reason) {
        //             error_message(reason);
        //             default_close_pad();
        //         });
        // } else if (padMode == padModes.API) {
        //     // API mode
        //     const cancelParams = new STPadServerLibApi.Signature.Params.cancel();
        //     cancelParams.setErase(0);
        //     STPadServerLibApi.Signature.cancel(cancelParams)
        //         .then(function (value) {
        //             const ctx = sigcanvas.getContext('2d');
        //             ctx.clearRect(0, 0, sigcanvas.width, sigcanvas.height);
        //             api_close_pad();
        //         }, STPadServerLibCommons.defaultReject)
        //         .then(
        //             function (value) {},
        //             function (reason) {
        //                 error_message(reason);
        //                 api_close_pad();
        //             },
        //         );
        // } else {
        //     alert('invalid padMode');
        //     return;
        // }
    };
    // signature cancel send end

    // selection confirm send begin
    // const selection_confirm_send = () => {

    //     if (padMode == padModes.Default) {
    //         // default mode
    //         let status = '';
    //         for (i = 1; i <= document.getElementById('check_boxes_selectedElements').value; i++) {
    //             status += 'Feld ' + i + ' = ' + document.getElementById('fieldChecked' + i).checked + '\n';
    //         }
    //         alert(status);
    //         signature_start();
    //     } else if (padMode == padModes.API) {
    //         // API mode
    //         // do nothing
    //     } else {
    //         alert('invalid padMode');
    //         return;
    //     }
    // };
    // selection confirm send end

    // selection change send begin
    // const selection_change_send = (fieldId, fieldChecked) => {

    //     if (padMode == padModes.Default) {
    //         // default mode
    //         for (i = 1; i <= document.getElementById('check_boxes_selectedElements').value; i++) {
    //             if (document.getElementById('fieldID' + i).value == fieldId) {
    //                 if (fieldChecked == 'TRUE') {
    //                     document.getElementById('fieldChecked' + i).checked = true;
    //                 } else {
    //                     document.getElementById('fieldChecked' + i).checked = false;
    //                 }
    //             }
    //         }
    //     } else if (padMode == padModes.API) {
    //         // API mode
    //         // do nothing
    //     } else {
    //         alert('invalid padMode');
    //         return;
    //     }
    // };
    // selection change send end

    // selection cancel send begin
    // const selection_cancel_send = () => {

    //     if (padMode == padModes.Default) {
    //         // default mode
    //         const ctx = sigcanvas.getContext('2d');
    //         ctx.clearRect(0, 0, sigcanvas.width, sigcanvas.height);
    //         STPadServerLibDefault.cancelSignature()
    //             .then(function (value) {
    //                 default_close_pad();
    //             }, STPadServerLibCommons.defaultReject)
    //             .then(null, function (reason) {
    //                 error_message(reason);
    //                 default_close_pad();
    //             });
    //     } else if (padMode == padModes.API) {
    //         // API mode
    //         // do nothing
    //     } else {
    //         alert('invalid padMode');
    //         return;
    //     }
    // };
    // selection cancel send end

    // error send begin
    const error_send = (error_context, return_code, error_description) => {
        const ret = return_code;
        if (ret < 0) {
            logMessage('Failed to confirm the signature. Reason: ' + error_description + ', Context: ' + error_context);
        }
    };
    // error send end

    // api sensor hot spot pressed send begin
    const api_sensor_hot_spot_pressed_send = (button) => {
        switch (button) {
            // cancel signing process
            case cancelButton:
                signature_cancel_send();
                break;

            // restart signing process
            case retryButton:
                signature_retry_send();
                break;

            // confirm signing process
            case confirmButton:
                signature_confirm_send();
                break;

            default:
                alert('unknown button id: ' + button);
        }
    };
    // api sensor hot spot pressed send end

    // api display scroll pos changed send begin
    function api_display_scroll_pos_changed_send(xPos, yPos) {
        logMessage(xPos + ',' + yPos);
    }
    // api display scroll pos changed send end

    return {
        loadSignotec,
        unloadSignotec,
        clearSignotec,
        sendSignotec,
    };
};
