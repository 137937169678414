import Toast from 'react-bootstrap/Toast';
import { VscError } from 'react-icons/vsc';
import { IToast } from './ConfirmationToast';

export const ErrorToast = (props: IToast) => {
    return (
        <Toast
            style={{
                backgroundColor: '#fed3d9',
                borderRadius: 10,
                width: props.width ? props.width : '250px',
                height: props.height ? props.height : '110px',
            }}
            onClose={props.onHide}
            show={props.show}
            delay={props.delay}
            autohide
        >
            <Toast.Body style={{ color: '#8c2437', fontSize: 18, width: props.width }}>
                <VscError size="30" color="#8c2437" />
                <div>{props.text}</div>
            </Toast.Body>
        </Toast>
    );
};

export default ErrorToast;
