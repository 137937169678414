import { Navbar } from 'react-bootstrap';
import styled from 'styled-components';

interface IStyledNavbar {
    backgroundColor?: string;
    boxShadow?: string;
}

export const StyledNavbar = styled(Navbar)<IStyledNavbar>`
    background-color: ${(prop) => (prop.backgroundColor ? prop.backgroundColor : prop.theme.navbar.backgroundColor)};
    box-shadow: ${(prop) => (prop.boxShadow ? prop.boxShadow : prop.theme.navbar.boxShadow)};
`;
