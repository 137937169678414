import { useContext } from 'react';
import Nav from 'react-bootstrap/Nav';
import { FaBed } from 'react-icons/fa';
import { ThemeContext } from 'styled-components';
import TerminierungContext from '../../context/terminierungContext';
import MyTooltip from '../Tooltip/MyTooltip';

const InpatientAppointment = () => {
    const themeContext = useContext(ThemeContext);
    const { tmdispatch } = useContext(TerminierungContext);

    const inpatientAppointment = async () => {
        /*
        tmdispatch({
            type: 'SETCANCELNEWAPPOINTMENTDATA',
            cancelNewAppointmentData: {
                showModal: false,
                data: props,
                reason: APPOINTMENT_POSTPONE_REASON,
            },
        });
        */
    };

    return (
        <div>
            <Nav.Link data-name="inpatientappointment" onClick={() => inpatientAppointment()}>
                <MyTooltip
                    id="InpatientAppointmentTooltip"
                    text="Stationäre Aufnahme"
                    iconDiv={true}
                    divWidth="25px"
                    marginLeft="auto"
                    marginRight="0"
                    icon={<FaBed size={25} color={themeContext.icon.default.color} />}
                ></MyTooltip>
            </Nav.Link>
        </div>
    );
};

export default InpatientAppointment;
