import { createContext } from 'react';

export type InitialIFrameType = {
    src: string;
    name: string;
    callingPage: string;
};

export const initialIFrameState = {
    src: '',
    name: '',
    callingPage: '',
};

const IFrameContext = createContext<{
    iframestate: InitialIFrameType;
    iframedispatch: React.Dispatch<any>;
}>({
    iframestate: initialIFrameState,
    iframedispatch: () => null,
});

export default IFrameContext;
