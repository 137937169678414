import { useContext, useEffect } from 'react';
import StyledButton from '../../components/Buttons/StyledButton';
import StyledCol from '../../components/Cols/StyledCol';
import { StickyButtonRowBottomDiv } from '../../components/Div/StyledDiv';
import { StyledRowNoMargin } from '../../components/Rows/StyledRow';
import AlertContext from '../../context/alertContext';
import LoginContext, { InitialLoginType } from '../../context/loginContext';
import { getDiagnosticReportsOfPatient, IDRDataEntry } from '../../services/fhir/FHIRDiagnosticReports';
import UploadContent from '../UploadContent/UploadContent';

interface ITermineButtons {
    handleNext(): void;
    nextButtonEnabled: boolean;
}

interface IUpload {
    showNextButton: boolean;
    nextButtonEnabled?: boolean;
    handleNextWithActiveStep?(currentActiveStep: number): void;
    currentActiveStep: number;
}

const UploadButtons = (props: ITermineButtons) => {
    const handleNext = () => {
        props.handleNext();
    };

    const buttonEnabled = () => {
        let enabled = true;
        if (props.nextButtonEnabled === false) {
            enabled = false;
        }
        return enabled;
    };

    return (
        <StickyButtonRowBottomDiv marginTop="30px">
            <StyledRowNoMargin>
                <StyledCol paddingLeft="0px" paddingRight="0px" textAlign="right">
                    <StyledButton disabled={!buttonEnabled()} onClick={() => handleNext()}>
                        Weiter
                    </StyledButton>
                </StyledCol>
            </StyledRowNoMargin>
        </StickyButtonRowBottomDiv>
    );
};

const Upload = (props: IUpload) => {
    const { state } = useContext(LoginContext);
    const { alertdispatch } = useContext(AlertContext);

    useEffect(() => {
        getDiagnosticReports(state, alertdispatch);
    }, []);

    const handleNextClick = () => {
        if (props.handleNextWithActiveStep) {
            props.handleNextWithActiveStep(props.currentActiveStep);
        }
    };

    const getDiagnosticReports = async (state: InitialLoginType, alertdispatch: any) => {
        const response = await getDiagnosticReportsOfPatient(state, alertdispatch);
        if (response) {
            const rep: IDRDataEntry[] = response.data.entry;
            console.log(rep);
            // setReports(rep);
        }
    };

    const buttonEnabled = () => {
        let enabled = true;
        if (props.nextButtonEnabled === false) {
            enabled = false;
        }
        return enabled;
    };

    return (
        <div>
            <UploadContent
                headerTitle="Briefe und Befunde"
                fileUploadButtonName="Dateien hochladen ..."
                cameraUploadButtonName="Kamera ..."
                acceptedFileTypes=".pdf, .jpg, .jpeg, .png"
            />
            {props.showNextButton && <UploadButtons handleNext={handleNextClick} nextButtonEnabled={buttonEnabled()} />}
        </div>
    );
};

export default Upload;
