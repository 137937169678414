import { useMediaQuery } from 'react-responsive';

/* Custom Screen Sizes */
const desktopMin = 1025;
const tabletMin = 768;
const tabletMax = 1024;
const mobileMax = 767;
const defaultMin = 768;

export const isDesktop = (screenWidth: number) => {
    if (screenWidth >= desktopMin) {
        return true;
    }
    return false;
};

export const Desktop = ({ children }: any) => {
    const isDesktop = useMediaQuery({ minWidth: desktopMin });
    return isDesktop ? children : null;
};

export const isTablet = (screenWidth: number) => {
    if (screenWidth >= tabletMin && screenWidth <= tabletMax) {
        return true;
    }
    return false;
};

export const Tablet = ({ children }: any) => {
    const isTablet = useMediaQuery({ minWidth: tabletMin, maxWidth: tabletMax });
    return isTablet ? children : null;
};

export const isMobile = (screenWidth: number) => {
    if (screenWidth <= mobileMax) {
        return true;
    }
    return false;
};

export const Mobile = ({ children }: any) => {
    const isMobile = useMediaQuery({ maxWidth: mobileMax });
    return isMobile ? children : null;
};

export const isDefault = (screenWidth: number) => {
    if (screenWidth >= defaultMin) {
        return true;
    }
    return false;
};

export const Default = ({ children }: any) => {
    const isNotMobile = useMediaQuery({ minWidth: defaultMin });
    return isNotMobile ? children : null;
};

/* Bootstrap Screen Sizes */
export const isBootstrap_lg = (screenWidth: number) => {
    if (screenWidth >= 992) {
        return true;
    }
    return false;
};

export const isBootstrap_md = (screenWidth: number) => {
    if (screenWidth >= 768) {
        return true;
    }
    return false;
};

export const isBootstrap_sm = (screenWidth: number) => {
    if (screenWidth >= 576) {
        return true;
    }
    return false;
};

export const isBootstrap_xl = (screenWidth: number) => {
    if (screenWidth >= 1200) {
        return true;
    }
    return false;
};

export const isBootstrap_xs = (screenWidth: number) => {
    if (screenWidth < 576) {
        return true;
    }
    return false;
};

export const isBootstrap_xxl = (screenWidth: number) => {
    if (screenWidth >= 1400) {
        return true;
    }
    return false;
};
