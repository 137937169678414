import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/esm/Row';
import { BsChatDots } from 'react-icons/bs';
import styled from 'styled-components';
import StyledCol from '../Cols/StyledCol';

interface IChatWidgetSymbol {
    isOpen: boolean;
    handleIconClick?: any;
    numberUnreadMsg?: number;
}

const ChatWidgetSymbolContainer = styled(Container)`
    align-self: flex-end;
    background-color: rgb(36, 94, 173);
    border: 0;
    border-radius: 500%;
    box-shadow: 0 2px 10px 1px #b5b5b5;
    height: 60px;
    margin-top: 10px;
    cursor: pointer;
    width: 60px;
`;

const WidgetSymbol = (props: IChatWidgetSymbol) => {
    let showUnreadBadge = false;
    if (props.numberUnreadMsg && props.numberUnreadMsg > 0) {
        showUnreadBadge = true;
    }

    if (props.isOpen) {
        return <p style={{ color: 'white', fontSize: '2rem', paddingTop: '5px' }}>X</p>;
    } else {
        return (
            <div>
                <BsChatDots size={30} color={'white'} style={{ marginTop: '12px' }} />
                {showUnreadBadge && (
                    <Badge variant="secondary" style={{ position: 'absolute' }}>
                        {props.numberUnreadMsg}
                    </Badge>
                )}
            </div>
        );
    }
};

const ChatWidgetSymbol = (props: IChatWidgetSymbol) => {
    return (
        <Container>
            <Row>
                <StyledCol xs={{ offset: 8 }} textAlign="center">
                    <ChatWidgetSymbolContainer onClick={props.handleIconClick}>
                        <WidgetSymbol isOpen={props.isOpen} numberUnreadMsg={props.numberUnreadMsg} />
                    </ChatWidgetSymbolContainer>
                </StyledCol>
            </Row>
        </Container>
    );
};

export default ChatWidgetSymbol;
