import { useContext } from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import LoginContext from '../../context/loginContext';
import useScreenResolution from '../../hooks/useScreenResolution';
import { Paths } from '../../Routes';
import TextSwitch from '../Text/TextSwitch';
import StyledButton from './StyledButton';

interface IEncounterTitle {
    smallScreen?: boolean;
}

const EncounterTitle = (props: IEncounterTitle) => {
    const { state, dispatch } = useContext(LoginContext);
    const navigate = useNavigate();
    const screenSize = useScreenResolution();

    const resetEncounter = () => {
        dispatch({
            type: 'SETENCOUNTER',
            encounterId: '',
            encounterTitle: '',
        });
        navigate(Paths.TERMINE);
    };

    const getWidth = () => {
        if (props.smallScreen) {
            if (screenSize.width > 430) {
                return '200px';
            } else if (screenSize.width > 380) {
                return '150px';
            } else {
                return '50px';
            }
        }
        return null;
    };

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="EncounterTitleTooltip">Terminauswahl aufheben</Tooltip>}
        >
            <StyledButton
                variant="secondary"
                paddingLeft="2px"
                onClick={resetEncounter}
                style={{
                    fontSize: props.smallScreen ? '0.6rem' : '1rem',
                    lineHeight: '1.0',
                    width: getWidth(),
                    height: props.smallScreen ? '50px' : null,
                    overflow: 'hidden',
                }}
            >
                <Badge>
                    <IoIosCloseCircleOutline size={20} />
                </Badge>
                {props.smallScreen ? (
                    <TextSwitch textParts={state.encounterTitle.split(' - ')} intervalSec={3} />
                ) : (
                    state.encounterTitle
                )}
            </StyledButton>
        </OverlayTrigger>
    );
};

export default EncounterTitle;
