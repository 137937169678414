import { useEffect, useState } from 'react';
import { useChart } from '../../../hooks/useChart';
import useScreenResolution from '../../../hooks/useScreenResolution';
import {
    getTableDataFromDiagnosticReportResponse,
    getTableHeight,
    IGetTableData,
    tables,
} from '../../../utils/chartUtils';
import { CenterDiv } from '../../Div/StyledDiv';
import LoadingSpinnerWithText from '../../Loading/LoadingSpinnerWithText';
import TableProvider, { initalTableData } from '../../TableProvider/TableProvider';

interface ITableProps {
    tableTitle: string;
    handleSetTableTitle(title: string): void;
}

const VALTable = (props: ITableProps) => {
    const { getTableData } = useChart({ chartId: tables.VALTBL.requestId });
    const [chartData, setChartData] = useState<IGetTableData>({
        ...initalTableData,
    });
    const [dataLoaded, setDataLoaded] = useState(false);
    const screenSize = useScreenResolution();

    useEffect(() => {
        const getData = async () => {
            const response = await getTableData();
            setDataLoaded(true);
            const [tableTitle, tableData] = getTableDataFromDiagnosticReportResponse(response);
            setChartData({
                tableTitle: tableTitle,
                tableData: tableData,
            });
            props.handleSetTableTitle(tableTitle);
        };

        getData();
    }, []);

    const showTable = () => {
        if (chartData.tableTitle && chartData.tableTitle.length > 0) {
            return <TableProvider maxHeight={getTableHeight(screenSize.height)} tableData={chartData.tableData} />;
        } else {
            return null;
        }
    };

    // return <>{showTable()}</>;
    return (
        <>
            {!dataLoaded ? (
                <CenterDiv height={(screenSize.height / 2).toString() + 'px'}>
                    <LoadingSpinnerWithText loadingText="Daten werden geladen..." />
                </CenterDiv>
            ) : (
                <>{showTable()}</>
            )}
        </>
    );
};

export default VALTable;
