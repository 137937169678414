import * as React from 'react';
import { AlertOnHide, AlertType } from '../context/alertContext';
import { IAlertMessageWithoutDispatch, showAlertMessage } from './alertHandling';

/* #### ERROR MESSAGES #### */
export const ERROR_MESSAGE_DEFAULT = 'Es ist ein Fehler aufgetreten.';

/* ######################## */

interface IResponseErrorHandling {
    response: any;
    alertdispatch: React.Dispatch<any>;
    alertMessage?: IAlertMessageWithoutDispatch;
}

export const responseErrorHandling = (props: IResponseErrorHandling) => {
    let responseMsg = '';
    if (props.response) {
        if (props.response.data) {
            if (props.response.data.issue) {
                if (props.response.data.issue[0].diagnostics !== undefined) {
                    responseMsg = props.response.data.issue[0].diagnostics;
                }
            } else {
                responseMsg = props.response.data;
            }
        } else if (props.response.message) {
            responseMsg = props.response.message;
        } else if (typeof props.response === 'string') {
            responseMsg = props.response;
        }
    }

    /* check if responseMsg is a string */
    if (typeof responseMsg !== 'string') {
        responseMsg = 'responseErrorHandling(): Keine error responseMsg gefunden.';
    }

    /* Priority Error Messages */
    if (responseMsg.includes('Authorization failed')) {
        showAlertMessage({
            alertTitle: 'Authorization',
            alertTxt: 'Authorization failed',
            alertType: AlertType.error,
            onHide: AlertOnHide.goToHome,
            // onHideCustom: onHideAlertModal,
            alertdispatch: props.alertdispatch,
        });

        /* Custom Error Message OR Default Error Message*/
    } else {
        showAlertMessage({
            alertTitle: props.alertMessage ? props.alertMessage.alertTitle : ERROR_MESSAGE_DEFAULT,
            alertTxt: props.alertMessage ? props.alertMessage.alertTxt : responseMsg,
            alertType: props.alertMessage ? props.alertMessage.alertType : AlertType.error,
            onHide: props.alertMessage ? props.alertMessage.onHide : AlertOnHide.onlyClose,
            onHideCustom: props.alertMessage?.onHideCustom ? props.alertMessage.onHideCustom : undefined,
            alertdispatch: props.alertdispatch,
        });
    }
};
