import { version } from '../../package.json';
import { charts, tables } from '../utils/chartUtils';

const appVersion = version;

const baseURL = (window as any).pcpconfig.baseURL;
const homepageURL = (window as any).pcpconfig.homepageURL;
const contextConfig = (window as any).pcpconfig.context;
const qrCodeConfig = (window as any).pcpconfig.qrcode;
const themeConfig = (window as any).pcpconfig.theme;
// const basicauthConfig = (window as any).pcpconfig.basicauth;

export const getBaseURL = () => {
    return baseURL;
};

export const getRestURL = () => {
    return baseURL + '/rest/';
};

export const getFhirURL = () => {
    return baseURL + '/fhir';
};

export const getTermsURL = () => {
    return baseURL + '/C.hd?calcWith=TermsHTML';
};

export const getPrivacyURL = () => {
    return baseURL + '/C.hd?calcWith=PrivacyHTML';
};

export const getHomepageURL = () => {
    return homepageURL;
};

export const getContextConfig = () => {
    return contextConfig;
};

export const getQRCodeConfig = () => {
    return qrCodeConfig;
};

// export const getBasicAuthConfig = () => {
//     return basicauthConfig;
// };

export const getAppVersion = () => {
    return appVersion;
};

export const getThemeConfig = () => {
    return themeConfig;
};

export const localStorageKey = {
    // lastInteraction: 'lastInteraction',
};

export const moment = require('moment/min/moment-with-locales');
moment.locale('de');

export const appointmentStatus = {
    booked: 'booked',
    cancelled: 'cancelled',
    enteredInError: 'entered-in-error',
    arrived: 'arrived',
};

export const PROFILE_HOME = 'HOME';

export const securityItemNames = {
    CHARTS: 'CHARTS',
    CHARTS_WEIGHT: charts.WEIGHT.id,
    CHARTS_FATMASS: charts.FATMASS.id,
    CHARTS_BLOODSUGAR: charts.BLOODSUGAR.id,
    CHARTS_FLI: charts.FLI.id,
    CHARTS_SAM: charts.SAM.id,
    CHARTS_ACTIVITY: charts.ACTIVITY.id,
    TABLE_VAL: tables.VALTBL.id,
    PATLIST: 'PATLIST',
    DOKUMENT: 'DOKUMENT',
    FOTOS: 'FOTOS',
    FRAGEBOGEN: 'FRAGEBOGEN',
    MEDIKATION: 'MEDIKATION',
    TAGESPLAN: 'TAGESPLAN',
    TERMINE: 'TERMINE',
    VIDEO: 'VIDEO',
    JOURNEY: 'JOURNEY',
    TODO: 'TODO',
    TAB_ORDER: 'TAB_ORDER',
};

export const jitsiOptions = {
    configOverwrite: {
        toolbarButtons: [
            'camera',
            'chat',
            // 'closedcaptions',
            'desktop',
            // 'download',
            // 'embedmeeting',
            // 'etherpad',
            // 'feedback',
            'filmstrip',
            'fullscreen',
            'hangup',
            // 'help',
            'highlight',
            // 'invite',
            // 'linktosalesforce',
            // 'livestreaming',
            'microphone',
            'noisesuppression',
            'participants-pane',
            'profile',
            'raisehand',
            // 'recording',
            // 'security',
            // 'select-background',
            'settings',
            // 'shareaudio',
            // 'sharedvideo',
            // 'shortcuts',
            // 'stats',
            'tileview',
            'toggle-camera',
            'videoquality',
            // 'whiteboard',
        ],
        breakoutRooms: {
            // Hides the add breakout room button. This replaces `hideAddRoomButton`.
            hideAddRoomButton: true,
            // Hides the auto assign participants button.
            hideAutoAssignButton: true,
            // Hides the join breakout room button.
            hideJoinRoomButton: true,
        },
    },
};
