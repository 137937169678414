import StyledButton from '../Buttons/StyledButton';
import { CenterDiv, VerticalCenterDiv } from '../Div/StyledDiv';

interface IFinalCheckIn {
    handleButtonClick(): void;
}

const FinalCheckIn = (props: IFinalCheckIn) => {
    const onButtonClick = () => {
        props.handleButtonClick();
    };

    return (
        <VerticalCenterDiv divTop="40%">
            <CenterDiv>
                <StyledButton size="lg" onClick={() => onButtonClick()}>
                    CheckIn abschließen
                </StyledButton>
            </CenterDiv>
        </VerticalCenterDiv>
    );
};

export default FinalCheckIn;
