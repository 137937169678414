import { useContext, useEffect } from 'react';
import TerminierungContext from '../../context/terminierungContext';
import { useGoogleAnalytics } from '../../hooks/useGoogleAnalytics';
import { getMacroNameFromSelectedResource } from '../../utils/appointmentUtils';
import { LeftDiv } from '../Div/StyledDiv';
import AppointmentCalendar from './AppointmentCalendar';

interface IAppCalHead {
    terminName?: string;
    handleTimelotClick?(currentActiveStep: number): void;
    currentActiveStep?: number;
}

const AppointmentCalendarWithHeadline = (props: IAppCalHead) => {
    const { tmstate } = useContext(TerminierungContext);
    const { sendGoogleAnalyticsPageView } = useGoogleAnalytics();

    useEffect(() => {
        sendGoogleAnalyticsPageView({ page: '/AppointmentCalendarWithHeadline', title: 'Termin wählen' });
    }, []);

    useEffect(() => {
        if (props.handleTimelotClick) {
            if (tmstate.selectedTimeslots && tmstate.selectedTimeslots.length > 0 && tmstate.timeslotClicked) {
                props.handleTimelotClick(props.currentActiveStep ? props.currentActiveStep : 0);
            }
        }
    }, [tmstate]);

    let terminName = 'Termin';
    if (props.terminName) {
        terminName = props.terminName;
    }

    let categoryName = tmstate.selectedCategory.name;
    if (!categoryName) {
        if (tmstate.selectedReason?.reasonName && tmstate.selectedReason?.reasonName !== undefined) {
            categoryName = tmstate.selectedReason.reasonName;
        } else if (tmstate.selectedCategory?.name && tmstate.selectedCategory?.name !== undefined) {
            categoryName = tmstate.selectedCategory.name;
        } else {
            categoryName = getMacroNameFromSelectedResource(tmstate.selectedResources[0]);
        }
    }

    return (
        <LeftDiv>
            <h3>{terminName} wählen</h3>
            <p>
                Wählen Sie Tag und Uhrzeit für <strong>{categoryName}</strong> aus.
            </p>
            <p>
                Behandler: <strong>{tmstate.selectedPhysician.name}</strong>
            </p>
            <AppointmentCalendar />
        </LeftDiv>
    );
};

export default AppointmentCalendarWithHeadline;
