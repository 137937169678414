import { useCallback, useContext, useRef, useState } from 'react';
import { ButtonToolbar, Form } from 'react-bootstrap';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Modal from 'react-bootstrap/esm/Modal';
import Nav from 'react-bootstrap/esm/Nav';
import Row from 'react-bootstrap/esm/Row';
import Image from 'react-bootstrap/Image';
import { AiOutlineMinusCircle } from 'react-icons/ai';
import { MdAddAPhoto, MdPhotoLibrary } from 'react-icons/md';
import Webcam from 'react-webcam';
import { ThemeContext } from 'styled-components';
import CheckInContext from '../../../context/checkInContext';
import useScreenResolution from '../../../hooks/useScreenResolution';
import { loadPerspectiveData } from '../../../services/RestServices';
import { fullSrc, IPhoto } from '../../../utils/photoUtils';
import StyledButton from '../../Buttons/StyledButton';
import { StyledToggleButton, ToggleButtonGroupFlex } from '../../Buttons/ToggleButtonGroupCst';
import StyledCol from '../../Cols/StyledCol';
import MyTooltip from '../../Tooltip/MyTooltip';
import { StyledModal } from '../StyledModal/StyledModal';
import { getWebcamDim } from './WebcamModal';

interface IWebcamPreviewModal {
    show: boolean;
    onCancel: () => void;
    photos: IPhoto[];
    onSave: () => void;
    onRemove: (index: number) => void;
    addPhoto: (photo: string) => void;
}

const myVars = {
    fmt: 'A4',
};

const WebcamPreviewModal = (props: IWebcamPreviewModal) => {
    const themeContext = useContext(ThemeContext);
    const webcamRef = useRef<null | Webcam>(null);
    const screenSize = useScreenResolution();
    const webcamDim = getWebcamDim(screenSize.width);
    const [bodyHeight, setBodyHeight] = useState(0);
    const { checkinstate } = useContext(CheckInContext);
    // const [formatButton, setFormatButton] = useState('A4');

    const ref = useRef<HTMLDivElement>(null);

    const onModalEnter = () => {
        if (ref) {
            const height = ref?.current?.clientHeight;
            if (height) {
                setBodyHeight(height - 30);
            }
        }
    };

    function photoPreview(photos: IPhoto[]) {
        if (photos.length > 0) {
            const previews = photos.map((item: IPhoto, index: number) => (
                <Row key={index} xs={2} style={{ paddingBottom: '5px', margin: 'auto' }}>
                    <Col sm={10}>
                        <div style={{ float: 'right' }}>
                            <Image
                                src={fullSrc(item.data)}
                                /*width={webcamDim.width / 2.5}
                                height={webcamDim.height / 2.5}*/
                                width={'100%'}
                            />
                        </div>
                    </Col>
                    <Col sm={2}>
                        <Nav.Link
                            onClick={() => {
                                props.onRemove(index);
                            }}
                        >
                            <MyTooltip
                                id="RemoveImageTooltip"
                                text="Foto entfernen"
                                iconDiv={true}
                                divWidth="30px"
                                marginLeft="0"
                                marginRight="auto"
                                icon={<AiOutlineMinusCircle size={25} color={themeContext.icon.neg.color} />}
                            ></MyTooltip>
                        </Nav.Link>
                    </Col>
                </Row>
            ));
            return previews;
        }
    }

    const getImage = async function (video: any): Promise<string> {
        // Temporary create canvas element

        const fmtPerspective = await loadPerspectiveData();
        const resKOORDS = fmtPerspective[myVars.fmt];

        const canvas = document.createElement('canvas');
        canvas.width = 2480;
        canvas.height = 3507;
        const ctx = canvas.getContext('2d');
        // Write video stream to canvas instance
        ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);
        //const dataURI = canvas.toDataURL('image/jpeg'); // can also use 'image/png'
        // Access canvas data
        const normalSrc = canvas.toDataURL('image/jpeg', 1);
        if (myVars.fmt == 'A5') {
            console.log('want A5 transformation.');
        } else if (myVars.fmt == 'A6') {
            console.log('want A6 transformation.');
        } else {
            console.log('want A4 transformation.');
        }
        let testImg = document.getElementById('__HIDDENIMG');
        if (testImg) {
            document.body.removeChild(testImg);
        }
        const src_image = document.createElement('img');
        src_image.src = normalSrc;
        src_image.id = '__HIDDENIMG';
        src_image.width = 2480;
        src_image.height = 3507;
        document.body.appendChild(src_image);
        return new Promise(function (done, errfnc) {
            src_image.addEventListener('load', function () {
                const w_ratio = src_image.naturalWidth / canvas.width;
                const h_ratio = src_image.naturalHeight / canvas.height;
                // try to create a WebGL canvas (will fail if WebGL isn't supported)
                // eslint-disable-next-line no-var
                var fx_canvas;
                try {
                    fx_canvas = fx.canvas();
                    console.log('Fx canvas loaded');
                } catch (e) {
                    alert(e);
                }
                const texture = fx_canvas.texture(src_image);
                /*
                const destTransform = [
                    0,
                    0,
                    src_image.width,
                    0,
                    0,
                    src_image.height,
                    src_image.width,
                    src_image.height,
                ];
                */
                const destTransform = [0, 0, 2480, 0, 0, 3507, 2480, 3507];
                const sourceTransform = [
                    resKOORDS.c1.x * w_ratio,
                    resKOORDS.c1.y * h_ratio,
                    resKOORDS.c2.x * w_ratio,
                    resKOORDS.c2.y * h_ratio,
                    resKOORDS.c3.x * w_ratio,
                    resKOORDS.c3.y * h_ratio,
                    resKOORDS.c4.x * w_ratio,
                    resKOORDS.c4.y * h_ratio,
                ];

                fx_canvas.draw(texture);
                fx_canvas.perspective(sourceTransform, destTransform);
                fx_canvas.update();

                /*
                canvas
                    .getContext('2d')
                    ?.drawImage(fx_canvas, 0, 0, src_image.width, src_image.height, 0, 0, canvas.width, canvas.height);
                */
                const destCanvas = document.createElement('canvas');
                destCanvas.width = 2480;
                destCanvas.height = 3507;
                destCanvas.getContext('2d')?.drawImage(fx_canvas, 0, 0);

                const resSrc = destCanvas.toDataURL('image/jpeg', 0.5);
                testImg = document.getElementById('__HIDDENIMG');
                if (testImg) {
                    document.body.removeChild(testImg);
                }
                done(resSrc);
            });
        });
        /*
        canvas.remove();

        const fx_canvas = document.createElement('canvas');
        const texture = fx_canvas.texture(src_image);

        // set mapping from src to dst coordinates in same canvas
        
        // src =   [0,0, 568,386,              2190,1920,                          2306,92];            // the 4 corners of the warped paper in the picture
        //           |     |                        |                                 |                     remember mapping of points
        dest =  [0,0,  src_image.width,0, 0,src_image.height, src_image.width,src_image.height]; // the 90deg rotated DinA4 mold
        //   [top left, top right, bottom left, bottom right]
        // apply the perspective transform filter initially
        fx_canvas.draw(texture);
        fx_canvas.perspective(src, dest);
        fx_canvas.update();

        // Add canvas to dom structure in place of dummy canvas
        if(shadow.getElementById("persp_canvas")){
            console.log("temp canvas present");
            fx_canvas.setAttribute("id", "transform_canvas");
            shadow.getElementById("persp_canvas").replaceWith(fx_canvas);
        } 
        */
        // return resSrc;
    };
    const isTransformation = function (): boolean {
        if (checkinstate.wpName?.length > 0) {
            return true;
        }
        return false;
    };
    const capture = useCallback(() => {
        if (isTransformation()) {
            getImage(webcamRef.current?.video).then((img: string) => {
                props.addPhoto(img);
            });
        } else {
            if (webcamRef.current) {
                const image = webcamRef.current?.getScreenshot();
                if (image) {
                    props.addPhoto(image);
                }
            }
        }
    }, [webcamRef]);

    interface IFormatChoice {
        handleFormatButtonChange(format: string): void;
    }
    const FormatChoice = (props: IFormatChoice) => {
        const [fmt, setFmt] = useState(myVars.fmt);
        const handleChange = (sfmt: string) => {
            setFmt(sfmt);
            props.handleFormatButtonChange(sfmt);
        };
        return (
            <div style={{ float: 'left' }}>
                <Form.Group>
                    <ButtonToolbar aria-label="Toolbar with button groups">
                        <ToggleButtonGroupFlex type="radio" value={fmt} name="Format">
                            <StyledToggleButton
                                key="A4"
                                width="100"
                                id="FormatA4"
                                value="A4"
                                marginTop="6px"
                                requiredmissing=""
                                onChange={() => {
                                    handleChange('A4');
                                }}
                            >
                                A4
                            </StyledToggleButton>
                            <StyledToggleButton
                                key="A5"
                                width="100"
                                id="FormatA5"
                                value="A5"
                                marginTop="6px"
                                requiredmissing=""
                                onChange={() => {
                                    handleChange('A5');
                                }}
                            >
                                A5
                            </StyledToggleButton>
                            <StyledToggleButton
                                key="A6"
                                width="100"
                                id="FormatA6"
                                value="A6"
                                marginTop="6px"
                                requiredmissing=""
                                onChange={() => {
                                    handleChange('A6');
                                }}
                            >
                                A6
                            </StyledToggleButton>
                        </ToggleButtonGroupFlex>
                    </ButtonToolbar>
                </Form.Group>
            </div>
        );
    };

    return (
        <>
            <StyledModal id="webcamModal" size="xl" show={props.show} onEnter={onModalEnter}>
                <Modal.Header>
                    <Modal.Title>
                        <MdPhotoLibrary size={30} /> Neue Fotodokumentation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body ref={ref}>
                    <Row xs={1} sm={1} md={1} lg={2} xl={2}>
                        <Col
                            xs={{ order: 'last' }}
                            sm={{ order: 'last' }}
                            md={{ order: 'last' }}
                            lg={{ order: 'first' }}
                            xl={{ order: 'first' }}
                        >
                            <div
                                style={{
                                    marginRight: '20px',
                                    float: 'right',
                                    maxHeight: bodyHeight.toString() + 'px',
                                    overflowY: 'scroll',
                                }}
                            >
                                {photoPreview(props.photos)}
                            </div>
                        </Col>
                        <Col
                            xs={{ order: 'first' }}
                            sm={{ order: 'first' }}
                            md={{ order: 'first' }}
                            lg={{ order: 'last' }}
                            xl={{ order: 'last' }}
                        >
                            <div style={{ marginRight: '20px', float: 'right', paddingBottom: '20px' }}>
                                <div style={{ textAlign: 'center' }}>
                                    <Webcam
                                        audio={false}
                                        height={webcamDim.height}
                                        width={webcamDim.width}
                                        ref={webcamRef}
                                        videoConstraints={{
                                            width: 4850,
                                            height: 2160,
                                        }}
                                        screenshotFormat="image/jpeg"
                                    />
                                </div>
                                <div style={{ float: 'right' }}>
                                    {isTransformation() && (
                                        <FormatChoice
                                            handleFormatButtonChange={(fmt: string) => {
                                                console.log('fmtChg: ' + fmt);
                                                myVars.fmt = fmt;
                                            }}
                                        />
                                    )}
                                    <StyledButton type="submit" onClick={capture}>
                                        <MdAddAPhoto size={25} style={{ paddingBottom: '5px' }} /> Foto aufnehmen
                                    </StyledButton>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Container className="mt-4">
                        <Row>
                            <StyledCol paddingLeft="0px" textAlign="left">
                                <StyledButton variant="secondary" onClick={props.onCancel}>
                                    Abbrechen
                                </StyledButton>
                            </StyledCol>
                            <StyledCol paddingRight="0px" textAlign="right">
                                <StyledButton type="submit" onClick={props.onSave}>
                                    Speichern
                                </StyledButton>
                            </StyledCol>
                        </Row>
                    </Container>
                </Modal.Footer>
            </StyledModal>
        </>
    );
};

export default WebcamPreviewModal;
