import { Component } from 'react';

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: '', errorInfo: '' };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        // console.log('ErrorBoundary: error = ' + error + ', errorInfo = ' + errorInfo);
        let stack = '';
        if (errorInfo.componentStack) {
            stack = errorInfo.componentStack;
        }

        this.setState({
            error: error,
            errorInfo: stack,
        });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div>
                    <h1>Something went wrong.</h1>
                    <p>{this.state.error ? this.state.error : ''}</p>
                    <p>{this.state.errorInfo ? this.state.errorInfo : ''}</p>
                </div>
            );
        }

        return this.props.children;
    }
}
