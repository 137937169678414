import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FiRefreshCw } from 'react-icons/fi';
import { MainHeaderButtonIcon } from '../Div/StyledDiv';

interface IRefreshButton {
    handleClick: any;
    buttonColor?: string;
    iconSize?: number;
    strokeWidth?: number;
}

const RefreshButton = (props: IRefreshButton) => {
    const handleActualizeClick = () => {
        props.handleClick();
    };

    return (
        <>
            <OverlayTrigger
                key="bottom"
                placement="bottom"
                overlay={<Tooltip id="refreshButtonTooltip">Aktualisieren</Tooltip>}
            >
                <MainHeaderButtonIcon data-name="refreshAppointments">
                    <FiRefreshCw
                        size={props.iconSize ? props.iconSize : 30}
                        strokeWidth={props.strokeWidth ? props.strokeWidth : 2}
                        color={props.buttonColor ? props.buttonColor : 'black'}
                        type="file"
                        onClick={() => handleActualizeClick()}
                    />
                </MainHeaderButtonIcon>
            </OverlayTrigger>
        </>
    );
};

export default RefreshButton;
