import * as React from 'react';
import { useContext, useRef, useState } from 'react';
import ButtonToolbar from 'react-bootstrap/esm/ButtonToolbar';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import ToggleButton from 'react-bootstrap/esm/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/esm/ToggleButtonGroup';
import AlertContext from '../../../context/alertContext';
import LoginContext from '../../../context/loginContext';
import useScreenResolution from '../../../hooks/useScreenResolution';
import { getDiagnosticReportsByCategory } from '../../../services/fhir/FHIRDiagnosticReports';
import {
    chartViews,
    getTableDataFromDiagnosticReportResponse,
    handleDisplayControllers,
    IChartProps,
} from '../../../utils/chartUtils';
import { calcButtonPaddingSize } from '../../../utils/lottieUtils';
import { isDesktop } from '../../../utils/screenResolution';
import StyledButton from '../../Buttons/StyledButton';
import { ChartsButtonCol, ChartsHeaderCol } from '../../Cols/StyledCol';
import { CenterDiv } from '../../Div/StyledDiv';
import { LottieButtonLoadingSpinner } from '../../Loading/Loading';
import LoadingSpinnerWithText from '../../Loading/LoadingSpinnerWithText';
import TableModal, { ITableModalShow } from '../../Modals/TableModal/TableModal';
import { initalTableData } from '../../TableProvider/TableProvider';
import EmptyView from './EmptyView';
import FiveYearsView from './FiveYearsView';
import MaxView from './MaxView';
import OneMonthView from './OneMonthView';
import OneWeekView from './OneWeekView';
import OneYearView from './OneYearView';

interface IViewSwitcher {
    chartLoaded: boolean;
    foundData: boolean;
    chartProps: IChartProps;
}

const ViewSwitcher = (props: IViewSwitcher) => {
    const { state } = useContext(LoginContext);
    const { alertdispatch } = useContext(AlertContext);
    const [rangeValue, setRangeValue] = useState(chartViews.ALL_VIEW);
    const [labelButtonVal, setLabelButtonVal] = useState('Werte anzeigen');
    const [labelOpt, setLabelOpt] = useState(false);
    const [showTableButtonLoadingSpinner, setShowTableButtonLoadingSpinner] = useState(false);
    const tableButtonRef = useRef<any>(null);

    const screenSize = useScreenResolution();
    const desktop = isDesktop(screenSize.width);
    const isSmallScreen = screenSize.width < 500 ? true : false;

    const [tableModalShow, setTableModalShow] = useState<ITableModalShow>({
        show: false,
        modalTitle: '',
        tableData: { ...initalTableData.tableData },
    });

    const handleValueChange = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e) {
            e.currentTarget.blur();
        }
        if ('Werte anzeigen' === labelButtonVal) {
            setLabelButtonVal('Werte ausblenden');
        } else {
            setLabelButtonVal('Werte anzeigen');
        }

        setLabelOpt(!labelOpt);
    };

    const oneWeekView = () => {
        if (rangeValue !== chartViews.ONE_WEEK_VIEW) {
            setRangeValue(chartViews.ONE_WEEK_VIEW);
        }

        if (props.foundData) {
            return (
                <Row>
                    <Col>
                        <OneWeekView {...props.chartProps} labelOption={labelOpt} />
                    </Col>
                </Row>
            );
        } else {
            return <EmptyView {...props.chartProps.mobileProps} />;
        }
    };

    const oneMonthView = () => {
        if (rangeValue !== chartViews.ONE_MONTH_VIEW) {
            setRangeValue(chartViews.ONE_MONTH_VIEW);
        }

        if (props.foundData) {
            return (
                <Row>
                    <Col>
                        <OneMonthView {...props.chartProps} labelOption={labelOpt} />
                    </Col>
                </Row>
            );
        } else {
            return <EmptyView {...props.chartProps.mobileProps} />;
        }
    };

    const oneYearView = () => {
        if (rangeValue !== chartViews.ONE_YEAR_VIEW) {
            setRangeValue(chartViews.ONE_YEAR_VIEW);
        }

        if (props.foundData) {
            return (
                <Row>
                    <Col>
                        <OneYearView {...props.chartProps} labelOption={labelOpt} />
                    </Col>
                </Row>
            );
        } else {
            return <EmptyView {...props.chartProps.mobileProps} />;
        }
    };

    const fiveYearView = () => {
        if (rangeValue !== chartViews.FIVE_YEAR_VIEW) {
            setRangeValue(chartViews.FIVE_YEAR_VIEW);
        }

        if (props.foundData) {
            return (
                <Row>
                    <Col>
                        <FiveYearsView {...props.chartProps} labelOption={labelOpt} />
                    </Col>
                </Row>
            );
        } else {
            return <EmptyView {...props.chartProps.mobileProps} />;
        }
    };

    const allView = () => {
        if (rangeValue !== chartViews.ALL_VIEW) {
            setRangeValue(chartViews.ALL_VIEW);
        }

        if (props.foundData) {
            return (
                <Row>
                    <Col>
                        <MaxView {...props.chartProps} labelOption={labelOpt} />
                    </Col>
                </Row>
            );
        } else {
            return <EmptyView {...props.chartProps.mobileProps} />;
        }
    };

    const getChartsView = () => {
        if (rangeValue === chartViews.ONE_WEEK_VIEW) {
            return oneWeekView();
        } else if (rangeValue === chartViews.ONE_MONTH_VIEW) {
            return oneMonthView();
        } else if (rangeValue === chartViews.ONE_YEAR_VIEW) {
            return oneYearView();
        } else if (rangeValue === chartViews.FIVE_YEAR_VIEW) {
            return fiveYearView();
        } else if (rangeValue === chartViews.ALL_VIEW) {
            return allView();
        }
    };

    const handleTableDataClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e) {
            e.currentTarget.blur();
        }
        setShowTableButtonLoadingSpinner(true);

        if (props?.chartProps?.chartOptions?.extraTables !== undefined) {
            let titleFromExtraTables = '';
            titleFromExtraTables = props?.chartProps?.chartOptions?.extraTables[0]?.name;
            const id = props?.chartProps?.chartOptions?.extraTables[0]?.id;

            if (id && id.length > 0) {
                const response = await getDiagnosticReportsByCategory(state, id, alertdispatch);
                if (response) {
                    const [tableTitle, tableData] = getTableDataFromDiagnosticReportResponse(response);
                    setShowTableButtonLoadingSpinner(false);
                    setTableModalShow({
                        show: true,
                        modalTitle: titleFromExtraTables,
                        tableData: tableData,
                    });
                }
            }
        }
    };

    const isTableIncluded = () => {
        if (props?.chartProps?.chartOptions?.extraTables !== undefined) {
            if (props?.chartProps?.chartOptions?.extraTables[0]?.id?.length > 0) {
                return true;
            }
        }

        return false;
    };

    const getTableId = () => {
        let id: string | undefined = '';
        id = props?.chartProps?.chartOptions?.extraTables?.[0]?.id;
        return id;
    };

    const ShowTableButton = () => {
        if (showTableButtonLoadingSpinner) {
            let calcButtonWidth = '35px';
            const lottieSize = 24;
            calcButtonWidth = calcButtonPaddingSize(tableButtonRef, lottieSize);

            return (
                <StyledButton
                    ref={tableButtonRef}
                    id="btnTableShow"
                    data-name={getTableId()}
                    paddingLeft={calcButtonWidth}
                    paddingRight={calcButtonWidth}
                    variant="warning"
                    onClick={(e) => handleTableDataClick(e)}
                >
                    <LottieButtonLoadingSpinner size={lottieSize} />
                </StyledButton>
            );
        } else {
            return (
                <StyledButton
                    ref={tableButtonRef}
                    id="btnTableShow"
                    data-name={getTableId()}
                    variant="primary"
                    width="200px"
                    onClick={(e) => handleTableDataClick(e)}
                >
                    Tabelle anzeigen
                </StyledButton>
            );
        }
    };

    return (
        <CenterDiv>
            {!props.chartLoaded ? (
                <LoadingSpinnerWithText loadingText="Charts werden geladen..." />
            ) : (
                <Container style={{ paddingLeft: '12px', paddingRight: '0px' }}>
                    <Row
                        xs={1}
                        sm={1}
                        md={1}
                        lg={1}
                        xl={7}
                        style={{
                            paddingTop: '15px',
                            paddingBottom: '15px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            display: handleDisplayControllers(props.chartProps.mobileProps),
                            opacity: props.chartLoaded ? 1 : 0.4,
                        }}
                    >
                        <ButtonToolbar
                            aria-label="Toolbar with button groups"
                            style={{ alignItems: 'flex-end', textAlign: 'left' }}
                        >
                            <ChartsHeaderCol style={{ justifyContent: 'flex-start' }}>
                                <ToggleButtonGroup
                                    className="mr-2"
                                    aria-label="First group"
                                    type="radio"
                                    name="range"
                                    size={isSmallScreen ? 'sm' : undefined}
                                    value={rangeValue}
                                >
                                    <ToggleButton
                                        id="mob_week"
                                        variant="light"
                                        value={chartViews.ONE_WEEK_VIEW}
                                        onChange={oneWeekView}
                                    >
                                        1 Woche
                                    </ToggleButton>
                                    <ToggleButton
                                        id="mob_month"
                                        variant="light"
                                        value={chartViews.ONE_MONTH_VIEW}
                                        onChange={oneMonthView}
                                    >
                                        1 Monat
                                    </ToggleButton>
                                    <ToggleButton
                                        id="mob_year"
                                        variant="light"
                                        value={chartViews.ONE_YEAR_VIEW}
                                        onChange={oneYearView}
                                    >
                                        {' '}
                                        1 Jahr{' '}
                                    </ToggleButton>
                                    <ToggleButton
                                        id="mob_year5"
                                        variant="light"
                                        value={chartViews.FIVE_YEAR_VIEW}
                                        onChange={fiveYearView}
                                    >
                                        {' '}
                                        5 Jahre{' '}
                                    </ToggleButton>
                                    <ToggleButton
                                        id="mob_all"
                                        variant="light"
                                        value={chartViews.ALL_VIEW}
                                        onChange={allView}
                                    >
                                        {' '}
                                        Max{' '}
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </ChartsHeaderCol>
                            <ChartsButtonCol justifyContent={desktop ? 'flex-end' : 'flex-start'}>
                                {isTableIncluded() && <ShowTableButton />}
                                <StyledButton
                                    id="btnValuesShow"
                                    variant="primary"
                                    width="200px"
                                    marginLeft="20px"
                                    onClick={(e) => handleValueChange(e)}
                                >
                                    {labelButtonVal}
                                </StyledButton>
                            </ChartsButtonCol>
                            {/* <Col>{isTableIncluded() && <ShowTableButton />}</Col>
                        <Col>
                            <StyledButton
                                id="btnValuesShow"
                                variant="warning"
                                width="160px"
                                onClick={(e) => handleValueChange(e)}
                            >
                                {labelButtonVal}
                            </StyledButton>
                        </Col> */}
                        </ButtonToolbar>
                    </Row>
                </Container>
            )}
            <Container style={{ opacity: props.chartLoaded ? 1 : 0.4 }}>
                {getChartsView()}
                <TableModal
                    show={tableModalShow.show}
                    onHide={() =>
                        setTableModalShow({
                            show: false,
                            modalTitle: '',
                            tableData: { ...initalTableData.tableData },
                        })
                    }
                    modalTitle={tableModalShow.modalTitle}
                    tableData={tableModalShow.tableData}
                />
            </Container>
        </CenterDiv>
    );
};

export default ViewSwitcher;
