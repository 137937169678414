import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { CategoryBadgeSelected } from '../../components/Badges/StyledBadge';
import { LeftDivMain } from '../../components/Div/StyledDiv';
import PhysicianSelectionCard from '../../components/PhysicianSelectionCard/PhysicianSelectionCard';
import { ParagraphFontSize } from '../../components/StyledParagraph/StyledParagraph';
import AppPropsContext from '../../context/appPropsContext';
import { IDepartmentReasons } from '../../context/loginContext';
import TerminierungContext, { ISelectedPhysician } from '../../context/terminierungContext';
import { useAppProperties } from '../../hooks/useAppProperties';
import { useAppointmentSelection } from '../../hooks/useAppointmentSelection';
import { useGoogleAnalytics } from '../../hooks/useGoogleAnalytics';
import { IPhysiciansFor, getPhysiciansFor } from '../../services/RestServices';

interface IAppointmentCategoryPhysician {
    department: string;
    reason: IDepartmentReasons;
    handlePhysicianClick(currentActiveStep: number): void;
    currentActiveStep: number;
}

const AppointmentCategoryPhysician = (props: IAppointmentCategoryPhysician) => {
    const themeContext = useContext(ThemeContext);
    const { tmstate, tmdispatch } = useContext(TerminierungContext);
    const { apstate } = useContext(AppPropsContext);
    const [allPhysicians, setAllPhysicians] = useState<ISelectedPhysician[]>();
    const { getAllMakroIdsFromFilteredReasons } = useAppointmentSelection();

    const { getCountDaysToVisualize } = useAppProperties();
    const { sendGoogleAnalyticsPageView } = useGoogleAnalytics();

    useEffect(() => {
        sendGoogleAnalyticsPageView({ page: '/AppointmentCategoryPhysician', title: 'Behandler wählen' });

        const getPhysicians = async () => {
            const makroId = getAllMakroIdsFromFilteredReasons();

            const physiciansForData: IPhysiciansFor = {
                makroId: makroId,
                selectedReason: tmstate.selectedReason.reasonId,
                selectedInsurance: tmstate.selectedInsurance,
                selectedDepartment: tmstate.selectedDepartment.name,
                selectedAptType: tmstate.selectedAppointmentType,
                countDaysToVisualize: getCountDaysToVisualize(),
                extendedPhysicians: apstate.extendedPhysicians,
            };

            const physiciansData = await getPhysiciansFor(physiciansForData);
            if (physiciansData?.physicians) {
                setAllPhysicians(physiciansData.physicians);
            } else {
                /* only earliest appointment possible, no physicians, so auto jump to next step */
                handleSetPhysicianClick(0, 0, '', '', []);
            }
        };

        tmdispatch({
            type: 'RESETTIMESLOT',
        });

        getPhysicians();
    }, []);

    const handleSetPhysicianClick = (
        id: number,
        makroId: number | undefined,
        name: string,
        pict?: string,
        description?: string[],
    ) => {
        tmdispatch({
            type: 'SELECTPHYSICIAN',
            selectedPhysician: {
                lines: description ? description : [],
                id: id ? id : 0,
                name: name ? name : '',
                pict: pict ? pict : '',
                makroId: makroId ? makroId : 0,
            },
        });

        /* search for the correct physician with makroId from filteredReasons and set it as selectedReason */
        if (makroId) {
            const filteredSelectedReason = tmstate.filteredReasons.filter((e) => e.makroId === makroId);
            tmdispatch({
                type: 'SELECTREASON',
                selectedReason: filteredSelectedReason[0],
            });
        }

        props.handlePhysicianClick(props.currentActiveStep);
    };

    const allPhysicianCards = () => {
        const allCards: any[] = [];

        allCards.push(
            <div key="defaultPhyscianCard" onClick={() => handleSetPhysicianClick(0, 0, '', '', [])}>
                <PhysicianSelectionCard name="Frühester Termin" showPicture={false} pict="" description={[]} />
            </div>,
        );

        allPhysicians?.forEach((e: ISelectedPhysician) => {
            let descrList: string[] = [];
            if (e.lines && typeof e.lines === 'string') {
                descrList.push(e.lines);
            } else if (e.lines) {
                descrList = e.lines;
            }
            allCards.push(
                <div key={e.id} onClick={() => handleSetPhysicianClick(e.id, e.makroId, e.name, e.pict, e.lines)}>
                    <PhysicianSelectionCard
                        name={e.name}
                        showPicture={true}
                        pict={e.pict ? e.pict : ''}
                        description={descrList}
                    />
                </div>,
            );
        });

        return allCards;
    };

    return (
        <LeftDivMain>
            <h3>Behandler wählen</h3>
            <br />
            <div>
                <h4>
                    Fachrichtung
                    <CategoryBadgeSelected
                        backgroundColor={themeContext.mainColor}
                        color="white"
                        marginLeft="20px"
                        fontSize={ParagraphFontSize.Medium}
                    >
                        {props.department}
                    </CategoryBadgeSelected>
                </h4>
                <h4>
                    Besuchsgrund
                    <CategoryBadgeSelected
                        backgroundColor={themeContext.mainColor}
                        color="white"
                        marginLeft="20px"
                        fontSize={ParagraphFontSize.Medium}
                    >
                        {props.reason.reasonName}
                    </CategoryBadgeSelected>
                </h4>
            </div>
            <br />
            {allPhysicianCards()}
        </LeftDivMain>
    );
};

export default AppointmentCategoryPhysician;
