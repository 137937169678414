import { useContext, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import Card from 'react-bootstrap/esm/Card';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { Paths } from '../../Routes';
import AppPropsContext from '../../context/appPropsContext';
import { GoogleAnalyticsAction, GoogleAnalyticsCategory, useGoogleAnalytics } from '../../hooks/useGoogleAnalytics';
import StyledButton from '../Buttons/StyledButton';
import { StyledMainCardTitle, StyledProfileCard } from '../Cards/StyledCard';
import { CenterDiv, RightDiv } from '../Div/StyledDiv';
const EnrollCodeBox = () => {
    const navigate = useNavigate();
    const themeContext = useContext(ThemeContext);
    const { apstate } = useContext(AppPropsContext);

    const { sendGoogleAnalyticsEvent } = useGoogleAnalytics();
    //const screenSize = useScreenResolution();

    const [anmeldKatSelected, setAnmeldKatSelected] = useState<string>();

    // const getMarginRightLeft = () => {
    //     if (screenSize.width > 1700) {
    //         return '50px';
    //     } else if (screenSize.width > 1300) {
    //         return '20px';
    //     } else {
    //         return '10px';
    //     }
    // };

    const handleSetAnmeldKat = (e: any) => {
        setAnmeldKatSelected(e.target.value.toString());
    };

    const getAnmeldKatOptions = () => {
        // if (props.departments.versicherungsArt && props.departments.versicherungsArt.length > 0) {
        //     return props.departments.versicherungsArt.map((versicherungsArt) => (
        //         <option key={versicherungsArt} value={versicherungsArt}>
        //             {versicherungsArt}
        //         </option>
        //     ));
        // } else {
        //     return (
        //         <>
        //             <option key="gesetzlich" value="Gesetzlich">
        //                 Gesetzlich
        //             </option>
        //             <option key="privat" value="Privat">
        //                 Privat
        //             </option>
        //         </>
        //     );
        // }

        if (apstate.anmeldeContexts && apstate.anmeldeContexts.length > 0) {
            return apstate.anmeldeContexts.map((anmeldeContext) => (
                <option key={anmeldeContext.code} value={anmeldeContext.code}>
                    {anmeldeContext.name}
                </option>
            ));
        } else {
            return null;
        }

        // return (
        //     <>
        //         <option key="kardio" value="ANMELDKARD">
        //             Kardiologie
        //         </option>
        //         <option key="ortho" value="ANMELDORTHO">
        //             Orthopädie
        //         </option>
        //     </>
        // );
    };

    const handleTerminAnfordernClick = () => {
        sendGoogleAnalyticsEvent({
            category: GoogleAnalyticsCategory.HOMESCREEN,
            action: GoogleAnalyticsAction.CLICK,
            label: 'Standort ' + anmeldKatSelected + ' ausgewählt',
        });
        navigate(Paths.NEUER_ACCOUNT_WL + '?reason=' + anmeldKatSelected + '&type=NewAccount');
    };

    return (
        <Row style={{ height: '100%' }}>
            <Col style={{ display: 'flex' }}>
                <StyledProfileCard>
                    <Card.Body>
                        <StyledMainCardTitle>Ihre Terminplanung ohne Account</StyledMainCardTitle>
                        <Container style={{ marginTop: '35px' }}>
                            <CenterDiv>
                                <FaRegCalendarAlt size={68} color={themeContext.icon.passive.color} />
                            </CenterDiv>
                            <CenterDiv style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <Form.Control
                                    as="select"
                                    name="reason"
                                    defaultValue=""
                                    value={anmeldKatSelected}
                                    onChange={(e) => handleSetAnmeldKat(e)}
                                >
                                    <option disabled key="anmeldKatDefault" hidden value="">
                                        Standort wählen
                                    </option>
                                    {getAnmeldKatOptions()}
                                </Form.Control>
                            </CenterDiv>
                            <RightDiv>
                                <StyledButton
                                    disabled={!anmeldKatSelected}
                                    variant="primary"
                                    onClick={() => handleTerminAnfordernClick()}
                                >
                                    Neuen Termin anfordern
                                </StyledButton>
                            </RightDiv>
                        </Container>
                    </Card.Body>
                </StyledProfileCard>
            </Col>
        </Row>
    );
    /* ALTE VARIANTE 
    return (
        <Row style={{ height: '100%' }}>
            <Col style={{ display: 'flex' }}>
                <StyledProfileCard>
                    <Card.Body>
                        <StyledMainCardTitle>Sie haben einen Termincode?</StyledMainCardTitle>
                        <Row style={{ marginTop: '75px', display: 'flex', textAlign: 'center' }}>
                            <Col style={{ marginTop: '0px', textAlign: 'left' }}>
                                <EnrollCodeInput numberOfInputFields={12} />
                            </Col>
                            <Col style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <FaRegCalendarAlt size={68} color={themeContext.icon.passive.color} />
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        <div style={{ float: 'right' }}>
                            <StyledButton variant="primary" onClick={() => navigate(Paths.TERMINIERUNG_WL)}>
                                Neuen Termin anfordern
                            </StyledButton>
                        </div>
                    </Card.Footer>
                </StyledProfileCard>
            </Col>
        </Row>
    );
    */
};

export default EnrollCodeBox;
