import Row from 'react-bootstrap/esm/Row';
import Modal from 'react-bootstrap/Modal';
import StyledCol from '../../Cols/StyledCol';
import { CenterDiv } from '../../Div/StyledDiv';
import { CallAcceptIcon, CallOffIcon, ITelephoneCallIcon } from '../../Telephone/Telephone';
import { StyledModal } from '../StyledModal/StyledModal';

interface ITelephoneModal {
    show: boolean;
    callProps: ITelephoneCallIcon;
    onCallAccept: () => void;
    onCallOff: () => void;
    onCallHide: () => void;
}

const TelephoneModal = (props: ITelephoneModal) => {
    return (
        <StyledModal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onCallHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Eingehender Anruf</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Sie werden von <strong>{props.callProps.vsstate.caller}</strong> gerufen
                </p>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'space-around' }}>
                <Row>
                    <StyledCol>
                        <CenterDiv onClick={() => props.onCallOff()} marginRight="40px">
                            <CallOffIcon {...props.callProps} />
                        </CenterDiv>
                    </StyledCol>
                    <StyledCol>
                        <CenterDiv onClick={() => props.onCallAccept()} marginLeft="40px">
                            <CallAcceptIcon tooltipText={'Anruf annehmen'} {...props.callProps} />
                        </CenterDiv>
                    </StyledCol>
                </Row>
            </Modal.Footer>
        </StyledModal>
    );
};

export default TelephoneModal;
