import { useContext } from 'react';
import Nav from 'react-bootstrap/Nav';
import { BiCalendarEdit } from 'react-icons/bi';
import { ThemeContext } from 'styled-components';
import TerminierungContext from '../../context/terminierungContext';
import { APPOINTMENT_POSTPONE_REASON, IAppointment } from '../../utils/appointmentUtils';
import MyTooltip from '../Tooltip/MyTooltip';

const PostponeAppointment = (props: IAppointment) => {
    const themeContext = useContext(ThemeContext);
    const { tmdispatch } = useContext(TerminierungContext);

    const postponeAppointment = async () => {
        tmdispatch({
            type: 'SETCANCELNEWAPPOINTMENTDATA',
            cancelNewAppointmentData: {
                showModal: false,
                data: props,
                reason: APPOINTMENT_POSTPONE_REASON,
            },
        });
    };

    return (
        <div>
            <Nav.Link data-name="postponeappointment" onClick={() => postponeAppointment()}>
                <MyTooltip
                    id="PostponeAppointmentTooltip"
                    text="Termin verschieben"
                    iconDiv={true}
                    divWidth="25px"
                    marginLeft="auto"
                    marginRight="0"
                    icon={<BiCalendarEdit size={25} color={themeContext.icon.default.color} />}
                ></MyTooltip>
            </Nav.Link>
        </div>
    );
};

export default PostponeAppointment;
