import { useContext, useEffect, useState } from 'react';
import LoginContext from '../context/loginContext';

const useNavbar = () => {
    const { state } = useContext(LoginContext);

    const [showProfileSwitch, setShowProfileSwitch] = useState(false);

    const [showPatSwitch, setShowPatSwitch] = useState(false);

    useEffect(() => {
        if (state.activeStudies !== undefined && state.activeStudies.length > 0) {
            setShowProfileSwitch(true);
        }
        if (state.activePatients !== undefined && state.activePatients.length > 0) {
            setShowPatSwitch(true);
        }
    }, [state.activeStudies, state.activePatients, state.activePatient]);

    return [showProfileSwitch, showPatSwitch];
};

export default useNavbar;
