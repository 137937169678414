import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SigPad from 'react-signature-canvas';
import { ISigData } from '../../context/sigpadContext';
import StyledButton from '../Buttons/StyledButton';

/* ### Usage for for Mouse Signature without device ###

    use either onSave OR onWriteEnd

   const onSignaturePadSave = (sigData) => {
        setSignatureData(sigData);
    };

    const onSignaturePadWriteEnd = (sigData) => {
        setSignatureData(sigData);
    };

    <SignaturePad onSave={onSignaturePadSave} onWriteEnd={onSignaturePadWriteEnd}/>
    
 */

interface ISignaturePad {
    onSave?: (sigData: ISigData) => void; // callback when click on buttonSave
    onWriteEnd?: (sigData: ISigData) => void; // callback when end of write
    onCancel?: () => void; // callback when click on buttonCancel
    onSign?: () => void; // callback when click on buttonSign
    onClickClear?: () => void; // clear signature pad done
    clickClear?: boolean; // clear signature pad
    clickSign?: boolean; // sign signature pad
    embedded?: boolean; // embedded mode
    width?: number; // override width
    height?: number; // override height
    offsetTop?: number; // Abstand von oben
    offsetLeft?: number; // Abstand von links
    margin?: string;
}

const SignaturePad = (props: ISignaturePad) => {
    const signatureRef = useRef<any>(null);
    const [divWidth, setDivWidth] = useState(700);
    const [divHeight, setDivHeight] = useState(300);
    const [offsetTop, setOffsetTop] = useState(0);
    const [offsetLeft, setOffsetLeft] = useState(0);
    const [signaturePadWidth, setSignaturePadWidth] = useState(690);
    const [signaturePadHeight, setSignaturePadHeight] = useState(240);

    useEffect(() => {
        /* clickClear clicked */
        if (props.clickClear) {
            onSignaturePadClear();
        }

        /* clickSign clicked */
        if (props.clickSign) {
            if (props.onSign) {
                props.onSign();
            }
        }

        /* explicity set width and height override all other sizes */
        if (props.width && props.width > 0) {
            setDivWidth(props.width);
            setSignaturePadWidth(props.width - 10);
        }
        if (props.height && props.height > 0) {
            setDivHeight(props.height);
            setSignaturePadHeight(props.height - 60);
        }
        if (props.offsetTop && props.offsetTop > 0) {
            setOffsetTop(props.offsetTop);
        }
        if (props.offsetLeft && props.offsetLeft > 0) {
            setOffsetLeft(props.offsetLeft);
        }
    }, [props.width, props.height, props.offsetTop, props.offsetLeft, props.clickClear, props.clickSign]);

    const onSignaturePadClear = (e?: React.MouseEvent<HTMLButtonElement>) => {
        if (e) {
            e.currentTarget.blur();
        }
        if (signatureRef) {
            signatureRef?.current.clear();
        }

        if (props.onClickClear) {
            props.onClickClear();
        }
    };

    const onSignaturePadSave = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e) {
            e.currentTarget.blur();
        }
        if (signatureRef && props.onSave) {
            // const sigDataPicture = signatureRef.current.getTrimmedCanvas().toDataURL('image/png');
            const sigDataPicture = signatureRef.current.getCanvas().toDataURL('image/png');

            // const sigDataPicture = signatureRef.current.toDataURL('image/svg+xml');
            const sigData: ISigData = {
                certId: '',
                pictureData: sigDataPicture,
                rsaSignature: '',
                signData: '',
                signingCert: '',
            };

            props.onSave(sigData);
        }
    };

    const onSignaturePadWriteEnd = () => {
        if (signatureRef && props.onWriteEnd) {
            // const sigDataPicture = signatureRef.current.getTrimmedCanvas().toDataURL('image/png');
            const sigDataPicture = signatureRef.current.getCanvas().toDataURL('image/png');

            // const sigDataPicture = signatureRef.current.toDataURL('image/svg+xml');
            const sigData: ISigData = {
                certId: '',
                pictureData: sigDataPicture,
                rsaSignature: '',
                signData: '',
                signingCert: '',
            };

            props.onWriteEnd(sigData);
        }
    };

    return props.embedded ? (
        <div
            style={{
                position: 'absolute',
                left: offsetLeft,
                top: offsetTop,
                width: divWidth,
                height: divHeight,
                border: '1px solid black',
            }}
        >
            <SigPad
                ref={signatureRef}
                penColor="black"
                onEnd={onSignaturePadWriteEnd}
                canvasProps={{ width: divWidth, height: divHeight, className: 'sigCanvas' }}
            />
        </div>
    ) : (
        <div
            style={{
                borderStyle: 'solid',
                width: divWidth,
                height: divHeight,
                padding: 0,
                margin: props.margin ? props.margin : '0',
            }}
        >
            <div>
                <SigPad
                    ref={signatureRef}
                    penColor="black"
                    onEnd={onSignaturePadWriteEnd}
                    canvasProps={{ width: signaturePadWidth, height: signaturePadHeight, className: 'sigCanvas' }}
                />
            </div>
            <Container>
                <Row>
                    <Col>
                        <StyledButton variant="secondary" onClick={(e) => onSignaturePadClear(e)}>
                            Löschen
                        </StyledButton>
                    </Col>
                    {props.onSave !== undefined && (
                        <Col>
                            <StyledButton onClick={(e) => onSignaturePadSave(e)}>Speichern</StyledButton>
                        </Col>
                    )}
                    {props.onCancel !== undefined && (
                        <Col>
                            <StyledButton variant="secondary" onClick={props.onCancel}>
                                Abbrechen
                            </StyledButton>
                        </Col>
                    )}
                    {props.onSign !== undefined && (
                        <Col>
                            <StyledButton onClick={props.onSign}>Jetzt signieren</StyledButton>
                        </Col>
                    )}
                </Row>
            </Container>
        </div>
    );
};

export default SignaturePad;
