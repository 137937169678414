import * as React from 'react';
import { IQuestionnaireWL } from '../../container/Fragebogen/QuestionnaireWL';
import { AlertOnHide, AlertType } from '../../context/alertContext';
import { InitialLoginType } from '../../context/loginContext';
import { InitialQuestionnaireType } from '../../context/questionnaireContext';
import { getBaseURL, getFhirURL } from '../../globals/global';
import { responseErrorHandling } from '../../utils/errorHandling';
import { getFHIRConfig } from './fhirConstants';

const fhir = require('fhir.js');

const QUESSTIONNAIRE_RESPONSE = 'QuestionnaireResponse';

export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';

export const fetchOptions = (method: string, sessionId: string) => ({
    method: method,
    headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: sessionId,
    },
});

export const getQuestionnaires = async (state: InitialLoginType, alertdispatch: React.Dispatch<any>) => {
    return await fhir(getFHIRConfig(state.sessionId))
        .search({
            type: 'Questionnaire',
            query: { patRoleId: state.activeUserId, requestTime: new Date().toISOString() },
        })
        .catch(function (e: any) {
            responseErrorHandling({
                response: e,
                alertdispatch: alertdispatch,
            });
        });
};

export const getQuestionnaireById = async (
    state: InitialLoginType,
    request: string,
    alertdispatch: React.Dispatch<any>,
) => {
    return await fhir(getFHIRConfig(state.sessionId))
        .search({
            type: 'Questionnaire',
            query: { name: request, patRoleId: state.activeUserId, requestTime: new Date().toISOString() },
        })
        .catch(function (e: any) {
            responseErrorHandling({
                response: e,
                alertdispatch: alertdispatch,
            });
        });
};

export const getQuestionnaireByFHIRId = async (
    state: InitialLoginType,
    fhirId: string,
    encounterId: string,
    alertdispatch: React.Dispatch<any>,
) => {
    return await fhir(getFHIRConfig(state.sessionId))
        .search({
            type: 'Questionnaire',
            query: {
                id: fhirId,
                patRoleId: state.activeUserId,
                encounterId: encounterId,
                requestTime: new Date().toISOString(),
            },
        })
        .catch(function (e: any) {
            responseErrorHandling({
                response: e,
                alertdispatch: alertdispatch,
            });
        });
};

export const getQuestionnaireContent = async (
    sessionId: string,
    request: string,
    alertdispatch: React.Dispatch<any>,
) => {
    return await fhir(getFHIRConfig(sessionId))
        .search({
            type: request,
        })
        .catch(function (e: any) {
            responseErrorHandling({
                response: e,
                alertdispatch: alertdispatch,
            });
        });
};

export const deleteQuestionnaireContent = async (
    state: InitialLoginType,
    qId: string,
    alertdispatch: React.Dispatch<any>,
) => {
    return await fhir(getFHIRConfig(state.sessionId))
        .delete({
            type: 'QuestionnaireResponse/' + qId,
        })
        .catch(function (e: any) {
            responseErrorHandling({
                response: e,
                alertdispatch: alertdispatch,
            });
        });
};

export const getQuestionnaireOTPAuth = async (state: IQuestionnaireWL) => {
    const session = 'PCP ' + state.otp;

    const query: IQuestionnaireWL = {
        name: state.name,
        userId: state.userId,
        t0: state.t0,
        requestTime: new Date().toISOString(),
    };

    if (state.reason) {
        query.reason = state.reason;
    }

    let baseUrl = state.baseURL;
    if (baseUrl !== undefined && !baseUrl?.endsWith('/fhir')) {
        baseUrl = baseUrl + '/fhir';
    }

    const response = await fhir(getFHIRConfig(session, baseUrl)).search({
        type: 'Questionnaire',
        query,
    });

    if (response?.data && response.data?.entry && response.data.entry.length > 0) {
        return response;
    } else {
        return null;
    }
};

export const sendQuestionnaire = async (
    state: InitialLoginType,
    qstate: InitialQuestionnaireType,
    alertdispatch: React.Dispatch<any>,
    status: string,
    targetPatId: number,
) => {
    let userId = '';
    let sessionId = '';
    if (qstate.isExternalRequest) {
        userId = qstate.externalUserId;
        sessionId = qstate.externalOTP;
    } else {
        userId = state.activeUserId;
        sessionId = state.sessionId;
    }

    if (targetPatId > 0) {
        userId = targetPatId?.toString();
    }

    const qr = {
        resourceType: QUESSTIONNAIRE_RESPONSE,
        status: status,
        questionnaire: { reference: 'Questionnaire/' + qstate.selectedQuestionnaire.resource.id },
        subject: { reference: 'Patient/' + userId },
        authored: new Date(),
        context: { reference: 'Encounter/' + userId + (qstate.encounterId ? '_' + qstate.encounterId : '_null') },
        // context: { reference: 'Encounter/' + userId },
        request: {
            method: 'PUT',
            url: getFhirURL() + '/' + QUESSTIONNAIRE_RESPONSE + '/qr_' + qstate.qData.id,
        },
        item: qstate.selectedAnswers.item,
    };

    let baseurl = getBaseURL();
    if (qstate.externalBaseURL) {
        baseurl = qstate.externalBaseURL;
    }
    if (qstate.isExternalRequest) {
        try {
            const url =
                baseurl +
                '/fhir/' +
                'QuestionnaireResponse?name=' +
                qstate.selectedQuestionnaire.resource.id +
                '&userId=' +
                userId +
                '&t0=' +
                qstate.externalT0;

            return await fetch(url, {
                ...fetchOptions(POST, sessionId),
                body: JSON.stringify(qr),
            });
            // return await res.json();
        } catch (error) {
            responseErrorHandling({
                response: error,
                alertdispatch: alertdispatch,
                alertMessage: {
                    alertTitle: 'Fragebogen',
                    alertTxt: 'Fehler beim senden des Fragebogens',
                    alertType: AlertType.error,
                    onHide: AlertOnHide.onlyClose,
                },
            });
        }
    } else {
        return await fhir(getFHIRConfig(sessionId))
            .create({
                resource: qr,
            })
            .catch(function (e: any) {
                responseErrorHandling({
                    response: e,
                    alertdispatch: alertdispatch,
                    alertMessage: {
                        alertTitle: 'Fragebogen',
                        alertTxt: 'Fehler beim senden des Fragebogens',
                        alertType: AlertType.error,
                        onHide: AlertOnHide.onlyClose,
                    },
                });
            });
    }
};

export const updateQuestionnaireContent = async (
    state: InitialLoginType,
    qstate: InitialQuestionnaireType,
    alertdispatch: React.Dispatch<any>,
) => {
    let userId = '';
    let sessionId = '';
    if (qstate.isExternalRequest) {
        userId = qstate.externalUserId;
        sessionId = qstate.externalOTP;
    } else {
        userId = state.activeUserId;
        sessionId = state.sessionId;
    }

    let baseurl = getBaseURL();
    if (qstate.externalBaseURL) {
        baseurl = qstate.externalBaseURL;
    }
    if (qstate.isExternalRequest) {
        try {
            const url =
                baseurl +
                '/fhir/' +
                'QuestionnaireResponse/' +
                qstate.questionnaireBody.data.id +
                '?name=' +
                qstate.selectedQuestionnaire.resource.name +
                '&userId=' +
                userId +
                '&t0=' +
                qstate.externalT0;

            return await fetch(url, {
                ...fetchOptions(PUT, sessionId),
                body: JSON.stringify(qstate.questionnaireBody.data),
            });
            // return await res.json();
        } catch (error) {
            responseErrorHandling({
                response: error,
                alertdispatch: alertdispatch,
                alertMessage: {
                    alertTitle: 'Fragebogen',
                    alertTxt: 'Fehler beim Speichern des Fragebogens',
                    alertType: AlertType.error,
                    onHide: AlertOnHide.onlyClose,
                },
            });
        }
    } else {
        return await fhir(getFHIRConfig(sessionId))
            .update({
                resource: qstate.questionnaireBody.data,
            })
            .catch(function (e: any) {
                responseErrorHandling({
                    response: e,
                    alertdispatch: alertdispatch,
                    alertMessage: {
                        alertTitle: 'Fragebogen',
                        alertTxt: 'Fehler beim Speichern des Fragebogens',
                        alertType: AlertType.error,
                        onHide: AlertOnHide.onlyClose,
                    },
                });
            });
    }
};
