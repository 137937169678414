import * as React from 'react';
import { SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import { useLocation } from 'react-router-dom';
import DokumenteContext from '../../context/dokumenteContext';
import MyThemeContext from '../../context/themeContext';
import { Paths } from '../../Routes';

const DragNDropArea = ({ children }: React.PropsWithChildren<unknown>) => {
    const currentLocation = useLocation();

    const { themestate } = useContext(MyThemeContext);

    // const [setDrag] = useState(false);
    const [dragCounter, setDragCounter] = useState(0);
    const dropRef = useRef<any>(null);
    const { docdispatch } = useContext(DokumenteContext);

    const fileToBase64 = (file: any): any => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (): SetStateAction<any> {
            const result = reader.result;

            docdispatch({
                type: 'SETFILEDATA',
                filedata: {
                    name: file.name,
                    lastModified: file.lastModified,
                    lastModifiedDate: file.lastModifiedDate,
                    size: file.size,
                    type: file.type,
                    data: result,
                    title: '',
                    conclusion: '',
                    uploaded: false,
                },
            });
        };
        reader.onerror = function (error): SetStateAction<any> {
            const result = 'ERROR: ' + error;
            docdispatch({
                type: 'SETFILEDATA',
                filedata: {
                    name: file.name,
                    lastModified: file.lastModified,
                    lastModifiedDate: file.lastModifiedDate,
                    size: file.size,
                    type: file.type,
                    data: result,
                    title: '',
                    conclusion: '',
                    uploaded: false,
                },
            });
        };
    };

    const handleDrag = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragIn = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setDragCounter(dragCounter + 1);
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            // setDrag(true);
        }
    };

    const handleDragOut = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setDragCounter(dragCounter - 1);
        if (dragCounter === 0) {
            // setDrag(false);
        }
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        // setDrag(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            if (currentLocation.pathname === Paths.DOKUMENTE) {
                fileToBase64(e.dataTransfer.files[0]);
            }
            /* 
            .
            . here handling for other pages/areas 
            .
            */
            e.dataTransfer.clearData();
            setDragCounter(0);
        }
    };

    useEffect(() => {
        const div = dropRef.current;
        div.addEventListener('dragenter', handleDragIn);
        div.addEventListener('dragleave', handleDragOut);
        div.addEventListener('dragover', handleDrag);
        div.addEventListener('drop', handleDrop);
        return () => {
            div.removeEventListener('dragenter', handleDragIn);
            div.removeEventListener('dragleave', handleDragOut);
            div.removeEventListener('dragover', handleDrag);
            div.removeEventListener('drop', handleDrop);
        };
    }, []);

    return (
        <Col
            id="dragNdropArea"
            md={themestate.navbarCollapsed ? 12 : 9}
            xl={10}
            xs={12}
            className="vh-100 d-flex flex-column"
            style={{ paddingTop: 80 }}
            ref={dropRef}
        >
            {children}
        </Col>
    );
};

export default DragNDropArea;
