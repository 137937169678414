export interface IAudio {
    audioFile: HTMLAudioElement;
    audioMuted: boolean;
}

const handleAudioPlay = (a: HTMLAudioElement) => {
    const audioPromise = a.play();
    if (audioPromise !== undefined) {
        audioPromise
            .then(() => {
                // autoplay started
            })
            .catch((err: any) => {
                // catch dom exception
                console.info(err);
            });
    }
};

// let delayTimeout: number;
export const playSound = (props: IAudio) => {
    props.audioFile.load();
    props.audioFile.currentTime = 0;
    props.audioFile.loop = true;
    props.audioFile.muted = props.audioMuted;
    handleAudioPlay(props.audioFile);
};

export const stopSound = (audio: HTMLAudioElement) => {
    audio.pause();
    audio.currentTime = 0;
};
