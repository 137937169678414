import { useContext } from 'react';
import ReactGA from 'react-ga4';
import AppPropsContext from '../context/appPropsContext';
import { isDEVMode } from '../utils/pcpUtils';

export interface IGoogleAnalyticsPageView {
    page: string;
    title: string;
}

export interface IGoogleAnalyticsEvent {
    category: GoogleAnalyticsCategory;
    action: GoogleAnalyticsAction;
    label?: string;
    value?: number;
    nonInteraction?: boolean;
    transport?: 'beacon' | 'xhr' | 'image';
}

export enum GoogleAnalyticsCategory {
    HOMESCREEN = 'HomeScreen',
    APPOINTMENT_BOOKING = 'AppointmentBooking',
}

export enum GoogleAnalyticsAction {
    CLICK = 'button_click',
    SUBMIT = 'button_submit',
}

export const useGoogleAnalytics = () => {
    const { apstate } = useContext(AppPropsContext);

    const isGoogleAnalyticsEnabled = () => {
        if (isDEVMode()) {
            return false;
        }
        if (apstate.googleAnalyticsID && apstate.googleAnalyticsID.length > 0) {
            return true;
        }
    };

    const sendGoogleAnalyticsPageView = (props: IGoogleAnalyticsPageView) => {
        if (isGoogleAnalyticsEnabled()) {
            ReactGA.send({ hitType: 'pageview', page: props.page, title: props.title });
        }
    };

    const sendGoogleAnalyticsEvent = (props: IGoogleAnalyticsEvent) => {
        if (isGoogleAnalyticsEnabled()) {
            // Send a custom event
            ReactGA.event({
                category: props.category,
                action: props.action,
                label: props.label ? props.label : undefined, // optional
                value: props.value ? props.value : undefined, // optional, must be a number
                nonInteraction: props.nonInteraction ? props.nonInteraction : false, // optional, true/false
                transport: props.transport ? props.transport : undefined, // optional, beacon/xhr/image
            });
        }
    };

    return {
        isGoogleAnalyticsEnabled,
        sendGoogleAnalyticsPageView,
        sendGoogleAnalyticsEvent,
    };
};
