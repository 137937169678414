import { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';

interface ITableTitleVal {
    name: string;
    title: string;
}

interface ITableDataVal {
    val?: string;
    table?: ITableData;
    type: string;
}

export interface ITableData {
    table: {
        title: ITableTitleVal[];
        data: ITableDataVal[][];
    };
}

interface ITableProvider {
    tableData: ITableData;
    maxHeight: string;
}

export const initalTableData = {
    tableTitle: '',
    tableData: {
        table: {
            title: [],
            data: [],
        },
    },
};

const TableProvider = (props: ITableProvider) => {
    const [tableToShow, setTableToShow] = useState<JSX.Element>(<></>);

    useEffect(() => {
        const tblDat = getTable();
        if (tblDat) {
            setTableToShow(tblDat);
        }
    }, []);

    const getTableHeader = (props: ITableTitleVal[]) => {
        const allHeaders: any[] = [];
        props.forEach((e) => {
            allHeaders.push(
                <th key={e.name} title={e.title} style={{ position: 'sticky', top: 0, background: 'white' }}>
                    {e.name}
                </th>,
            );
        });

        return allHeaders;
    };

    const getTableRows = (props: ITableDataVal[][]) => {
        const allTr: any[] = [];
        props.forEach((line, key) => {
            const allTd: any[] = [];
            line.forEach((td, key) => {
                if (!td.type || td.type === 'text' || td.type === 'document') {
                    if (td.val) {
                        allTd.push(<td key={'td_' + key}>{td.val}</td>);
                    }
                } else if (td.type === 'table') {
                    if (td.table) {
                        const subTableData: ITableData = td as unknown as ITableData;
                        const subTable = generateTable(subTableData);
                        allTd.push(<td key={'td_table_' + key}>{subTable}</td>);
                    }
                }
            });
            allTr.push(<tr key={'tr_' + key}>{allTd}</tr>);
        });
        return allTr;
    };

    const generateTable = (props: ITableData) => {
        const table = (
            <Table striped bordered hover>
                <thead>
                    <tr>{getTableHeader(props.table.title)}</tr>
                </thead>
                <tbody>{getTableRows(props.table.data)}</tbody>
            </Table>
        );

        return table;
    };

    const getTable = () => {
        if (props.tableData) {
            return generateTable(props.tableData);
        } else {
            return null;
        }
    };

    return (
        <div
            style={{
                overflow: 'auto',
                maxHeight: props.maxHeight,
                border: '1px solid #ffffff',
                backgroundColor: 'white',
            }}
        >
            {tableToShow}
        </div>
    );
};

export default TableProvider;
