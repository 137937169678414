import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertContext, { AlertOnHide, AlertType } from '../../context/alertContext';
import LoginContext from '../../context/loginContext';
import { getBaseURL } from '../../globals/global';
import { Paths } from '../../Routes';
import { consignation } from '../../services/RestServices';
import { showAlertMessage } from '../../utils/alertHandling';
import ConsignationAddModal from '../Modals/ConsignationModal/ConsignationAddModal';

interface IConsignationAdd {
    ondenial: () => void;
    oncancel: () => void;
}

const ConsignationAdd = (props: IConsignationAdd) => {
    const navigate = useNavigate();
    const { state } = useContext(LoginContext);
    const { alertdispatch } = useContext(AlertContext);
    const [consigModalShow, setConsigModalShow] = useState(true);
    const [checkConsignation, setCheckConsignation] = useState(false);

    const handleChange1 = () => {
        setCheckConsignation(!checkConsignation);
    };

    const getUrlTerms = () => {
        const urlTerms = getBaseURL() + '/' + state.addConsignationsToAsk[0];
        return urlTerms;
    };

    const handleSubmit = async () => {
        const consigRes = await consignation(state.userId, state.sessionId, 'given', true, getUrlTerms());

        if (consigRes.status === 'success') {
            setConsigModalShow(false);
            navigate(Paths.DOKUMENTE);
        } else {
            showAlertMessage({
                alertTitle: 'Datenschutzerklärung',
                alertTxt: 'Ein Fehler ist aufgetreten!',
                alertType: AlertType.error,
                onHide: AlertOnHide.onlyClose,
                alertdispatch: alertdispatch,
            });
        }
    };

    const handleDenial = async () => {
        const consigDenialRes = await consignation(state.userId, state.sessionId, 'not given', true, getUrlTerms());

        if (consigDenialRes.status === 'success') {
            setConsigModalShow(false);
            {
                props.ondenial();
            }
        } else {
            showAlertMessage({
                alertTitle: 'Datenschutzerklärung',
                alertTxt: 'Ein Fehler ist aufgetreten!',
                alertType: AlertType.error,
                onHide: AlertOnHide.onlyClose,
                alertdispatch: alertdispatch,
            });
        }
    };

    const handleCancel = () => {
        setConsigModalShow(false);
        {
            props.oncancel();
        }
    };

    return (
        <div>
            <ConsignationAddModal
                show={consigModalShow}
                onHide={() => handleCancel()}
                onSubmit={() => handleSubmit()}
                onDeny={() => handleDenial()}
                checkbox={() => handleChange1()}
                checkConsignation={checkConsignation}
                sessionId={state.sessionId}
                userId={state.userId}
            />
        </div>
    );
};

export default ConsignationAdd;
