import Calendar from 'react-calendar';
import styled from 'styled-components';

const StyledCalendar = styled(Calendar)`
    .react-calendar__tile--now {
        background: #f0f0f0;
        color: rgb(36, 94, 173);
        border: 1px solid;
        border-color: rgb(36, 94, 173);
    }
    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
        background: rgb(36, 94, 173);
        color: white;
        border-color: rgb(36, 94, 173);
        border: 1px solid;
    }
    .react-calendar__tile--active {
        background: #5a86d1;
        color: white;
    }
    abbr[title] {
        text-decoration: none;
        border-bottom: 0;
    }
    .react-calendar__tile:not(:disabled) {
        background: rgb(163, 193, 233);
        color: black;
        font-weight: normal;
    }
`;

export default StyledCalendar;
