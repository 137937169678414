import { useContext } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { ThemeContext } from 'styled-components';
import { VerticalCenterDiv } from '../Div/StyledDiv';
import { LoadingSpinner } from './Loading';

export interface ILoadingSpinner {
    loadingText: string;
}
const LoadingSpinnerWithText = (props: ILoadingSpinner) => {
    const themeContext = useContext(ThemeContext);

    return (
        <VerticalCenterDiv>
            <Row>
                <Col>
                    <LoadingSpinner />
                </Col>
            </Row>
            <Row>
                <Col>
                    <p style={{ color: themeContext.mainColor, paddingTop: 20 }}>{props.loadingText}</p>
                </Col>
            </Row>
        </VerticalCenterDiv>
    );
};

export default LoadingSpinnerWithText;
