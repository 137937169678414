import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

interface IStyledModal {
    borderRadius?: string;
    boxShadow?: string;
    height?: string;
    width?: string;
}

export const StyledModal = styled(Modal)<IStyledModal>`
    .modal-content {
        border-radius: ${(prop) => (prop.borderRadius ? prop.borderRadius : prop.theme.modal.borderRadius)};
        box-shadow: ${(prop) => (prop.boxShadow ? prop.boxShadow : prop.theme.modal.boxShadow)};
    }
`;

export const StyledModalMedicationDetail = styled(Modal)`
    .modal-dialog {
        max-width: 85%;
        max-height: 90%;
        margin: '1.75rem auto';
        height: 90%;
    }
    .modal-content {
        min-height: 90%;
        border-radius: ${(props) => props.theme.modal.borderRadius};
        box-shadow: ${(props) => props.theme.modal.boxShadow};
    }

    .modal-body {
        min-height: 90%;
    }
`;
