import { InitialSigPadType, ISigData, ISignaturePadDevice, ISignotecDeviceActions } from './sigpadContext';

type Action =
    | {
          type: 'SETDEVICEDATA';
          deviceData: ISignaturePadDevice;
      }
    | {
          type: 'SETSIGDATA';
          sigData: ISigData;
      }
    | {
          type: 'SETCANVAS';
          canvasRef: any;
      }
    | {
          type: 'SETDEVICEACTION_SEND';
          deviceActions: ISignotecDeviceActions;
      }
    | {
          type: 'SETDEVICEACTION_CANCEL';
          deviceActions: ISignotecDeviceActions;
      }
    | {
          type: 'RESETDEVICEDATA';
      }
    | {
          type: 'RESETSIGDATA';
      }
    | {
          type: 'RESETDEVICEACTIONS';
      }
    | {
          type: 'RESETALL';
      };

export const sigpadReducer = (state: InitialSigPadType, action: Action) => {
    switch (action.type) {
        case 'SETDEVICEDATA':
            return {
                ...state,
                deviceData: {
                    deviceName: action.deviceData.deviceName,
                    canStoreEncryptKey: action.deviceData.canStoreEncryptKey,
                    firmwareVersion: action.deviceData.firmwareVersion,
                    serialNumber: action.deviceData.serialNumber,
                    supportsRSA: action.deviceData.supportsRSA,
                    padConnectionType: action.deviceData.padConnectionType,
                    padIndex: action.deviceData.padIndex,
                    displayHeight: action.deviceData.displayHeight,
                    displayWidth: action.deviceData.displayWidth,
                    sampleRate: action.deviceData.sampleRate,
                    scaleFactorX: action.deviceData.scaleFactorX,
                    scaleFactorY: action.deviceData.scaleFactorY,
                    xResolution: action.deviceData.xResolution,
                    yResolution: action.deviceData.yResolution,
                },
            };
        case 'SETSIGDATA':
            return {
                ...state,
                sigData: {
                    certId: action.sigData.certId,
                    pictureData: action.sigData.pictureData,
                    rsaSignature: action.sigData.rsaSignature,
                    signData: action.sigData.signData,
                    signingCert: action.sigData.signingCert,
                },
            };
        case 'SETCANVAS':
            return {
                ...state,
                canvasRef: action.canvasRef,
            };
        case 'SETDEVICEACTION_SEND':
            return {
                ...state,
                deviceActions: {
                    ...state.deviceActions,
                    sendClicked: action.deviceActions.sendClicked,
                },
            };
        case 'SETDEVICEACTION_CANCEL':
            return {
                ...state,
                deviceActions: {
                    ...state.deviceActions,
                    cancelClicked: action.deviceActions.cancelClicked,
                },
            };
        case 'RESETDEVICEDATA':
            return {
                ...state,
                deviceData: {
                    deviceName: '',
                    canStoreEncryptKey: false,
                    firmwareVersion: '',
                    serialNumber: '',
                    supportsRSA: false,
                    padConnectionType: '',
                    padIndex: 0,
                    displayHeight: 0,
                    displayWidth: 0,
                    sampleRate: 0,
                    scaleFactorX: 0,
                    scaleFactorY: 0,
                    xResolution: 0,
                    yResolution: 0,
                },
            };
        case 'RESETSIGDATA':
            return {
                ...state,
                sigData: {
                    certId: '',
                    pictureData: '',
                    rsaSignature: '',
                    signData: '',
                    signingCert: '',
                },
            };
        case 'RESETDEVICEACTIONS':
            return {
                ...state,
                deviceActions: {
                    sendClicked: false,
                    cancelClicked: false,
                },
            };
        case 'RESETALL':
            return {
                deviceData: {
                    deviceName: '',
                    canStoreEncryptKey: false,
                    firmwareVersion: '',
                    serialNumber: '',
                    supportsRSA: false,
                    padConnectionType: '',
                    padIndex: 0,
                    displayHeight: 0,
                    displayWidth: 0,
                    sampleRate: 0,
                    scaleFactorX: 0,
                    scaleFactorY: 0,
                    xResolution: 0,
                    yResolution: 0,
                },
                sigData: {
                    certId: '',
                    pictureData: '',
                    rsaSignature: '',
                    signData: '',
                    signingCert: '',
                },
                deviceActions: {
                    sendClicked: false,
                    cancelClicked: false,
                },
                canvasRef: null,
            };

        default:
            return state;
    }
};
