import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { StyledWaitingCard, StyledWaitingCardBody } from '../../components/Cards/StyledCard';
import { CenterDiv } from '../../components/Div/StyledDiv';
import AppPropsContext from '../../context/appPropsContext';
import TerminierungContext from '../../context/terminierungContext';
import VideochatContext from '../../context/videochatContext';
import { jitsiOptions } from '../../globals/global';
import { useAppProperties } from '../../hooks/useAppProperties';
import { useResizedScreenResolution } from '../../hooks/useScreenResolution';

interface IVideochatWL {
    checkInVariant?: boolean;
    onVideoHangup?(): void;
}

declare class JitsiMeetExternalAPI {
    constructor(domain: string, options: any);
}

interface VCContext {
    jitsiDomain: string;
    jitsiAllowed: boolean;
}
let api: JitsiMeetExternalAPI | null | any;
const VideochatWL = (props: IVideochatWL) => {
    const { vsstate } = useContext(VideochatContext);
    const { tmstate } = useContext(TerminierungContext);
    const { apstate } = useContext(AppPropsContext);
    const themeContext = useContext(ThemeContext);
    const [vcContext, setVcContext] = useState<VCContext>({ jitsiDomain: '', jitsiAllowed: false });

    const { disableFullscreenMode, enableFullscreenMode, isFullscreenMode } = useAppProperties();

    const includeExternalScript = (url, whenFinished) => {
        let script: any;
        script = document.querySelector(`script[src="${url}"]`);

        const handleScript = (e) => {
            if (e.type === 'load') {
                whenFinished();
            }
        };

        if (!script) {
            script = document.createElement('script');
            script.type = 'application/javascript';
            script.src = url;
            script.async = true;
            document.body.appendChild(script);
            script.addEventListener('load', handleScript);
            script.addEventListener('error', handleScript);
        } else {
            whenFinished();
        }

        script.addEventListener('load', handleScript);
        script.addEventListener('error', handleScript);
    };

    useEffect(() => {
        let jitsNowAllowed = false;
        if (vcContext.jitsiAllowed || vsstate.jitsiAllowed) {
            jitsNowAllowed = true;
            if (!isFullscreenMode()) {
                enableFullscreenMode();
            }
        }
        if (vcContext.jitsiDomain != apstate.jitsiDomain || (!vcContext.jitsiAllowed && jitsNowAllowed)) {
            setVcContext({ jitsiDomain: apstate.jitsiDomain, jitsiAllowed: jitsNowAllowed });
        }
    }, [apstate, vsstate]);

    const [getResizedHeight] = useResizedScreenResolution();
    const jitsiContent = () => {
        const style = {
            height: getResizedHeight(isFullscreenMode() ? 100 : 75)?.toString() + 'px',
        };
        const url = 'https://' + apstate.jitsiDomain + '/external_api.js';
        const options = apstate.jitsiOptions;
        options.roomName = vsstate.roomName ? vsstate.roomName : tmstate.enrollData.enrollCode;
        const displayName = tmstate?.personalData?.firstName + ' ' + tmstate?.personalData?.lastName;
        options.userInfo = {
            displayName: displayName,
        };
        if (!options.configOverwrite) {
            options.configOverwrite = jitsiOptions.configOverwrite;
        }
        includeExternalScript(url, function () {
            const jp = document.getElementById('jitsiParent');
            if (!api && jp && eval('typeof JitsiMeetExternalAPI') === 'function') {
                options.parentNode = document.getElementById('jitsiParent');
                if (!options.parentNode.querySelector('IFRAME')) {
                    api = new JitsiMeetExternalAPI(apstate.jitsiDomain, options);

                    api.addEventListener('readyToClose', () => {
                        if (props.onVideoHangup) {
                            props.onVideoHangup();
                            setVcContext({ jitsiDomain: '', jitsiAllowed: false });
                            if (isFullscreenMode()) {
                                disableFullscreenMode();
                            }
                        }
                    });
                }
            } else {
                if (jp === null || jp.querySelector('IFRAME') === null) {
                    api = null;
                }
            }
        });
        return <div id="jitsiParent" style={style}></div>;
    };

    const waitScreen = (errmsg: string) => {
        return (
            <CenterDiv style={{ marginTop: '100px', display: 'flex', justifyContent: 'center' }}>
                <StyledWaitingCard>
                    <StyledWaitingCardBody>
                        {errmsg ? (
                            <>
                                <h5>Informationen zu Ihrem Videotermin</h5>
                                <p>{errmsg}</p>
                            </>
                        ) : (
                            <>
                                <h5>Warte auf den Beginn des Videochats</h5>
                                <p>Der Videochat beginnt automatisch, sobald der Arzt bereit ist.</p>
                            </>
                        )}
                    </StyledWaitingCardBody>
                </StyledWaitingCard>
            </CenterDiv>
        );
    };

    const contentToShow = () => {
        if (vcContext.jitsiDomain) {
            if (vcContext.jitsiAllowed) {
                return jitsiContent();
            } else {
                return waitScreen(vsstate.jitsiError);
            }
        }
    };

    return <>{contentToShow()}</>;
};

export default VideochatWL;
