import Nav from 'react-bootstrap/Nav';
import styled from 'styled-components';

interface INavButtonProps {
    color: string;
    backgroundColor: string;
    backgroundColorHover: string;
    borderRadius: string;
    border: string;
    borderColor: string;
    borderColorHover: string;
    width: string;
    textAlign: string;
    fontWeight: string;
    margin: string;
}

const navButtonProps = (props: any): INavButtonProps => {
    /* defaultValues / primary */
    const navb: INavButtonProps = {
        color: props.theme.mainColor,
        backgroundColor: props.theme.navbutton.default.backgroundColor,
        backgroundColorHover: props.theme.navbutton.default.backgroundColorHover,
        borderRadius: '0px',
        border: '1px transparent',
        borderColor: props.theme.navbutton.default.borderColor,
        borderColorHover: props.theme.navbutton.default.borderColorHover,
        width: '200px',
        textAlign: 'left',
        fontWeight: 'normal',
        margin: 'auto',
    };
    if (props.variant === 'horizontal') {
        if (props.eventKey === props.activekey) {
            navb.color = props.theme.navbutton.horizontal.colorActive;
            navb.fontWeight = 'bold';
        } else {
            navb.color = props.theme.navbutton.horizontal.colorInactive;
            navb.fontWeight = 'normal';
        }
        navb.backgroundColorHover = props.theme.navbutton.horizontal.backgroundColorHover;
        navb.borderRadius = '20px';
        navb.width = '100%';
        navb.textAlign = 'center';
    }
    if (props.variant === 'vertical') {
        if (props.eventKey === props.activekey) {
            navb.color = props.theme.navbutton.vertical.colorActive;
            navb.backgroundColor = props.theme.navbutton.vertical.backgroundColorActive;
            navb.fontWeight = 'bold';
        } else {
            navb.color = props.theme.navbutton.vertical.colorInactive;
            navb.backgroundColor = props.theme.navbutton.vertical.backgroundColorInactive;
            navb.fontWeight = 'normal';
        }
        navb.backgroundColorHover = props.theme.navbutton.vertical.backgroundColorHover;
        navb.borderRadius = '0px 30px 30px 0px';
        navb.border = '1px transparent';
        navb.borderColor = 'white';
        navb.borderColorHover = props.theme.navbutton.vertical.borderColorHover;
        navb.width = '350px';
        navb.textAlign = 'left';
    }
    return navb;
};

const StyledNavButton = styled(Nav.Link)`
    margin: 'auto';
    color: ${(props) => (props.color ? props.color : navButtonProps(props).color)};
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : navButtonProps(props).backgroundColor};
    border-radius: ${(props) => (props.borderRadius ? props.borderRadius : navButtonProps(props).borderRadius)};
    border: ${(props) => (props.border ? props.border : navButtonProps(props).border)};
    border-color: ${(props) => (props.borderColor ? props.borderColor : navButtonProps(props).borderColor)};
    width: ${(props) => (props.width ? props.width : navButtonProps(props).width)};
    text-align: ${(props) => (props.textAlign ? props.textAlign : navButtonProps(props).textAlign)};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : navButtonProps(props).fontWeight)};
    &:hover {
        background-color: ${(props) =>
            props.backgroundColorHover ? props.backgroundColorHover : navButtonProps(props).backgroundColorHover};
        border-color: ${(props) =>
            props.borderColorHover ? props.borderHover : navButtonProps(props).borderColorHover};
    }
`;

export const StepperNavLinkButton = styled(Nav.Link)`
    padding: 0;
`;

export default StyledNavButton;
