import { createContext } from 'react';

export type InitialThemeType = {
    theme: string;
    navbarCollapsed: boolean;
};

export const initialThemeState = {
    theme: 'PCP',
    navbarCollapsed: false,
};

// const LoginContext = createContext<InitialLoginType>(initialLoginState);
const ThemeContext = createContext<{
    themestate: InitialThemeType;
    themedispatch: React.Dispatch<any>;
}>({
    themestate: initialThemeState,
    themedispatch: () => null,
});

export default ThemeContext;
