import {
    IExtension,
    IQData,
    IQItem,
    IQItemChoiceOption,
    IQItemEnableWhen,
    IQResource,
} from '../context/questionnaireContext';

export interface IPostStoreAppointment {
    makroId: number;
    makroName: string;
}

export function getAllItems<T>(data: any[], allItems: T[]) {
    for (let i = 0; i < data.length; i++) {
        if (data[i].hasOwnProperty('item')) {
            getAllItems(data[i].item, allItems);
        } else {
            allItems.push(data[i]);
        }
    }
    return allItems;
}

export const getQuestionnaireResourceFromRequest = (responseData: IQData) => {
    let resource: any = undefined;
    responseData.entry.some((res: any) => {
        const resourceFromEntry: IQResource = res.resource;
        if (resourceFromEntry.resourceType === 'Questionnaire') {
            resource = resourceFromEntry;
            return true;
        }
    });

    return resource;
};

export const getQuestionnaireResponseResourceFromRequest = (responseData: IQData) => {
    let resource: any = undefined;
    responseData.entry.some((res: any) => {
        const resourceFromEntry: IQResource = res.resource;
        if (resourceFromEntry.resourceType === 'QuestionnaireResponse') {
            resource = resourceFromEntry;
            return true;
        }
    });

    return resource;
};

export const getQuestionnaireDiagnosticReportFromRequest = (responseData: IQData) => {
    let resource: any = undefined;
    responseData.entry.some((res: any) => {
        const resourceFromEntry: IQResource = res.resource;
        if (resourceFromEntry.resourceType === 'DiagnosticReport') {
            resource = resourceFromEntry;
            return true;
        }
    });

    return resource;
};

/**
 * returns a mathematically correct rounded decimal number with given number of decimal positions
 * @param input decimal number as string with arbitrary number of decimal positions
 * @param precision desired number of decimal positions after rounding
 */
export function roundDecimalInput(input: string, precision: number | undefined) {
    const scale = precision ? Math.pow(10, precision) : 1; // default 1 = no decimal position
    return Math.round(parseFloat(input) * scale) / scale;
}

/**
 *
 * @param item an item that depends on one or more answers of related items
 * Returns all ids and required values of the related items.
 * So far, the types 'answerCoding' and 'answerBoolean' are implemented.
 */
export function getRequiredItems(item: IQItem) {
    const questionLinkIds: string[] = [];
    const requiredValues: any[] = [];
    const operators: any[] = [];
    if (item.enableWhen) {
        for (let i = 0; i < item.enableWhen.length; i++) {
            questionLinkIds.push(item.enableWhen[i].question);
            if (item.enableWhen[i].answerCoding) {
                requiredValues.push(item.enableWhen[i].answerCoding.code);
            } else if (item.enableWhen[i].answerBoolean) {
                requiredValues.push(item.enableWhen[i].answerBoolean.toString());
            } /* more cases can be added here */
            operators.push(getExtensionOperator(item.enableWhen[i]));
        }
    }
    return { questionLinkIds, requiredValues, operators };
}

/**
 *
 * @param item an item with possible andSubExpression.
 * If an item is displayed only if multiple other answers are given (as specified in "enableWhen"), it contains the indices of them as "andSubExpressions".
 * An array of those indices (as strings) is returned.
 * If there is no andSubExpression in the extension/modifierExtension, an empty array is returned.
 */
export function getAndExpressionAsArray(item: IQItem) {
    const andExp = getExtensionAndSubExpressions(item);
    let andExpArray: string[] = [];
    if (andExp !== '') {
        andExpArray = andExp.split(',');
    }
    return andExpArray;
}

export const modifyTimeInput = (input: string) => {
    let hour = '';
    let minutes = '';

    if (input.includes(':') || input.includes('.') || input.includes(',')) {
        if (input.includes(':')) {
            hour = input.split(':')[0];
            minutes = input.split(':')[1];
        } else if (input.includes('.')) {
            hour = input.split('.')[0];
            minutes = input.split('.')[1];
        } else if (input.includes(',')) {
            hour = input.split(',')[0];
            minutes = input.split(',')[1];
        }
        hour = hour.length === 1 ? '0' + hour : hour;
        minutes = minutes.length === 1 ? '0' + minutes : minutes;
        input = hour + ':' + minutes;
    } else {
        if (input.length === 1) {
            input = '0' + input + ':00'; /* 9 => 09:00 */
        } else if (input.length === 2) {
            input = input + ':00'; /* 15 => 15:00 */
        } else if (input.length === 3 || input.length === 4) {
            hour = input.substring(0, input.length - 2);
            minutes = input.substring(input.length - 2, input.length);
            hour = hour.length === 1 ? '0' + hour : hour;
            input = hour + ':' + minutes;
        }
    }

    input = input === '24:00' ? '00:00' : input;

    return input;
};

export const getExtensionClassValue = (item: IQItem) => {
    let className = '';

    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('class')) {
                className = ext.valueString;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (className === '') {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('class')) {
                    className = ext.valueString;
                }
            });
        }
    }
    return className;
};

export const getExtensionPresetValue = (item: IQItem) => {
    let presetVal = '';

    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('preset')) {
                presetVal = ext.valueString;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (presetVal === '') {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('preset')) {
                    presetVal = ext.valueString;
                }
            });
        }
    }
    return presetVal;
};

export const getOptionExtensionSingleSelection = (option: IQItemChoiceOption) => {
    let hasSingleSelection = false;

    if (option.modifierExtension) {
        /*check modifierExtension for questionnaire-singleSelection in url and get valueBoolean */
        option.modifierExtension?.forEach((opt) => {
            if (opt.url.endsWith('questionnaire-singleSelection')) {
                if (opt.valueBoolean) {
                    hasSingleSelection = opt.valueBoolean;
                }
            }
        });
    }
    return hasSingleSelection;
};

export const getExtensionMinValue = (item: IQItem) => {
    let minValue: number | undefined = undefined;
    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('minValue')) {
                minValue = ext.valueDecimal !== undefined ? ext.valueDecimal : ext.valueInteger;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (minValue === undefined) {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('minValue')) {
                    minValue = ext.valueDecimal !== undefined ? ext.valueDecimal : ext.valueInteger;
                }
            });
        }
    }
    return minValue;
};

export const getExtensionMaxValue = (item: IQItem) => {
    let maxValue: number | undefined = undefined;
    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('maxValue')) {
                maxValue = ext.valueDecimal !== undefined ? ext.valueDecimal : ext.valueInteger;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (maxValue === undefined) {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('maxValue')) {
                    maxValue = ext.valueDecimal !== undefined ? ext.valueDecimal : ext.valueInteger;
                }
            });
        }
    }
    return maxValue;
};

export const getExtensionLabelRight = (item: IQItem) => {
    let labelRight = '';
    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('labelRight')) {
                labelRight = ext.valueString;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (labelRight === '') {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('labelRight')) {
                    labelRight = ext.valueString;
                }
            });
        }
    }
    return labelRight;
};

export const getExtensionRowType = (item: IQItem) => {
    let rowtype = '';
    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('rowtype')) {
                rowtype = ext.valueString;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (rowtype === '') {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('rowtype')) {
                    rowtype = ext.valueString;
                }
            });
        }
    }
    return rowtype;
};

export const getExtensionLabelLeft = (item: IQItem) => {
    let labelLeft = '';
    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('labelLeft')) {
                labelLeft = ext.valueString;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (labelLeft === '') {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('labelLeft')) {
                    labelLeft = ext.valueString;
                }
            });
        }
    }
    return labelLeft;
};

export const getExtensionStepWidth = (item: IQItem) => {
    let stepWidth: number | undefined = 1;
    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('stepWidth')) {
                stepWidth = ext.valueDecimal !== undefined ? ext.valueDecimal : ext.valueInteger;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (stepWidth === 1) {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('stepWidth')) {
                    stepWidth = ext.valueDecimal !== undefined ? ext.valueDecimal : ext.valueInteger;
                }
            });
        }
    }
    return stepWidth;
};

export const extensionSkipKA = (item: IQItem) => {
    let skipKA: boolean | undefined = false;
    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('skipKA')) {
                skipKA = ext.valueBoolean;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (skipKA === false) {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('skipKA')) {
                    skipKA = ext.valueBoolean;
                }
            });
        }
    }
    return skipKA;
};

export const getExtensionLabelKA = (item: IQItem) => {
    let labelKA: string | undefined = 'k.A.';
    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('labelKA')) {
                labelKA = ext.valueString;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (labelKA === '') {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('labelKA')) {
                    labelKA = ext.valueString;
                }
            });
        }
    }
    return labelKA;
};

export const getExtensionSize = (item: IQItem) => {
    let sizeVal: string | undefined = '';
    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('size')) {
                sizeVal = ext.valueString;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (sizeVal === '') {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('size')) {
                    sizeVal = ext.valueString;
                }
            });
        }
    }
    return sizeVal;
};

export const getNumberOfDrafts = (selectedResource: IQResource) => {
    let numberOfDrafts = 0;

    if (selectedResource?.extension && selectedResource?.extension.length > 0) {
        selectedResource.extension.forEach((e) => {
            if (e.url.endsWith('draft')) {
                numberOfDrafts++;
            }
        });
    }
    return numberOfDrafts;
};

export const getDraft = (selectedResource: IQResource): IExtension | null => {
    let resultExtension: IExtension | null = null;
    if (selectedResource?.extension && selectedResource?.extension.length > 0) {
        selectedResource.extension.forEach((e) => {
            if (e.url.endsWith('draft')) {
                resultExtension = e;
            }
        });
    }

    return resultExtension;
};

export const getExtensionPosition = (item: IQItem) => {
    let positionVal: string | undefined = '';
    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('position')) {
                positionVal = ext.valueString;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (positionVal === '') {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('position')) {
                    positionVal = ext.valueString;
                }
            });
        }
    }
    return positionVal;
};

export const getExtensionAppearanceValue = (item: IQItem) => {
    let appearanceValue = '';
    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('appearance')) {
                appearanceValue = ext.valueString;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (appearanceValue === '') {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('appearance')) {
                    appearanceValue = ext.valueString;
                }
            });
        }
    }
    return appearanceValue;
};

export const getExtensionGroupValue = (item: IQItem) => {
    let groupValue = '';
    if (item.extension || item.modifierExtension) {
        /* check extension for group */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('group')) {
                groupValue = ext.valueString;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (groupValue === '') {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('group')) {
                    groupValue = ext.valueString;
                }
            });
        }
    }
    return groupValue;
};

export const getExtensionDisplayType = (item: IQItem) => {
    let displayType = '';
    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('displayType')) {
                displayType = ext.valueString;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (displayType === '') {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('displayType')) {
                    displayType = ext.valueString;
                }
            });
        }
    }
    return displayType;
};

export const getExtensionPrecision = (item: IQItem) => {
    let precision: number | undefined = 1;
    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('precision')) {
                precision = ext.valueDecimal !== undefined ? ext.valueDecimal : ext.valueInteger;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (precision === 1) {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('precision')) {
                    precision = ext.valueDecimal !== undefined ? ext.valueDecimal : ext.valueInteger;
                }
            });
        }
    }
    return precision;
};

export const getExtensionAndSubExpressions = (item: IQItem) => {
    let andSubExpressions = '';

    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('andSubExpressions')) {
                andSubExpressions = ext.valueString;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (andSubExpressions === '') {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('andSubExpressions')) {
                    andSubExpressions = ext.valueString;
                }
            });
        }
    }
    return andSubExpressions;
};

export const getExtensionImageWidth = (item: IQItem) => {
    let imageWidth = '';

    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('imageWidth')) {
                imageWidth = ext.valueString;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (imageWidth === '') {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('imageWidth')) {
                    imageWidth = ext.valueString;
                }
            });
        }
    }
    return imageWidth;
};

export const getExtensionImageHeight = (item: IQItem) => {
    let imageHeight = '';

    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('imageHeight')) {
                imageHeight = ext.valueString;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (imageHeight === '') {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('imageHeight')) {
                    imageHeight = ext.valueString;
                }
            });
        }
    }
    return imageHeight;
};

export const getExtensionImageDescAppearance = (item: IQItem) => {
    let descAppearance = '';

    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('descAppearance')) {
                descAppearance = ext.valueString;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (descAppearance === '') {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('descAppearance')) {
                    descAppearance = ext.valueString;
                }
            });
        }
    }
    return descAppearance;
};

export const getExtensionFieldNote = (item: IQItem) => {
    let fieldNote = '';

    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('fieldNote')) {
                fieldNote = ext.valueString;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (fieldNote === '') {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('fieldNote')) {
                    fieldNote = ext.valueString;
                }
            });
        }
    }
    return fieldNote;
};

export const getExtensionLabel = (item: IQItem) => {
    let labelName = '';

    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('label')) {
                labelName = ext.valueString;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (labelName === '') {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('label')) {
                    labelName = ext.valueString;
                }
            });
        }
    }
    return labelName;
};

export const getExtensionOperator = (enableWhen: IQItemEnableWhen) => {
    if (enableWhen.modifierExtension) {
        for (const ext of enableWhen.modifierExtension) {
            if (ext.url.endsWith('operator')) {
                return ext.valueString;
            }
        }
    }
    return null;
};

export const getExtensionTooltip = (item: IQItem) => {
    let tooltip = '';

    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('tooltip')) {
                tooltip = ext.valueString;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (tooltip === '') {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('tooltip')) {
                    tooltip = ext.valueString;
                }
            });
        }
    }
    return tooltip;
};

export const getExtensionFunction = (item: IQItem) => {
    let functionName = '';

    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('function')) {
                functionName = ext.valueString;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (functionName === '') {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('function')) {
                    functionName = ext.valueString;
                }
            });
        }
    }
    return functionName;
};

export const getExtensionPossibleFileType = (item: IQItem) => {
    let possibleFileType = '';

    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('possibleFileType')) {
                possibleFileType = ext.valueString;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (possibleFileType === '') {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('possibleFileType')) {
                    possibleFileType = ext.valueString;
                }
            });
        }
    }
    return possibleFileType;
};

export const getExtensionFinishButtonName = (item: IQItem) => {
    let finishButtonName = '';

    if (item.extension || item.modifierExtension) {
        /* check extension for displayType */
        item.extension?.forEach((ext) => {
            if (ext.url.endsWith('finishButtonName')) {
                finishButtonName = ext.valueString;
            }
        });
        /* check modifierExtension for displayType if not found in extension */
        if (finishButtonName === '') {
            item.modifierExtension?.forEach((ext) => {
                if (ext.url.endsWith('finishButtonName')) {
                    finishButtonName = ext.valueString;
                }
            });
        }
    }
    return finishButtonName;
};

/**
 * is intermediate save allowed
 */
export const isDisableTempStorage = (resourceData: IQResource) => {
    let tempStorageDisabled = false;
    if (resourceData && resourceData.extension) {
        resourceData.extension.forEach((e) => {
            if (e.url.endsWith('disableTempStorage')) {
                if (e.valueBoolean) {
                    tempStorageDisabled = e.valueBoolean;
                }
            }
        });
    }

    return tempStorageDisabled;
};

export const getPostStoreDocumentRefId = (questionnaireResponse: any) => {
    let documentId = '';
    let questData = questionnaireResponse;
    if (questionnaireResponse?.data) {
        questData = questionnaireResponse.data;
    }

    if (questData) {
        if (questData.extension) {
            questData.extension.forEach((ext) => {
                if (ext.url.endsWith('postStoreAction')) {
                    if (ext.valueString) {
                        if (ext.valueString === 'openDocumentRef') {
                            if (questData.id) {
                                documentId = questData.id;
                            }
                        }
                    }
                }
            });
        }
    }

    return documentId;
};

export const getPostStoreStartAppointmentSearch = (questionnaireResponse: any): IPostStoreAppointment => {
    const makroData: IPostStoreAppointment = {
        makroId: 0,
        makroName: '',
    };

    if (questionnaireResponse?.data?.extension) {
        const questRespExtension = questionnaireResponse.data.extension;

        questRespExtension.forEach((ext) => {
            if (ext.url.endsWith('postStoreAction')) {
                if (ext.valueString) {
                    if (ext.valueString === 'startAppointmentSearch') {
                        if (ext._valueString.extension) {
                            ext._valueString.extension.forEach((extValStr) => {
                                if (extValStr.url.endsWith('makroId')) {
                                    makroData.makroId = extValStr.valueInteger;
                                } else if (extValStr.url.endsWith('makroName')) {
                                    makroData.makroName = extValStr.valueString;
                                }
                            });
                        }
                    }
                }
            }
        });
    }

    return makroData;
};
