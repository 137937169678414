import styled from 'styled-components';

interface IStickyDiv {
    stickyBreakpoint?: string;
}

export const StickyTitle = styled.div<IStickyDiv>`
    background: white;
    height: 70px;
    line-height: 80px;
    padding-left: 10px;
    position: -webkit-sticky;
    position: sticky;
    top: ${(prop) => (prop.stickyBreakpoint ? prop.stickyBreakpoint : '114px')};
    z-index: 20;
    padding-top: 20px;
`;
