import produce from 'immer';
import moment from 'moment';
import {
    IAvailableTimeslot,
    IAvailableTimeslots,
    InitialTerminierungType,
    IReferenceDisplay,
    ISelectedResources,
    ISelectedTimeslots,
    ITimeslot,
    ITimeslotReplacedServiceType,
} from '../context/terminierungContext';
import { isMobile, isTablet } from './screenResolution';

export const APPOINTMENT_POSTPONE_REASON = 'Termin verschieben';

export const getPractitionerNameFromResource = (resource: any) => {
    let participantDisplay = '';
    if (resource !== undefined) {
        const participantData = resource.participant.find((p: any) => p.actor.reference.startsWith('Prac'));

        if (participantData) {
            participantDisplay = participantData.actor.display;
        }
    }

    return participantDisplay;
};

export const getParticipantIdFromResource = (resource: any) => {
    let _maId = '';

    if (resource !== undefined) {
        const participantData = resource.participant.find((p: any) => p.actor.reference.startsWith('Prac'));

        if (participantData) {
            _maId = participantData.actor.reference.split('/')[1];
        }
    }

    return _maId;
};

export const getLocationDepartmentNameFromResource = (resource: any) => {
    let locationDepartmentDisplay = '';

    if (resource !== undefined) {
        const locationDepartmentData = resource.participant.find(
            (p: any) => p.actor.reference.startsWith('Location') && p.type[0].text.startsWith('Department'),
        );

        if (locationDepartmentData) {
            locationDepartmentDisplay = locationDepartmentData.actor.display;
        }
    }

    return locationDepartmentDisplay;
};

export const getLocationDepartmentLongNameFromResource = (resource: any) => {
    let locationDepartmentDisplayLong = '';

    if (resource !== undefined) {
        const locationDepartmentDisplay = getLocationDepartmentNameFromResource(resource);
        locationDepartmentDisplayLong = locationDepartmentDisplay.split(':')[1];
    }

    return locationDepartmentDisplayLong;
};

export const getLocationDepartmentIdFromResource = (resource: any) => {
    let _departmentId = '';

    if (resource !== undefined) {
        const locationDepartmentData = resource.participant.find(
            (p: any) => p.actor.reference.startsWith('Location') && p.type[0].text.startsWith('Department'),
        );

        if (locationDepartmentData) {
            _departmentId = locationDepartmentData.actor.reference.split('/')[1];
        }
    }

    return _departmentId;
};

export const getLocationCareUnitNameFromResource = (resource: any) => {
    let locationCareUnitDisplay = '';

    if (resource !== undefined) {
        const locationCareUnitData = resource.participant.find(
            (p: any) => p.actor.reference.startsWith('Location') && p.type[0].text.startsWith('CareUnit'),
        );

        if (locationCareUnitData) {
            locationCareUnitDisplay = locationCareUnitData.actor.display;
        }
    }

    return locationCareUnitDisplay;
};

export const getLocationCareUnitLongNameFromResource = (resource: any) => {
    let locationCareUnitDisplayLong = '';

    if (resource !== undefined) {
        const locationCareUnitDisplay = getLocationCareUnitNameFromResource(resource);
        locationCareUnitDisplayLong = locationCareUnitDisplay.split(':')[1];
    }
    return locationCareUnitDisplayLong;
};

export const getLocationCareUnitIdFromResource = (resource: any) => {
    let _ambId = '';

    if (resource !== undefined) {
        const locationCareUnitData = resource.participant.find(
            (p: any) => p.actor.reference.startsWith('Location') && p.type[0].text.startsWith('CareUnit'),
        );

        if (locationCareUnitData) {
            _ambId = locationCareUnitData.actor.reference.split('/')[1];
        }
    }

    return _ambId;
};

export const getLocationRoomNameFromResource = (resource: any) => {
    let locationRoomDisplay = '';

    if (resource !== undefined) {
        const locationRoomData = resource.participant.find(
            (p: any) => p.actor.reference.startsWith('Location') && p.type[0].text.startsWith('Room'),
        );

        if (locationRoomData) {
            locationRoomDisplay = locationRoomData.actor.display;
        }
    }

    return locationRoomDisplay;
};

export const getLocationRoomLongNameFromResource = (resource: any) => {
    let locationRoomDisplayLong = '';

    if (resource !== undefined) {
        const locationRoomDisplay = getLocationRoomNameFromResource(resource);
        locationRoomDisplayLong = locationRoomDisplay.split(':')[1];
    }

    return locationRoomDisplayLong;
};

export const getLocationRoomIdFromResource = (resource: any) => {
    let _roomId = '';

    if (resource !== undefined) {
        const locationRoomData = resource.participant.find(
            (p: any) => p.actor.reference.startsWith('Location') && p.type[0].text.startsWith('Room'),
        );

        if (locationRoomData) {
            _roomId = locationRoomData.actor.reference.split('/')[1];
        }
    }

    return _roomId;
};

export const getLongNameFromAppointmentData = (appDataValue) => {
    let longName = appDataValue;
    if (longName && longName.includes(': ')) {
        longName = appDataValue.split(': ')[1];
    }
    return longName;
};

export const setReplacedRoomForResource = (resource: any, replacedRoom: IReferenceDisplay) => {
    if (replacedRoom) {
        const mResource = produce(resource, (draft: any) => {
            const locationRoomData = draft.participant.find(
                (p: any) => p.actor.reference.startsWith('Location') && p.type[0].text.startsWith('Room'),
            );
            if (locationRoomData) {
                if (replacedRoom.reference.length > 0) {
                    locationRoomData.actor.reference = replacedRoom.reference;
                }
                if (replacedRoom.display.length > 0) {
                    locationRoomData.actor.display = replacedRoom.display;
                }
            }
        });
        return mResource;
    } else {
        return resource;
    }
};

export const setReplacedPractitionerForResource = (resource: any, replacedPractitioner: IReferenceDisplay) => {
    if (replacedPractitioner) {
        const mResource = produce(resource, (draft: any) => {
            const practitionerData = draft.participant.find((p: any) => p.actor.reference.startsWith('Practitioner'));
            if (replacedPractitioner) {
                if (replacedPractitioner.reference.length > 0) {
                    practitionerData.actor.reference = replacedPractitioner.reference;
                }
                if (replacedPractitioner.display.length > 0) {
                    practitionerData.actor.display = replacedPractitioner.display;
                }
            }
        });
        return mResource;
    } else {
        return resource;
    }
};

export const setReplacedCareUnitForResource = (resource: any, replacedCareUnit: IReferenceDisplay) => {
    if (replacedCareUnit) {
        const mResource = produce(resource, (draft: any) => {
            const careUnitData = draft.participant.find(
                (p: any) => p.actor.reference.startsWith('Location') && p.type[0].text.startsWith('CareUnit'),
            );
            if (careUnitData) {
                if (replacedCareUnit.reference.length > 0) {
                    careUnitData.actor.reference = replacedCareUnit.reference;
                }
                if (replacedCareUnit.display.length > 0) {
                    careUnitData.actor.display = replacedCareUnit.display;
                }
            }
        });
        return mResource;
    } else {
        return resource;
    }
};

export const setReplacedDepartmentForResource = (resource: any, replacedDepartment: IReferenceDisplay) => {
    if (replacedDepartment) {
        const mResource = produce(resource, (draft: any) => {
            const departmentData = draft.participant.find(
                (p: any) => p.actor.reference.startsWith('Location') && p.type[0].text.startsWith('Department'),
            );
            if (replacedDepartment) {
                if (replacedDepartment.reference.length > 0) {
                    departmentData.actor.reference = replacedDepartment.reference;
                }
                if (replacedDepartment.display.length > 0) {
                    departmentData.actor.display = replacedDepartment.display;
                }
            }
        });
        return mResource;
    } else {
        return resource;
    }
};

export const setReplacedServiceTypeForResource = (resource: any, replacedServiceType: ITimeslotReplacedServiceType) => {
    if (replacedServiceType) {
        const mResource = produce(resource, (draft: any) => {
            const serviceTypeData = draft.serviceType.find((p: any) => p.coding);
            serviceTypeData.coding = replacedServiceType.coding;
        });
        return mResource;
    } else {
        return resource;
    }
};

export const setReplacedStartEndForResource = (resource: any, newStartEnd: ITimeslot) => {
    if (newStartEnd) {
        const mResource = produce(resource, (draft: any) => {
            draft.start = newStartEnd.start;
            draft.end = newStartEnd.end;
        });
        return mResource;
    } else {
        return resource;
    }
};

export const getCountAPKsFromExtension = (cancelNewAppointmentDataExtension: any) => {
    const extension = cancelNewAppointmentDataExtension.find((p: any) => p.url.endsWith('countAPKs'));

    let countAPKs = 0;
    if (extension) {
        countAPKs = extension.valueInteger;
    }

    return countAPKs;
};

export const getMissingAPKsFromExtension = (cancelNewAppointmentDataExtension: any) => {
    const extension = cancelNewAppointmentDataExtension.find((p: any) => p.url.endsWith('missingAPKNr'));

    let missingAPK = '';
    if (extension) {
        missingAPK = extension.valueString;
    }

    return missingAPK;
};

export const getEnrollCodeFromExtension = (cancelNewAppointmentDataExtension: any) => {
    const extension = cancelNewAppointmentDataExtension.find((p: any) => p.url.endsWith('enrollCode'));

    let enrollCode = '';
    if (extension) {
        enrollCode = extension.valueString;
    }

    return enrollCode;
};

export const getAPKNrFromExtension = (cancelNewAppointmentDataExtension: any) => {
    const extension = cancelNewAppointmentDataExtension.find((p: any) => p.url.endsWith('apkNr'));

    let apkNr = 0;
    if (extension) {
        apkNr = extension.valueInteger;
    }

    return apkNr;
};

export const getIdentifierId = (dataIdentifier: any) => {
    const originalId = dataIdentifier.find((p: any) => p.system.endsWith('originalId'));

    let id = '';
    if (originalId) {
        id = originalId.value;
    }

    return id;
};

export const getMacroNameFromAppointment = (appointment: IAppointment) => {
    let macroName = '';
    if (appointment.serviceType) {
        macroName = appointment.serviceType[0].coding[0].display;
    }
    return macroName;
};

export const getSelectedReasonFromAppointment = (appointment: IAppointment) => {
    let selectedReason = '';
    if (appointment.extension) {
        const extension = appointment.extension.find((e: any) => e.url.endsWith('selectedReason'));
        if (extension && extension.valueString) {
            const selectedReasonObj = JSON.parse(extension.valueString);
            selectedReason = selectedReasonObj.reasonName;
        }
    }
    return selectedReason;
};

export const getSelectedReasonDataFromAppointment = (appointment: IAppointment) => {
    let selectedReasonObj: any = null;
    if (appointment.extension) {
        const extension = appointment.extension.find((e: any) => e.url.endsWith('selectedReason'));
        if (extension && extension.valueString) {
            selectedReasonObj = JSON.parse(extension.valueString);
            if (appointment.serviceType) {
                selectedReasonObj.makroId = appointment.serviceType[0].coding[0].id;
            }
        }
    }
    return selectedReasonObj;
};

export const getMacroNameFromSelectedResource = (selectedResource: ISelectedResources) => {
    let macroName = '';
    try {
        if (selectedResource) {
            macroName = selectedResource.resource.serviceType[0].coding[0].display;
        }
    } catch (error) {
        macroName = '';
    }

    return macroName;
};

export const isAppSeriesAppointment = (appointment: IAppointment) => {
    let isAppSeries = false;
    if (appointment.identifier) {
        appointment.identifier.forEach((e: any) => {
            if (e.system !== undefined && e.system.endsWith('appSeries')) {
                isAppSeries = true;
            }
        });
    }
    return isAppSeries;
};

export interface IAppointment {
    id: any;
    start: any;
    end: any;
    status: any;
    resource: any;
    participant: IParticipant[];
    comment: any;
    type: any;
    cardPosition?: string;
    cardWidth?: string;
    isCancelable: boolean;
    isInError: boolean;
    extension?: any;
    serviceType?: any;
    showChatIcon?: boolean;
    enrollCode?: string;
    identifier?: any;
    supressDuration?: boolean;
    modifierExtension?: any;
    standort?: string;
}

export interface IParticipantType {
    type: string;
}
export interface IParticipant {
    actor: IActor;
    status: any;
    type?: IParticipantType;
}

export interface IActor {
    reference: string;
    display: string;
}

export function getStickyPosition(screenWidth: number) {
    if (screenWidth === 1024) {
        // iPad Pro
        return '120px';
    } else if (isTablet(screenWidth)) {
        return '160px';
    } else if (isMobile(screenWidth)) {
        return '120px';
    } else {
        return '114px';
    }
}

export function getTabHeight(screenWidth: number) {
    if (screenWidth === 1024) {
        // iPad Pro
        return '65px';
    } else if (isTablet(screenWidth)) {
        return '110px';
    } else if (isMobile(screenWidth)) {
        return '80px';
    } else {
        return '65px';
    }
}

export const updateSelectedResources = (
    currentSelectedResources: ISelectedResources[],
    appointmentNr: number,
    newSelectedResource: any,
) => {
    const newResource: ISelectedResources = {
        appointmentNr: appointmentNr,
        resource: newSelectedResource,
    };

    const updatedSelectedResources = currentSelectedResources;
    let foundIndex = -1;

    updatedSelectedResources.some((e, index) => {
        if (e.appointmentNr === appointmentNr) {
            foundIndex = index;
            return true;
        }
    });

    if (foundIndex >= 0) {
        updatedSelectedResources.splice(foundIndex, 1);
        updatedSelectedResources.push(newResource);
    } else {
        updatedSelectedResources.push(newResource);
    }

    return updatedSelectedResources;
};

export const resetSelectedResourceWithNr = (currentSelectedResources: ISelectedResources[], appointmentNr: number) => {
    const updatedSelectedResources = currentSelectedResources;

    let foundIndex = -1;
    updatedSelectedResources.some((e, index) => {
        if (e.appointmentNr === appointmentNr) {
            foundIndex = index;
            return true;
        }
    });

    if (foundIndex >= 0) {
        updatedSelectedResources.splice(foundIndex, 1);
    }

    return updatedSelectedResources;
};

export const updateAvailableTimeslots = (
    currentAvailableTimeslots: IAvailableTimeslots[],
    appointmentNr: number,
    newAvailableTimeslot: IAvailableTimeslot[],
    supressDuration: boolean,
) => {
    const newTimeslots: IAvailableTimeslots = {
        appointmentNr: appointmentNr,
        availableTimeslot: newAvailableTimeslot,
        supressDuration: supressDuration,
    };

    let updatedAvailableTimeslots: IAvailableTimeslots[] = [];
    if (currentAvailableTimeslots) {
        updatedAvailableTimeslots = currentAvailableTimeslots;
    }

    let foundIndex = -1;

    updatedAvailableTimeslots.some((e, index) => {
        if (e.appointmentNr === appointmentNr) {
            foundIndex = index;
            return true;
        }
    });

    if (foundIndex >= 0) {
        updatedAvailableTimeslots.splice(foundIndex, 1);
        updatedAvailableTimeslots.push(newTimeslots);
    } else {
        updatedAvailableTimeslots.push(newTimeslots);
    }

    return updatedAvailableTimeslots;
};

export const resetAvailableTimeslotsWithNr = (
    currentAvailableTimeslots: IAvailableTimeslots[],
    appointmentNr: number,
) => {
    const updatedAvailableTimeslots = currentAvailableTimeslots;

    let foundIndex = -1;
    updatedAvailableTimeslots.some((e, index) => {
        if (e.appointmentNr === appointmentNr) {
            foundIndex = index;
            return true;
        }
    });

    if (foundIndex >= 0) {
        updatedAvailableTimeslots.splice(foundIndex, 1);
    }

    return updatedAvailableTimeslots;
};

export const updateSelectedTimeslot = (
    currentTimeslots: ISelectedTimeslots[],
    appointmentNr: number,
    newSelectedTimeslot: ITimeslot,
) => {
    const newSelctedTimeslots: ISelectedTimeslots = {
        appointmentNr: appointmentNr,
        selectedTimeslot: newSelectedTimeslot,
    };

    let updatedSelectedTimeslots: ISelectedTimeslots[] = [];
    if (currentTimeslots) {
        updatedSelectedTimeslots = currentTimeslots;
    }

    let foundIndex = -1;

    if (updatedSelectedTimeslots) {
        updatedSelectedTimeslots.some((e, index) => {
            if (e.appointmentNr === appointmentNr) {
                foundIndex = index;
                return true;
            }
        });
    }

    if (foundIndex >= 0) {
        updatedSelectedTimeslots.splice(foundIndex, 1);
        updatedSelectedTimeslots.push(newSelctedTimeslots);
    } else {
        updatedSelectedTimeslots.push(newSelctedTimeslots);
    }

    return updatedSelectedTimeslots;
};

export const resetSelectedTimeslotsWithNr = (currentTimeslots: ISelectedTimeslots[], appointmentNr: number) => {
    const updatedSelectedTimeslots = currentTimeslots;

    let foundIndex = -1;
    updatedSelectedTimeslots.some((e, index) => {
        if (e.appointmentNr === appointmentNr) {
            foundIndex = index;
            return true;
        }
    });

    if (foundIndex >= 0) {
        updatedSelectedTimeslots.splice(foundIndex, 1);
    }

    return updatedSelectedTimeslots;
};

export const sortParticipantsForCard = (participant: IParticipant[]): IParticipant[] => {
    const reorderedParticipants = new Array<IParticipant>(4);

    for (const p of participant) {
        const ptype = getParticipantType(p);
        if (ptype === APPOINTMENTTYPE_DEPARTMENT) {
            reorderedParticipants[0] = p;
        } else if (ptype === APPOINTMENTTYPE_CAREUNIT) {
            reorderedParticipants[1] = p;
        } else if (ptype === APPOINTMENTTYPE_PRACTITIONER) {
            reorderedParticipants[2] = p;
        } else if (ptype === APPOINTMENTTYPE_ROOM) {
            reorderedParticipants[3] = p;
        }
    }

    return reorderedParticipants;
};

export const APPOINTMENTTYPE_DEPARTMENT = 'Department';
export const APPOINTMENTTYPE_CAREUNIT = 'CareUnit';
export const APPOINTMENTTYPE_ROOM = 'Room';
export const APPOINTMENTTYPE_PRACTITIONER = 'Practitioner';

export const getParticipantType = (participant: IParticipant) => {
    let cond = participant.actor.reference.split('/')[0];

    /* Namensumwandlungen */
    if (cond === 'Location') {
        if (participant.type !== undefined && participant.type[0].text !== undefined) {
            cond = participant.type[0].text;
        }
    }

    switch (cond) {
        case APPOINTMENTTYPE_DEPARTMENT:
            return APPOINTMENTTYPE_DEPARTMENT;
        case APPOINTMENTTYPE_CAREUNIT:
            return APPOINTMENTTYPE_CAREUNIT;
        case APPOINTMENTTYPE_ROOM:
            return APPOINTMENTTYPE_ROOM;
        case APPOINTMENTTYPE_PRACTITIONER:
            return APPOINTMENTTYPE_PRACTITIONER;
        default:
            return '';
    }
};

export const getOriginalAppointmentDate = (tmstate: InitialTerminierungType) => {
    let originalAppointmentDetails = '';
    try {
        if (tmstate.selectedTimeslots[0]?.selectedTimeslot !== undefined) {
            const startDate = tmstate.selectedTimeslots[0]?.selectedTimeslot?.start;
            const endDate = tmstate.selectedTimeslots[0]?.selectedTimeslot?.end;
            if (startDate) {
                const dateStart = moment(startDate).format('DD.MM.YYYY');
                const timeStart = moment(startDate).format('HH:mm');
                const timeEnd = moment(endDate).format('HH:mm');
                originalAppointmentDetails = ' (auf ' + dateStart + ' ' + timeStart + '-' + timeEnd + ')';
            }
        }
    } catch (e) {
        console.error('cannot get original appointment date for postpone remark');
    }
    return originalAppointmentDetails;
};

export const getPhysicianFrom = (appointData: any) => {
    if (appointData.participant) {
        for (const p of appointData.participant) {
            const ref = p.actor?.reference;
            if (ref && ref.startsWith(APPOINTMENTTYPE_PRACTITIONER)) {
                return ref.substring(APPOINTMENTTYPE_PRACTITIONER.length + 1);
            }
        }
    }
    return '';
};
