import Container from 'react-bootstrap/Container';
import styled from 'styled-components';

export const ChatWidgetBaseContainer = styled(Container)`
    bottom: 0;
    display: flex;
    flex-direction: column;
    margin: 0 20px 20px 0;
    max-width: 370px;
    position: fixed;
    right: 0;
    width: 90vw;
    z-index: 1000;
`;

export const ChatWidgetContainer = styled(Container)`
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 10px 1px #b5b5b5;
    background-color: white;
`;
