import { useState } from 'react';
import TimeSlots from '../../components/Calendar/TimeSlots';
import StyledCol from '../../components/Cols/StyledCol';
import Calendar from '../Calendar/Calendar';
import { LeftDiv } from '../Div/StyledDiv';
import { StyledRowNoMargin } from '../Rows/StyledRow';

//test commit
const AppointmentCalendar = () => {
    const [selectedDay, setSelectedDay] = useState<string>('');

    return (
        <LeftDiv marginBottom="30px">
            <StyledRowNoMargin xs={1} sm={2}>
                <StyledCol paddingLeft="0px">
                    <Calendar setSelectedDay={setSelectedDay} />
                </StyledCol>
                <StyledCol>
                    <TimeSlots selectedDay={selectedDay} />
                </StyledCol>
            </StyledRowNoMargin>
        </LeftDiv>
    );
};

export default AppointmentCalendar;
