import checkAnimation from '../lotties/checkAnimation.json';

export const lottieCheck = {
    loop: false,
    autoplay: true,
    animationData: checkAnimation, //fill color = #adcf03 (successGreen from theme)
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

export const calcButtonPaddingSize = (buttonRef: React.MutableRefObject<any>, lottieWidth: number) => {
    let calcButtonWidth = '35px';
    if (buttonRef.current) {
        const buttonWidth = buttonRef.current.offsetWidth;
        const calcButtonW = (buttonWidth - lottieWidth) / 2;
        calcButtonWidth = calcButtonW.toString() + 'px';
    }
    return calcButtonWidth;
};
