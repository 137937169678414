import * as React from 'react';
import styled from 'styled-components';

interface IImageDiv {
    textAlign?: string;
    float?: string;
    marginTop?: string;
    marginBottom?: string;
    marginLeft?: string;
    marginRight?: string;
    textDirection?: string;
}

interface IImageDescDiv {
    textAlign?: string;
    divWidth?: string;
    marginTop?: string;
    marginBottom?: string;
    marginLeft?: string;
    marginRight?: string;
    float?: string;
    textDirection?: string;
}

interface IImageFloatDiv {
    float: string;
    display: string;
    direction: string;
    alignItems: string;
    width: string;
    children: React.ReactNode;
}

interface IQImageDiv {
    textAlign?: string;
}

const imageProps = (props: IImageDiv): IImageDiv => {
    const imageProps: IImageDiv = {
        textAlign: props.textAlign ? props.textAlign : 'left',
        float: props.float ? props.float : 'none',
        marginTop: props.marginTop ? props.marginTop : '5px',
        marginBottom: props.marginBottom ? props.marginBottom : '5px',
        marginLeft: props.marginLeft ? props.marginLeft : '5px',
        marginRight: props.marginRight ? props.marginRight : '5px',
        textDirection: props.textDirection ? props.textDirection : 'left',
    };

    /* WITH FLOAT */
    if (props.float && props.float === 'left') {
        if (props.textDirection && props.textDirection === 'left') {
            imageProps.marginRight = '40px';
        } else if (props.textDirection && props.textDirection === 'right') {
        } else if (props.textDirection && props.textDirection === 'top') {
        } else if (props.textDirection && props.textDirection === 'bottom') {
        } else {
            imageProps.marginTop = '5px';
            imageProps.marginBottom = '5px';
            imageProps.marginLeft = '5px';
            imageProps.marginRight = '5px';
        }
    } else if (props.float && props.float === 'right') {
        if (props.textDirection && props.textDirection === 'left') {
        } else if (props.textDirection && props.textDirection === 'right') {
            props.marginLeft = '40px';
        } else if (props.textDirection && props.textDirection === 'top') {
        } else if (props.textDirection && props.textDirection === 'bottom') {
        } else {
            imageProps.marginTop = '5px';
            imageProps.marginBottom = '5px';
            imageProps.marginLeft = '5px';
            imageProps.marginRight = '5px';
        }
    } else {
        if (props.textDirection && props.textDirection === 'left') {
            props.marginBottom = '40px';
        } else if (props.textDirection && props.textDirection === 'right') {
            props.marginBottom = '40px';
        } else if (props.textDirection && props.textDirection === 'top') {
            props.marginBottom = '40px';
        } else if (props.textDirection && props.textDirection === 'bottom') {
        } else {
            imageProps.marginTop = '5px';
            imageProps.marginBottom = '5px';
            imageProps.marginLeft = '5px';
            imageProps.marginRight = '5px';
        }
    }

    return imageProps;
};

const imageDescProps = (props: IImageDescDiv): IImageDescDiv => {
    const imageDescProps: IImageDescDiv = {
        textAlign: props.textAlign,
        marginTop: props.marginTop ? props.marginTop : '0px',
        marginBottom: props.marginBottom ? props.marginBottom : '0px',
        marginLeft: props.marginLeft ? props.marginLeft : '0px',
        marginRight: props.marginRight ? props.marginRight : '0px',
        textDirection: props.textDirection ? props.textDirection : 'left',
    };

    /* WITHOUT FLOAT */
    if (props.textAlign === 'left') {
        if (props.textDirection && props.textDirection === 'top') {
            imageDescProps.marginBottom = '5px';
        } else {
            imageDescProps.marginTop = '5px';
            imageDescProps.marginBottom = '40px';
            imageDescProps.marginLeft = '5px';
        }
    } else if (props.textAlign === 'right') {
        if (props.textDirection && props.textDirection === 'top') {
            imageDescProps.marginBottom = '5px';
        } else {
            imageDescProps.marginTop = '5px';
            imageDescProps.marginBottom = '40px';
            imageDescProps.marginLeft = '5px';
        }
    } else if (props.textAlign === 'center') {
        if (props.textDirection && props.textDirection === 'top') {
            imageDescProps.marginLeft = '5px';
            imageDescProps.marginTop = '5px';
            imageDescProps.marginBottom = '5px';
            imageDescProps.marginRight = '5px';
        } else if (props.textDirection && props.textDirection === 'bottom') {
            imageDescProps.marginLeft = '5px';
            imageDescProps.marginTop = '5px';
            imageDescProps.marginBottom = '40px';
            imageDescProps.marginRight = '5px';
        } else {
            imageDescProps.marginTop = '5px';
            imageDescProps.marginBottom = '40px';
            imageDescProps.marginLeft = '5px';
            imageDescProps.marginRight = '5px';
        }
    }

    /* WITH FLOAT */
    if (props.float && props.float === 'left') {
        if (props.textDirection && props.textDirection === 'left') {
            imageDescProps.marginLeft = '5px';
            imageDescProps.marginBottom = '40px';
            imageDescProps.marginRight = '5px';
        } else if (props.textDirection && props.textDirection === 'right') {
            imageDescProps.marginLeft = '5px';
            imageDescProps.marginBottom = '40px';
            imageDescProps.marginRight = '40px';
        } else if (props.textDirection && props.textDirection === 'top') {
            imageDescProps.marginLeft = '5px';
            imageDescProps.marginTop = '5px';
            imageDescProps.marginBottom = '5px';
            imageDescProps.marginRight = '40px';
        } else if (props.textDirection && props.textDirection === 'bottom') {
            imageDescProps.marginLeft = '5px';
            imageDescProps.marginTop = '5px';
            imageDescProps.marginBottom = '5px';
            imageDescProps.marginRight = '40px';
        } else {
            imageDescProps.marginLeft = '5px';
            imageDescProps.marginBottom = '40px';
            imageDescProps.marginRight = '40px';
        }
    } else if (props.float && props.float === 'right') {
        if (props.textDirection && props.textDirection === 'left') {
            imageDescProps.marginLeft = '40px';
            imageDescProps.marginBottom = '40px';
            imageDescProps.marginRight = '5px';
        } else if (props.textDirection && props.textDirection === 'right') {
            imageDescProps.marginLeft = '5px';
            imageDescProps.marginBottom = '40px';
            imageDescProps.marginRight = '5px';
        } else if (props.textDirection && props.textDirection === 'top') {
            imageDescProps.marginLeft = '40px';
            imageDescProps.marginTop = '5px';
            imageDescProps.marginBottom = '5px';
            imageDescProps.marginRight = '5px';
        } else if (props.textDirection && props.textDirection === 'bottom') {
            imageDescProps.marginLeft = '40px';
            imageDescProps.marginTop = '5px';
            imageDescProps.marginBottom = '5px';
            imageDescProps.marginRight = '5px';
        } else {
            imageDescProps.marginLeft = '40px';
            imageDescProps.marginRight = '40px';
            imageDescProps.marginBottom = '40px';
        }
    }

    return imageDescProps;
};

export const ImageDiv = styled.div<IImageDiv>`
    text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
    float: ${(props) => (props.float ? props.float : 'none')};
    margin-top: ${(props) => (props.marginTop ? props.marginTop : imageProps(props).marginTop)};
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : imageProps(props).marginBottom)};
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : imageProps(props).marginLeft)};
    margin-right: ${(props) => (props.marginRight ? props.marginRight : imageProps(props).marginRight)};
`;

export const ImageDescDiv = styled.div<IImageDescDiv>`
    text-align: left;
    float: ${(props) => (props.float ? props.float : 'none')};
    width: ${(props) => (props.divWidth ? props.divWidth : 'auto')};
    margin-top: ${(props) => (props.marginTop ? props.marginTop : imageDescProps(props).marginTop)};
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : imageDescProps(props).marginBottom)};
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : imageDescProps(props).marginLeft)};
    margin-right: ${(props) => (props.marginRight ? props.marginRight : imageDescProps(props).marginRight)};
    font-size: 4mm;
    font-style: italic;
`;

export const StyledImageFloatDiv = styled.div<IImageFloatDiv>`
    float: ${(props) => (props.float ? props.float : 'none')};
    margin-top: 40px;
    display: ${(props) => (props.display ? props.display : 'flex')};
    flex-direction: ${(props) => (props.direction ? props.direction : 'column')};
    align-items: ${(props) => (props.alignItems ? props.alignItems : 'left')};
    width: ${(props) => (props.width ? props.width : 'auto')};
`;

export const ImageFloatDiv = (props: IImageFloatDiv) => {
    return (
        <StyledImageFloatDiv
            float={props.float}
            direction={props.direction}
            display={props.display}
            alignItems={props.alignItems}
            width={props.width}
        >
            {props.children}
        </StyledImageFloatDiv>
    );
};

export const QImageDiv = styled.div<IQImageDiv>`
    margin-bottom: '30px';
    display: 'block';
    text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
`;
