import moment from 'moment';
import { useState } from 'react';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { Bar, Line } from 'react-chartjs-2';
import useScreenResolution from '../../../hooks/useScreenResolution';
import {
    chartSettingsForDevice,
    getChartOptionsData,
    getDatasetData,
    handleDisplayControllers,
    IChartOptions,
    IChartProps,
} from '../../../utils/chartUtils';
import { ToggleButtonGroupFlex } from '../../Buttons/ToggleButtonGroupCst';
import { ChartInnerDiv, ChartOuterDiv } from '../../Div/StyledDiv';

const OneMonthView = (props: IChartProps) => {
    // const [xMin, setXMin] = useState(moment(moment().startOf('month').format('DD.MM.YYYY'), 'DD.MM.YYYY'));
    // const [xMax, setXMax] = useState(moment(moment().endOf('month').format('DD.MM.YYYY'), 'DD.MM.YYYY'));

    // start with displaying the month with the latest data entry
    const [xMin, setXMin] = useState(
        moment(
            props.chartData.data[props.chartData.data.length - 1].t.clone().startOf('month').format('DD.MM.YYYY'),
            'DD.MM.YYYY',
        ),
    );
    const [xMax, setXMax] = useState(
        moment(
            props.chartData.data[props.chartData.data.length - 1].t.clone().endOf('month').format('DD.MM.YYYY'),
            'DD.MM.YYYY',
        ),
    );

    const screenSize = useScreenResolution();

    const chartSettings = chartSettingsForDevice('oneMonth', screenSize.width, screenSize.height);

    const [selectedMonth, setSelectedMonth] = useState(
        props.chartData.data[props.chartData.data.length - 1].t.clone().month(),
    );
    const [selectedYear, setSelectedYear] = useState(
        props.chartData.data[props.chartData.data.length - 1].t.clone().year(),
    );

    const scalesOffset = props?.mobileProps?.sessionId ? true : false;
    // const ticksMinRotation = props?.mobileProps?.sessionId ? 20 : 0;
    // const ticksMaxRotation = props?.mobileProps?.sessionId ? 20 : chartSettings.rotation;
    const ticksLabelOffset = props?.mobileProps?.sessionId ? 30 : 0;

    // get year of first and last weight data entry
    const firstYear = props.chartData.data[0].t.year();
    const lastYear = props.chartData.data[props.chartData.data.length - 1].t.year();

    // create a list of objects with the years and an index for the buttons
    const yearList: Array<{ name: number; value: number }> = [];
    let index = 0;
    for (let year = firstYear; year <= lastYear; year++) {
        yearList.push({ name: year, value: index });
        index++;
    }

    const monthsList = [
        { name: 'Januar', value: 0 },
        { name: 'Februar', value: 1 },
        { name: 'März', value: 2 },
        { name: 'April', value: 3 },
        { name: 'Mai', value: 4 },
        { name: 'Juni', value: 5 },
        { name: 'Juli', value: 6 },
        { name: 'August', value: 7 },
        { name: 'September', value: 8 },
        { name: 'Oktober', value: 9 },
        { name: 'November', value: 10 },
        { name: 'Dezember', value: 11 },
    ];

    const datasetProps = {
        chartProps: props,
        barPercentage: 2,
    };

    const [datasets, labelsToDisplay] = getDatasetData(datasetProps);
    const data = {
        labels: labelsToDisplay, // labels to be displayed by the datalabels.formatter
        datasets: datasets,
    };

    const chartOptionsProps: IChartOptions = {
        chartProps: props,
        ticksXMin: xMin,
        ticksXMax: xMax,
        scalesOffset: scalesOffset,
        ticksLabelOffset: ticksLabelOffset,
        // ticksMinRotation: ticksMinRotation,
        // ticksMaxRotation: ticksMaxRotation,
        chartSettings: chartSettings,
        updateLimits: (e) => updateLimits(e),
    };

    const options = getChartOptionsData(chartOptionsProps);

    function updateLimits(context: any) {
        if (!props.mobileProps?.sessionId) {
            setXMin(context.chart.scales['x-axis-0'].min);
            setXMax(context.chart.scales['x-axis-0'].max);
        }
    }

    const [radioValYear, setRadioValYear] = useState(yearList[yearList.length - 1].value);
    const [radioValMonth, setRadioValMonth] = useState(props.chartData.data[props.chartData.data.length - 1].t.month());

    const handleYearChange = (selectedYearVal: React.SetStateAction<number>) => {
        const yearName = yearList.find((i) => i.value === selectedYearVal)?.name;
        const selectedYearAndMonth = moment([yearName, selectedMonth]);
        setRadioValYear(selectedYearVal);
        setSelectedYear(yearList.find((i) => i.value === selectedYearVal)?.name);
        setXMin(moment(selectedYearAndMonth).startOf('month'));
        setXMax(moment(selectedYearAndMonth).endOf('month'));
    };

    const handleMonthChange = (selectedMonthVal: React.SetStateAction<number>) => {
        const selectedYearAndMonth = moment([selectedYear, Number(selectedMonthVal)]);
        setRadioValMonth(Number(selectedMonthVal));
        setSelectedMonth(selectedMonthVal);
        setXMin(moment(selectedYearAndMonth).startOf('month'));
        setXMax(moment(selectedYearAndMonth).endOf('month'));
    };

    const MonthOptions = () => {
        const allMonthOptions: any[] = [];
        {
            monthsList.map((month, idx) => {
                allMonthOptions.push(
                    <React.Fragment key={idx}>
                        <ToggleButton
                            id={'mob_month_' + month.name}
                            type="radio"
                            variant="light"
                            name="radio"
                            value={month.value}
                            checked={Number(radioValMonth) === month.value}
                            onChange={() => handleMonthChange(month.value)}
                        >
                            {month.name}
                        </ToggleButton>
                    </React.Fragment>,
                );
            });
        }

        return (
            <ToggleButtonGroupFlex
                id="mob_monthController"
                type="radio"
                name="monthOption"
                defaultValue={selectedMonth}
            >
                {allMonthOptions}
            </ToggleButtonGroupFlex>
        );
    };

    const YearOptions = () => {
        const allYearOptions: any[] = [];
        {
            yearList.map((year, idx) => {
                allYearOptions.push(
                    <React.Fragment key={idx}>
                        <ToggleButton
                            id={'mob_year_' + year.name}
                            type="radio"
                            variant="light"
                            name="radio"
                            value={year.value}
                            checked={radioValYear === year.value}
                            onChange={() => handleYearChange(year.value)}
                        >
                            {year.name}
                        </ToggleButton>
                    </React.Fragment>,
                );
            });
        }

        return (
            <ToggleButtonGroupFlex id="mob_yearController" type="radio" name="yearOption" defaultValue={radioValYear}>
                {allYearOptions}
            </ToggleButtonGroupFlex>
        );
    };

    const MyLine = () => {
        if (props.mobileProps?.height !== undefined) {
            return (
                <ChartOuterDiv style={{ height: props.mobileProps?.height }}>
                    <ChartInnerDiv>
                        <Line
                            redraw={true}
                            data={data}
                            options={options}
                            width={props.mobileProps?.width}
                            height={props.mobileProps?.height}
                        />
                    </ChartInnerDiv>
                </ChartOuterDiv>
            );
        } else {
            return (
                <ChartOuterDiv>
                    <ChartInnerDiv>
                        <Line redraw={true} data={data} options={options} height={chartSettings.height} />
                    </ChartInnerDiv>
                </ChartOuterDiv>
            );
        }
    };

    const MyBar = () => {
        if (props.mobileProps?.height !== undefined) {
            return (
                <ChartOuterDiv style={{ height: props.mobileProps?.height }}>
                    <ChartInnerDiv>
                        <Bar
                            redraw={true}
                            data={data}
                            options={options}
                            width={props.mobileProps?.width}
                            height={props.mobileProps?.height}
                        />
                    </ChartInnerDiv>
                </ChartOuterDiv>
            );
        } else {
            return (
                <ChartOuterDiv>
                    <ChartInnerDiv>
                        <Bar redraw={true} data={data} options={options} height={chartSettings.height} />
                    </ChartInnerDiv>
                </ChartOuterDiv>
            );
        }
    };

    return (
        <div>
            <Row style={{ display: props?.mobileProps && handleDisplayControllers(props.mobileProps) }}>
                <Col>
                    <YearOptions />
                </Col>
            </Row>
            <Row style={{ display: props?.mobileProps && handleDisplayControllers(props.mobileProps) }}>
                <Col style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                    <MonthOptions />
                </Col>
            </Row>
            {props.displayOption === 1 ? <MyLine /> : <MyBar />}
        </div>
    );
};

export default OneMonthView;
