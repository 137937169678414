import * as React from 'react';
import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import StyledButton from '../../Buttons/StyledButton';
import StyledCol from '../../Cols/StyledCol';
import { StyledFormLabelForInput } from '../../StyledForm/SyledForm';
import { StyledModal } from '../StyledModal/StyledModal';

export interface ICodeInputModalShow {
    show: boolean;
    modalTitle: string;
    modalText: string;
}

interface ICodeInputModal {
    show: boolean;
    modalTitle: string;
    modalText: string;
    onHide: () => void;
    onSubmit: (code: string) => void;
}

export const CodeInputModal = (props: ICodeInputModal) => {
    const [code, setCode] = useState('');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (event) {
            event.currentTarget.blur();
        }
        setCode('');
        props.onSubmit(code);
    };

    return (
        <StyledModal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">{props.modalTitle}</Modal.Title>
            </Modal.Header>
            <Form className="m-3" noValidate onSubmit={handleSubmit}>
                <Container fluid>
                    <Modal.Body>
                        <Row style={{ padding: 10 }}>
                            <Col>
                                <Form.Group controlId="formGroupCode">
                                    <StyledFormLabelForInput>{props.modalText}</StyledFormLabelForInput>
                                    <Form.Control
                                        autoFocus
                                        type="text"
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container className="mt-4">
                            <Row>
                                <StyledCol paddingLeft="0px" textAlign="left">
                                    <StyledButton variant="secondary" onClick={props.onHide}>
                                        Abbrechen
                                    </StyledButton>
                                </StyledCol>
                                <StyledCol paddingRight="0px" textAlign="right">
                                    <StyledButton type="submit">Absenden</StyledButton>
                                </StyledCol>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Container>
            </Form>
        </StyledModal>
    );
};

export default CodeInputModal;
