import Lottie from 'react-lottie';
import Mainscreen from '../../container/Mainscreen/Mainscreen';
import loadingSpinner from '../../lotties/loadingSpinner.json';
import loadingSpinnerWhite from '../../lotties/loadingSpinnerWhite.json';
import { VerticalCenterDiv } from '../Div/StyledDiv';

const lottieSpinnerOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingSpinner,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const lottieSpinnerWhiteOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingSpinnerWhite,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const LottieSpinner = () => {
    return <Lottie options={lottieSpinnerOptions} height={50} width={50} />;
};

export const LoadingSpinner = () => {
    return (
        <VerticalCenterDiv>
            <LottieSpinner />
        </VerticalCenterDiv>
    );
};

export const LoadingSpinnerInMainscreen = () => {
    return (
        <Mainscreen>
            <VerticalCenterDiv>
                <LottieSpinner />
            </VerticalCenterDiv>
        </Mainscreen>
    );
};

interface ILottieButtonLoadingSpinner {
    size: number;
}

export const LottieButtonLoadingSpinner = (props: ILottieButtonLoadingSpinner) => {
    return <Lottie options={lottieSpinnerWhiteOptions} height={props.size} width={props.size} />;
};
