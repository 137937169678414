import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { containerHeader } from '../../../components/Header/Header';
import VALTable from '../../../components/Tables/VAL/VALTable';
import { tables } from '../../../utils/chartUtils';
import Mainscreen from '../../Mainscreen/Mainscreen';

const TableVAL = () => {
    const [tableTitle, setTableTitle] = useState(tables.VALTBL.name);

    const handleSetTableTitle = (title: string) => {
        setTableTitle(title);
    };

    return (
        <Mainscreen>
            <Container fluid>
                <Row>
                    <Col>
                        {containerHeader(tableTitle)}
                        <Container id="VALTableParent" fluid>
                            <VALTable tableTitle={tableTitle} handleSetTableTitle={handleSetTableTitle} />
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Mainscreen>
    );
};

export default TableVAL;
