import moment from 'moment';
import { useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import useScreenResolution from '../../../hooks/useScreenResolution';
import {
    chartSettingsForDevice,
    getChartOptionsData,
    getDatasetData,
    IChartOptions,
    IChartProps,
} from '../../../utils/chartUtils';
import { ChartInnerDiv, ChartOuterDiv } from '../../Div/StyledDiv';

const MaxView = (props: IChartProps) => {
    const [xMin, setXMin] = useState(props.chartData.data[0].t);
    const [xMax, setXMax] = useState(moment().endOf('year').add(12, 'month'));

    const screenSize = useScreenResolution();

    const chartSettings = chartSettingsForDevice('max', screenSize.width, screenSize.height);

    const datasetProps = {
        chartProps: props,
        barPercentage: 2,
    };

    const [datasets, labelsToDisplay] = getDatasetData(datasetProps);
    const data = {
        labels: labelsToDisplay, // labels to be displayed by the datalabels.formatter
        datasets: datasets,
    };

    const chartOptionsProps: IChartOptions = {
        chartProps: props,
        ticksXMin: xMin,
        ticksXMax: xMax,
        chartSettings: chartSettings,
        updateLimits: (e) => updateLimits(e),
    };

    const options = getChartOptionsData(chartOptionsProps);

    function updateLimits(context: any) {
        if (!props.mobileProps?.sessionId) {
            setXMin(context.chart.scales['x-axis-0'].min);
            setXMax(context.chart.scales['x-axis-0'].max);
        }
    }

    const MyLine = () => {
        if (props.mobileProps?.height !== undefined) {
            return (
                <ChartOuterDiv style={{ height: props.mobileProps?.height }}>
                    <ChartInnerDiv>
                        <Line
                            redraw={true}
                            data={data}
                            options={options}
                            width={props.mobileProps?.width}
                            height={props.mobileProps?.height}
                        />
                    </ChartInnerDiv>
                </ChartOuterDiv>
            );
        } else {
            return (
                <ChartOuterDiv>
                    <ChartInnerDiv>
                        <Line redraw={true} data={data} options={options} height={chartSettings.height} />
                    </ChartInnerDiv>
                </ChartOuterDiv>
            );
        }
    };

    const MyBar = () => {
        if (props.mobileProps?.height !== undefined) {
            return (
                <ChartOuterDiv style={{ height: props.mobileProps?.height }}>
                    <ChartInnerDiv>
                        <Bar
                            redraw={true}
                            data={data}
                            options={options}
                            width={props.mobileProps?.width}
                            height={props.mobileProps?.height}
                        />
                    </ChartInnerDiv>
                </ChartOuterDiv>
            );
        } else {
            return (
                <ChartOuterDiv>
                    <ChartInnerDiv>
                        <Bar redraw={true} data={data} options={options} height={chartSettings.height} />
                    </ChartInnerDiv>
                </ChartOuterDiv>
            );
        }
    };

    return <div>{props.displayOption === 1 ? <MyLine /> : <MyBar />}</div>;
};

export default MaxView;
