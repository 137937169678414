import { useContext, useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../../Routes';
import AlertContext, { AlertOnHide, AlertType } from '../../../context/alertContext';
import { useHandleLogoutAfterIdleTime } from '../../../hooks/useHandleLogout';
import { resetShowAlertMessage } from '../../../utils/alertHandling';
import StyledButton from '../../Buttons/StyledButton';
import { StyledModal } from '../StyledModal/StyledModal';

const AlertModal = () => {
    const navigate = useNavigate();
    const { alertstate, alertdispatch } = useContext(AlertContext);
    const { handleLogoutAfterIdleTime } = useHandleLogoutAfterIdleTime();
    const [errorType, setErrorType] = useState('info');

    useEffect(() => {
        if (alertstate.alertMessage.alertType !== undefined) {
            if (alertstate.alertMessage.alertType === AlertType.info) {
                setErrorType('info');
            } else if (alertstate.alertMessage.alertType === AlertType.error) {
                setErrorType('danger');
            } else {
                setErrorType('info');
            }
        }
    }, [alertstate.alertMessage]);

    const onHideHandling = () => {
        resetShowAlertMessage(alertdispatch);

        if (
            alertstate.alertMessage.onHide === AlertOnHide.custom &&
            alertstate.alertMessage.onHideCustom !== undefined
        ) {
            alertstate.alertMessage.onHideCustom();
        } else {
            if (alertstate.alertMessage.onHide === AlertOnHide.onlyClose) {
                // do nothing
            } else {
                handleLogoutAfterIdleTime();

                if (alertstate.alertMessage.onHide === AlertOnHide.goToHome) {
                    navigate(Paths.HOME);
                } else if (alertstate.alertMessage.onHide === AlertOnHide.reload) {
                    window.location.reload();
                } else {
                    navigate(Paths.HOME);
                }
            }
        }
    };

    const getAlertBody = () => {
        if (alertstate.alertMessage.doBig) {
            return <p style={{ fontSize: '1.5rem' }}>{alertstate.alertMessage.alertTxt}</p>;
        }
        if (alertstate.alertMessage.doHTML) {
            return <div id="alertBody" dangerouslySetInnerHTML={{ __html: alertstate.alertMessage.alertTxt }} />;
        }
        return <p>{alertstate.alertMessage.alertTxt}</p>;
    };

    return (
        <StyledModal
            show={alertstate.alertMessage.showModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                {alertstate.alertMessage.doBig ? (
                    <Modal.Title style={{ fontSize: '2rem' }} id="contained-modal-title-vcenter">
                        {alertstate.alertMessage.alertTitle}
                    </Modal.Title>
                ) : (
                    <Modal.Title id="contained-modal-title-vcenter">{alertstate.alertMessage.alertTitle}</Modal.Title>
                )}
            </Modal.Header>
            <Modal.Body>
                <Alert variant={errorType}>{getAlertBody()}</Alert>
            </Modal.Body>
            <Modal.Footer>
                <StyledButton onClick={onHideHandling}>{alertstate.alertMessage.buttonName}</StyledButton>
            </Modal.Footer>
        </StyledModal>
    );
};

export default AlertModal;
