import { InitialQuestionnaireType, IQAnswers, IQBody, IQData, IQResource } from '../context/questionnaireContext';

type Action =
    | {
          type: 'SETQDATA';
          qData: IQData;
      }
    | {
          type: 'SETSELECTEDQUESTIONNAIRE';
          selectedQuestionnaire: {
              resource: IQResource;
          };
      }
    | {
          type: 'SETSELECTEDANSWERS';
          selectedAnswers: {
              item: IQAnswers[];
          };
      }
    | {
          type: 'SETQUESTIONNAIREBODY';
          questionnaireBody: {
              data: IQBody;
          };
      }
    | {
          type: 'SETEXTERNALREQUESTDATA';
          isExternalRequest: boolean;
          externalUserId: string;
          externalOTP: string;
          externalT0: string;
          externalBaseURL: string;
      }
    | {
          type: 'SETCALLINGPAGE';
          callingPage: string;
      }
    | {
          type: 'SETENCOUNTERID';
          encounterId: string;
      }
    | {
          type: 'RESETQUESTIONNAIRE';
      };

export const questionnaireReducer = (state: InitialQuestionnaireType, action: Action) => {
    switch (action.type) {
        case 'SETQDATA':
            return {
                ...state,
                qData: action.qData,
            };

        case 'SETSELECTEDQUESTIONNAIRE':
            return {
                ...state,
                selectedQuestionnaire: {
                    resource: action.selectedQuestionnaire.resource,
                },
            };

        case 'SETSELECTEDANSWERS':
            return {
                ...state,
                selectedAnswers: {
                    item: action.selectedAnswers.item,
                },
            };

        case 'SETQUESTIONNAIREBODY':
            return {
                ...state,
                questionnaireBody: {
                    data: action.questionnaireBody.data,
                },
            };

        case 'SETEXTERNALREQUESTDATA':
            return {
                ...state,
                isExternalRequest: action.isExternalRequest,
                externalUserId: action.externalUserId,
                externalOTP: action.externalOTP,
                externalT0: action.externalT0,
                externalBaseURL: action.externalBaseURL,
            };

        case 'SETCALLINGPAGE':
            return {
                ...state,
                callingPage: action.callingPage,
            };
        case 'SETENCOUNTERID':
            return {
                ...state,
                encounterId: action.encounterId,
            };
        case 'RESETQUESTIONNAIRE':
            return {
                ...state,
                qData: {
                    entry: [
                        {
                            resource: [
                                {
                                    id: '',
                                    item: [
                                        {
                                            item: [
                                                {
                                                    linkId: '',
                                                    text: '',
                                                    type: '',
                                                },
                                            ],
                                            linkId: '',
                                            text: '',
                                            type: '',
                                        },
                                    ],
                                    name: '',
                                    resourceType: '',
                                    title: '',
                                    extension: [
                                        {
                                            url: '',
                                            valueString: '',
                                            valueReference: {
                                                reference: '',
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    id: '',
                    resourceType: '',
                    type: '',
                },
                selectedQuestionnaire: {
                    resource: {
                        id: '',
                        item: [],
                        name: '',
                        resourceType: '',
                        title: '',
                        extension: [
                            {
                                url: '',
                                valueString: '',
                                valueReference: {
                                    reference: '',
                                },
                            },
                        ],
                    },
                },
                selectedAnswers: {
                    item: [],
                },
                questionnaireBody: {
                    data: {
                        authored: '',
                        context: {
                            reference: '',
                        },
                        id: '',
                        identifier: {
                            system: '',
                            value: '',
                        },
                        item: [],
                        meta: {
                            versionId: '',
                            lastUpdated: '',
                        },
                        questionnaire: {
                            reference: '',
                        },
                        resourceType: '',
                        source: {
                            reference: '',
                        },
                        status: '',
                        subject: {
                            reference: '',
                        },
                    },
                },
                isExternalRequest: false,
                externalUserId: '',
                externalOTP: '',
                externalT0: '',
                externalBaseURL: '',
                callingPage: '',
                encounterId: '',
            };

        default:
            return state;
    }
};
