import { createGlobalStyle } from 'styled-components';
import Inter from '../fonts/Inter.ttf';
import Roboto from '../fonts/Roboto-Regular.ttf';

const GlobalStyles = createGlobalStyle`
/*
    @font-face {
        font-family: 'Inter';
        src: local('Inter'), url(${Inter}) format('truetype');
    }
    @font-face {
        font-family: 'Roboto';
        src: local('Roboto'), url(${Roboto}) format('truetype');
    }
*/
    body {

    }

    @media screen and (max-width: 500px) {
        body {
            font-size: 0.8rem;
        }
    }

    .App {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Inter', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
            'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    @media screen and (max-width: 500px) {
        .MuiStepLabel-label {
            font-size: 13px !important;
        }

        .nav-link {
            padding: 4px;
        }
        
    }
    #page-wrap {
        text-align: center;
    }
`;

export default GlobalStyles;
