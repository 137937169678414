import moment from 'moment';
import { Nav } from 'react-bootstrap';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import useScreenResolution from '../../hooks/useScreenResolution';
import { getWeekDay } from '../../utils/dateUtils';
import { DateSwitcherTimeslotCol, DateSwitcherTimeslotColStartEnd } from '../Cols/StyledCol';
import { IPhysicianSelectionCardWithDateSwitcher } from '../PhysicianSelectionCard/PhysicianSelectionCardWithDateSwitcher';
import { DateSwitcherRow } from '../Rows/StyledRow';
import MyTooltip from '../Tooltip/MyTooltip';

type IHorizontalDateSwitcher = IPhysicianSelectionCardWithDateSwitcher;

const HorizontalDateSwitcher = (props: IHorizontalDateSwitcher) => {
    const screenSize = useScreenResolution();

    const showDate = (date: Date, offset: number) => {
        const myDate = moment(date).add(offset, 'days');
        const weekday = getWeekDay(myDate.toString(), 'dd');

        // return weekday;

        return (
            <div style={{ textAlign: 'center' }}>
                <div style={{ fontWeight: 'bold' }}>{weekday}</div>
                <div>{myDate.format('DD.MM.')}</div>
            </div>
        );
    };

    const dates = () => {
        const dates: any[] = [];

        for (let i = 0; i < props.countDaysToVisualize; i++) {
            dates.push(<DateSwitcherTimeslotCol key={i}>{showDate(props.startDate, i)}</DateSwitcherTimeslotCol>);
        }

        return dates;
    };

    return (
        <DateSwitcherRow>
            <DateSwitcherTimeslotColStartEnd>
                <Nav.Link style={{ padding: '0px' }} disabled={false} onClick={() => props.handlePreviousDate()}>
                    <MyTooltip
                        id="goBackTooltip"
                        text="Zurück"
                        iconDiv={true}
                        divWidth="30px"
                        marginLeft="auto"
                        marginRight="0"
                        icon={<RiArrowLeftSLine size={30} />}
                    ></MyTooltip>
                </Nav.Link>
            </DateSwitcherTimeslotColStartEnd>
            {dates()}
            <DateSwitcherTimeslotColStartEnd>
                <Nav.Item>
                    <Nav.Link style={{ padding: '0px' }} disabled={false} onClick={() => props.handleNextDate()}>
                        <MyTooltip
                            id="goForwardTooltip"
                            text="Weiter"
                            iconDiv={true}
                            divWidth="30px"
                            marginLeft="0"
                            marginRight="auto"
                            icon={<RiArrowRightSLine size={30} />}
                        ></MyTooltip>
                    </Nav.Link>
                </Nav.Item>
            </DateSwitcherTimeslotColStartEnd>
        </DateSwitcherRow>
    );
};

export default HorizontalDateSwitcher;
