import { useContext, useEffect, useState } from 'react';
import LoginContext from '../../../context/loginContext';
import { useChart } from '../../../hooks/useChart';
import { charts, IChartProps, IGetChartData, initalChartData } from '../../../utils/chartUtils';
import { IAppCharts } from '../AppCharts/AppCharts';
import ViewSwitcher from '../ChartViews/ViewSwitcher';

const BloodSugarCharts = (props: IAppCharts) => {
    const { state } = useContext(LoginContext);
    const { getChartData } = useChart({ ...props, chartId: charts.BLOODSUGAR.requestId, dataPrecisionVal: 2 });

    const [chartData, setChartData] = useState<IGetChartData>({
        chartLoaded: false,
        foundChartData: false,
        chartData: { ...initalChartData },
    });

    useEffect(() => {
        const getData = async () => {
            const data = await getChartData();
            setChartData(data);
        };

        getData();
    }, [state.activePatient]);

    const getData = () => {
        const data: IChartProps = {
            chartName: charts.BLOODSUGAR.id,
            mobileProps: props,
            displayOption: 1,
            chartOptions: chartData.chartData.chartOptions,
            chartData: chartData.chartData.chartData,
            targetBarrier: chartData.chartData.targetBarrier,
            targetArea: chartData.chartData.targetArea,
            targetCeiling: chartData.chartData.targetCeiling,
            chartViewLimits: chartData.chartData.chartViewLimits,
            labels: chartData.chartData.labels,
            labelOption: false,
            tooltipPrecisionVal: 1,
        };

        return data;
    };

    return (
        <div>
            <ViewSwitcher
                chartLoaded={chartData.chartLoaded}
                foundData={chartData.foundChartData}
                chartProps={getData()}
            />
        </div>
    );
};

export default BloodSugarCharts;
