import { useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/Form';
import { MdSend } from 'react-icons/md';
import { VerticalCenterDiv } from '../Div/StyledDiv';

interface IChatInput {
    handleUserMessage(userMsg: string): void;
}

const ChatInput = (props: IChatInput) => {
    const [msg, setMsg] = useState('');

    const sendMessage = () => {
        props.handleUserMessage(msg);
        setMsg('');
    };

    const handleEnter = (event: any) => {
        if (event.key === 'Enter') {
            sendMessage();
        }
    };

    return (
        <Form>
            <Form.Row>
                <Col xs={11}>
                    <Form.Control
                        placeholder="Nachricht senden..."
                        value={msg}
                        onChange={(e) => setMsg(e.target.value)}
                        onKeyDown={handleEnter}
                    ></Form.Control>
                </Col>
                <Col xs={1}>
                    <VerticalCenterDiv style={{ cursor: 'pointer' }}>
                        <MdSend size={25} color={'#007bff'} onClick={sendMessage} />
                    </VerticalCenterDiv>
                </Col>
            </Form.Row>
        </Form>
    );
};

export default ChatInput;
