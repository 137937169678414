import moment, { Moment } from 'moment';
import { useState } from 'react';
import * as React from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { Bar, Line } from 'react-chartjs-2';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import useScreenResolution from '../../../hooks/useScreenResolution';
import {
    chartSettingsForDevice,
    getChartOptionsData,
    getDatasetData,
    handleDisplayControllers,
    IChartOptions,
    IChartProps,
} from '../../../utils/chartUtils';
import { ToggleButtonGroupFlex } from '../../Buttons/ToggleButtonGroupCst';
import { ChartInnerDiv, ChartOuterDiv } from '../../Div/StyledDiv';

const OneWeekView = (props: IChartProps) => {
    // start with displaying the week with the latest data entry
    const [xMin, setXMin] = useState(
        moment(
            props.chartData.data[props.chartData.data.length - 1].t.clone().startOf('isoWeek').format('DD.MM.YYYY'),
            'DD.MM.YYYY',
        ),
    );
    const [xMax, setXMax] = useState(
        moment(
            props.chartData.data[props.chartData.data.length - 1].t.clone().endOf('isoWeek').format('DD.MM.YYYY'),
            'DD.MM.YYYY',
        ),
    );

    const screenSize = useScreenResolution();

    const chartSettings = chartSettingsForDevice('oneWeek', screenSize.width, screenSize.height);

    // start with button selection with the year, month and week from the latest data entry
    const [selectedYear, setSelectedYear] = useState(
        props.chartData.data[props.chartData.data.length - 1].t.clone().year(),
    );
    const [selectedMonth, setSelectedMonth] = useState(
        props.chartData.data[props.chartData.data.length - 1].t.clone().month(),
    );
    const [selectedWeekNumber, setSelectedWeekNumber] = useState(
        props.chartData.data[props.chartData.data.length - 1].t.clone().isoWeek(),
    );

    const scalesOffset = props?.mobileProps?.sessionId ? true : false;
    // const ticksMinRotation = props?.mobileProps?.sessionId ? 20 : 0;
    // const ticksMaxRotation = props?.mobileProps?.sessionId ? 20 : chartSettings.rotation;
    const ticksLabelOffset = props?.mobileProps?.sessionId ? 30 : 0;

    // get year of first and last weight data entry
    const firstYearOfData = props.chartData.data[0].t.year();
    const lastYearOfData = props.chartData.data[props.chartData.data.length - 1].t.year();

    // create a list of objects with the years and an index for the buttons
    const yearList: Array<{ name: number; value: number }> = [];
    let index = 0;
    for (let year = firstYearOfData; year <= lastYearOfData; year++) {
        yearList.push({ name: year, value: index });
        index++;
    }

    const monthsList = [
        { name: 'Januar', value: 0 },
        { name: 'Februar', value: 1 },
        { name: 'März', value: 2 },
        { name: 'April', value: 3 },
        { name: 'Mai', value: 4 },
        { name: 'Juni', value: 5 },
        { name: 'Juli', value: 6 },
        { name: 'August', value: 7 },
        { name: 'September', value: 8 },
        { name: 'Oktober', value: 9 },
        { name: 'November', value: 10 },
        { name: 'Dezember', value: 11 },
    ];

    const datasetProps = {
        chartProps: props,
        barPercentage: 5,
    };

    const [datasets, labelsToDisplay] = getDatasetData(datasetProps);
    const data = {
        labels: labelsToDisplay, // labels to be displayed by the datalabels.formatter
        datasets: datasets,
    };

    const chartOptionsProps: IChartOptions = {
        chartProps: props,
        ticksXMin: xMin,
        ticksXMax: xMax,
        scalesOffset: scalesOffset,
        ticksLabelOffset: ticksLabelOffset,
        // ticksMinRotation: ticksMinRotation,
        // ticksMaxRotation: ticksMaxRotation,
        chartSettings: chartSettings,
        updateLimits: (e) => updateLimits(e),
    };

    const options = getChartOptionsData(chartOptionsProps);

    function updateLimits(context: any) {
        if (!props.mobileProps?.sessionId) {
            setXMin(context.chart.scales['x-axis-0'].min);
            setXMax(context.chart.scales['x-axis-0'].max);
        }
    }

    const [radioValYear, setRadioValYear] = useState(yearList[yearList.length - 1].value);
    const [radioValMonth, setRadioValMonth] = useState(props.chartData.data[props.chartData.data.length - 1].t.month());

    const handleYearChange = (selectedYearVal: React.SetStateAction<number>) => {
        if (yearList !== undefined) {
            const yearName = yearList.find((i) => i.value === selectedYearVal)?.name;
            if (yearName !== undefined) {
                const selectedYearAndWeek = moment().year(yearName).week(selectedWeekNumber);
                setRadioValYear(selectedYearVal);
                setSelectedYear(yearList.find((i) => i.value === selectedYearVal)?.name);
                setSelectedMonth(selectedYearAndWeek.month());
                setRadioValMonth(selectedYearAndWeek.month());
                setXMin(moment(selectedYearAndWeek).startOf('isoWeek'));
                setXMax(moment(selectedYearAndWeek).endOf('isoWeek'));
            }
        }
    };

    const handleMonthChange = (selectedMonthVal: React.SetStateAction<number>) => {
        const selectedYearAndMonth = moment([selectedYear, Number(selectedMonthVal)]);
        setSelectedWeekNumber(moment(selectedYearAndMonth).isoWeek());
        setRadioValMonth(Number(selectedMonthVal));
        setSelectedMonth(selectedMonthVal);
        setXMin(moment(selectedYearAndMonth).startOf('isoWeek'));
        setXMax(moment(selectedYearAndMonth).endOf('isoWeek'));
    };

    function setValues(newDate: Moment) {
        setSelectedWeekNumber(newDate.isoWeek());
        setSelectedMonth(newDate.month());
        setRadioValMonth(newDate.month());
        setSelectedYear(newDate.year());
        if (yearList !== undefined) {
            const year = yearList.find((i) => i.name === newDate.year());
            if (year !== undefined) {
                setRadioValYear(year.value);
            }
        }
    }

    const handleWeekBack = () => {
        // jump one week back and get first and last dates of iso week
        const oneWeekBack_beginOfWeek = moment()
            .year(selectedYear)
            .week(selectedWeekNumber)
            .startOf('isoWeek')
            .subtract(1, 'week');
        const oneWeekBack_endOfWeek = moment()
            .year(selectedYear)
            .week(selectedWeekNumber)
            .endOf('isoWeek')
            .subtract(1, 'week');

        // set x-axis time limits left and right
        setXMin(oneWeekBack_beginOfWeek);
        setXMax(oneWeekBack_endOfWeek);

        // set all time hooks by passing beginning of week
        setValues(oneWeekBack_beginOfWeek);
    };

    const handleWeekForward = () => {
        // jump one week forward and get first and last dates of iso week
        const oneWeekForward_beginOfWeek = moment()
            .year(selectedYear)
            .week(selectedWeekNumber)
            .startOf('isoWeek')
            .add(1, 'week');
        const oneWeekForward_endOfWeek = moment()
            .year(selectedYear)
            .week(selectedWeekNumber)
            .endOf('isoWeek')
            .add(1, 'week');

        // set x-axis time limits left and right
        setXMin(oneWeekForward_beginOfWeek);
        setXMax(oneWeekForward_endOfWeek);

        // set all time hooks by passing beginning of week
        setValues(oneWeekForward_endOfWeek);
    };

    // returns year if one would step 1 week back in time
    const backwardCheck = () => {
        return moment().year(selectedYear).week(selectedWeekNumber).endOf('isoWeek').subtract(1, 'week').year();
    };

    // returns year of one would step 1 week forward in time
    const forwardCheck = () => {
        return moment().year(selectedYear).week(selectedWeekNumber).startOf('isoWeek').add(1, 'week').year();
    };

    const MonthOptions = () => {
        const allMonthOptions: any[] = [];
        {
            monthsList.map((month, idx) => {
                allMonthOptions.push(
                    <React.Fragment key={idx}>
                        <ToggleButton
                            id={'mob_month_' + month.name}
                            type="radio"
                            variant="light"
                            name="radio"
                            value={month.value}
                            checked={Number(radioValMonth) === month.value}
                            onChange={() => handleMonthChange(month.value)}
                        >
                            {month.name}
                        </ToggleButton>
                    </React.Fragment>,
                );
            });
        }

        return (
            <ToggleButtonGroupFlex
                id="mob_monthController"
                type="radio"
                name="monthOption"
                defaultValue={selectedMonth}
            >
                {allMonthOptions}
            </ToggleButtonGroupFlex>
        );
    };

    const YearOptions = () => {
        const allYearOptions: any[] = [];
        {
            yearList.map((year, idx) => {
                allYearOptions.push(
                    <React.Fragment key={idx}>
                        <ToggleButton
                            id={'mob_year_' + year.name}
                            type="radio"
                            variant="light"
                            name="radio"
                            value={year.value}
                            checked={radioValYear === year.value}
                            onChange={() => handleYearChange(year.value)}
                        >
                            {year.name}
                        </ToggleButton>
                    </React.Fragment>,
                );
            });
        }

        return (
            <ToggleButtonGroupFlex id="mob_yearController" type="radio" name="yearOption" defaultValue={radioValYear}>
                {allYearOptions}
            </ToggleButtonGroupFlex>
        );
    };

    const MyLine = () => {
        if (props.mobileProps?.height !== undefined) {
            return (
                <ChartOuterDiv style={{ height: props.mobileProps?.height }}>
                    <ChartInnerDiv>
                        <Line
                            redraw={true}
                            data={data}
                            options={options}
                            width={props.mobileProps?.width}
                            height={props.mobileProps?.height}
                        />
                    </ChartInnerDiv>
                </ChartOuterDiv>
            );
        } else {
            return (
                <ChartOuterDiv>
                    <ChartInnerDiv>
                        <Line redraw={true} data={data} options={options} height={chartSettings.height} />
                    </ChartInnerDiv>
                </ChartOuterDiv>
            );
        }
    };

    const MyBar = () => {
        if (props.mobileProps?.height !== undefined) {
            return (
                <ChartOuterDiv style={{ height: props.mobileProps?.height }}>
                    <ChartInnerDiv>
                        <Bar
                            redraw={true}
                            data={data}
                            options={options}
                            width={props.mobileProps?.width}
                            height={props.mobileProps?.height}
                        />
                    </ChartInnerDiv>
                </ChartOuterDiv>
            );
        } else {
            return (
                <ChartOuterDiv>
                    <ChartInnerDiv>
                        <Bar redraw={true} data={data} options={options} height={chartSettings.height} />
                    </ChartInnerDiv>
                </ChartOuterDiv>
            );
        }
    };

    return (
        <div>
            <Row style={{ display: props?.mobileProps && handleDisplayControllers(props.mobileProps) }}>
                <Col>
                    <YearOptions />
                </Col>
            </Row>
            <Row style={{ display: props?.mobileProps && handleDisplayControllers(props.mobileProps) }}>
                <Col style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                    <MonthOptions />
                </Col>
            </Row>
            <Row style={{ display: props.mobileProps ? handleDisplayControllers(props.mobileProps) : true }}>
                <Col>
                    <h3>
                        <Button
                            variant="light"
                            onClick={() => handleWeekBack()}
                            disabled={firstYearOfData > backwardCheck()}
                        >
                            <IoIosArrowBack />
                        </Button>
                        <Badge variant="light">Woche {selectedWeekNumber}</Badge>
                        <Button
                            variant="light"
                            onClick={() => handleWeekForward()}
                            disabled={lastYearOfData < forwardCheck()}
                        >
                            <IoIosArrowForward />
                        </Button>
                    </h3>
                </Col>
            </Row>
            {props.displayOption === 1 ? <MyLine /> : <MyBar />}
        </div>
    );
};

export default OneWeekView;
