import { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import { FaChartPie, FaWeight } from 'react-icons/fa';
import { GiLiver } from 'react-icons/gi';
import { ImDroplet, ImTable } from 'react-icons/im';
import { RiFootprintFill } from 'react-icons/ri';
import StyledCardContent from '../../components/Cards/StyledCardContent';
import { containerHeader } from '../../components/Header/Header';
import LoginContext from '../../context/loginContext';
import { Paths } from '../../Routes';
import { charts, tables } from '../../utils/chartUtils';
import Mainscreen from '../Mainscreen/Mainscreen';

const ChartsContent = () => {
    const { state } = useContext(LoginContext);
    const [chartsOrder, setChartsOrder] = useState([
        charts.WEIGHT.id,
        charts.ACTIVITY.id,
        charts.BLOODSUGAR.id,
        charts.FATMASS.id,
        charts.SAM.id,
        charts.FLI.id,
        tables.VALTBL.id,
    ]);

    // const {
    //     isChartsWeightEnabled,
    //     isChartsBodyFatEnabled,
    //     isChartsBloodSugarEnabled,
    //     isChartsFLIEnabled,
    //     isChartsSAMEnabled,
    //     isChartsActivityEnabled,
    // } = useSecurityItems();

    useEffect(() => {
        if (state.finalSecurityItemsOrder.length > 0) {
            setChartsOrder(state.finalSecurityItemsOrder);
        }
    }, [state.finalSecurityItemsOrder]);

    const showChartItems = () => {
        const allChartItems: any[] = [];

        chartsOrder.forEach((e) => {
            if (!e.startsWith('CHARTS:')) {
                return;
            }

            let route = '';
            let title = '';
            let icon = <ImDroplet size={80} />;

            if (e === charts.WEIGHT.id) {
                route = Paths.CHARTS_GEWICHT;
                title = charts.WEIGHT.name;
                icon = <FaWeight size={80} />;
            } else if (e === charts.ACTIVITY.id) {
                route = Paths.CHARTS_ACTIVITY;
                title = charts.ACTIVITY.name;
                icon = <RiFootprintFill size={80} />;
            } else if (e === charts.BLOODSUGAR.id) {
                route = Paths.CHARTS_BLUTZUCKER;
                title = charts.BLOODSUGAR.name;
                icon = <ImDroplet size={80} />;
            } else if (e === charts.FATMASS.id) {
                route = Paths.CHARTS_FETTMASSE;
                title = charts.FATMASS.name;
                icon = <FaChartPie size={80} />;
            } else if (e === charts.SAM.id) {
                route = Paths.CHARTS_SAM;
                title = charts.SAM.name;
                icon = <FaChartPie size={80} />;
            } else if (e === charts.FLI.id) {
                route = Paths.CHARTS_FLI;
                title = charts.FLI.name;
                icon = <GiLiver size={80} />;
            } else if (e === tables.VALTBL.id) {
                route = Paths.TABLE_VAL;
                title = tables.VALTBL.name;
                icon = <ImTable size={80} />;
            }

            allChartItems.push(
                <StyledCardContent key={e} type="link" route={route} title={title} icon={icon}></StyledCardContent>,
            );
        });

        return allChartItems;
    };

    return (
        <div>
            <Nav className="justify-content-center">
                <Row className="justify-content-md-center">{showChartItems()}</Row>
            </Nav>
        </div>

        // <div>
        //     <Nav className="justify-content-center">
        //         <Row className="justify-content-md-center">
        //             {isChartsWeightEnabled() && (
        //                 <StyledCardContent
        //                     type="link"
        //                     route={Paths.CHARTS_GEWICHT}
        //                     title={charts.WEIGHT.name}
        //                     icon={<FaWeight size={80} />}
        //                 ></StyledCardContent>
        //             )}
        //             {isChartsBodyFatEnabled() && (
        //                 <StyledCardContent
        //                     type="link"
        //                     route={Paths.CHARTS_FETTMASSE}
        //                     title={charts.FATMASS.name}
        //                     icon={<FaChartPie size={80} />}
        //                 ></StyledCardContent>
        //             )}
        //             {isChartsBloodSugarEnabled() && (
        //                 <StyledCardContent
        //                     type="link"
        //                     route={Paths.CHARTS_BLUTZUCKER}
        //                     title={charts.BLOODSUGAR.name}
        //                     icon={<ImDroplet size={80} />}
        //                 ></StyledCardContent>
        //             )}
        //             {isChartsFLIEnabled() && (
        //                 <StyledCardContent
        //                     type="link"
        //                     route={Paths.CHARTS_FLI}
        //                     title={charts.FLI.name}
        //                     icon={<ImDroplet size={80} />}
        //                 ></StyledCardContent>
        //             )}
        //             {isChartsActivityEnabled() && (
        //                 <StyledCardContent
        //                     type="link"
        //                     route={Paths.CHARTS_ACTIVITY}
        //                     title={charts.ACTIVITY.name}
        //                     icon={<RiFootprintFill size={80} />}
        //                 ></StyledCardContent>
        //             )}
        //             {isChartsSAMEnabled() && (
        //                 <StyledCardContent
        //                     type="link"
        //                     route={Paths.CHARTS_SAM}
        //                     title={charts.SAM.name}
        //                     icon={<ImDroplet size={80} />}
        //                 ></StyledCardContent>
        //             )}
        //         </Row>
        //     </Nav>
        // </div>
    );
};

const Charts = () => {
    return (
        <Mainscreen>
            <Container fluid>
                <Row>
                    <Col>
                        {containerHeader('Charts')}
                        <ChartsContent />
                    </Col>
                </Row>
            </Container>
        </Mainscreen>
    );
};

export default Charts;
