import { Paths } from '../Routes';

export const getHashParam = (hashLocation: string) => {
    const hashSub1 = hashLocation.substring(0, 1);
    const hashSub2 = hashLocation.substring(0, 2);
    let hash = '';

    if (hashSub2 === '#/') {
        hash = hashLocation.replace(hashLocation.substring(0, 2), '');
    } else if (hashSub1 === '#') {
        hash = hashLocation.replace(hashLocation.substring(0, 1), '');
    } else {
        hash = hashLocation;
    }

    const result = hash.split('&').reduce(function (result: any, item: string) {
        const parts = item.split('=');
        result[parts[0]] = parts[1];
        return result;
    }, {});

    return result;
};

export const getQueryParams = (url: string) => {
    const regex = /[?&]([^=#]+)=([^&#]*)/g;
    const params: any = {};
    let match: any;

    while ((match = regex.exec(url))) {
        if (!isNaN(match[2])) {
            params[match[1]] = parseInt(match[2]);
        } else if (match[2] === 'true' || match[2] === 'false') {
            params[match[1]] = match[2] === 'true';
        } else {
            params[match[1]] = match[2];
        }
    }

    return params;
};

export const getCurrentBaseURL = (currentPathName: string) => {
    const completeUrl = window.location.href;
    const baseUrl = completeUrl.split(currentPathName)[0];
    return baseUrl;
};

export const getTermincodeEnrollIdURL = (baseUrl: string, enrollId: string) => {
    return baseUrl + Paths.TERMINCODE + '#enrollId=' + enrollId;
};

export const addParamToURL = (url: string, key: string, value: string): string => {
    let questChar = '?';
    let andChar = '&';

    if (url.includes('?')) {
        questChar = '';
    } else {
        andChar = '';
    }

    url = url + questChar + andChar + key + '=' + value;

    return url;
};
