import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Mainscreen from '../../Mainscreen/Mainscreen';
import { containerHeader } from '../../../components/Header/Header';
import WeightCharts from '../../../components/Charts/Weight/WeightCharts';

const ChartsGewichtContent = () => {
    return (
        <Container fluid>
            <WeightCharts />
        </Container>
    );
};

const ChartsGewicht = () => {
    return (
        <Mainscreen>
            <Container fluid>
                <Row>
                    <Col>
                        {containerHeader('Gewicht')}
                        <ChartsGewichtContent />
                    </Col>
                </Row>
            </Container>
        </Mainscreen>
    );
};

export default ChartsGewicht;
