import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import FLICharts from '../../../components/Charts/FLI/FLICharts';
import { containerHeader } from '../../../components/Header/Header';
import Mainscreen from '../../Mainscreen/Mainscreen';

const ChartsFLIContent = () => {
    return (
        <Container fluid>
            <FLICharts />
        </Container>
    );
};

const ChartsFLI = () => {
    return (
        <Mainscreen>
            <Container fluid>
                <Row>
                    <Col>
                        {containerHeader('FLI')}
                        <ChartsFLIContent />
                    </Col>
                </Row>
            </Container>
        </Mainscreen>
    );
};

export default ChartsFLI;
