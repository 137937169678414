import { Card, Col, Row } from 'react-bootstrap';
import HorizontalDateSwitcher from '../Calendar/HorizontalDateSwitcher';
import { StyledPhysicianSelectionCard } from '../Cards/StyledCard';
import { DateCol, DatePlaceholderCol } from '../Cols/StyledCol';

export interface IPhysicianSelectionCardWithDateSwitcher {
    startDate: Date;
    handlePreviousDate(): void;
    handleNextDate(): void;
    countDaysToVisualize: number;
}

const PhysicianSelectionCardWithDateSwitcher = (props: IPhysicianSelectionCardWithDateSwitcher) => {
    return (
        <StyledPhysicianSelectionCard /*height={'100px'} */>
            <Card.Body style={{ textAlign: 'left' }}>
                <Row>
                    <Col
                        md={2}
                        sm={4}
                        style={{ marginRight: '20px', width: '160px', flexBasis: '160px', minWidth: '160px' }}
                    ></Col>
                    <DatePlaceholderCol md={3} sm={3}></DatePlaceholderCol>
                    <DateCol>
                        <HorizontalDateSwitcher
                            startDate={props.startDate}
                            handlePreviousDate={props.handlePreviousDate}
                            handleNextDate={props.handleNextDate}
                            countDaysToVisualize={props.countDaysToVisualize}
                        />
                    </DateCol>
                </Row>
            </Card.Body>
        </StyledPhysicianSelectionCard>
    );
};

export default PhysicianSelectionCardWithDateSwitcher;
