import { useContext } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';
import { ListGroupCst, ListGroupItemCst, StyledProfileCard } from '../../components/Cards/StyledCard';
import AppPropsContext from '../../context/appPropsContext';
import TerminierungContext from '../../context/terminierungContext';
import { getGenderDE } from '../../utils/pcpUtils';

interface IPersonalDataSummary {
    width?: string;
}

const PersonalDataSummary = (props: IPersonalDataSummary) => {
    const { tmstate } = useContext(TerminierungContext);
    const { apstate } = useContext(AppPropsContext);

    interface IDataItem {
        label: string;
        data: string;
    }

    const DataItem = (props: IDataItem) => {
        return (
            <ListGroupItemCst>
                <Row>
                    <Col sm={4}>{props.label}</Col>
                    <Col sm={8}>
                        <strong>{props.data}</strong>
                    </Col>
                </Row>
            </ListGroupItemCst>
        );
    };

    const getPlannedStudiesList = () => {
        let studyList = '';

        if (tmstate.personalData.plannedStudies.length > 0) {
            for (let i = 0; i < tmstate.personalData.plannedStudies.length; i++) {
                if (tmstate.personalData.plannedStudies[i].participate === true) {
                    if (i === 0) {
                        studyList = studyList + tmstate.personalData.plannedStudies[i].display;
                    } else {
                        studyList = studyList + ', ' + tmstate.personalData.plannedStudies[i].display;
                    }
                }
            }
        }

        return studyList;
    };

    return (
        <StyledProfileCard
            style={{ width: props.width ? props.width : '100%', marginBottom: '10px', marginTop: '0px' }}
        >
            <Card.Body style={{ textAlign: 'left' }}>
                <Card.Title>Ihre persönlichen Daten</Card.Title>
                <Row>
                    <Col style={{ padding: 5 }}>
                        <Row>
                            <Container>
                                <Row>
                                    <Col>
                                        <ListGroupCst variant="flush">
                                            {apstate.RegistrationVisibleFields.academicTitle === true && (
                                                <DataItem label="Titel" data={tmstate.personalData.academicTitle} />
                                            )}
                                            {apstate.RegistrationVisibleFields.firstName === true && (
                                                <DataItem label="Vorname" data={tmstate.personalData.firstName} />
                                            )}
                                            {apstate.RegistrationVisibleFields.lastName === true && (
                                                <DataItem label="Nachname" data={tmstate.personalData.lastName} />
                                            )}
                                            {apstate.RegistrationVisibleFields.birthDate === true && (
                                                <DataItem label="Geburtstag" data={tmstate.personalData.birthday} />
                                            )}
                                            {apstate.RegistrationVisibleFields.gender === true && (
                                                <DataItem
                                                    label="Geschlecht"
                                                    data={getGenderDE(tmstate.personalData.gender)}
                                                />
                                            )}
                                            {apstate.RegistrationVisibleFields.phone === true && (
                                                <DataItem label="Telefonnummer" data={tmstate.personalData.telephone} />
                                            )}
                                            {apstate.RegistrationVisibleFields.mobilePhone === true && (
                                                <DataItem
                                                    label="Mobil-Telefonnummer"
                                                    data={tmstate.personalData.mobilePhone}
                                                />
                                            )}
                                            {apstate.RegistrationVisibleFields.email === true && (
                                                <DataItem label="E-Mail" data={tmstate.personalData.email} />
                                            )}
                                            {apstate.RegistrationVisibleFields.zip === true && (
                                                <DataItem label="Postleitzahl" data={tmstate.personalData.zip} />
                                            )}
                                            {apstate.RegistrationVisibleFields.city === true && (
                                                <DataItem label="Ort" data={tmstate.personalData.city} />
                                            )}
                                            {apstate.RegistrationVisibleFields.street === true && (
                                                <DataItem label="Straße" data={tmstate.personalData.street} />
                                            )}
                                            {apstate.RegistrationVisibleFields.street === true && (
                                                <DataItem label="Hausnummer" data={tmstate.personalData.housenr} />
                                            )}
                                            {apstate.RegistrationVisibleFields.country === true && (
                                                <DataItem label="Land" data={tmstate.personalData.country} />
                                            )}
                                            {apstate.RegistrationVisibleFields.insuranceNr === true && (
                                                <DataItem
                                                    label="Versichertennr."
                                                    data={tmstate.personalData.insuranceNr}
                                                />
                                            )}
                                            {apstate.RegistrationVisibleFields.insuranceName === true && (
                                                <DataItem
                                                    label="Krankenkasse"
                                                    data={tmstate.personalData.insuranceName}
                                                />
                                            )}
                                            {apstate.RegistrationVisibleFields.language === true && (
                                                <DataItem label="Sprache" data={tmstate.personalData.language} />
                                            )}
                                            {tmstate.personalData.plannedStudies.length > 0 && (
                                                <DataItem
                                                    label="Gewünschte Studienteilnahmen"
                                                    data={getPlannedStudiesList()}
                                                />
                                            )}
                                        </ListGroupCst>
                                    </Col>
                                </Row>
                            </Container>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </StyledProfileCard>
    );
};

export default PersonalDataSummary;
