import Row from 'react-bootstrap/Row';
import TabContent from 'react-bootstrap/TabContent';
import styled from 'styled-components';

export const StyledTabRow = styled(Row)`
    background-color: white;
    border-radius: 50px 50px 0px 0px;
    padding: 5px;
`;

export const StyledTabContent = styled(TabContent)`
    background-color: white;
    width: 100%;
    margin: 0 auto;
`;

export const FixedTabsHeader = styled.div`
    overflow: hidden;
    position: fixed;
    width: 80%;
    margin-top: 45px;
    background-color: ${(props) => props.theme.mainColor};
    z-index: 10;
    align-content: center;
`;
