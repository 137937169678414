import 'chartjs-plugin-annotation';
import moment from 'moment';
import { useCallback, useContext } from 'react';
import { IAppCharts } from '../components/Charts/AppCharts/AppCharts';
import AlertContext from '../context/alertContext';
import LoginContext from '../context/loginContext';
import { getDiagnosticReportsByCategory } from '../services/fhir/FHIRDiagnosticReports';
import { charts, IGetChartData, initalChartData, transformAndSortDates } from '../utils/chartUtils';

interface IUseCharts extends IAppCharts {
    chartId: string;
}

export const useChart = (props: IUseCharts) => {
    const { state } = useContext(LoginContext);
    const { alertdispatch } = useContext(AlertContext);

    const getChartData = useCallback(async () => {
        let dataPrecision = 1;
        if (props.dataPrecisionVal !== undefined) {
            dataPrecision = props.dataPrecisionVal;
        }

        const data: IGetChartData = {
            chartLoaded: false,
            foundChartData: false,
            chartData: { ...initalChartData },
        };

        const userData: any = {};
        let response;
        if (props.sessionId) {
            userData.sessionId = props.sessionId;
            userData.activeUserId = props.activeUserId;
            response = await getDiagnosticReportsByCategory(userData, props.chartId, alertdispatch);
        } else {
            response = await getDiagnosticReportsByCategory(state, props.chartId, alertdispatch);
        }
        data.chartLoaded = true;
        if (response) {
            let foundDataEntry = false;

            if (response.data?.entry) {
                foundDataEntry = true;
            }

            if (foundDataEntry) {
                const dataString = response.data.entry[0].resource.presentedForm[0].extension[0].valueString;
                const jsonRes = JSON.parse(dataString);

                // convert dates (string) into moment objects and sort chronologically
                const sortedJSON = transformAndSortDates(jsonRes);

                const labelsArray: any[] = [];
                let tlind = 1;
                let foundData = false;
                for (let i = 0; i < sortedJSON.data.datasets.length; i++) {
                    if (
                        sortedJSON.data.datasets[i].TYPE === 'Line' ||
                        sortedJSON.data.datasets[i].TYPE === 'Day bars' ||
                        sortedJSON.data.datasets[i].TYPE === 'MultiLine'
                    ) {
                        for (let j = 0; j < jsonRes.data.datasets[i].data.length; j++) {
                            const origDataVal: number = jsonRes.data.datasets[i].data[j].y;
                            const dataValPrec: string = origDataVal.toFixed(dataPrecision);
                            labelsArray.push(dataValPrec);
                        }
                        data.chartData.labels = labelsArray;
                        data.chartData.chartData = sortedJSON.data.datasets[i];

                        if (jsonRes.data?.datasets[i]?.data[jsonRes.data.datasets[i].data.length - 1]?.t) {
                            data.foundChartData = true;
                            foundData = true;
                        }

                        if (foundData) {
                            data.chartData.chartViewLimits = {
                                fiveYears: sortedJSON.data.datasets[i].data[
                                    sortedJSON.data.datasets[i].data.length - 1
                                ].t
                                    .clone()
                                    .subtract(4, 'years'),
                                oneYear: sortedJSON.data.datasets[i].data[sortedJSON.data.datasets[i].data.length - 1].t
                                    .clone()
                                    .subtract(1, 'years'),
                                oneMonth: sortedJSON.data.datasets[i].data[
                                    sortedJSON.data.datasets[i].data.length - 1
                                ].t
                                    .clone()
                                    .subtract(1, 'months'),
                                oneWeek: sortedJSON.data.datasets[i].data[sortedJSON.data.datasets[i].data.length - 1].t
                                    .clone()
                                    .subtract(1, 'week'),
                            };
                        }
                    } else if (sortedJSON.data.datasets[i].TYPE === 'TargetLine') {
                        const lastTargetWeight =
                            sortedJSON.data.datasets[i].data[sortedJSON.data.datasets[i].data.length - 1].y;
                        const today = moment();
                        sortedJSON.data.datasets[i].data.push({
                            t: today.add(4, 'months'),
                            y: lastTargetWeight,
                        });
                        if (tlind === 1) {
                            data.chartData.targetChart1 = sortedJSON.data.datasets[i];
                            tlind++;
                        } else if (tlind === 2) {
                            data.chartData.targetChart2 = sortedJSON.data.datasets[i];
                            tlind++;
                        } else if (tlind === 3) {
                            data.chartData.targetChart3 = sortedJSON.data.datasets[i];
                            tlind++;
                        }
                    }

                    /* specific chart options */
                    if (props.chartId === charts.FLI.requestId) {
                        if (sortedJSON.data.datasets[i].TYPE === 'TargetBarrier') {
                            data.chartData.targetBarrier = sortedJSON.data.datasets[i];
                        }
                        if (sortedJSON.data.datasets[i].TYPE === 'TargetArea') {
                            data.chartData.targetArea = sortedJSON.data.datasets[i];
                        }
                    }
                }

                data.chartData.chartOptions = jsonRes.options;
            }
        }

        return data;
    }, []);

    const getTableData = useCallback(async () => {
        const response = await getDiagnosticReportsByCategory(state, props.chartId, alertdispatch);
        if (response) {
            return response;
        }

        return 'FAILURE';
    }, []);

    return {
        getChartData,
        getTableData,
    };
};
