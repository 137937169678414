import { useEffect, useRef, useState } from 'react';
import { ChatMessagesContainer } from './ChatMessagesContainer';
import { IncomingMessage, OutgoingMessage } from './Message';

export enum MessageType {
    Incoming,
    Outgoing,
}

export interface IChatMessages {
    chatContainerHeight: string;
    messages: IChatMessage[];
}

export interface IMessage {
    txt: string;
    time: string;
}

export interface IChatMessage extends IMessage {
    type: MessageType;
}

const ChatMessages = (props: IChatMessages) => {
    const messageBottomRef = useRef<any>(null);
    const [allMsg, setAllMsg] = useState<IChatMessage[]>([]);

    useEffect(() => {
        displayAllMessages();
    }, [props.messages]);

    useEffect(() => {
        messageBottomRef.current.scrollIntoView({ block: 'nearest' });
    }, [allMsg]);

    const displayAllMessages = () => {
        const allMessages: any[] = [];

        props.messages.forEach((message: IChatMessage) => {
            if (message.type === MessageType.Outgoing) {
                allMessages.push(<OutgoingMessage txt={message.txt} time={message.time} />);
            } else if (message.type === MessageType.Incoming) {
                allMessages.push(<IncomingMessage txt={message.txt} time={message.time} />);
            }
        });

        setAllMsg(allMessages);
    };

    return (
        <ChatMessagesContainer height={props.chatContainerHeight}>
            {allMsg}
            <div id="dumyRef" ref={messageBottomRef}></div>
        </ChatMessagesContainer>
    );
};

export default ChatMessages;
