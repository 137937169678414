import Nav from 'react-bootstrap/Nav';
import SidebarItemList from '../../components/SidebarItems/SidebarItemList';
import { StyledNavbar } from './StyledNavbar';

const NavbarBottom = () => {
    return (
        <StyledNavbar fixed="bottom" style={{ paddingLeft: '5px', paddingRight: '5px' }}>
            <Nav style={{ alignItems: 'center', width: '100%' }}>
                {/* <Nav>Test1</Nav>
                <Nav.Link>Test1</Nav.Link>
                <Nav.Link>Test1</Nav.Link> */}
                <SidebarItemList />
            </Nav>
        </StyledNavbar>
    );
};

export default NavbarBottom;
