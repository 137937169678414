import { InitialDokumenteType } from '../context/dokumenteContext';
import { IFile } from '../utils/fileUtils';

type Action =
    | {
          type: 'SETFILEDATA';
          filedata: IFile;
      }
    | {
          type: 'SETUPLOADED';
          filedata: IFile;
      }
    | {
          type: 'RESETFILEDATA';
          filedata: IFile;
      };

export const dokumenteReducer = (state: InitialDokumenteType, action: Action) => {
    switch (action.type) {
        case 'SETFILEDATA':
            return {
                ...state,
                filedata: {
                    name: action.filedata.name,
                    lastModified: action.filedata.lastModified,
                    lastModifiedDate: action.filedata.lastModifiedDate,
                    size: action.filedata.size,
                    type: action.filedata.type,
                    data: action.filedata.data,
                    title: action.filedata.title,
                    conclusion: action.filedata.conclusion,
                    uploaded: action.filedata.uploaded,
                },
            };
        case 'SETUPLOADED':
            return {
                ...state,
                filedata: {
                    ...state.filedata,
                    uploaded: true,
                },
            };
        case 'RESETFILEDATA':
            return {
                ...state,
                filedata: {
                    name: '',
                    lastModified: 0,
                    lastModifiedDate: new Date(),
                    size: 0,
                    type: '',
                    data: '',
                    title: '',
                    conclusion: '',
                    uploaded: false,
                },
            };

        default:
            return state;
    }
};
