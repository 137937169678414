import { useContext, useEffect, useState } from 'react';
import { IDisplayAppointments } from '../../container/Termine/Termine';
import AlertContext from '../../context/alertContext';
import AppPropsContext from '../../context/appPropsContext';
import LoginContext from '../../context/loginContext';
import TerminierungContext from '../../context/terminierungContext';
import useScreenResolution from '../../hooks/useScreenResolution';
import { getAppointmentsOfPatient, structureAppointments } from '../../services/fhir/FHIRAppointment';
import { IAppointment, getStickyPosition } from '../../utils/appointmentUtils';
import AppointmentCard from '../Cards/AppointmentCard';
import { StickyTitle } from './StyledHeader';

enum Scheduled {
    Today = 0,
    Future,
    WithoutTime,
    Incomplete,
}

export const UpcomingAppointments = (props: IDisplayAppointments) => {
    const { state } = useContext(LoginContext);
    const { apstate } = useContext(AppPropsContext);
    const { alertdispatch } = useContext(AlertContext);
    const { tmstate, tmdispatch } = useContext(TerminierungContext);

    const [structuredAppointments, setStructuredAppointments] = useState<any[]>();

    const screenSize = useScreenResolution();
    const stickyPosition = getStickyPosition(screenSize.width);

    const getAppointments = async () => {
        const response = await getAppointmentsOfPatient(state, alertdispatch);
        if (response) {
            let apt: IAppointment[] = response.data.entry;

            if (apt === undefined) {
                apt = [];
            }

            if (apt) {
                apt.sort((a, b) => (a.resource.start > b.resource.start ? 1 : -1));
                setStructuredAppointments(structureAppointments(apt));

                if (props.checkInVariant) {
                    let currentApt: IAppointment | undefined = undefined;
                    if (tmstate?.enrollData?.enrollCode) {
                        const bestEnrollCode = tmstate.enrollData.enrollCode;
                        currentApt = apt.find((a) => {
                            if (a.resource?.extension) {
                                const extensionData = a.resource.extension;
                                const enrollCodeData = extensionData.find((ext) => {
                                    if (ext.url && ext.url.endsWith('enrollCode')) {
                                        return true;
                                    }
                                });
                                if (enrollCodeData.valueString == bestEnrollCode) {
                                    return true;
                                }
                            }
                        });
                    }
                    if (!currentApt) {
                        const extensionData = apt[0]?.resource?.extension;
                        if (extensionData) {
                            const enrollCodeData = extensionData.find((ext) => {
                                if (ext.url && ext.url.endsWith('enrollCode')) {
                                    return true;
                                }
                            });
                            if (enrollCodeData) {
                                tmdispatch({
                                    type: 'SETENROLLDATA',
                                    enrollData: {
                                        ...tmstate.enrollData,
                                        enrollCode: enrollCodeData.valueString,
                                    },
                                });
                            }
                        }
                    }
                }
            }
        }
    };

    function UpcomingAppointmentsTitle(category: Scheduled) {
        if (structuredAppointments !== undefined) {
            if (category === Scheduled.Incomplete) {
                if (structuredAppointments[0][category].data.length <= 0) {
                    return <></>;
                }
            }
            return (
                <StickyTitle stickyBreakpoint={stickyPosition}>
                    {stickyPosition === '120px' ? (
                        <h4>{structuredAppointments[0][category].key}</h4>
                    ) : (
                        <h3>{structuredAppointments[0][category].key}</h3>
                    )}
                </StickyTitle>
            );
        }
        return <></>;
    }

    function UpcomingAppointmentsData(category: Scheduled) {
        let chatIcon = false;
        if (category === Scheduled.Today && apstate.allowChat) {
            chatIcon = true;
        }
        if (structuredAppointments !== undefined) {
            if (category === Scheduled.Incomplete) {
                if (structuredAppointments[0][category].data.length <= 0) {
                    return <></>;
                }
            }
            const upcomingAppointments = structuredAppointments[0][category].data.map(
                (data: IAppointment, index: number) => (
                    <div key={index}>
                        <div style={{ width: 'auto', height: 'auto', zIndex: 0 }}>
                            <AppointmentCard {...data} type="upcoming" showChatIcon={chatIcon} />
                        </div>
                    </div>
                ),
            );
            return upcomingAppointments;
        }
        return <></>;
    }

    useEffect(() => {
        getAppointments();

        tmdispatch({
            type: 'CANCELEDAPPOINTMENT',
            canceledAppointment: false,
        });
    }, [state.activeProfile, tmstate.canceledAppointment === true, tmstate.cancelNewAppointmentData.showModal]);

    useEffect(() => {
        if (props.refreshAppointments) {
            getAppointments();

            tmdispatch({
                type: 'CANCELEDAPPOINTMENT',
                canceledAppointment: false,
            });

            props.resetRefresh();
        }
    }, [props.refreshAppointments]);

    return (
        <div>
            {UpcomingAppointmentsTitle(Scheduled.Incomplete)}
            {UpcomingAppointmentsData(Scheduled.Incomplete)}
            {UpcomingAppointmentsTitle(Scheduled.Today)}
            {UpcomingAppointmentsData(Scheduled.Today)}
            {UpcomingAppointmentsTitle(Scheduled.Future)}
            {UpcomingAppointmentsData(Scheduled.Future)}
            {UpcomingAppointmentsTitle(Scheduled.WithoutTime)}
            {UpcomingAppointmentsData(Scheduled.WithoutTime)}
        </div>
    );
};

export default UpcomingAppointments;
