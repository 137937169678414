import { useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import TerminierungContext from '../../context/terminierungContext';

const EarlierAppointmentPossible = () => {
    const [cbxValue, setCbxValue] = useState(false);
    const { tmdispatch } = useContext(TerminierungContext);

    const handleChange = (setter: (v: boolean) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setter(event.target.checked);

        tmdispatch({
            type: 'SETEARLIERAPPOINTMENTWANTED',
            earlierAppointmentWanted: event.target.checked,
        });
    };

    return (
        <div key="cbxEarlierAppointment" className="mt-3 mb-3">
            <Form.Check type="checkbox" id="earlierAppointmentPossible-check-1">
                <Form.Check.Input type="checkbox" checked={cbxValue} onChange={handleChange(setCbxValue)} />
                <Form.Check.Label>
                    <div style={{ color: 'black' }}>
                        <b>Bitte benachrichtigen Sie mich wenn ein früherer Termin verfügbar ist</b>
                    </div>
                </Form.Check.Label>
            </Form.Check>
        </div>
    );
};

export default EarlierAppointmentPossible;
