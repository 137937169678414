import { InitialIFrameType } from './iframeContext';

type Action =
    | {
          type: 'SETIFRAME';
          src: string;
          name: string;
          callingPage: string;
      }
    | {
          type: 'RESETIFRAME';
          src: string;
          name: string;
          callingPage: string;
      };

export const iframeReducer = (state: InitialIFrameType, action: Action) => {
    switch (action.type) {
        case 'SETIFRAME':
            return {
                ...state,
                src: action.src,
                name: action.name,
                callingPage: action.callingPage,
            };
        case 'RESETIFRAME':
            return {
                ...state,
                src: '',
                name: '',
                callingPage: '',
            };

        default:
            return state;
    }
};
