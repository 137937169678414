import { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { Paths } from '../../Routes';
import { CenterDiv, VerticalCenterDiv } from '../../components/Div/StyledDiv';
import loadingSplashWait from '../../lotties/splashscreenWait.json';
import { getTabletURL } from '../../services/RestServices';
import { getQueryParams } from '../../utils/urlUtils';
import MultiFragebogen, { IMultiFragebogen } from '../Fragebogen/MultiFragebogen';

export interface ICheckInSplashscreenMultifragebogenWaiting {
    id?: string;
}

export interface IGetTabletURLResponse {
    t0: string;
    otp: string;
    userId: string;
    name: string;
    key: string;
    formNames: any;
    webAppURL?: string;
}

let intervalTimer;

const CheckInSplashscreenMultifragebogenWaiting = () => {
    const navigate = useNavigate();
    const navtype = useNavigationType();
    // const [intervalTimer, setIntervalTimer] = useState<any>();

    const location = useLocation();
    const hashParams: ICheckInSplashscreenMultifragebogenWaiting = getQueryParams(location.search);

    const [foundData, setFoundData] = useState<boolean>(false);
    const [multifragebogen, setMultifragebogen] = useState<JSX.Element>(<></>);

    useEffect(() => {
        startListening();

        return () => {
            // clearInterval(intervalTimer);
            clearInterval(intervalTimer);
        };
    }, []);

    /*
    useEffect(() => {
        /* avoid browser back button click * /
        return () => {
            if (navtype === 'POP') {
                navigate(Paths.SPLASHSCREEN_MULTIFRAGEBOGEN_WAITING);
            }
        };
    }, [history]);
    */

    const lottieSpinnerOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingSplashWait,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const LottieSplashWait = () => {
        return <Lottie options={lottieSpinnerOptions} height={200} width={200} />;
    };

    const LoadingSpinner = () => {
        return (
            <VerticalCenterDiv>
                <CenterDiv>
                    <h1>Fragebogen Terminal</h1>
                    <LottieSplashWait />
                    <h2>Warten auf Daten...</h2>
                </CenterDiv>
            </VerticalCenterDiv>
        );
    };

    const startListening = () => {
        // const timer = setInterval(() => listeningWorkflow(), 1000);
        // setIntervalTimer(timer);
        intervalTimer = setInterval(() => listeningWorkflow(), 1000);
    };

    const listeningWorkflow = async () => {
        let tabletID = '';

        if (hashParams.id === undefined) {
            return;
        } else {
            tabletID = hashParams.id;
        }

        const response: IGetTabletURLResponse = await getTabletURL(tabletID);
        if (response && response.webAppURL) {
            window.location.replace(response.webAppURL);
            return;
        }
        if (
            response &&
            response.t0?.length > 0 &&
            response.otp?.length > 0 &&
            response.userId?.length > 0 &&
            response.name?.length > 0 &&
            response.key?.length > 0
        ) {
            const multifragebogenData: IMultiFragebogen = {
                type: 'Questionnaire',
                name: response.name,
                userId: response.userId,
                t0: response.t0,
                otp: response.otp,
                formNames: response.formNames,
            };

            const multifragebogen = <MultiFragebogen {...multifragebogenData} />;
            clearInterval(intervalTimer);
            setFoundData(true);
            setMultifragebogen(multifragebogen);
        }
    };

    return foundData ? (
        multifragebogen
    ) : (
        <div>
            <LoadingSpinner />
        </div>
    );
};

export default CheckInSplashscreenMultifragebogenWaiting;
