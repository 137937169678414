import { InitialExecuteRuleType } from './executeRuleContext';

type Action =
    | {
          type: 'SETRULEPROPERTIES';
          ruleName: string;
          ruleParam: string;
          persInfo: string;
      }
    | {
          type: 'RESETRULEPROPERTIES';
      };

export const executeRuleReducer = (state: InitialExecuteRuleType, action: Action) => {
    switch (action.type) {
        case 'SETRULEPROPERTIES':
            return {
                ...state,
                ruleName: action.ruleName,
                ruleParam: action.ruleParam,
                persInfo: action.persInfo,
            };
        case 'RESETRULEPROPERTIES':
            return {
                ...state,
                ruleName: '',
                ruleParam: '',
                persInfo: '',
            };
        default:
            return state;
    }
};
