import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import AlertContext, { AlertOnHide, AlertType } from '../../../context/alertContext';
import { ISigData } from '../../../context/sigpadContext';
import { addSignatureToPDF } from '../../../services/RestServices';
import { showAlertMessage } from '../../../utils/alertHandling';
import StyledButton from '../../Buttons/StyledButton';
import IFramePDF from '../../IFrame/IFramePDF';
import SignDocumentModal, { ISignDocumentModalShow } from '../SignDocumentModal/SignDocumentModal';

interface IModalCst {
    fullScreen?: boolean;
}

export const ModalCst = styled(Modal)<IModalCst>`
    .modal-dialog {
        max-width: ${(props) => (props.fullScreen ? '100%' : '90%')};
        max-height: ${(props) => (props.fullScreen ? '100%' : '93%')};
        margin: ${(props) => (props.fullScreen ? '0' : '1.75rem auto')};
        height: ${(props) => (props.fullScreen ? '100%' : '93%')};
        width: ${(props) => (props.fullScreen ? '100%' : null)};
    }
    .modal-content {
        min-height: ${(props) => (props.fullScreen ? '100%' : '93%')};
        border-radius: ${(props) => props.theme.modal.borderRadius};
        box-shadow: ${(props) => props.theme.modal.boxShadow};
    }

    .modal-body {
        min-height: ${(props) => (props.fullScreen ? '100%' : '93%')};
    }
`;

export interface IPDFModal {
    show: boolean;
    onHide(): void;
    modalTitle: string;
    pdfData: any;
    isSignable?: boolean;
    isSigned?: boolean;
    diagnosticReportData?: any;
    fullScreen?: boolean;
}

const PDFModal = (props: IPDFModal) => {
    const [signModalShow, setSignModalShow] = useState<ISignDocumentModalShow>({
        show: false,
    });
    const [docIsSigned, setDocIsSigned] = useState(false);
    const [pdfDocAfterSign, setPdfDocAfterSign] = useState<any>();

    const { alertdispatch } = useContext(AlertContext);

    useEffect(() => {
        if (props.isSigned && props.isSigned === true) {
            setDocIsSigned(true);
        }
    }, [props.show]);

    const showSignModal = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e) {
            e.currentTarget.blur();
        }
        setSignModalShow({
            show: true,
        });
    };

    const onSignModalSigned = async (sigData: ISigData) => {
        setSignModalShow({ show: false });
        if (sigData && sigData.pictureData.length > 0 && props.diagnosticReportData) {
            const response = await addSignatureToPDF(sigData, props.diagnosticReportData);
            if (response.success !== 'true') {
                showAlertMessage({
                    alertTitle: 'PDF signieren',
                    alertTxt: 'Es ist ein Fehler bei der Signierung des PDFs aufgetreten.',
                    alertType: AlertType.error,
                    onHide: AlertOnHide.onlyClose,
                    alertdispatch: alertdispatch,
                });
            } else {
                setDocIsSigned(true);
                if (response.pdfURF) {
                    setPdfDocAfterSign(<IFramePDF src={response.pdfURF} />);
                }
            }
        }
    };

    const onSignModalCancel = () => {
        setSignModalShow({ show: false });
    };

    const showPDFData = () => {
        if (docIsSigned && pdfDocAfterSign) {
            return pdfDocAfterSign;
        } else {
            return props.pdfData;
        }
    };

    return (
        <>
            <ModalCst show={props.show} fullScreen={props.fullScreen === false ? false : true}>
                <Modal.Header>
                    <Modal.Title>{props.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{showPDFData()}</Modal.Body>
                <Modal.Footer>
                    {props.isSignable && (
                        <StyledButton disabled={docIsSigned} variant="secondary" onClick={(e) => showSignModal(e)}>
                            {docIsSigned ? 'ist signiert' : 'Signieren'}
                        </StyledButton>
                    )}
                    <StyledButton style={{ marginLeft: '40px' }} onClick={() => props.onHide()}>
                        Schließen
                    </StyledButton>
                </Modal.Footer>
            </ModalCst>
            <SignDocumentModal
                show={signModalShow.show}
                embedded={false}
                onSign={onSignModalSigned}
                onCancel={onSignModalCancel}
            />
        </>
    );
};

export default PDFModal;
