import { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { FaHospitalAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { Paths } from '../../Routes';
import AppointmentDate, { startDate } from '../../components/Cards/AppointmentDate';
import {
    StyledAppointmentCard,
    StyledAppointmentCardBody,
    StyledAppointmentCardFooter,
    StyledAppointmentCardHeader,
} from '../../components/Cards/StyledCard';
import AppPropsContext from '../../context/appPropsContext';
import LoginContext from '../../context/loginContext';
import TerminierungContext from '../../context/terminierungContext';
import VideochatContext from '../../context/videochatContext';
import { appointmentStatus } from '../../globals/global';
import {
    APPOINTMENTTYPE_DEPARTMENT,
    IAppointment,
    IParticipant,
    getMacroNameFromAppointment,
    getParticipantType,
    getSelectedReasonFromAppointment,
    isAppSeriesAppointment,
    sortParticipantsForCard,
} from '../../utils/appointmentUtils';
import StyledButton from '../Buttons/StyledButton';
import { isChatOpen } from '../Chat/Chat';
import {
    AppointmentCardButtonCol,
    AppointmentCardDateCol,
    AppointmentCardHeaderStatusButtonCol,
    AppointmentCardStatusCol,
} from '../Cols/StyledCol';
import { AppointmentCardStatusButtonRow } from '../Rows/StyledRow';
import { AppointmentChat, AppointmentChatIcon } from './AppointmentChat';
import AppointmentStatus from './AppointmentStatus';
import CancelAppointment from './CancelAppointment';
import InpatientAppointment from './InpatientAppointment';
import Participants from './Participants';
import PostponeAppointment from './PostponeAppointment';
const moment = require('moment');

const DetailsButtonParentDiv = styled.div`
    background-color: white;
    text-align: right;
`;

const DetailsButton = styled(StyledButton)`
    width: 50%;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
`;

function displayParticipants(appointment: IAppointment, standort?: string, color?: string) {
    const sortedParticipant = sortParticipantsForCard(appointment.participant);

    const allParticipants: any[] = [];

    if (standort && standort.length > 0) {
        allParticipants.push(
            <div style={{ margin: 7 }}>
                <FaHospitalAlt size={15} color={color} /> {standort}
            </div>,
        );
    }

    const participants = sortedParticipant.map((data: IParticipant, index: number) => (
        <div key={index}>
            <Participants {...data} />
        </div>
    ));

    allParticipants.push(participants);
    return allParticipants;
}

const AppointmentCard = (props: IAppointment) => {
    const { apstate } = useContext(AppPropsContext);
    const { vsstate } = useContext(VideochatContext);
    const { tmstate } = useContext(TerminierungContext);
    const themeContext = useContext(ThemeContext);
    const [chatOpen, setChatOpen] = useState(false);
    const { state, dispatch } = useContext(LoginContext);
    const navigate = useNavigate();

    useEffect(() => {
        // const isChatOpen = () => {
        //     let appointmentChatEntry = vsstate.appointmentChat.find((e) => e.id === props.id);
        //     if (appointmentChatEntry) {
        //         return appointmentChatEntry.chatOpen;
        //     }
        //     return false;
        // };

        if (isChatOpen(vsstate.appointmentChat, props.id)) {
            setChatOpen(true);
        } else {
            setChatOpen(false);
        }
    }, [JSON.stringify(vsstate.appointmentChat)]);

    const showMacroName = () => {
        let macroName = getMacroNameFromAppointment(props);
        if (macroName.length <= 0) {
            macroName = tmstate.selectedCategory.name;
        }

        return (
            <Row>
                <Col>
                    <h5>{macroName}</h5>
                </Col>
            </Row>
        );
    };

    const showReason = () => {
        let name = '';
        const selectedReason = getSelectedReasonFromAppointment(props);

        if (selectedReason && selectedReason.length > 0) {
            name = selectedReason;
        } else if (tmstate.selectedReason && tmstate.selectedReason.reasonName) {
            name = tmstate.selectedReason.reasonName;
        } else {
            /* if no reason, fallback to macro */
            const macroName = getMacroNameFromAppointment(props);
            if (macroName.length <= 0) {
                name = tmstate.selectedCategory.name;
            } else {
                name = macroName;
            }
        }

        return (
            <Row>
                <Col>
                    <h5>{name}</h5>
                </Col>
            </Row>
        );
    };

    const isInpatient = () => {
        if (!props.extension) {
            return false;
        }
        for (const ext of props.extension) {
            if (ext.url === 'http://www.principa.siegele-software.com/isInpatient' && ext.valueBoolean == true) {
                return true;
            }
        }
        return false;
    };

    const showPostponeAppointmentIcon = () => {
        let showIcon = true;
        const dateStart = moment(props.start);
        const today = moment();
        if (dateStart.isSame(today, 'day')) {
            const startHour = dateStart.format('HH');
            if (startHour === '00') {
                showIcon = false; //then we asume that it is a Soforttermin
            }
        }
        if (isAppSeriesAppointment(props)) {
            showIcon = false;
        }
        if (isInpatient()) {
            showIcon = false;
        }

        return showIcon;
    };

    const getEncounterTitle = () => {
        const sortedParticipant = sortParticipantsForCard(props.participant);
        let department = '';
        for (const data of sortedParticipant) {
            const ptype = getParticipantType(data);
            if (ptype === APPOINTMENTTYPE_DEPARTMENT) {
                department = data.actor.display;
                break;
            }
        }
        return startDate(props) + ' - ' + department;
    };
    const activateEncounter = () => {
        dispatch({
            type: 'SETENCOUNTER',
            encounterId: props.id,
            encounterTitle: getEncounterTitle(),
        });
        navigate(Paths.JOURNEY);
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: props.cardPosition ? props.cardPosition : 'center',
                alignItems: props.cardPosition ? props.cardPosition : 'center',
            }}
        >
            <StyledAppointmentCard bg={'light'} className="mb-2" width={props.cardWidth}>
                <StyledAppointmentCardHeader type={props.type}>
                    <Container>
                        <Row>
                            <AppointmentCardDateCol>
                                <AppointmentDate {...props} />
                            </AppointmentCardDateCol>
                            <AppointmentCardHeaderStatusButtonCol>
                                <AppointmentCardStatusButtonRow>
                                    <AppointmentCardStatusCol xs={5}>
                                        <AppointmentStatus {...props} />
                                    </AppointmentCardStatusCol>
                                    <AppointmentCardButtonCol>
                                        {props.isCancelable &&
                                        props.status !== appointmentStatus.cancelled &&
                                        props.status !== appointmentStatus.arrived ? (
                                            <>
                                                {showPostponeAppointmentIcon() ? (
                                                    <PostponeAppointment {...props} />
                                                ) : (
                                                    <></>
                                                )}
                                                {isInpatient() ? <></> : <CancelAppointment {...props} />}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        {props.showChatIcon && props.showChatIcon === true && !isInpatient() ? (
                                            <>
                                                <AppointmentChatIcon {...props} chatOpen={chatOpen} />
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        {isInpatient() ? (
                                            <>
                                                <InpatientAppointment />
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </AppointmentCardButtonCol>
                                </AppointmentCardStatusButtonRow>
                            </AppointmentCardHeaderStatusButtonCol>
                        </Row>
                    </Container>
                </StyledAppointmentCardHeader>
                <StyledAppointmentCardBody>
                    {/* {showMacroName()} */}
                    {showReason()}
                    <Row>
                        <Col>
                            {displayParticipants(
                                props,
                                apstate.AppointmentSummary.standort ? tmstate.journeyData.site : '',
                                themeContext.icon.passive.color,
                            )}
                        </Col>
                    </Row>
                </StyledAppointmentCardBody>
                {props.comment ? (
                    <>
                        <div>
                            <StyledAppointmentCardFooter status={props.status}>
                                {props.comment}
                            </StyledAppointmentCardFooter>
                        </div>
                    </>
                ) : (
                    <></>
                )}
                {state.activeUserId && state.activeUserId != '0' && state.isLoggedIn ? (
                    <DetailsButtonParentDiv>
                        <DetailsButton variant="secondary" onClick={activateEncounter}>
                            Details
                        </DetailsButton>
                    </DetailsButtonParentDiv>
                ) : (
                    <></>
                )}
                {chatOpen ? <AppointmentChat {...props} /> : <></>}
            </StyledAppointmentCard>
        </div>
    );
};

export default AppointmentCard;
