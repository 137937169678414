import * as React from 'react';
import { useState } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import StyledButton from '../../Buttons/StyledButton';
import StyledCol from '../../Cols/StyledCol';
import { StyledFormLabelForInput } from '../../StyledForm/SyledForm';
import { StyledModal } from '../StyledModal/StyledModal';

export interface ILoginCardModalShow {
    show: boolean;
}

interface ILoginCardModal {
    show: boolean;
    onHide: () => void;
    onLoginClick(username: string, pwd: string): void;
}

const LoginCardModal = (props: ILoginCardModal) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        {
            props.onLoginClick(username, password);
        }
    };

    return (
        <StyledModal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Anmelden</Modal.Title>
            </Modal.Header>
            <Form className="m-3" noValidate onSubmit={handleSubmit}>
                <Container fluid>
                    <div>
                        <Modal.Body>
                            <Row style={{ padding: 10 }}>
                                <Col>
                                    <Form.Group controlId="loginPwd">
                                        <StyledFormLabelForInput>Benutzername</StyledFormLabelForInput>
                                        <br />
                                        <Form.Control
                                            required
                                            type="user"
                                            className="mx-sm-2"
                                            onChange={(e) => {
                                                setUsername(e.target.value);
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row style={{ padding: 10 }}>
                                <Col>
                                    <Form.Group controlId="formGroupPassword">
                                        <StyledFormLabelForInput>Passwort</StyledFormLabelForInput>
                                        <Form.Control
                                            required
                                            type="password"
                                            className="mx-sm-2"
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div>
                                <div style={{ float: 'left' }}>
                                    <StyledCol textAlign="left">
                                        <StyledButton variant="secondary" onClick={props.onHide}>
                                            Abbrechen
                                        </StyledButton>
                                    </StyledCol>
                                </div>
                                <div style={{ float: 'right' }}>
                                    <StyledCol paddingRight="0px">
                                        <fieldset disabled={username.length <= 0 || password.length <= 0}>
                                            <StyledButton type="submit">Anmelden</StyledButton>
                                        </fieldset>
                                    </StyledCol>
                                </div>
                            </div>
                        </Modal.Body>
                    </div>
                </Container>
            </Form>
        </StyledModal>
    );
};

export default LoginCardModal;
