import { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/esm/Nav';
import EnrollCodeBox from '../../components/EnrollCodeBox/EnrollCodeBox';
import ExtraLinks from '../../components/ExtraLinks/ExtraLinks';
import QRCodeForApp from '../../components/QRCode/QRCodeForApp';
import AlertContext from '../../context/alertContext';
import AppPropsContext from '../../context/appPropsContext';
import { useAppProperties } from '../../hooks/useAppProperties';
import { resetShowAlertMessage } from '../../utils/alertHandling';
import Login from '../Login/Login';
import FullScreenImpressum from '../Mainscreen/FullScreenImpressum';

const Home = () => {
    interface IShowHomeElements {
        showLogin: boolean;
        showEnrollCodeBox: boolean;
        showQRCodeForApp: boolean;
        row_lg: number;
    }

    const { apstate } = useContext(AppPropsContext);
    const { alertdispatch } = useContext(AlertContext);
    // const themeContext = useContext(ThemeContext);

    const { getImpressum } = useAppProperties();

    const [homeElements, setHomeElements] = useState<IShowHomeElements>({
        showLogin: false,
        showEnrollCodeBox: false,
        showQRCodeForApp: false,
        row_lg: 0,
    });

    useEffect(() => {
        resetShowAlertMessage(alertdispatch);
    }, []);

    useEffect(() => {
        const homeEle = {
            showLogin: false,
            showEnrollCodeBox: false,
            showQRCodeForApp: false,
            row_lg: 0,
        };
        let row_lg_counter = 0;

        if (apstate.allowLogin) {
            homeEle.showLogin = true;
            row_lg_counter++;
        }
        if (apstate.allowAppointmentsWithoutLogin) {
            homeEle.showEnrollCodeBox = true;
            row_lg_counter++;
        }
        if (apstate.showMobileQR !== false) {
            homeEle.showQRCodeForApp = true;
            row_lg_counter++;
        }

        homeEle.row_lg = row_lg_counter;

        setHomeElements(homeEle);
    }, [apstate]);

    const brandingSrc = 'data:image/jpg;base64,' + apstate.brandingBytes;

    const impressum = getImpressum();

    return (
        <FullScreenImpressum impressum={impressum}>
            <div>
                <Container fluid>
                    <Row style={{ paddingTop: 20, paddingBottom: 20 }}>
                        {apstate.extraLinks ? <Col></Col> : null}
                        <Col>
                            <div style={{ textAlign: 'center' }}>
                                <Image src={brandingSrc} fluid style={{ paddingTop: 20 }} />
                            </div>
                        </Col>
                        {apstate.extraLinks ? (
                            <Col>
                                <div style={{ float: 'right' }}>
                                    <Nav>
                                        <ExtraLinks />
                                    </Nav>
                                </div>
                            </Col>
                        ) : null}
                    </Row>
                    <Row xs={1} sm={1} md={1} lg={homeElements.row_lg} className="mt-5">
                        {homeElements.showEnrollCodeBox ? (
                            <Col>
                                <EnrollCodeBox />
                            </Col>
                        ) : null}
                        {homeElements.showLogin ? (
                            <Col>
                                <Login />
                            </Col>
                        ) : null}

                        {homeElements.showQRCodeForApp ? (
                            <Col>
                                <QRCodeForApp />
                            </Col>
                        ) : null}
                    </Row>
                </Container>
            </div>
            )
        </FullScreenImpressum>
    );
};

export default Home;
