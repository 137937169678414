import 'styled-components';
import { DefaultTheme } from 'styled-components';

/**
 * search for the theme
 * default is 'PCP -> pcpTheme'
 *
 * @param themeName
 *      the theme name
 * @returns
 *      the StyledComponents theme
 */
export const getTheme = (themeName: string) => {
    if (themeName && themeName.toUpperCase() === 'BETA') {
        return betaTheme;
    } else if (themeName && (themeName.toUpperCase() === 'PCPV2' || themeName.toUpperCase() === 'PCP')) {
        return pcpV2Theme;
    } else if (themeName && themeName.toUpperCase() === 'PCPV1') {
        return pcpTheme;
    } else {
        return pcpV2Theme;
    }
};

/** THE DEFAULT THEME DECLARATION */
declare module 'styled-components' {
    export interface DefaultTheme {
        borderRadius: string;
        mainColor: string;
        passiveColor: string;
        selectButton: {
            backgroundColor: string;
            backgroundColorHover: string;
            backgroundColorActive: string;
        };
        button: {
            borderRadius: string;
            boxShadow: string;
            primary: {
                color: string;
                colorHover: string;
                backgroundColor: string;
                backgroundColorHover: string;
                border: string;
                borderColor: string;
                borderColorHover: string;
                borderRadiusTopLeft: string;
                borderRadiusTopRight: string;
                borderRadiusBottomLeft: string;
                borderRadiusBottomRight: string;
            };
            secondary: {
                color: string;
                colorHover: string;
                backgroundColor: string;
                backgroundColorHover: string;
                border: string;
                borderColor: string;
                borderColorHover: string;
                borderRadiusTopLeft: string;
                borderRadiusTopRight: string;
                borderRadiusBottomLeft: string;
                borderRadiusBottomRight: string;
            };
            warning: {
                color: string;
                colorHover: string;
                backgroundColor: string;
                backgroundColorHover: string;
                border: string;
                borderColor: string;
                borderColorHover: string;
                borderRadiusTopLeft: string;
                borderRadiusTopRight: string;
                borderRadiusBottomLeft: string;
                borderRadiusBottomRight: string;
            };
            success: {
                color: string;
                colorHover: string;
                backgroundColor: string;
                backgroundColorHover: string;
                border: string;
                borderColor: string;
                borderColorHover: string;
                borderRadiusTopLeft: string;
                borderRadiusTopRight: string;
                borderRadiusBottomLeft: string;
                borderRadiusBottomRight: string;
            };
            danger: {
                color: string;
                colorHover: string;
                backgroundColor: string;
                backgroundColorHover: string;
                border: string;
                borderColor: string;
                borderColorHover: string;
                borderRadiusTopLeft: string;
                borderRadiusTopRight: string;
                borderRadiusBottomLeft: string;
                borderRadiusBottomRight: string;
            };
        };
        badge: {
            pos: {
                color: string;
                borderColor: string;
            };
            neg: {
                color: string;
                borderColor: string;
            };
        };
        icon: {
            size: number;
            strokeWidth: number;
            default: {
                color: string;
                backgroundColorHover: string;
            };
            neg: {
                color: string;
                backgroundColorHover: string;
            };
            passive: {
                color: string;
                backgroundColorHover: string;
            };
        };
        font: {
            alertColor: string;
            passiveColor: string;
            highlightColor: string;
            mainColorHover: string;
        };
        navbar: {
            backgroundColor: string;
            boxShadow: string;
        };
        navbutton: {
            default: {
                color: string;
                backgroundColor: string;
                backgroundColorHover: string;
                borderColor: string;
                borderColorHover: string;
            };
            horizontal: {
                colorActive: string;
                colorInactive: string;
                backgroundColorHover: string;
            };
            vertical: {
                colorActive: string;
                colorInactive: string;
                backgroundColorActive: string;
                backgroundColorInactive: string;
                backgroundColorHover: string;
                borderColor: string;
                borderColorHover: string;
            };
        };
        accordionMenu: {
            backgroundColor: string;
        };
        newMessageBadge: {
            backgroundColor: string;
        };
        step: {
            active: string;
            completed: string;
        };
        header: {
            main: {
                backgroundColor: string;
                color: string;
                buttonColor: string;
            };
        };
        sidebar: {
            backgroundImage: string;
            boxShadow: string;
            itemBackground: string;
        };
        tabcontainer: {
            backgroundImage: string;
        };
        modal: {
            borderRadius: string;
            boxShadow: string;
        };
        appointmentCard: {
            borderRadius: string;
        };
        list: {
            item: {
                backgroundColorHover: string;
                colorHover: string;
                boxShadow: string;
            };
        };
        medication: {
            icon: {
                acceptColor: string;
                rejectColor: string;
                laterColor: string;
                resultAcceptColor: string;
            };
        };
        hint: {
            backgroundColor: string;
        };
    }
}

/**
 *
 * ALL THEMES
 *
 * */

/** PCP Theme */
const pcpColors = {
    mainColor: '#1b396b',
    selectButtonActive: 'rgb(163, 193, 233)',

    darkOrange: '#fabf31',
    midOrange: '#fbb102',
    lightOrange: '#fcd581',
    warningOrange: '#d39e00', //own color
    warningOrangeBorder: '#c69500', // own color
    warningOrangeText: '#212529', // own color
    dateSelectionBackgroundOrange: 'rgb(254,216,110)',
    dateSelectionFrameOrange: 'rgb(255,165,0)',

    callGreen: '#4cda64',
    tickGreen: '#00ca73',
    lightGreen: '#9ccc65',
    midGreen: '#689f38',
    darkGreen: '#33691e',
    successGreen: '#adcf03', //own color
    successGreenHover: '#adcf03c2', //own color

    activeStep: '#fed86e',

    darkBlue: '#1c3351',
    darkBlueOp_50: 'rgba(28, 51, 81, .5)',
    inputBlue: '#415486',
    textBlue: '#707ea4',
    cornerBlue: '#BFD3EB',
    myBlue: '#d7e3f2',
    extraLightBlue: '#dde3eb',
    lightBlue: '#F1F2F6',
    p1: 'rgb(236,242,250)',
    p2: 'rgb(221,232,247)',
    p3: 'rgb(205,220,243)',
    p4: 'rgb(163,193,233)',
    p5: 'rgb(36,94,173)',

    callRed: '#ff3b2f',
    lightRed: '#ff4248',
    extraLightRed: '#fe5f55',
    red: 'rgb(192,0,0)',

    lightGray: 'rgb(245, 245, 245)',
    lightGrayOp: 'rgba(245, 245, 245, 0.97)',
    midGray: '#E3E3E3',
    lightBlack: '#2D2D2D',
    fontColorGray: 'rgba(116,118,130)',

    iosPDFbackgroundColor: '#1c1c1e',
    androidPDFbackgroundColor: '#eeeeee',

    extralightGray: 'rgba(250, 250, 250, .95)',

    bloodPressureColor: 'rgb(77,94,196)',
    bodyTemperatureColor: 'rgb(188,113,96)',
    oxygenColor: 'rgb(64,167,177)',

    inactiveColor: 'gray',
    iconBackgroundColorHover: '#88aedd',

    accordionMenuBackground: '#e2e6ea',
    navbarBackground: '#f8f9fa',
    sidebarBackground: 'linear-gradient(to right top, #1b396b, #1b396b, #1b396b, #1b396b, #1b396b)',
    tabContainerBackground: 'none',

    hintBackground: 'rgb(240, 240, 240)',
};

const pcpValues = {
    borderRadius: '4px',
    buttonBorderRadius: '4px',
    buttonBoxShadow: 'none',
    navbarBoxShadow: 'none',
    sidebarBoxShadow: 'none',
    modalBorderRadius: '0px',
    modalBoxShadow: 'none',
    iconSize: 30,
    strokeWidth: 2,
};

export const pcpTheme: DefaultTheme = {
    borderRadius: pcpValues.borderRadius,
    mainColor: pcpColors.mainColor,
    passiveColor: pcpColors.lightGray,
    selectButton: {
        backgroundColor: 'white',
        backgroundColorHover: pcpColors.selectButtonActive,
        backgroundColorActive: pcpColors.selectButtonActive,
    },
    button: {
        borderRadius: pcpValues.buttonBorderRadius,
        boxShadow: pcpValues.buttonBoxShadow,
        primary: {
            color: 'white',
            colorHover: 'white',
            backgroundColor: pcpColors.p5,
            backgroundColorHover: pcpColors.p4,
            border: '1px transparent',
            borderColor: pcpColors.p5,
            borderColorHover: pcpColors.p4,
            borderRadiusTopLeft: pcpValues.buttonBorderRadius,
            borderRadiusTopRight: pcpValues.buttonBorderRadius,
            borderRadiusBottomLeft: pcpValues.buttonBorderRadius,
            borderRadiusBottomRight: pcpValues.buttonBorderRadius,
        },
        secondary: {
            color: pcpColors.p5,
            colorHover: pcpColors.p5,
            backgroundColor: 'white',
            backgroundColorHover: pcpColors.p4,
            border: 'solid 1px transparent',
            borderColor: pcpColors.p5,
            borderColorHover: pcpColors.p4,
            borderRadiusTopLeft: pcpValues.buttonBorderRadius,
            borderRadiusTopRight: pcpValues.buttonBorderRadius,
            borderRadiusBottomLeft: pcpValues.buttonBorderRadius,
            borderRadiusBottomRight: pcpValues.buttonBorderRadius,
        },
        warning: {
            color: pcpColors.warningOrangeText,
            colorHover: pcpColors.warningOrangeText,
            backgroundColor: pcpColors.warningOrange,
            backgroundColorHover: pcpColors.midOrange,
            border: 'solid 1px transparent',
            borderColor: pcpColors.warningOrangeBorder,
            borderColorHover: pcpColors.warningOrangeBorder,
            borderRadiusTopLeft: pcpValues.buttonBorderRadius,
            borderRadiusTopRight: pcpValues.buttonBorderRadius,
            borderRadiusBottomLeft: pcpValues.buttonBorderRadius,
            borderRadiusBottomRight: pcpValues.buttonBorderRadius,
        },
        success: {
            color: 'white',
            colorHover: 'white',
            backgroundColor: pcpColors.successGreen,
            backgroundColorHover: pcpColors.successGreenHover,
            border: 'solid 1px transparent',
            borderColor: pcpColors.successGreen,
            borderColorHover: pcpColors.successGreenHover,
            borderRadiusTopLeft: pcpValues.buttonBorderRadius,
            borderRadiusTopRight: pcpValues.buttonBorderRadius,
            borderRadiusBottomLeft: pcpValues.buttonBorderRadius,
            borderRadiusBottomRight: pcpValues.buttonBorderRadius,
        },
        danger: {
            color: pcpColors.red,
            colorHover: 'white',
            backgroundColor: 'white',
            backgroundColorHover: pcpColors.lightRed,
            border: 'solid 1px transparent',
            borderColor: pcpColors.red,
            borderColorHover: pcpColors.lightRed,
            borderRadiusTopLeft: pcpValues.buttonBorderRadius,
            borderRadiusTopRight: pcpValues.buttonBorderRadius,
            borderRadiusBottomLeft: pcpValues.buttonBorderRadius,
            borderRadiusBottomRight: pcpValues.buttonBorderRadius,
        },
    },
    badge: {
        pos: {
            color: 'green',
            borderColor: 'green',
        },
        neg: {
            color: 'red',
            borderColor: 'red',
        },
    },
    icon: {
        size: pcpValues.iconSize,
        strokeWidth: pcpValues.strokeWidth,
        default: {
            color: pcpColors.mainColor,
            backgroundColorHover: pcpColors.iconBackgroundColorHover,
        },
        neg: {
            color: 'red',
            backgroundColorHover: pcpColors.iconBackgroundColorHover,
        },
        passive: {
            color: pcpColors.fontColorGray,
            backgroundColorHover: pcpColors.iconBackgroundColorHover,
        },
    },
    font: {
        alertColor: 'red',
        passiveColor: pcpColors.fontColorGray,
        highlightColor: pcpColors.p5,
        mainColorHover: pcpColors.textBlue,
    },
    navbar: {
        backgroundColor: pcpColors.navbarBackground,
        boxShadow: pcpValues.navbarBoxShadow,
    },
    navbutton: {
        default: {
            color: pcpColors.mainColor,
            backgroundColor: 'white',
            backgroundColorHover: pcpColors.lightGray,
            borderColor: 'white',
            borderColorHover: pcpColors.lightGray,
        },
        horizontal: {
            colorActive: pcpColors.mainColor,
            colorInactive: pcpColors.inactiveColor,
            backgroundColorHover: 'white',
        },
        vertical: {
            colorActive: pcpColors.mainColor,
            colorInactive: pcpColors.darkBlue,
            backgroundColorActive: pcpColors.myBlue,
            backgroundColorInactive: 'white',
            backgroundColorHover: pcpColors.lightGray,
            borderColor: 'white',
            borderColorHover: pcpColors.lightGray,
        },
    },
    accordionMenu: {
        backgroundColor: pcpColors.accordionMenuBackground,
    },
    newMessageBadge: {
        backgroundColor: pcpColors.selectButtonActive,
    },
    step: {
        active: pcpColors.activeStep,
        completed: pcpColors.successGreen,
    },
    header: {
        main: {
            backgroundColor: pcpColors.mainColor,
            color: 'white',
            buttonColor: 'white',
        },
    },
    sidebar: {
        backgroundImage: pcpColors.sidebarBackground,
        boxShadow: pcpValues.sidebarBoxShadow,
        itemBackground: 'transparent',
    },
    tabcontainer: {
        backgroundImage: pcpColors.tabContainerBackground,
    },
    modal: {
        borderRadius: pcpValues.modalBorderRadius,
        boxShadow: pcpValues.modalBoxShadow,
    },
    appointmentCard: {
        borderRadius: '10px',
    },
    list: {
        item: {
            backgroundColorHover: pcpColors.p4,
            colorHover: pcpColors.p5,
            boxShadow: pcpValues.buttonBoxShadow,
        },
    },
    medication: {
        icon: {
            acceptColor: pcpColors.mainColor,
            rejectColor: 'red',
            laterColor: pcpColors.fontColorGray,
            resultAcceptColor: 'green',
        },
    },
    hint: {
        backgroundColor: pcpColors.hintBackground,
    },
};

/** PCP V2 Theme */
const pcpV2Colors = {
    mainColor: '#1b396b',
    selectButtonActive: 'rgb(163, 193, 233)',

    darkOrange: '#fabf31',
    midOrange: '#fbb102',
    lightOrange: '#fcd581',
    warningOrange: '#d39e00', //own color
    warningOrangeBorder: '#c69500', // own color
    warningOrangeText: '#212529', // own color
    dateSelectionBackgroundOrange: 'rgb(254,216,110)',
    dateSelectionFrameOrange: 'rgb(255,165,0)',

    callGreen: '#4cda64',
    tickGreen: '#00ca73',
    lightGreen: '#9ccc65',
    midGreen: '#689f38',
    darkGreen: '#33691e',
    successGreen: '#adcf03', //own color
    successGreenHover: '#adcf03c2', //own color

    activeStep: '#fed86e',

    darkBlue: '#1c3351',
    darkBlueOp_50: 'rgba(28, 51, 81, .5)',
    inputBlue: '#415486',
    textBlue: '#707ea4',
    cornerBlue: '#BFD3EB',
    myBlue: '#d7e3f2',
    extraLightBlue: '#dde3eb',
    lightBlue: '#F1F2F6',
    p1: 'rgb(236,242,250)',
    p2: 'rgb(221,232,247)',
    p3: 'rgb(205,220,243)',
    p4: 'rgb(163,193,233)',
    p5: 'rgb(36,94,173)',

    callRed: '#ff3b2f',
    lightRed: '#ff4248',
    extraLightRed: '#fe5f55',
    red: 'rgb(192,0,0)',

    lightGray: 'rgb(245, 245, 245)',
    lightGrayOp: 'rgba(245, 245, 245, 0.97)',
    midGray: '#E3E3E3',
    lightBlack: '#2D2D2D',
    fontColorGray: 'rgba(116,118,130)',

    iosPDFbackgroundColor: '#1c1c1e',
    androidPDFbackgroundColor: '#eeeeee',

    extralightGray: 'rgba(250, 250, 250, .95)',

    bloodPressureColor: 'rgb(77,94,196)',
    bodyTemperatureColor: 'rgb(188,113,96)',
    oxygenColor: 'rgb(64,167,177)',

    inactiveColor: 'gray',
    // iconBackgroundColorHover: '#88aedd',
    iconBackgroundColorHover: 'rgb(213 213 215)',

    accordionMenuBackground: '#e2e6ea',
    navbarBackground: 'hsl(210,8%,97.5%)',
    sidebarBackground: 'linear-gradient(to right top, #1b396b, #144875, #17577c, #256582, #387387)',
    tabContainerBackground: 'linear-gradient(to bottom, #1b396b, #144875, #17577c, #256582, #387387)',
    // sidebarBackground: 'linear-gradient(to right top, #2e4d81, #34588b, #3b6494, #436f9d, #4c7ba6)',

    hintBackground: 'rgb(240, 240, 240)',
};

const pcpV2Values = {
    borderRadius: '8px',
    buttonBorderRadius: '24px',
    // buttonBoxShadow: '2px 2px 7px 1px rgb(0 0 0 / 20%)',
    buttonBoxShadow: 'rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0',
    navbarBoxShadow: '0 2px 4px rgb(0 0 0 / 50%)',
    sidebarBoxShadow: '2px 0px 4px rgb(0 0 0 / 50%)',
    modalBorderRadius: '20px',
    modalBoxShadow: '4px 4px 14px 2px rgb(0 0 0 / 20%)',
    iconSize: 30,
    strokeWidth: 1.5,
};

export const pcpV2Theme: DefaultTheme = {
    borderRadius: pcpV2Values.borderRadius,
    mainColor: pcpV2Colors.mainColor,
    passiveColor: pcpV2Colors.lightGray,
    selectButton: {
        backgroundColor: 'white',
        backgroundColorHover: pcpV2Colors.selectButtonActive,
        backgroundColorActive: pcpV2Colors.selectButtonActive,
    },
    button: {
        borderRadius: pcpV2Values.buttonBorderRadius,
        boxShadow: pcpV2Values.buttonBoxShadow,
        primary: {
            color: 'white',
            colorHover: 'white',
            backgroundColor: pcpV2Colors.p5,
            backgroundColorHover: '#16437E',
            border: 'none',
            borderColor: pcpV2Colors.p5,
            borderColorHover: pcpV2Colors.p4,
            borderRadiusTopLeft: pcpV2Values.buttonBorderRadius,
            borderRadiusTopRight: pcpV2Values.buttonBorderRadius,
            borderRadiusBottomLeft: pcpV2Values.buttonBorderRadius,
            borderRadiusBottomRight: pcpV2Values.buttonBorderRadius,
        },
        secondary: {
            color: pcpV2Colors.p5,
            colorHover: '#174ea6',
            backgroundColor: 'white',
            backgroundColorHover: '#e4e6ea',
            border: 'none',
            borderColor: pcpV2Colors.p5,
            borderColorHover: pcpV2Colors.p4,
            borderRadiusTopLeft: pcpV2Values.buttonBorderRadius,
            borderRadiusTopRight: pcpV2Values.buttonBorderRadius,
            borderRadiusBottomLeft: pcpV2Values.buttonBorderRadius,
            borderRadiusBottomRight: pcpV2Values.buttonBorderRadius,
        },
        warning: {
            color: pcpV2Colors.warningOrangeText,
            colorHover: pcpV2Colors.warningOrangeText,
            backgroundColor: pcpV2Colors.warningOrange,
            backgroundColorHover: pcpV2Colors.midOrange,
            border: 'none',
            borderColor: pcpV2Colors.warningOrangeBorder,
            borderColorHover: pcpV2Colors.warningOrangeBorder,
            borderRadiusTopLeft: pcpV2Values.buttonBorderRadius,
            borderRadiusTopRight: pcpV2Values.buttonBorderRadius,
            borderRadiusBottomLeft: pcpV2Values.buttonBorderRadius,
            borderRadiusBottomRight: pcpV2Values.buttonBorderRadius,
        },
        success: {
            color: 'white',
            colorHover: 'white',
            backgroundColor: pcpV2Colors.successGreen,
            backgroundColorHover: pcpV2Colors.successGreenHover,
            border: 'none',
            borderColor: pcpV2Colors.successGreen,
            borderColorHover: pcpV2Colors.successGreenHover,
            borderRadiusTopLeft: pcpV2Values.buttonBorderRadius,
            borderRadiusTopRight: pcpV2Values.buttonBorderRadius,
            borderRadiusBottomLeft: pcpV2Values.buttonBorderRadius,
            borderRadiusBottomRight: pcpV2Values.buttonBorderRadius,
        },
        danger: {
            color: pcpColors.red,
            colorHover: 'white',
            backgroundColor: 'white',
            backgroundColorHover: pcpColors.lightRed,
            border: 'solid 1px transparent',
            borderColor: pcpColors.red,
            borderColorHover: pcpColors.lightRed,
            borderRadiusTopLeft: pcpValues.buttonBorderRadius,
            borderRadiusTopRight: pcpValues.buttonBorderRadius,
            borderRadiusBottomLeft: pcpValues.buttonBorderRadius,
            borderRadiusBottomRight: pcpValues.buttonBorderRadius,
        },
    },
    badge: {
        pos: {
            color: 'green',
            borderColor: 'green',
        },
        neg: {
            color: 'red',
            borderColor: 'red',
        },
    },
    icon: {
        size: pcpV2Values.iconSize,
        strokeWidth: pcpV2Values.strokeWidth,
        default: {
            color: pcpV2Colors.mainColor,
            backgroundColorHover: pcpV2Colors.iconBackgroundColorHover,
        },
        neg: {
            color: 'red',
            backgroundColorHover: pcpV2Colors.iconBackgroundColorHover,
        },
        passive: {
            color: pcpV2Colors.fontColorGray,
            backgroundColorHover: pcpV2Colors.iconBackgroundColorHover,
        },
    },
    font: {
        alertColor: 'red',
        passiveColor: pcpV2Colors.fontColorGray,
        highlightColor: pcpV2Colors.p5,
        mainColorHover: pcpV2Colors.textBlue,
    },
    navbar: {
        backgroundColor: pcpV2Colors.navbarBackground,
        boxShadow: pcpV2Values.navbarBoxShadow,
    },
    navbutton: {
        default: {
            color: pcpV2Colors.mainColor,
            backgroundColor: 'white',
            backgroundColorHover: pcpV2Colors.lightGray,
            borderColor: 'white',
            borderColorHover: pcpV2Colors.lightGray,
        },
        horizontal: {
            colorActive: pcpV2Colors.mainColor,
            colorInactive: pcpV2Colors.inactiveColor,
            backgroundColorHover: 'white',
        },
        vertical: {
            colorActive: pcpV2Colors.mainColor,
            colorInactive: pcpV2Colors.darkBlue,
            backgroundColorActive: pcpV2Colors.myBlue,
            backgroundColorInactive: 'white',
            backgroundColorHover: pcpV2Colors.lightGray,
            borderColor: 'white',
            borderColorHover: pcpV2Colors.lightGray,
        },
    },
    accordionMenu: {
        backgroundColor: pcpV2Colors.accordionMenuBackground,
    },
    newMessageBadge: {
        backgroundColor: pcpV2Colors.selectButtonActive,
    },
    step: {
        active: pcpV2Colors.activeStep,
        completed: pcpV2Colors.successGreen,
    },
    header: {
        main: {
            backgroundColor: 'white',
            color: 'black',
            buttonColor: pcpV2Colors.mainColor,
        },
    },
    sidebar: {
        backgroundImage: pcpV2Colors.sidebarBackground,
        boxShadow: pcpV2Values.sidebarBoxShadow,
        itemBackground: '#1b396b',
    },
    tabcontainer: {
        backgroundImage: pcpV2Colors.tabContainerBackground,
    },
    modal: {
        borderRadius: pcpV2Values.modalBorderRadius,
        boxShadow: pcpV2Values.modalBoxShadow,
    },
    appointmentCard: {
        borderRadius: '24px',
    },
    list: {
        item: {
            backgroundColorHover: '#e4e6ea',
            colorHover: '#174ea6',
            boxShadow: pcpV2Values.buttonBoxShadow,
        },
    },
    medication: {
        icon: {
            acceptColor: pcpV2Colors.mainColor,
            rejectColor: 'red',
            laterColor: pcpV2Colors.fontColorGray,
            resultAcceptColor: 'green',
        },
    },
    hint: {
        backgroundColor: pcpV2Colors.hintBackground,
    },
};

/** BETA Theme */
const betaColors = {
    mainColor: '#e99628',
    selectButtonActive: 'rgb(255,189,109)',
    midOrange: '#fbb102',
    primaryColor: '#e99628',
    primaryColorHover: '#ffbd6d',
    warningOrange: '#d39e00', //own color
    warningOrangeBorder: '#c69500', // own color
    warningOrangeText: '#212529', // own color
    successGreen: '#adcf03', //own color
    successGreenHover: '#adcf03c2', //own color
    activeStep: '#fed86e',
    fontColorGray: 'rgba(116,118,130)',
    lightGray: 'rgb(245, 245, 245)',
    inactiveColor: 'gray',
    darkBlue: '#1c3351',
    myBlue: '#d7e3f2',
    textBlue: '#707ea4',
    iconBackgroundColorHover: '#ffbd6d',
    accordionMenuBackground: '#e2e6ea',
    navbarBackground: '#f8f9fa',
    sidebarBackground: 'linear-gradient(to right top, #e99628, #e99628, #e99628, #e99628, #e99628)',
    tabContainerBackground: 'none',
    hintBackground: 'rgb(240, 240, 240)',
};

const betaValues = {
    borderRadius: '4px',
    buttonBorderRadius: '4px',
    buttonBoxShadow: 'none',
    navbarBoxShadow: 'none',
    sidebarBoxShadow: 'none',
    modalBorderRadius: '0px',
    modalBoxShadow: 'none',
    iconSize: 30,
    strokeWidth: 2,
};

export const betaTheme: DefaultTheme = {
    borderRadius: betaValues.borderRadius,
    mainColor: betaColors.mainColor,
    passiveColor: betaColors.lightGray,
    selectButton: {
        backgroundColor: 'white',
        backgroundColorHover: betaColors.selectButtonActive,
        backgroundColorActive: betaColors.selectButtonActive,
    },
    button: {
        borderRadius: betaValues.buttonBorderRadius,
        boxShadow: betaValues.buttonBoxShadow,
        primary: {
            color: 'white',
            colorHover: 'white',
            backgroundColor: betaColors.primaryColor,
            backgroundColorHover: betaColors.primaryColorHover,
            border: 'solid 1px transparent',
            borderColor: betaColors.primaryColor,
            borderColorHover: betaColors.primaryColorHover,
            borderRadiusTopLeft: betaValues.buttonBorderRadius,
            borderRadiusTopRight: betaValues.buttonBorderRadius,
            borderRadiusBottomLeft: betaValues.buttonBorderRadius,
            borderRadiusBottomRight: betaValues.buttonBorderRadius,
        },
        secondary: {
            color: betaColors.primaryColor,
            colorHover: 'white',
            backgroundColor: 'white',
            backgroundColorHover: betaColors.primaryColorHover,
            border: 'solid 1px transparent',
            borderColor: betaColors.primaryColor,
            borderColorHover: betaColors.primaryColorHover,
            borderRadiusTopLeft: betaValues.buttonBorderRadius,
            borderRadiusTopRight: betaValues.buttonBorderRadius,
            borderRadiusBottomLeft: betaValues.buttonBorderRadius,
            borderRadiusBottomRight: betaValues.buttonBorderRadius,
        },
        warning: {
            color: betaColors.warningOrangeText,
            colorHover: betaColors.warningOrangeText,
            backgroundColor: betaColors.warningOrange,
            backgroundColorHover: betaColors.midOrange,
            border: 'solid 1px transparent',
            borderColor: betaColors.warningOrangeBorder,
            borderColorHover: betaColors.warningOrangeBorder,
            borderRadiusTopLeft: betaValues.buttonBorderRadius,
            borderRadiusTopRight: betaValues.buttonBorderRadius,
            borderRadiusBottomLeft: betaValues.buttonBorderRadius,
            borderRadiusBottomRight: betaValues.buttonBorderRadius,
        },
        success: {
            color: 'white',
            colorHover: 'white',
            backgroundColor: betaColors.successGreen,
            backgroundColorHover: betaColors.successGreenHover,
            border: 'solid 1px transparent',
            borderColor: betaColors.successGreen,
            borderColorHover: betaColors.successGreenHover,
            borderRadiusTopLeft: betaValues.buttonBorderRadius,
            borderRadiusTopRight: betaValues.buttonBorderRadius,
            borderRadiusBottomLeft: betaValues.buttonBorderRadius,
            borderRadiusBottomRight: betaValues.buttonBorderRadius,
        },
        danger: {
            color: pcpColors.red,
            colorHover: 'white',
            backgroundColor: 'white',
            backgroundColorHover: pcpColors.lightRed,
            border: 'solid 1px transparent',
            borderColor: pcpColors.red,
            borderColorHover: pcpColors.lightRed,
            borderRadiusTopLeft: pcpValues.buttonBorderRadius,
            borderRadiusTopRight: pcpValues.buttonBorderRadius,
            borderRadiusBottomLeft: pcpValues.buttonBorderRadius,
            borderRadiusBottomRight: pcpValues.buttonBorderRadius,
        },
    },
    badge: {
        pos: {
            color: 'green',
            borderColor: 'green',
        },
        neg: {
            color: 'red',
            borderColor: 'red',
        },
    },
    icon: {
        size: betaValues.iconSize,
        strokeWidth: betaValues.strokeWidth,
        default: {
            color: betaColors.mainColor,
            backgroundColorHover: betaColors.iconBackgroundColorHover,
        },
        neg: {
            color: 'red',
            backgroundColorHover: betaColors.primaryColorHover,
        },
        passive: {
            color: betaColors.fontColorGray,
            backgroundColorHover: betaColors.primaryColorHover,
        },
    },
    font: {
        alertColor: 'red',
        passiveColor: betaColors.fontColorGray,
        highlightColor: betaColors.primaryColor,
        mainColorHover: betaColors.primaryColorHover,
    },
    navbar: {
        backgroundColor: betaColors.navbarBackground,
        boxShadow: betaValues.navbarBoxShadow,
    },
    navbutton: {
        default: {
            color: betaColors.mainColor,
            backgroundColor: 'white',
            backgroundColorHover: betaColors.lightGray,
            borderColor: 'white',
            borderColorHover: betaColors.lightGray,
        },
        horizontal: {
            colorActive: betaColors.mainColor,
            colorInactive: betaColors.inactiveColor,
            backgroundColorHover: 'white',
        },
        vertical: {
            colorActive: betaColors.mainColor,
            colorInactive: betaColors.darkBlue,
            backgroundColorActive: betaColors.myBlue,
            backgroundColorInactive: 'white',
            backgroundColorHover: betaColors.lightGray,
            borderColor: 'white',
            borderColorHover: betaColors.lightGray,
        },
    },
    accordionMenu: {
        backgroundColor: pcpColors.accordionMenuBackground,
    },
    newMessageBadge: {
        backgroundColor: pcpColors.selectButtonActive,
    },
    step: {
        active: betaColors.activeStep,
        completed: betaColors.successGreen,
    },
    header: {
        main: {
            backgroundColor: betaColors.mainColor,
            color: 'white',
            buttonColor: 'white',
        },
    },
    sidebar: {
        backgroundImage: betaColors.sidebarBackground,
        boxShadow: betaValues.sidebarBoxShadow,
        itemBackground: 'transparent',
    },
    tabcontainer: {
        backgroundImage: betaColors.tabContainerBackground,
    },
    modal: {
        borderRadius: betaValues.modalBorderRadius,
        boxShadow: betaValues.modalBoxShadow,
    },
    appointmentCard: {
        borderRadius: '10px',
    },
    list: {
        item: {
            backgroundColorHover: betaColors.primaryColorHover,
            colorHover: 'white',
            boxShadow: betaValues.buttonBoxShadow,
        },
    },
    medication: {
        icon: {
            acceptColor: betaColors.mainColor,
            rejectColor: 'red',
            laterColor: betaColors.fontColorGray,
            resultAcceptColor: 'green',
        },
    },
    hint: {
        backgroundColor: betaColors.hintBackground,
    },
};
