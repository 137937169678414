import * as React from 'react';
import { useContext } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Lottie from 'react-lottie';
import { Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import LoginContext, { InitialLoginType } from '../../context/loginContext';
import TerminierungContext, { InitialTerminierungType } from '../../context/terminierungContext';
import VideochatContext, { InitialVideochatType } from '../../context/videochatContext';
import incomingCallAnimation from '../../lotties/telephoneRing.json';
import callOffAnimation from '../../lotties/telephoneRingOff.json';
import { Paths } from '../../Routes';
import { IAudio, playSound, stopSound } from '../../utils/soundUtils';

// import { audiofile } from '../../sounds/ringTone.mp3';

// const ringtone = new Audio(audiofile);
const ringtone = new Audio('/ringTone.mp3');

let ringtoneStarted = false;

const lottieIncomingCall = {
    loop: true,
    autoplay: true,
    animationData: incomingCallAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const lottieCallOff = {
    loop: false,
    autoplay: false,
    animationData: callOffAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

interface ITelephoneRing {
    incomingCall: boolean;
    callAccepted: boolean;
    caller: string;
}

const gotIceCandidate = (event: RTCPeerConnectionIceEvent, remoteSid: string, sessionId: string) => {
    if (event.candidate !== null) {
        const sid = sessionId;
        window.app.wsconnect.send(
            JSON.stringify({ rtc: 'true', ice: event.candidate, mySid: sid, remoteSid: remoteSid }),
        );
        // console.log('chat.gotIceCandidate for ' + sid + ': ' + JSON.stringify(event.candidate));
    }
};

const callAccept = async (
    state: InitialLoginType,
    vsstate: InitialVideochatType,
    vsdispatch: React.Dispatch<any>,
    tmstate: InitialTerminierungType,
    navigate: NavigateFunction,
) => {
    let sid = '';
    if (tmstate.withLogin) {
        sid = state.sessionId;
    } else {
        sid = tmstate.enrollData.enrollCode;
    }

    stopSound(ringtone);
    ringtoneStarted = false;

    window.app.wsconnect.send(
        JSON.stringify({
            rtc: 'true',
            cmd: 'callAccepted',
            mySid: sid,
            remoteSid: vsstate.remoteSid,
            hasOwnVideo: true,
        }),
    );

    window.app.wsconnect.peerConnection.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
        let sid = '';
        if (tmstate.withLogin) {
            sid = state.sessionId;
        } else {
            sid = tmstate.enrollData.enrollCode;
        }
        gotIceCandidate(event, vsstate.remoteSid, sid);
    };

    window.app.wsconnect.peerConnection.ontrack = (event: RTCTrackEvent) => {
        vsdispatch({
            type: 'ONREMOTESTREAM',
            remoteStream: event.streams[0],
        });
    };

    vsdispatch({
        type: 'ONACCEPT',
    });

    if (tmstate.withCheckIn) {
        // handled in CheckInWL
    } else if (tmstate.withLogin) {
        navigate(Paths.VIDEOCHAT);
    } else {
        navigate(Paths.VIDEOCHAT_WL);
    }
};

export const callOff = async (
    navigation: NavigateFunction,
    location: Location,
    userAction: boolean,
    vsdispatch: React.Dispatch<any>,
    tmstate: InitialTerminierungType,
    state?: InitialLoginType,
    vsstate?: InitialVideochatType,
) => {
    stopRingTone();

    if (userAction) {
        if (state && vsstate) {
            let sid = '';
            if (tmstate.withLogin) {
                sid = state.sessionId;
            } else {
                sid = tmstate.enrollData.enrollCode;
            }

            window.app.wsconnect.send(
                JSON.stringify({
                    rtc: 'true',
                    cmd: 'callRefused',
                    mySid: sid,
                    remoteSid: vsstate.remoteSid,
                    type: 'ABORT',
                }),
            );
        }
    }

    vsdispatch({
        type: 'ONDISCONNECT',
    });

    if (tmstate.withCheckIn) {
        // handled in CheckInWL
    } else if (tmstate.withLogin) {
        if (location.pathname.endsWith(Paths.VIDEOCHAT)) {
            navigation(Paths.TERMINE);
        }
    } else {
        navigation(Paths.TERMINCODE);
    }
};

export interface ITelephoneCallIcon {
    state: InitialLoginType;
    vsstate: InitialVideochatType;
    vsdispatch: React.Dispatch<any>;
    tmstate: InitialTerminierungType;
    tooltipText?: string;
}

export const CallAcceptIcon = (props: ITelephoneCallIcon) => {
    const navigate = useNavigate();
    const callAcceptTooltip = () => {
        if (props.tooltipText) {
            return <Tooltip id={`tooltip-bottom`}>{props.tooltipText}</Tooltip>;
        } else {
            return (
                <Tooltip id={`tooltip-bottom`}>
                    Sie werden von <strong>{props.vsstate.caller}</strong> gerufen
                </Tooltip>
            );
        }
    };

    return (
        <OverlayTrigger key="bottom" placement="bottom" overlay={callAcceptTooltip()}>
            <div onClick={() => callAccept(props.state, props.vsstate, props.vsdispatch, props.tmstate, navigate)}>
                <Lottie options={lottieIncomingCall} height={50} width={50} />
            </div>
        </OverlayTrigger>
    );
};

export const CallOffIcon = (props: ITelephoneCallIcon) => {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <OverlayTrigger
            key="bottom"
            placement="bottom"
            overlay={<Tooltip id={`tooltip-bottom`}>Anruf beenden</Tooltip>}
        >
            <div
                onClick={() =>
                    callOff(navigate, location, true, props.vsdispatch, props.tmstate, props.state, props.vsstate)
                }
            >
                <Lottie options={lottieCallOff} height={50} width={50} />
            </div>
        </OverlayTrigger>
    );
};

export const playRingTone = (soundConfig: IAudio) => {
    playSound(soundConfig);
};

export const stopRingTone = () => {
    stopSound(ringtone);
    ringtoneStarted = false;
};

const TelephoneRing = (props: ITelephoneRing) => {
    const { state } = useContext(LoginContext);
    const { vsstate, vsdispatch } = useContext(VideochatContext);
    const { tmstate } = useContext(TerminierungContext);

    const callProps: ITelephoneCallIcon = {
        state: state,
        vsstate: vsstate,
        vsdispatch: vsdispatch,
        tmstate: tmstate,
    };

    if (props.incomingCall) {
        if (!ringtoneStarted) {
            const soundConfig: IAudio = {
                audioFile: ringtone,
                audioMuted: vsstate.audioMuted,
            };

            playRingTone(soundConfig);
            ringtoneStarted = true;
        }

        return <CallAcceptIcon {...callProps} />;
    } else if (props.callAccepted) {
        return <CallOffIcon {...callProps} />;
    } else {
        return null;
    }
};

const Telephone = () => {
    const { vsstate } = useContext(VideochatContext);

    return (
        <TelephoneRing
            incomingCall={vsstate.incomingCall}
            callAccepted={vsstate.callAccepted}
            caller={vsstate.caller}
        />
    );
};

export default Telephone;
