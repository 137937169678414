import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import StyledButton from '../../Buttons/StyledButton';
import StyledCol from '../../Cols/StyledCol';
import { StyledModal } from '../StyledModal/StyledModal';

interface IForeignQRModal {
    show: boolean;
    onHide: () => void;
    onSubmit: () => void;
}

export const ForeignQRModal = (props: IForeignQRModal) => {
    return (
        <StyledModal size="lg" show={props.show} onHide={() => props.onHide()}>
            <Modal.Header closeButton>
                <Modal.Title>QR Scan - Vertreter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Zertifikat einer anderen Person: Wollen Sie die Daten trotzdem ins Formular übernehmen?
            </Modal.Body>
            <Modal.Footer>
                <Container className="mt-4">
                    <Row>
                        <StyledCol paddingLeft="0px" textAlign="left">
                            <StyledButton variant="secondary" onClick={props.onHide}>
                                Abbrechen
                            </StyledButton>
                        </StyledCol>
                        <StyledCol paddingRight="0px" textAlign="right">
                            <StyledButton type="submit" onClick={props.onSubmit}>
                                Daten übernehmen
                            </StyledButton>
                        </StyledCol>
                    </Row>
                </Container>
            </Modal.Footer>
        </StyledModal>
    );
};

export default ForeignQRModal;
