import moment from 'moment';

export const getBirthdayPrettyFormatedWithAge = (birthdate: string) => {
    if (birthdate !== undefined) {
        const date = moment(birthdate, 'YYYY-MM-DD', true);
        date.locale('de');
        return date.format('DD.MM.YYYY') + ' (' + moment().diff(date, 'years') + ' Jahre)';
    }

    return birthdate;
};

export const isDateToday = (date: string) => {
    const momentDate = moment(date);
    const isToday = momentDate.isSame(moment(), 'day');
    return isToday;
};

export const isDateBeforeToday = (date: string | Date) => {
    const momentDate = moment(date);
    const isBeforeToday = momentDate.isBefore(moment(), 'day');
    return isBeforeToday;
};

/**
 * get the elapsed time for a time with format (HH:mm - HH:mm)
 *
 * @param time
 * @returns
 *      the elapsed time for a time with format (HH:mm - HH:mm)
 */
export const getCalculatedElapsedTimeFromEndFormat = (time: string) => {
    const times = time.split(' - ');
    return moment(times[0], 'HH:mm').fromNow();
};

export const getCurrentDateTime = () => {
    // return moment().format('YYYY-MM-DDTHH:mm:ss');
    return moment().format();
};

export const setTimeOnDate = (date: string, time: string) => {
    const timeParts = time.split(':');
    return moment(date)
        .set({ hour: parseInt(timeParts[0]), minute: parseInt(timeParts[1]) })
        .format();
};

/**
 * get the current time with format (HH:mm)
 */
export const getCurrentTime = () => {
    const time = moment().format('LT');
    return time;
};

export const monthNameConverter = (month: string) => {
    let convertedMonth = month;
    if (month.includes('Januar')) {
        convertedMonth = month.replace('Januar', 'Jänner');
    }

    return convertedMonth;
};

/**
 * get the weekday from a date
 *
 * @param date
 *      the date to get the weekday from
 * @param format
 *    the format to return the weekday in (e.g. 'dddd' for the full name of the weekday)
 * @returns
 *  the weekday from a date
 */
export const getWeekDay = (date: string, format: string) => {
    const momentDate = moment(date);
    return momentDate.format(format);
};
