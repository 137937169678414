import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import StyledButton from '../../Buttons/StyledButton';
import StyledCol from '../../Cols/StyledCol';
import { StyledFormLabelForInput } from '../../StyledForm/SyledForm';
import { StyledModal } from '../StyledModal/StyledModal';

interface ICancelAppointmentModal {
    show: boolean;
    onHide: () => void;
    onInput: (reason: string) => void;
    onSubmit: () => void;
    commentPossible: boolean;
}

const CancelAppointmentModal = (props: ICancelAppointmentModal) => {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        {
            props.onSubmit();
        }
    };

    return (
        <StyledModal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Termin stornieren</Modal.Title>
            </Modal.Header>
            <Form className="m-3" noValidate onSubmit={handleSubmit}>
                <Container fluid>
                    <div>
                        <Modal.Body>
                            {props.commentPossible && (
                                <Row style={{ paddingBottom: 30 }}>
                                    <Col>
                                        <Form.Group controlId="reason">
                                            <StyledFormLabelForInput>Stornierungsgrund</StyledFormLabelForInput>
                                            <br />
                                            <Form.Control
                                                type="text"
                                                className="mx-sm-2"
                                                onChange={(e) => {
                                                    props.onInput(e.target.value);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            )}
                            <div>
                                <div style={{ float: 'left' }}>
                                    <StyledCol textAlign="left">
                                        <StyledButton variant="secondary" onClick={props.onHide}>
                                            Abbrechen
                                        </StyledButton>
                                    </StyledCol>
                                </div>
                                <div style={{ float: 'right' }}>
                                    <StyledCol paddingLeft="30px">
                                        <StyledButton type="submit">Termin stornieren</StyledButton>
                                    </StyledCol>
                                </div>
                            </div>
                        </Modal.Body>
                    </div>
                </Container>
            </Form>
            {/* <Modal.Body>
            <h6>Wollen Sie den Termin wirklich stornieren?</h6>
                <div>
                    <div style={{float: 'left'}}>
                        <StyledCol textAlign="left">
                            <StyledButton variant="secondary" onClick={props.onHide}>Abbrechen</StyledButton>
                        </StyledCol>
                    </div>
                    <div style={{float: 'right'}}>
                        <StyledCol paddingLeft="30px">
                            <StyledButton onClick={props.onSubmit}>Termin stornieren</StyledButton>
                        </StyledCol>
                    </div>
                </div>
            </Modal.Body> */}
        </StyledModal>
    );
};

export default CancelAppointmentModal;
