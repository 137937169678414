import { useCallback, useContext } from 'react';
import LoginContext, { IActivePatients } from '../context/loginContext';
import { pushActivePatient } from '../services/RestServices';

export const usePatientSwitch = () => {
    const { state, dispatch } = useContext(LoginContext);

    const MAXACTIVEPATIENTS = 5;

    const isPatientSelected = useCallback(() => {
        if (state.activeUserId && state.activePatient?.length > 0) {
            return true;
        }
        return false;
    }, [state.activePatients, state.activePatient]);

    const getActivePatient = useCallback((): IActivePatients | undefined => {
        if (isPatientSelected()) {
            const pat = state.activePatients.find((pat) => pat.patientId === state.activeUserId);
            return pat;
        }
        return undefined;
    }, [state.activePatients, state.activePatient]);

    const setNewPatient = useCallback(
        (props: IActivePatients) => {
            const currPatients: IActivePatients[] = state.activePatients;

            // check if patient is already in list
            const patIndex = currPatients.findIndex((pat) => pat.patientId === props.patientId);
            if (patIndex !== -1) {
                // patient is already in list
                // move patient to top of list
                const pat = currPatients[patIndex];
                currPatients.splice(patIndex, 1);
                currPatients.unshift(pat);
            } else {
                currPatients.splice(0, 0, {
                    patientId: props.patientId,
                    nameLast: props.nameLast,
                    nameFirst: props.nameFirst,
                    birthDate: props.birthDate,
                });
            }
            if (currPatients.length > MAXACTIVEPATIENTS) {
                currPatients.splice(MAXACTIVEPATIENTS, 1);
            }

            dispatch({
                type: 'SETACTIVEPATIENTS',
                activePatients: currPatients,
            });
            dispatch({
                type: 'SETACTIVEPATIENT',
                activePatient: props.nameFirst + ' ' + props.nameLast,
            });
            dispatch({
                type: 'SETACTIVEUSERID',
                activeUserId: props.patientId,
            });
            pushActivePatient(props.patientId, state.sessionId);
        },
        [state.activePatients, state.activePatient],
    );

    const setLastPatOpened = useCallback(() => {
        const currPatients: IActivePatients[] = [];
        if (state.lastPatOpened) {
            state.lastPatOpened.forEach((pat) => {
                currPatients.push({
                    patientId: pat.patId,
                    nameLast: pat.nameLast,
                    nameFirst: pat.nameFirst,
                    birthDate: pat.birthDate,
                });
            });

            dispatch({
                type: 'SETACTIVEPATIENTS',
                activePatients: currPatients,
            });
            dispatch({
                type: 'SETACTIVEPATIENT',
                activePatient: currPatients[0].nameFirst + ' ' + currPatients[0].nameLast,
            });
            dispatch({
                type: 'SETACTIVEUSERID',
                activeUserId: currPatients[0].patientId,
            });
        }
    }, [state.lastPatOpened]);

    return {
        setNewPatient,
        isPatientSelected,
        getActivePatient,
        setLastPatOpened,
    };
};
