import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from 'react-icons/io';
import { ThemeContext } from 'styled-components';
import LoginContext from '../../../context/loginContext';
import { getTermsHTML } from '../../../services/RestServices';
import StyledButton from '../../Buttons/StyledButton';
import StyledCol from '../../Cols/StyledCol';
import { StyledParagraph } from '../../StyledParagraph/StyledParagraph';
import { StyledModal } from '../StyledModal/StyledModal';

interface IConsigAddModal {
    show: boolean;
    onHide: () => void;
    onSubmit: () => void;
    onDeny: () => void;
    checkbox: () => void;
    checkConsignation: boolean;
    sessionId: string;
    userId: string;
}

const ConsignationAddModal = (props: IConsigAddModal) => {
    const themeContext = useContext(ThemeContext);

    const [activeId, setActiveId] = useState('0');
    const { state } = useContext(LoginContext);
    const [termsHTMLBottom, setTermsHTMLBottom] = useState(false);
    const [htmlTerm, setHTMLTerm] = useState('');

    const submitButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        console.log('getTermsHTML...');
        getTermHTML();
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        submitButtonRef?.current?.setAttribute('disabled', 'disabled');

        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        {
            props.onSubmit();
        }
    };

    function toggleActive(id: string) {
        if (activeId !== id) {
            setActiveId(id);
        } else {
            setActiveId('');
        }
    }

    const getIcon = (id: string) => {
        return activeId === id ? <IoIosArrowDropupCircle size={30} /> : <IoIosArrowDropdownCircle size={30} />;
    };

    const termsHTMLScrollHandling = (e: any) => {
        let bottom = false;
        //const scrollHeight = e.target.scrollHeight;
        const scrollHeight = document.getElementById('termsHTMLAddDiv')?.offsetHeight;
        const scrollTop = e.target.scrollTop;
        const clientHeight = e.target.clientHeight;

        const diff = (scrollHeight ? scrollHeight : 0) - scrollTop;

        if (diff - 1 <= clientHeight) {
            bottom = true;
        }

        if (bottom) {
            setTermsHTMLBottom(true);
        }
    };

    const scrollInfoMessage = () => {
        if (!termsHTMLBottom) {
            return (
                <>
                    <Row>
                        <StyledParagraph color={themeContext.font.alertColor}>
                            Lesen Sie die Einwilligungserklärung vollständig bis zum Schluss durch. Am Textende haben
                            Sie die Möglichkeit, die Zustimmung zu aktivieren.
                        </StyledParagraph>
                    </Row>
                </>
            );
        }

        return null;
    };

    const getTermHTML = async () => {
        if (state.addConsignationsToAsk) {
            const htmlText = await getTermsHTML(state.sessionId, state.addConsignationsToAsk[0]);
            setHTMLTerm(htmlText);
        }

        return '';
    };

    return (
        <StyledModal show={props.show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Einwilligungserklärung</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Accordion defaultActiveKey={activeId}>
                    <Card>
                        <Card.Header style={{ backgroundColor: themeContext.mainColor, textAlign: 'center' }}>
                            <Accordion.Toggle
                                onClick={() => toggleActive('0')}
                                as={Button}
                                variant="link"
                                eventKey="0"
                                style={{ color: 'white' }}
                            >
                                <div style={{ margin: 'auto', width: '100%' }}>
                                    Einwilligungserklärung {getIcon('0')}
                                </div>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body
                                id="termsHTMLAddCardBody"
                                style={{ height: '400px', overflow: 'auto' }}
                                onScroll={termsHTMLScrollHandling}
                            >
                                <div id="termsHTMLAddDiv" dangerouslySetInnerHTML={{ __html: htmlTerm }} />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <Row style={{ paddingTop: 20 }}>
                    <Col>
                        <Form noValidate onSubmit={handleSubmit}>
                            {scrollInfoMessage()}
                            <Row>
                                <Col>
                                    <div key="checkboxAdd1" className="mb-3">
                                        <Form.Check type="checkbox" id="check-2">
                                            <Form.Check.Input
                                                type="checkbox"
                                                disabled={!termsHTMLBottom}
                                                isValid
                                                checked={props.checkConsignation}
                                                onChange={props.checkbox}
                                            />
                                            <Form.Check.Label>
                                                <div style={{ color: 'black' }}>
                                                    <b>
                                                        Ich habe die Einwilligungserklärung zur Kenntnis genommen und
                                                        stimme dieser zu.
                                                    </b>
                                                </div>
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <StyledCol textAlign="left">
                                    <StyledButton variant="secondary" onClick={props.onHide}>
                                        Abbrechen
                                    </StyledButton>
                                </StyledCol>
                                <div style={{ float: 'left' }}>
                                    <Col>
                                        <StyledButton variant="primary" onClick={props.onDeny}>
                                            Ablehnen
                                        </StyledButton>
                                    </Col>
                                </div>
                                <div style={{ float: 'right' }}>
                                    <Col>
                                        <fieldset disabled={!props.checkConsignation}>
                                            <StyledButton ref={submitButtonRef} type="submit">
                                                Ich stimme zu
                                            </StyledButton>
                                        </fieldset>
                                    </Col>
                                </div>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Modal.Body>
        </StyledModal>
    );
};

export default ConsignationAddModal;
