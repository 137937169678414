import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import AlertContext from '../../context/alertContext';
import VideochatContext from '../../context/videochatContext';
import { addChatEntry, addChatEntryAppointment } from '../../services/RestServices';
import ChatInput from './ChatInput';
import ChatMessages, { IChatMessage, MessageType } from './ChatMessages';
import { ChatWidgetBaseContainer, ChatWidgetContainer } from './ChatWidgetContainer';
import ChatWidgetSymbol from './ChatWidgetSymbol';

export enum MessageIdType {
    AppId,
    EnrollCode,
}

export interface IIncomingMessage {
    txt: string;
    isPat?: boolean;
    name: string;
    ts: string;
}

export interface IChat {
    id: string;
    incomingMessages: IIncomingMessage[];
}

export interface IChatExt extends IChat {
    idType: MessageIdType;
    asWidget: boolean;
    showChatInput: boolean;
    show: boolean;
}

export interface IAppointmentChat {
    id: string;
    chatOpen: boolean;
    incomingMessages?: IIncomingMessage[];
    numberUnreadMsg?: number;
}

export const isChatOpen = (appointmentChat: IAppointmentChat[], appId: string) => {
    const appointmentChatEntry = appointmentChat.find((e) => e.id === appId);
    if (appointmentChatEntry) {
        return appointmentChatEntry.chatOpen;
    }
    return false;
};

export const getAppointmentChatEntry = (
    appointmentChat: IAppointmentChat[],
    appId: string,
): IAppointmentChat | undefined => {
    const appointmentChatEntry = appointmentChat.find((e) => e.id === appId);
    return appointmentChatEntry;
};

const Chat = (props: IChatExt) => {
    const [messages, setMessages] = useState<IChatMessage[]>([]);
    const [widgetIconClicked, setWidgetIconClicked] = useState(true);
    const { vsstate, vsdispatch } = useContext(VideochatContext);
    const { alertdispatch } = useContext(AlertContext);

    useEffect(() => {
        const allIncomingMsg: IChatMessage[] = [];
        props.incomingMessages.forEach((message: IIncomingMessage) => {
            const msg: IChatMessage = {
                txt: '',
                time: '',
                type: MessageType.Incoming,
            };
            msg.txt = message.txt;
            msg.time = message.ts;
            msg.type = MessageType.Incoming;
            if (message.isPat) {
                msg.type = MessageType.Outgoing;
            }

            allIncomingMsg.push(msg);
        });

        // setMessages((messages) => [...messages, ...allIncomingMsg]);
        if (allIncomingMsg.length > 0) {
            if (allIncomingMsg.length > messages.length) {
                setMessages(allIncomingMsg);
            }
        }
    }, [props.incomingMessages]);

    useEffect(() => {
        const allIncomingMsg: IChatMessage[] = [];
        if (vsstate.chatMessages) {
            vsstate.chatMessages.forEach((message: IIncomingMessage) => {
                const msg: IChatMessage = {
                    txt: '',
                    time: '',
                    type: MessageType.Incoming,
                };
                msg.txt = message.txt;
                msg.time = message.ts;
                msg.type = MessageType.Incoming;
                if (message.isPat) {
                    msg.type = MessageType.Outgoing;
                }

                allIncomingMsg.push(msg);
            });

            // setMessages((messages) => [...messages, ...allIncomingMsg]);
            if (allIncomingMsg.length > 0) {
                setMessages(allIncomingMsg);
            }
        }
    }, [vsstate.chatMessages]);

    const handleUserMessage = (userMsg: string) => {
        let response;
        if (props.idType === MessageIdType.AppId) {
            response = addChatEntryAppointment(props.id, userMsg, alertdispatch);
        } else if (props.idType === MessageIdType.EnrollCode) {
            response = addChatEntry(props.id, userMsg, alertdispatch);
        }

        if (response) {
            setMessages((messages) => [
                ...messages,
                { txt: userMsg, time: moment().format('L LT'), type: MessageType.Outgoing },
            ]);
        }
    };

    const handleWidgetSymbolClick = () => {
        setWidgetIconClicked(!widgetIconClicked);
        vsdispatch({
            type: 'RESETNUMBERUNREADMESSAGES',
        });
    };

    const ChatWithWidget = () => {
        return (
            <ChatWidgetBaseContainer>
                {widgetIconClicked && (
                    <ChatWidgetContainer fluid>
                        <ChatMessages chatContainerHeight={'400px'} messages={messages} />
                        {props.showChatInput === true && <ChatInput handleUserMessage={handleUserMessage} />}
                    </ChatWidgetContainer>
                )}
                <ChatWidgetSymbol
                    isOpen={widgetIconClicked}
                    handleIconClick={handleWidgetSymbolClick}
                    numberUnreadMsg={vsstate.numberUnreadMessages}
                />
            </ChatWidgetBaseContainer>
        );
    };

    const ChatWithoutWidget = () => {
        return (
            <Container fluid>
                <ChatMessages chatContainerHeight={'200px'} messages={messages} />
                {props.showChatInput === true && <ChatInput handleUserMessage={handleUserMessage} />}
            </Container>
        );
    };

    if (props.show) {
        if (props.asWidget) {
            return <ChatWithWidget />;
        } else {
            return <ChatWithoutWidget />;
        }
    } else {
        return <></>;
    }
};

export default Chat;
