import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import BodyFatCharts from '../../../components/Charts/BodyFat/BodyFatCharts';
import { containerHeader } from '../../../components/Header/Header';
import { charts } from '../../../utils/chartUtils';
import Mainscreen from '../../Mainscreen/Mainscreen';

const ChartsFettmasseContent = () => {
    return (
        <Container fluid>
            <BodyFatCharts />
        </Container>
    );
};

const ChartsFettmasse = () => {
    return (
        <Mainscreen>
            <Container fluid>
                <Row>
                    <Col>
                        {containerHeader(charts.FATMASS.name)}
                        <ChartsFettmasseContent />
                    </Col>
                </Row>
            </Container>
        </Mainscreen>
    );
};

export default ChartsFettmasse;
