import { useContext, useEffect, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import { IconBaseProps } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import LoginContext from '../../context/loginContext';
import useScreenResolution from '../../hooks/useScreenResolution';
import { isMobile } from '../../utils/screenResolution';
import { CenterRow } from '../Rows/StyledRow';

interface ISidebarItem {
    route: string;
    label: string;
    icon: IconBaseProps;
    className?: string;
    forMoreMenu?: boolean;
}

const SidebarItem = (props: ISidebarItem) => {
    const themeContext = useContext(ThemeContext);
    const { state } = useContext(LoginContext);
    const [isActive, setIsActive] = useState(false);

    const screenSize = useScreenResolution();

    useEffect(() => {
        if (state.activeSidebarItem === props.label || state.activeSidebarItem === props.route) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }, [state.activeSidebarItem]);

    if (isMobile(screenSize.width)) {
        if (props.forMoreMenu) {
            return (
                <NavDropdown.Item
                    eventKey={props.label}
                    className={props.className}
                    as={Link}
                    to={props.route}
                    style={{
                        color: isActive ? themeContext.icon.default.color : 'black',
                        backgroundColor: 'transparent',
                        paddingLeft: '5px',
                        paddingRight: '5px',
                    }}
                >
                    <Container style={{ display: 'block', paddingLeft: '5px', paddingRight: '5px' }}>
                        <Row>
                            <Col>{props.icon}</Col>
                            <Col style={{ fontSize: '0.8rem' }}>{props.label}</Col>
                        </Row>
                    </Container>
                </NavDropdown.Item>
            );
        } else {
            return (
                <Nav.Link
                    eventKey={props.label}
                    className={props.className}
                    as={Link}
                    to={props.route}
                    style={{
                        color: isActive ? themeContext.icon.default.color : 'black',
                        backgroundColor: 'transparent',
                        paddingLeft: '5px',
                        paddingRight: '5px',
                    }}
                >
                    <Container style={{ display: 'block', paddingLeft: '5px', paddingRight: '5px' }}>
                        <CenterRow>
                            <Col>{props.icon}</Col>
                        </CenterRow>
                        <CenterRow>
                            <Col>{props.label}</Col>
                        </CenterRow>
                    </Container>
                </Nav.Link>
            );
        }
    } else {
        return (
            <Nav.Link
                eventKey={props.label}
                className={props.className}
                as={Link}
                to={props.route}
                style={{
                    color: themeContext.passiveColor,
                    backgroundColor: isActive ? themeContext.sidebar.itemBackground : 'transparent',
                    alignItems: 'center',
                    borderRadius: '26px',
                    padding: '3px',
                    marginBottom: '5px',
                }}
            >
                <Container style={{ display: 'block' }}>
                    <Row>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div style={{ float: 'right', alignItems: 'center' }}>{props.icon}</div>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ alignItems: 'center' }}>
                            {props.label}
                        </Col>
                    </Row>
                </Container>
            </Nav.Link>
        );
    }
};

export default SidebarItem;
