import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import TerminierungContext from '../../context/terminierungContext';
import { Paths } from '../../Routes';
import { IAppointment } from '../../utils/appointmentUtils';
import { translateStatus } from '../../utils/pcpUtils';
import { StyledBadge } from '../Badges/StyledBadge';
import MyTooltip from '../Tooltip/MyTooltip';

const CompleteAppointmentBadge = (props: IAppointment) => {
    const navigate = useNavigate();
    const { tmdispatch } = useContext(TerminierungContext);

    const handleCompleteAppointment = () => {
        tmdispatch({
            type: 'SETCANCELNEWAPPOINTMENTDATA',
            cancelNewAppointmentData: {
                showModal: false,
                data: props,
                reason: '',
            },
        });
        tmdispatch({
            type: 'SELECTCATEGORY',
            selectedCategory: {
                name: props.serviceType[0].coding[0].display,
                id: props.serviceType[0].coding[0].id,
                countAPKs: 2,
                oneMacro: false,
            },
        });
        navigate(Paths.TERMINIERUNG_ALTERNATE_DATE);
    };

    return (
        <>
            <MyTooltip
                id="CompleteAppointmentTooltip"
                text="Terminserie vervollständigen"
                iconDiv={true}
                divWidth="110px"
                marginLeft="auto"
                marginRight="0"
                icon={
                    <StyledBadge pill status={props.status} onClick={() => handleCompleteAppointment()}>
                        {translateStatus(props.status)}
                    </StyledBadge>
                }
            ></MyTooltip>
        </>
    );
};

export default CompleteAppointmentBadge;
