import { useContext } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { AiOutlineUser } from 'react-icons/ai';
import { RiLogoutBoxRLine } from 'react-icons/ri';
import { ThemeContext } from 'styled-components';
import { IconDiv } from '../../components/Div/StyledDiv';
import Avatar from '../../components/SidebarItems/Avatar';
import MyTooltip from '../../components/Tooltip/MyTooltip';

export interface INavbarCheckInWL {
    onLogout?(): void;
    showAvatarIcon: boolean;
    onProfileClick?(): void;
    showLoginIcon: boolean;
    onLoginClick?(): void;
    wpName?: string;
}

const NavbarCheckInWL = (props: INavbarCheckInWL) => {
    const themeContext = useContext(ThemeContext);

    return (
        <Navbar expand={true}>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            {props.wpName && props.wpName.length > 0 && (
                <div className="justify-content-start">
                    <p>
                        Arbeitsplatz: <b>{props.wpName}</b>
                    </p>
                </div>
            )}
            <Navbar.Collapse className="justify-content-end">
                <Nav>
                    {/* <Nav.Link as={'div'}>
                        <VolumeIcon />
                    </Nav.Link> */}
                    {/*
                    <Nav.Link as={'div'}>
                        <Telephone />
                    </Nav.Link>
                    */}
                    {props.showAvatarIcon ? (
                        <Nav.Link onClick={props.onProfileClick}>
                            <Avatar />
                        </Nav.Link>
                    ) : null}
                    {props.showLoginIcon ? (
                        <Nav.Link as={'div'}>
                            <IconDiv onClick={props.onLoginClick}>
                                <MyTooltip
                                    id="LoginTooltipWL"
                                    text="Anmelden"
                                    iconDiv={true}
                                    divWidth="35px"
                                    marginLeft="0"
                                    marginRight="auto"
                                    icon={
                                        <AiOutlineUser
                                            size={35}
                                            color={themeContext.icon.default.color}
                                            style={{ paddingTop: 5 }}
                                        />
                                    }
                                ></MyTooltip>
                            </IconDiv>
                        </Nav.Link>
                    ) : null}
                    <Nav.Link as={'div'}>
                        <IconDiv onClick={props.onLogout}>
                            <MyTooltip
                                id="LogoutTooltipWL"
                                text="Abmelden"
                                iconDiv={true}
                                divWidth="35px"
                                marginLeft="0"
                                marginRight="auto"
                                icon={
                                    <RiLogoutBoxRLine
                                        size={35}
                                        color={themeContext.icon.default.color}
                                        style={{ paddingTop: 5 }}
                                    />
                                }
                            ></MyTooltip>
                        </IconDiv>
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default NavbarCheckInWL;
