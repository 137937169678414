export type BarcodeScanCallback = (txt: string) => void;
export interface IBarCodeScanner {
    isInScan: boolean;
    prefixChecked: boolean;
    stopEventListing: any;
    onScanKeyDown: any;
    onScanKey: any;
    lastKeyPressed: number | null;
    stopTimer: any;
    onScanKeyUp: any;

    startup: any;
    removeListeners: any;
    scanPrefix: string;

    scanTxt: string;
    onScanFinish: BarcodeScanCallback | null;
}

const ScanUtils = (): IBarCodeScanner | null => {
    const scanStart = 13;
    const scanStart1 = 22;
    const scanStart1A = 60;
    const scanEnd = 13;
    const scanEnd1 = 226;
    const scanEnd1A = 60;
    const maxDTFirstChar = 300;
    const checkCtrlKey = true;
    const checkKVForm = true;

    /*
    const isKVMForm = function(txt: string): boolean {
        if (txt.length < 3) {
            return false;
        }
        if ((txt.charAt(0) >= '0') && (txt.charAt(0) <= '9')
            && (txt.charAt(1) >= '0') && (txt.charAt(1) <= '9')
            && (txt.charAt(2) == '\t')) {
            return true;
        }
        return false;
    };
    */
    const isKVMForm1st = function (txt: string): boolean {
        if (txt.length < 2) {
            return false;
        }
        if (txt.charAt(0) >= '0' && txt.charAt(0) <= '9' && txt.charAt(1) >= '0' && txt.charAt(1) <= '9') {
            return true;
        }
        return false;
    };

    /*
    const isQREscape = function (txt: string): boolean {
        return txt.startsWith('\\0026') || txt.startsWith('\\000026');
    };
    */

    const barcodescanner: IBarCodeScanner = {
        isInScan: false,
        prefixChecked: false,
        lastKeyPressed: null,
        stopTimer: null,
        scanPrefix: '',
        scanTxt: '',
        onScanFinish: null,
        // barcodescanner.onScanFinsh = null;
        stopEventListing: function (this: HTMLElement, ev: KeyboardEvent) {
            if (ev.stopPropagation) {
                ev.stopPropagation();
            } else {
                ev.cancelBubble = true;
            }

            if (ev.preventDefault) {
                ev.preventDefault();
            } else {
                ev.returnValue = false;
            }
            return false;
        },

        onScanKeyDown: function (this: HTMLElement, ev: KeyboardEvent) {
            if (ev.ctrlKey && checkCtrlKey && barcodescanner.isInScan) {
                ev.stopPropagation();
                ev.preventDefault();
                console.log('found ctrlKey???');
                return false;
            }
            // xml.doLog('onScanKeyDown (' + barcodescanner.isInScan + '): charcode=' + ev.charCode + '/keyCode=' + ev.keyCode + '/txt=barcodescanner.scanTxt=\n>' + barcodescanner.scanTxt + '<\n', true, true);
            if (ev.keyCode == 9 && barcodescanner.isInScan && checkKVForm && isKVMForm1st(barcodescanner.scanTxt)) {
                barcodescanner.scanTxt += '\t';
                ev.stopPropagation();
                ev.preventDefault();
                return false;
            }
            if (!barcodescanner.isInScan) {
                if (
                    ev.keyCode == scanStart ||
                    ev.keyCode == scanStart1 ||
                    ev.keyCode == scanStart1A ||
                    !barcodescanner.scanPrefix
                ) {
                    barcodescanner.isInScan = true;
                    barcodescanner.prefixChecked = false;
                    barcodescanner.scanTxt = '';
                    barcodescanner.lastKeyPressed = new Date().getTime();
                }
            } else if (barcodescanner.isInScan) {
                if (barcodescanner.lastKeyPressed) {
                    const currentDT = new Date().getTime();
                    if (currentDT > barcodescanner.lastKeyPressed + maxDTFirstChar) {
                        // it seems, this is the real keyboard. do not process further messages
                        console.log(
                            'Stop barcode - key - handling. to slow after scanStart (barcodescanner.scanTxt=' +
                                barcodescanner.scanTxt +
                                ')',
                        );
                        barcodescanner.isInScan = false;
                        barcodescanner.lastKeyPressed = null;
                    } else {
                        barcodescanner.lastKeyPressed = currentDT;
                    }
                }
                console.log('onScanKeyDown (isInScan): charcode=' + ev.charCode + '/keyCode=' + ev.keyCode);
                if (barcodescanner.stopTimer) {
                    window.clearTimeout(barcodescanner.stopTimer);
                    barcodescanner.stopTimer = null;
                }
                if (scanEnd && ev.keyCode == scanEnd) {
                    barcodescanner.onScanFinish ? barcodescanner.scanTxt : null;
                    barcodescanner.scanTxt = '';
                    barcodescanner.isInScan = false;
                    barcodescanner.lastKeyPressed = null;
                    console.log('Stop barcode - key - handling. found scanEnd');
                } else if (ev.keyCode == scanEnd1 || ev.keyCode == scanEnd1A || !barcodescanner.scanPrefix) {
                    barcodescanner.stopTimer = window.setTimeout(function () {
                        if (barcodescanner.scanTxt.length > 20 && barcodescanner.onScanFinish) {
                            barcodescanner.onScanFinish(barcodescanner.scanTxt);
                        }
                        barcodescanner.isInScan = false;
                        barcodescanner.lastKeyPressed = null;
                        barcodescanner.stopTimer = null;
                        barcodescanner.scanTxt = '';
                    }, 500);
                }
            }
            // console.log('scan char:, ml.onScanKeyDown..., ev.charCode=' + ev.charCode + ', isInScan=' + ml.isInScan + ', ml.scanText=' + ml.scanText + ', keyCode=' + ev.keyCode);
            if (barcodescanner.isInScan) {
                // return barcodescanner.stopEventListing(ev);
            }
        },

        onScanKey: function (this: HTMLElement, ev: KeyboardEvent) {
            if (barcodescanner.isInScan) {
                console.log('onScanKey (isInScan): charcode=' + ev.charCode + '/keyCode=' + ev.keyCode);
                const chr = String.fromCharCode(ev.keyCode || ev.charCode);
                if (chr == String.fromCharCode(scanStart) || (ev.keyCode || ev.charCode) == scanStart) {
                    console.log('onScanKey: found scanstart, do nothing');
                    return;
                }
                barcodescanner.scanTxt += chr;
                if (!barcodescanner.prefixChecked && barcodescanner.scanPrefix) {
                    if (barcodescanner.scanTxt.length == barcodescanner.scanPrefix.length) {
                        if (barcodescanner.scanTxt == barcodescanner.scanPrefix) {
                            barcodescanner.prefixChecked = true;
                        }
                    } else if (barcodescanner.scanTxt.length > barcodescanner.scanPrefix.length) {
                        barcodescanner.isInScan = false;
                    }
                }
                // console.log('onScanKey: ' + barcodescanner.scanTxt);
                if (barcodescanner.scanPrefix) {
                    return barcodescanner.stopEventListing(ev);
                }
            }
        },

        onScanKeyUp: function (this: HTMLElement, ev: KeyboardEvent) {
            if (barcodescanner.isInScan) {
                // return barcodescanner.stopEventListing(ev);
            }
        },

        startup: function (elem: HTMLElement, scanPrefix: string, scanDone: BarcodeScanCallback) {
            elem.removeEventListener('keydown', barcodescanner.onScanKeyDown, true);
            elem.addEventListener('keydown', barcodescanner.onScanKeyDown, true);
            elem.removeEventListener('keypress', barcodescanner.onScanKey, true);
            elem.addEventListener('keypress', barcodescanner.onScanKey, true);
            elem.removeEventListener('keyup', barcodescanner.onScanKeyUp, true);
            elem.addEventListener('keyup', barcodescanner.onScanKeyUp, true);
            barcodescanner.isInScan = false;
            barcodescanner.scanTxt = '';
            barcodescanner.scanPrefix = scanPrefix;

            barcodescanner.onScanFinish = scanDone;
        },

        removeListeners: function (elem: HTMLElement) {
            if (elem) {
                elem.removeEventListener('keydown', barcodescanner.onScanKeyDown, true);
                elem.removeEventListener('keypress', barcodescanner.onScanKey, true);
                elem.removeEventListener('keyup', barcodescanner.onScanKeyUp, true);
            }
            if (barcodescanner.stopTimer) {
                window.clearTimeout(barcodescanner.stopTimer);
                barcodescanner.stopTimer = null;
            }

            barcodescanner.onScanFinish = null;
        },
    };
    return barcodescanner;
};

export default ScanUtils;
