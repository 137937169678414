import { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import NavbarMenu from '../../container/Navbar/Navbar';
import Sidebar from '../../container/Sidebar/Sidebar';
import QuestionnaireContext from '../../context/questionnaireContext';
import { getIFrameURL } from '../../services/RestServices';
import StyledButton from '../Buttons/StyledButton';
import StyledCol from '../Cols/StyledCol';
import DragNDropArea from '../Div/DragNDropArea';
import { containerHeader } from '../Header/Header';

const IFrameContent = () => {
    const { qstate } = useContext(QuestionnaireContext);

    // console.log('qstate: ', qstate);

    const formId = qstate.selectedQuestionnaire.resource.id;
    const n = qstate.selectedQuestionnaire.resource.extension[0].valueString.lastIndexOf('='); // get index of last = in string
    const patId = qstate.selectedQuestionnaire.resource.extension[0].valueString.substring(n + 1); // extract patientId after last =

    const [iFrame, setIFrame] = useState<string | false>('');

    //const iFrameSrc = 'data:image/jpg;base64,' + iFrame;
    const iFrameSrc = '' + iFrame;

    const getLinkForIFrame = async () => {
        const iframeResp = await getIFrameURL(formId, patId);
        // console.log('iiframeRep: ', iframeResp);
        setIFrame(iframeResp);
    };

    useEffect(() => {
        getLinkForIFrame();
    }, []);

    const navigate = useNavigate();
    return (
        <>
            <iframe
                src={iFrameSrc}
                width="100%"
                height="100%"
                name="iFrame"
                style={{ height: '-webkit-fill-available' }}
            ></iframe>
            <Row>
                {/* <Col>
                    {iFrame !== '' ? (
                        <Image src={iFrameSrc} fluid style={{ paddingTop: 20 }} />
                    ) : <></>}
                </Col> */}
            </Row>
            <Row>
                <Col>
                    <StyledCol paddingLeft="0px" textAlign="left">
                        <StyledButton variant="secondary" onClick={() => navigate(-1)}>
                            Zurück
                        </StyledButton>
                    </StyledCol>
                </Col>
            </Row>
        </>
    );
};

const IFrameFragebogen = () => {
    const themeContext = useContext(ThemeContext);

    return (
        <div>
            <header>
                <NavbarMenu />
            </header>
            <Container fluid>
                <Row>
                    <Col
                        md={3}
                        xl={2}
                        xs={12}
                        className="vh-100 d-flex flex-column"
                        style={{ backgroundColor: themeContext.mainColor }}
                    >
                        <Sidebar paddingTop="80px" />
                    </Col>
                    <DragNDropArea>
                        {containerHeader('Fragebogen')}
                        <IFrameContent />
                    </DragNDropArea>
                </Row>
            </Container>
        </div>
    );
};

export default IFrameFragebogen;
