import { useContext } from 'react';
import BadgesList from '../../components/Badges/BadgesList';
import { LeftDivMain } from '../../components/Div/StyledDiv';
import { StyledMainHeader } from '../../components/StyledText/StyledHeader';
import AlertContext from '../../context/alertContext';
import LoginContext, { ICodeName } from '../../context/loginContext';
import TerminierungContext from '../../context/terminierungContext';
import useScreenResolution from '../../hooks/useScreenResolution';

interface IAppointmentCategoryLocation {
    handleLocationClick(location: ICodeName): void;
}

const AppointmentCategoryLocation = (props: IAppointmentCategoryLocation) => {
    const { alertdispatch } = useContext(AlertContext);
    const { state } = useContext(LoginContext);
    const { tmstate, tmdispatch } = useContext(TerminierungContext);

    const screenSize = useScreenResolution();

    const getBadgesList = () => {
        const allBadges: string[] = [];
        state.anmeldeContexts.forEach((e) => {
            allBadges.push(e.name);
        });

        return allBadges;
    };

    const handleLocationClick = (locationName: string) => {
        const location = state.anmeldeContexts.find((loc) => loc.name === locationName);

        if (location) {
            tmdispatch({
                type: 'SELECTLOCATION',
                selectedLocation: location,
            });
            props.handleLocationClick(location);
        }
    };

    return (
        <LeftDivMain>
            <StyledMainHeader>Standort wählen</StyledMainHeader>
            <p>Wählen Sie den gwünschten Standort aus</p>
            <br />
            <BadgesList
                badgesList={getBadgesList()}
                showAsGrid={screenSize.width < 440 ? true : false}
                handleBadgeClick={handleLocationClick}
            />
        </LeftDivMain>
    );
};

export default AppointmentCategoryLocation;
