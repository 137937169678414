import * as React from 'react';
import { useContext, useRef, useState } from 'react';
import { Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { BiCamera } from 'react-icons/bi';
import { FiPlusCircle, FiUpload } from 'react-icons/fi';
import { ThemeContext } from 'styled-components';
import { MainHeaderButtonCol, MainHeaderCol } from '../../components/Cols/StyledCol';
import { CenterDiv, MainHeaderButtonIcon, StickyHeaderTitleDiv } from '../../components/Div/StyledDiv';
import { MainH1Title } from '../../components/Header/Header';
import WebcamPreviewModal from '../../components/Modals/WebcamModal/WebcamPreviewModal';
import RefreshButton from '../../components/RefreshButton/RefreshButton';
import { MainHeaderRow } from '../../components/Rows/StyledRow';
import UploadContentCards from '../../components/UploadContentCards/UploadContentCards';
import AlertContext, { AlertOnHide, AlertType } from '../../context/alertContext';
import LoginContext from '../../context/loginContext';
import useScreenResolution from '../../hooks/useScreenResolution';
import { sendPhotoDiagnosticReport } from '../../services/fhir/FHIRDiagnosticReports';
import { showAlertMessage } from '../../utils/alertHandling';
import { isFileTypeAccepted } from '../../utils/fileUtils';
import { IPhoto } from '../../utils/photoUtils';

interface IUploadContent {
    headerTitle: string;
    fileUploadButtonName: string;
    cameraUploadButtonName: string;
    acceptedFileTypes: string;
    maxHeight?: string;
    overflowY?: string;
}

const UploadContent = (props: IUploadContent): JSX.Element => {
    const { state } = useContext(LoginContext);
    const screenSize = useScreenResolution();
    const themeContext = useContext(ThemeContext);
    const { alertdispatch } = useContext(AlertContext);
    const [showOverlay, setShowOverlay] = useState(false);
    const [openWebcamPreview, setOpenWebcamPreview] = useState(false);
    const [refreshPhotos, setRefreshPhotos] = useState(false);

    const [photoPreviewColl, setPreviewColl] = useState<IPhoto[]>([]);

    const isSmallScreen = screenSize.width < 500 ? true : false;

    const photoUploadRef = useRef<HTMLInputElement>(null);

    const handleOpenPreview = () => {
        setOpenWebcamPreview(true);
        setShowOverlay(false);
    };

    const handleClosePreview = () => {
        setOpenWebcamPreview(false);
        setPreviewColl([]);
    };

    const handleAddPhotoToPreview = (photo: string) => {
        if (photo) {
            const photoData = photo.split(',').pop();
            if (photoData) {
                const newPhoto: IPhoto = {
                    name: '',
                    lastModifiedDate: new Date(),
                    size: 0,
                    type: '',
                    data: photoData,
                };
                setPreviewColl((photoPreviewColl) => [...photoPreviewColl, newPhoto]); // add new photo to array in hook
            }
        }
    };

    const handleRemovePhoto = (photoIndex: number) => {
        const temp = [...photoPreviewColl];
        temp.splice(photoIndex, 1);
        setPreviewColl(temp);
    };

    const handleSaveCollection = () => {
        if (photoPreviewColl.length > 0) {
            uploadSelectedPhotos();
        }
        setOpenWebcamPreview(false);
    };

    const uploadSelectedPhotos = async () => {
        const response = await sendPhotoDiagnosticReport(state, photoPreviewColl, alertdispatch);
        if (response && response.status === 201) {
            setPreviewColl([]);
        } else {
            showAlertMessage({
                alertTitle: 'Probleme beim Upload',
                alertTxt: 'Es traten Problem beim Upload der Fotos auf.',
                alertType: AlertType.error,
                onHide: AlertOnHide.onlyClose,
                alertdispatch: alertdispatch,
            });
        }
        setRefreshPhotos(true);
    };

    const handleUploadOpen = async (photoUploadRef: React.RefObject<HTMLInputElement>) => {
        photoUploadRef?.current?.click();
    };

    const areFileTypesAccepted = (files: any[]) => {
        let isAccepted = true;
        if (props.acceptedFileTypes) {
            for (let i = 0; i < files.length; i++) {
                if (files[i].name) {
                    if (!isFileTypeAccepted(files[0].name, props.acceptedFileTypes)) {
                        isAccepted = false;
                        break;
                    }
                }
            }

            if (!isAccepted) {
                showAlertMessage({
                    alertTitle: 'Falsches Dateiformat',
                    alertTxt:
                        'Es werden nur Dateien mit dem Format ' + props.acceptedFileTypes.toString() + ' akzeptiert',
                    alertType: AlertType.error,
                    onHide: AlertOnHide.onlyClose,
                    alertdispatch: alertdispatch,
                });
            }
        }

        return isAccepted;
    };

    const handleSelectedPhoto = async (e: React.ChangeEvent<any>) => {
        setShowOverlay(false);
        const files: any[] = Array.from(e.target.files);

        if (areFileTypesAccepted(files)) {
            for (let i = 0; i < files.length; i++) {
                const base64Src = await toBase64(files[i]);
                const myString = base64Src as string;
                const myModString = myString.split(',').pop();
                if (myModString) {
                    photoPreviewColl.push({
                        name: files[i].name,
                        lastModifiedDate: files[i].lastModifiedDate,
                        size: files[i].size,
                        type: files[i].type,
                        data: myModString,
                    });
                }
            }
            uploadSelectedPhotos();
        }
    };

    const toBase64 = (file: any) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handleRefreshClick = () => {
        setRefreshPhotos(true);
    };

    const resetRefresh = () => {
        setRefreshPhotos(false);
    };

    return (
        <div>
            <WebcamPreviewModal
                show={openWebcamPreview}
                onCancel={() => handleClosePreview()}
                photos={photoPreviewColl}
                onSave={() => handleSaveCollection()}
                onRemove={(photoIndex: number) => handleRemovePhoto(photoIndex)}
                addPhoto={(photo: string) => handleAddPhotoToPreview(photo)}
            />
            <StickyHeaderTitleDiv>
                <MainHeaderRow noGutters>
                    {!isSmallScreen && <MainHeaderCol></MainHeaderCol>}
                    <MainHeaderCol>
                        <MainH1Title style={{ fontSize: isSmallScreen ? '1.5rem' : '' }}>
                            {props.headerTitle}
                        </MainH1Title>
                    </MainHeaderCol>
                    <MainHeaderButtonCol>
                        <OverlayTrigger
                            trigger="click"
                            show={showOverlay}
                            key="right"
                            placement="bottom-end"
                            onToggle={() => setShowOverlay(!showOverlay)}
                            overlay={
                                <Popover id={'popover-positioned-right'}>
                                    <Popover.Content>
                                        <Form.File id="formcheck-api-custom" custom>
                                            <Button
                                                variant="light"
                                                block
                                                onClick={() => handleUploadOpen(photoUploadRef)}
                                            >
                                                <div style={{ float: 'left' }}>
                                                    <FiUpload size={30} type="file" style={{ paddingBottom: '5px' }} />{' '}
                                                    {props.fileUploadButtonName}
                                                </div>
                                                <input
                                                    type="file"
                                                    id="multi"
                                                    ref={photoUploadRef}
                                                    onChange={(e) => handleSelectedPhoto(e)}
                                                    multiple
                                                    hidden
                                                    accept={props.acceptedFileTypes ? props.acceptedFileTypes : ''}
                                                />
                                            </Button>
                                        </Form.File>
                                        <Button variant="light" block onClick={() => handleOpenPreview()}>
                                            <div style={{ float: 'left' }}>
                                                <BiCamera size={30} style={{ paddingBottom: '5px' }} />
                                                {props.cameraUploadButtonName}
                                            </div>
                                        </Button>
                                    </Popover.Content>
                                </Popover>
                            }
                        >
                            <MainHeaderButtonIcon>
                                <FiPlusCircle
                                    size={themeContext.icon.size}
                                    color={themeContext.header.main.buttonColor}
                                    strokeWidth={themeContext.icon.strokeWidth}
                                />
                            </MainHeaderButtonIcon>
                        </OverlayTrigger>
                        <RefreshButton
                            buttonColor={themeContext.header.main.buttonColor}
                            iconSize={themeContext.icon.size}
                            strokeWidth={themeContext.icon.strokeWidth}
                            handleClick={handleRefreshClick}
                        />
                    </MainHeaderButtonCol>
                </MainHeaderRow>
            </StickyHeaderTitleDiv>
            <CenterDiv maxHeight={props.maxHeight} overflowY={props.overflowY}>
                <UploadContentCards
                    refreshPhotos={refreshPhotos}
                    resetRefresh={resetRefresh}
                    acceptedFileTypes={props.acceptedFileTypes}
                />
            </CenterDiv>
        </div>
    );
};

export default UploadContent;
