import { useCallback } from 'react';
import { ISignotec, useSignotecConnector } from './useSignotecConnector';

export const SignaturePadDeviceName = {
    SIGNOTEC_SIGMA: 'Sigma USB',
    SIGNOTEC_GAMMA: 'Gamma USB',
};

export interface ISignaturePad {
    signotecProps: ISignotec;
}

export const useSignaturePad = (props: ISignaturePad) => {
    const { loadSignotec, unloadSignotec, clearSignotec, sendSignotec } = useSignotecConnector(props.signotecProps);

    const loadSignaturePad = useCallback(() => {
        loadSignotec();
    }, []);

    const unloadSignaturePad = useCallback((deviceName: string) => {
        // if (
        //     deviceName === SignaturePadDeviceName.SIGNOTEC_SIGMA ||
        //     deviceName === SignaturePadDeviceName.SIGNOTEC_GAMMA
        // ) {
        //     unloadSignotec();
        // }
        unloadSignotec();
    }, []);

    const clearSignaturePad = useCallback((deviceName: string, canvasRef: any) => {
        if (
            deviceName === SignaturePadDeviceName.SIGNOTEC_SIGMA ||
            deviceName === SignaturePadDeviceName.SIGNOTEC_GAMMA
        ) {
            clearSignotec(canvasRef);
        }
    }, []);

    const sendSignaturePad = useCallback((deviceName: string) => {
        if (
            deviceName === SignaturePadDeviceName.SIGNOTEC_SIGMA ||
            deviceName === SignaturePadDeviceName.SIGNOTEC_GAMMA
        ) {
            sendSignotec();
        }
    }, []);

    return {
        loadSignaturePad,
        unloadSignaturePad,
        clearSignaturePad,
        sendSignaturePad,
    };
};
