import { memo } from 'react';
import SidebarItemList from '../../components/SidebarItems/SidebarItemList';
import './Sidebar.css';

interface ISidebar {
    backgroundColor?: string;
    backgroundImage?: string;
    width?: string;
    paddingTop?: string;
    paddingLeft?: string;
    paddingRight?: string;
}

const Sidebar = memo((props: ISidebar) => {
    return (
        <div
            className="sidebar"
            style={{
                paddingTop: props.paddingTop ? props.paddingTop : '0px',
                paddingLeft: props.paddingLeft ? props.paddingLeft : '0px',
                paddingRight: props.paddingRight ? props.paddingRight : '0px',
                width: props.width ? props.width : '100%',
                backgroundColor: props.backgroundColor ? props.backgroundColor : 'transparent',
                backgroundImage: props.backgroundImage ? props.backgroundImage : 'none',
            }}
        >
            <SidebarItemList />
        </div>
    );
});

Sidebar.displayName = 'Sidebar';

export default Sidebar;
