import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ExecuteRuleContext from '../../context/executeRuleContext';
import { Paths } from '../../Routes';
import { setRuleProperties } from '../../utils/executeRuleUtils';
import { getQueryParams } from '../../utils/urlUtils';

const ExecuteRule = () => {
    const navigate = useNavigate();
    const { executeruledispatch } = useContext(ExecuteRuleContext);

    const hashParams = getQueryParams(location.hash);
    setRuleProperties(hashParams, executeruledispatch);
    navigate(Paths.HOME);
    return null;
};

export default ExecuteRule;
