import { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import TerminierungContext from '../../../context/terminierungContext';
import { getAPKNrFromExtension } from '../../../utils/appointmentUtils';
import StyledButton from '../../Buttons/StyledButton';
import StyledCol from '../../Cols/StyledCol';

export const ModalCst = styled(Modal)`
    .modal-dialog {
        max-width: 50%;
        margin: 1.75rem auto;
    }
    .modal-content {
        border-radius: ${(prop) => (prop.borderRadius ? prop.borderRadius : prop.theme.modal.borderRadius)};
        box-shadow: ${(prop) => (prop.boxShadow ? prop.boxShadow : prop.theme.modal.boxShadow)};
    }
`;

interface IAskNewAppointmentModal {
    show: boolean;
    onHide: () => void;
    onCancelOtherAppointment: () => void;
    onRenewAppointment: () => void;
}

const AskNewAppointmentModal = (props: IAskNewAppointmentModal) => {
    const { tmstate } = useContext(TerminierungContext);
    const [cancelAPKNr, setCancelAPKNr] = useState('1');
    const [bookedAPKNr, setBookedAPKNr] = useState('1');

    // useEffect(() => {}, [props.show]);

    useEffect(() => {
        if (props.show) {
            getCancelAPKNr();
        }

        // return () => {};
    }, [props.show]);

    const getCancelAPKNr = () => {
        const cancelNr = getAPKNrFromExtension(tmstate.cancelNewAppointmentData.data.extension);
        if (cancelNr === 0) {
            setCancelAPKNr('1');
            setBookedAPKNr('2');
        } else if (cancelNr === 1) {
            setCancelAPKNr('2');
            setBookedAPKNr('1');
        }
    };

    return (
        <>
            <ModalCst show={props.show}>
                <Modal.Body>
                    <Container>
                        <Row className="mb-2">
                            <StyledCol paddingLeft="0px" textAlign="center">
                                Möchten Sie einen Ersatz für Termin {cancelAPKNr} suchen?
                            </StyledCol>
                        </Row>
                        <Row className="mb-5">
                            <StyledCol paddingRight="0px" textAlign="center">
                                <StyledButton variant="primary" onClick={props.onRenewAppointment}>
                                    Ersatztermin suchen
                                </StyledButton>
                            </StyledCol>
                        </Row>
                        <Row className="mb-5">
                            <StyledCol paddingLeft="0px" textAlign="center">
                                --------------- ODER ---------------
                            </StyledCol>
                        </Row>
                        <Row className="mb-2">
                            <StyledCol paddingLeft="0px" textAlign="center">
                                Möchten Sie den Termin {bookedAPKNr} auch stornieren?
                            </StyledCol>
                        </Row>
                        <Row className="mb-2">
                            <StyledCol paddingRight="0px" textAlign="center">
                                <StyledButton variant="primary" onClick={props.onCancelOtherAppointment}>
                                    Termin {bookedAPKNr} stornieren
                                </StyledButton>
                            </StyledCol>
                        </Row>
                    </Container>
                </Modal.Body>
            </ModalCst>
        </>
    );
};

export default AskNewAppointmentModal;
