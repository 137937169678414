import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { ParagraphFontSize } from '../StyledParagraph/StyledParagraph';
import { CategoryBadge } from './StyledBadge';

interface IBadgesList {
    badgesList: string[];
    handleBadgeClick(badgeName: string): void;
    showAsGrid: boolean;
}

const BadgesList = (props: IBadgesList) => {
    const themeContext = useContext(ThemeContext);

    const handleBadgeClick = (badge: string) => {
        props.handleBadgeClick(badge);
    };

    const getAllBadges = () => {
        const allBadges: any[] = [];

        props.badgesList.forEach((badge) => {
            allBadges.push(
                <CategoryBadge
                    key={badge}
                    backgroundColor="#e4e8ed"
                    backgroundColorSelected={themeContext.mainColor}
                    color="black"
                    colorSelected="white"
                    marginRight="20px"
                    marginBottom="20px"
                    fontSize={ParagraphFontSize.Medium}
                    onClick={() => handleBadgeClick(badge)}
                >
                    {badge}
                </CategoryBadge>,
            );
        });

        return allBadges;
    };

    return <div style={{ display: props.showAsGrid ? 'grid' : 'block' }}>{getAllBadges()}</div>;
};

export default BadgesList;
