import { createContext } from 'react';
import { IFile } from '../utils/fileUtils';

export type InitialDokumenteType = {
    filedata: IFile;
};

export const initialDokumenteState = {
    filedata: {
        name: '',
        lastModified: 0,
        lastModifiedDate: new Date(),
        size: 0,
        type: '',
        data: '',
        title: '',
        conclusion: '',
        uploaded: false,
    },
};

const DokumenteContext = createContext<{
    docstate: InitialDokumenteType;
    docdispatch: React.Dispatch<any>;
}>({
    docstate: initialDokumenteState,
    docdispatch: () => null,
});

export default DokumenteContext;
