import { AlertOnHide, AlertType, InitialAlertType } from './alertContext';

type Action =
    | {
          type: 'SETSHOWALERTMESSAGE';
          alertMessage: {
              showModal: boolean;
              alertTitle: string;
              alertTxt: string;
              alertType: AlertType;
              onHide: AlertOnHide;
              onHideCustom: () => void;
              buttonName?: string;
              doBig?: boolean;
              doHTML?: boolean;
          };
      }
    | {
          type: 'RESETSHOWALERTMESSAGE';
      };

export const alertReducer = (state: InitialAlertType, action: Action) => {
    switch (action.type) {
        case 'SETSHOWALERTMESSAGE':
            return {
                ...state,
                alertMessage: {
                    showModal: action.alertMessage.showModal,
                    alertTitle: action.alertMessage.alertTitle,
                    alertTxt: action.alertMessage.alertTxt,
                    alertType: action.alertMessage.alertType,
                    onHide: action.alertMessage.onHide,
                    onHideCustom: action.alertMessage.onHideCustom,
                    buttonName: action.alertMessage.buttonName ? action.alertMessage.buttonName : 'OK',
                    doBig: action.alertMessage.doBig ? true : false,
                    doHTML: action.alertMessage.doHTML ? true : false,
                },
            };
        case 'RESETSHOWALERTMESSAGE':
            return {
                ...state,
                alertMessage: {
                    showModal: false,
                    alertTitle: '',
                    alertTxt: '',
                    alertType: AlertType.info,
                    onHide: AlertOnHide.onlyClose,
                    onHideCustom: undefined,
                    buttonName: 'OK',
                    doBig: false,
                    doHTML: false,
                },
            };
        default:
            return state;
    }
};
