import { useCallback, useContext } from 'react';
import QuestionnaireContext, { IQAnswers, IQData, IQResource } from '../context/questionnaireContext';
import { getAllItems } from '../utils/questionnaireUtils';

export const useLoadQuestionnaireAnswers = () => {
    const { qdispatch } = useContext(QuestionnaireContext);

    const setSelectedQuestionnaire = useCallback(
        (resourceData: IQResource | IQResource[]) => {
            if (resourceData !== undefined) {
                qdispatch({
                    type: 'SETSELECTEDQUESTIONNAIRE',
                    selectedQuestionnaire: {
                        resource: resourceData,
                    },
                });
            }
        },
        [qdispatch],
    );

    const setQuestionnaireBody = useCallback(
        (data: any) => {
            // save questionnaire body for further updates
            if (data !== undefined) {
                qdispatch({
                    type: 'SETQUESTIONNAIREBODY',
                    questionnaireBody: {
                        data: data,
                    },
                });
            }
        },
        [qdispatch],
    );

    const loadQuestionnaireAnswers = useCallback((data: any): IQAnswers[] => {
        let answers: IQAnswers[] = [];

        // get and set existing answers from previous save
        if (data?.item !== undefined) {
            answers = getAllItems<IQAnswers>(data.item, answers);
        }

        return answers;
    }, []);

    const setSelectedQuestionnaireAnswers = useCallback(
        (answers: IQAnswers[]) => {
            qdispatch({
                type: 'SETSELECTEDANSWERS',
                selectedAnswers: {
                    item: answers,
                },
            });
        },
        [qdispatch],
    );

    const setQuestionnaireData = useCallback(
        (responseData: IQData) => {
            qdispatch({
                type: 'SETQDATA',
                qData: responseData,
            });
        },
        [qdispatch],
    );

    return [
        setSelectedQuestionnaire,
        setQuestionnaireBody,
        loadQuestionnaireAnswers,
        setSelectedQuestionnaireAnswers,
        setQuestionnaireData,
    ] as const;
};
