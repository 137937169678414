import { useCallback } from 'react';

export const useMessages = () => {
    const allowMessages = useCallback(() => {
        window.app.allowVideochatMessages = true;
    }, []);

    const forbidMessages = useCallback(() => {
        window.app.allowVideochatMessages = false;
    }, []);

    const videochatMessagesAllowed = useCallback(() => {
        return window.app.allowVideochatMessages;
    }, []);

    return {
        allowMessages,
        forbidMessages,
        videochatMessagesAllowed,
    };
};
