import { Card, Col, Row } from 'react-bootstrap';
import { StyledPhysicianSelectionCard, StyledPhysicianSelectionCardTitle } from '../Cards/StyledCard';
import PhysicianSelectionDescriptionList from './PhysicianSelectionDescriptionList';
import PhysicianSelectionPhoto from './PhysicianSelectionPhoto';

type IPhyscianSelectionCard = {
    name: string;
    description?: string[];
    showPicture: boolean;
    pict?: string;
};

const PhysicianSelectionCard = (props: IPhyscianSelectionCard) => {
    return (
        <StyledPhysicianSelectionCard>
            <Card.Body style={{ textAlign: 'left' }}>
                <Row>
                    <Col
                        md={2}
                        sm={4}
                        style={{ marginRight: '20px', width: '160px', flexBasis: '160px', minWidth: '160px' }}
                    >
                        <PhysicianSelectionPhoto showPicture={props.showPicture} pict={props.pict} />
                    </Col>
                    <Col md={3} sm={3} style={{ minWidth: '230px' }}>
                        {props.name && (
                            <StyledPhysicianSelectionCardTitle>{props.name}</StyledPhysicianSelectionCardTitle>
                        )}
                        <PhysicianSelectionDescriptionList description={props.description} />
                    </Col>
                </Row>
            </Card.Body>
        </StyledPhysicianSelectionCard>
    );
};

export default PhysicianSelectionCard;
