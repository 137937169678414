import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import BloodSugarCharts from '../../../components/Charts/BloodSugar/BloodSugarCharts';
import { containerHeader } from '../../../components/Header/Header';
import Mainscreen from '../../Mainscreen/Mainscreen';

const ChartsBlutzuckerContent = () => {
    return (
        <Container fluid>
            <BloodSugarCharts />
        </Container>
    );
};

const ChartsBlutzucker = () => {
    return (
        <Mainscreen>
            <Container fluid>
                <Row>
                    <Col>
                        {containerHeader('Blutzucker')}
                        <ChartsBlutzuckerContent />
                    </Col>
                </Row>
            </Container>
        </Mainscreen>
    );
};

export default ChartsBlutzucker;
