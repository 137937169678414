import { InitialThemeType } from './themeContext';

type Action =
    | {
          type: 'SETTHEME';
          theme: string;
      }
    | {
          type: 'SETNAVBARCOLLAPSED';
          navbarCollapsed: boolean;
      };

export const themeReducer = (state: InitialThemeType, action: Action) => {
    switch (action.type) {
        case 'SETTHEME':
            return {
                ...state,
                theme: action.theme,
            };
        case 'SETNAVBARCOLLAPSED':
            return {
                ...state,
                navbarCollapsed: action.navbarCollapsed,
            };

        default:
            return state;
    }
};
