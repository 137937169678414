import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertContext, { AlertOnHide, AlertType } from '../context/alertContext';
import CheckInContext from '../context/checkInContext';
import ExecuteRuleContext from '../context/executeRuleContext';
import LoginContext from '../context/loginContext';
import QuestionnaireContext from '../context/questionnaireContext';
import TerminierungContext from '../context/terminierungContext';
import VideochatContext from '../context/videochatContext';
import { logout, unblockAppointment } from '../services/RestServices';
import { showAlertMessage } from '../utils/alertHandling';
import { useAppointmentSelection } from './useAppointmentSelection';

const useHandleLogout = () => {
    const navigate = useNavigate();
    const { state, dispatch } = useContext(LoginContext);
    const { vsdispatch } = useContext(VideochatContext);
    const { tmdispatch } = useContext(TerminierungContext);
    const { alertdispatch } = useContext(AlertContext);
    const { qdispatch } = useContext(QuestionnaireContext);
    const { executeruledispatch } = useContext(ExecuteRuleContext);

    const handleLogout = async (
        event?: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        if (event !== undefined) {
            event.preventDefault();
        }

        const loginData = await logout(state.sessionId);

        if (loginData.error) {
            if (loginData.error.includes('SID not found')) {
                showAlertMessage({
                    alertTitle: 'Authorization',
                    alertTxt: 'Authorization failed',
                    alertType: AlertType.error,
                    onHide: AlertOnHide.goToHome,
                    alertdispatch: alertdispatch,
                });
            }
        } else {
            dispatch({ type: 'LOGOUT' });
            vsdispatch({ type: 'ONDISCONNECT' });
            vsdispatch({ type: 'CLOSEALLAPPOINTMENTCHATS' });
            vsdispatch({ type: 'SETAUDIOMUTED', audioMuted: false });
            tmdispatch({ type: 'RESETALL' });
            qdispatch({ type: 'RESETQUESTIONNAIRE' });
            executeruledispatch({ type: 'RESETRULEPROPERTIES' });
            navigate('/');
        }
    };

    return {
        handleLogout,
    };
};

export const useHandleLogoutWL = () => {
    const navigate = useNavigate();
    const { vsdispatch } = useContext(VideochatContext);
    const { tmdispatch } = useContext(TerminierungContext);
    const { qdispatch } = useContext(QuestionnaireContext);
    const { executeruledispatch } = useContext(ExecuteRuleContext);

    const handleLogout = async (
        event?: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        if (event !== undefined) {
            event.preventDefault();
        }

        vsdispatch({ type: 'ONDISCONNECT' });
        vsdispatch({ type: 'SETAUDIOMUTED', audioMuted: false });
        tmdispatch({ type: 'RESETALL' });
        qdispatch({ type: 'RESETQUESTIONNAIRE' });
        executeruledispatch({ type: 'RESETRULEPROPERTIES' });
        navigate('/');
    };

    return {
        handleLogout,
    };
};

export const useHandleLogoutAfterIdleTime = () => {
    const { dispatch } = useContext(LoginContext);
    const { vsdispatch } = useContext(VideochatContext);
    const { tmdispatch } = useContext(TerminierungContext);
    const { qdispatch } = useContext(QuestionnaireContext);

    const { executeruledispatch } = useContext(ExecuteRuleContext);

    const handleLogoutAfterIdleTime = () => {
        dispatch({ type: 'LOGOUT' });
        vsdispatch({ type: 'ONDISCONNECT' });
        vsdispatch({ type: 'CLOSEALLAPPOINTMENTCHATS' });
        vsdispatch({ type: 'SETAUDIOMUTED', audioMuted: false });
        tmdispatch({ type: 'RESETALL' });
        qdispatch({ type: 'RESETQUESTIONNAIRE' });
        executeruledispatch({ type: 'RESETRULEPROPERTIES' });
    };

    return {
        handleLogoutAfterIdleTime,
    };
};

export const useHandleLogoutCheckIn = () => {
    const { dispatch } = useContext(LoginContext);
    const { vsdispatch } = useContext(VideochatContext);
    const { tmdispatch } = useContext(TerminierungContext);
    const { qdispatch } = useContext(QuestionnaireContext);
    const { executeruledispatch } = useContext(ExecuteRuleContext);
    const { checkindispatch } = useContext(CheckInContext);

    const { getSelectedResource } = useAppointmentSelection();

    const handleLogoutCheckIn = () => {
        dispatch({ type: 'LOGOUT' });
        vsdispatch({ type: 'ONDISCONNECT' });
        vsdispatch({ type: 'CLOSEALLAPPOINTMENTCHATS' });
        vsdispatch({ type: 'SETAUDIOMUTED', audioMuted: false });
        tmdispatch({ type: 'RESETALL' });
        qdispatch({ type: 'RESETQUESTIONNAIRE' });
        checkindispatch({ type: 'RESETALL' });
        executeruledispatch({ type: 'RESETRULEPROPERTIES' });

        const resource_1 = getSelectedResource(1);
        if (resource_1) {
            unblockAppointment(resource_1);
        }
    };

    return {
        handleLogoutCheckIn,
    };
};

export default useHandleLogout;
