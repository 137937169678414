import {
    IAvailableTimeslots,
    IHints,
    IHintsAnswers,
    InitialTerminierungType,
    ISelectedPhysician,
    ISelectedTimeslots,
} from '../context/terminierungContext';
import { IPlannedStudies } from '../services/fhir/FHIRPatientResource';
import { IParticipant } from '../utils/appointmentUtils';
import { ICodeName, IDepartmentReasons, IDepartments } from './loginContext';

type Action =
    | {
          type: 'SETWITHLOGIN';
          withLogin: boolean;
      }
    | {
          type: 'SETWITHCHECKIN';
          withCheckIn: boolean;
      }
    | {
          type: 'SELECTLOCATION';
          selectedLocation: {
              code: string;
              name: string;
          };
      }
    | {
          type: 'SELECTCATEGORY';
          selectedCategory: {
              name: string;
              id: number;
              countAPKs: number;
              oneMacro: boolean;
              macroCode: string;
              macroType: string;
          };
      }
    | {
          type: 'SELECTLOCATION';
          selectedLocation: ICodeName;
      }
    | {
          type: 'SELECTDEPARTMENT';
          selectedDepartment: IDepartments;
      }
    | {
          type: 'HDBOOKING';
          hdBooking: string;
      }
    | {
          type: 'SETFILTEREDREASONS';
          filteredReasons: IDepartmentReasons[];
      }
    | {
          type: 'SELECTREASON';
          selectedReason: IDepartmentReasons;
      }
    | {
          type: 'SELECTREASONHINTS';
          selectedReasonHints: IHintsAnswers[];
      }
    | {
          type: 'SELECTINSURANCE';
          selectedInsurance: string;
      }
    | {
          type: 'SELECTPHYSICIAN';
          selectedPhysician: ISelectedPhysician;
      }
    | {
          type: 'SELECTAPPOINTMENTTYPE';
          selectedAppointmentType: string;
      }
    | {
          type: 'RESETCATEGORY';
      }
    | {
          type: 'SETAVAILABLETIMESLOTS';
          availableTimeslots: IAvailableTimeslots[];
      }

    /*
    | {
          type: 'SETAVAILABLETIMESLOTS_2';
          availableTimeslots_2: IAvailableTimeslot[];
      }
      */
    | {
          type: 'SELECTTIMESLOT';
          selectedTimeslots: ISelectedTimeslots[];
      }
    | {
          type: 'RESETTIMESLOT';
      }
    | {
          type: 'TIMESLOTCLICKED';
          timeslotClicked: boolean;
      }
    /*
    | {
          type: 'SELECTTIMESLOT_2';
          selectedTimeslot_2: ITimeslot;
      }
      */
    | {
          type: 'SETRESOURCE';
          selectedResources: any;
      }
    /*
    | {
          type: 'SETRESOURCE_2';
          selectedResource_2: any;
      }
      */
    | {
          type: 'SETRENEWAPPOINTMENT';
          renewAppointment: boolean;
      }
    | {
          type: 'SETPERSONALDATA_INSURANCENR';
          personalData: {
              insuranceNr: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_INSURANCENAME';
          personalData: {
              insuranceName: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_LANGUAGE';
          personalData: {
              language: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_FIRSTNAME';
          personalData: {
              firstName: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_LASTNAME';
          personalData: {
              lastName: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_BIRTHDAY';
          personalData: {
              birthday: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_GENDER';
          personalData: {
              gender: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_EMAIL';
          personalData: {
              email: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_TELEPHONE';
          personalData: {
              telephone: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_STREET';
          personalData: {
              street: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_HOUSENR';
          personalData: {
              housenr: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_CITY';
          personalData: {
              city: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_ZIP';
          personalData: {
              zip: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_COUNTRY';
          personalData: {
              country: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_MOBILEPHONE';
          personalData: {
              mobilePhone: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_ACADEMICTITLE';
          personalData: {
              academicTitle: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_USERNAME';
          personalData: {
              username: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PASSWORD';
          personalData: {
              password: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PROFILEPHOTO';
          personalData: {
              profilePhoto: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PLANNEDSTUDIES';
          personalData: {
              plannedStudies: IPlannedStudies[];
          };
      }
    | {
          type: 'SETPERSONALDATA';
          personalData: {
              insuranceNr: string;
              firstName: string;
              lastName: string;
              birthday: string;
              gender: string;
              email: string;
              telephone: string;
              street: string;
              housenr: string;
              city: string;
              zip: string;
              country: string;
              eGK: string;
              mobilePhone: string;
              academicTitle: string;
              kvBarcode: string;
              state: string;
              profilePhoto: string;
              plannedStudies: IPlannedStudies[];
              username: string;
              password: string;
              insuranceName: string;
              language: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PHYSPAT_INSURANCENR';
          personalDataPhysPat: {
              insuranceNr: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PHYSPAT_INSURANCENAME';
          personalDataPhysPat: {
              insuranceName: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PHYSPAT_LANGUAGE';
          personalDataPhysPat: {
              language: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PHYSPAT_FIRSTNAME';
          personalDataPhysPat: {
              firstName: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PHYSPAT_LASTNAME';
          personalDataPhysPat: {
              lastName: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PHYSPAT_BIRTHDAY';
          personalDataPhysPat: {
              birthday: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PHYSPAT_GENDER';
          personalDataPhysPat: {
              gender: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PHYSPAT_EMAIL';
          personalDataPhysPat: {
              email: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PHYSPAT_TELEPHONE';
          personalDataPhysPat: {
              telephone: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PHYSPAT_STREET';
          personalDataPhysPat: {
              street: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PHYSPAT_HOUSENR';
          personalDataPhysPat: {
              housenr: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PHYSPAT_CITY';
          personalDataPhysPat: {
              city: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PHYSPAT_ZIP';
          personalDataPhysPat: {
              zip: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PHYSPAT_COUNTRY';
          personalDataPhysPat: {
              country: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PHYSPAT_MOBILEPHONE';
          personalDataPhysPat: {
              mobilePhone: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PHYSPAT_ACADEMICTITLE';
          personalDataPhysPat: {
              academicTitle: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PHYSPAT_USERNAME';
          personalDataPhysPat: {
              username: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PHYSPAT_PASSWORD';
          personalDataPhysPat: {
              password: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PHYSPAT_PROFILEPHOTO';
          personalDataPhysPat: {
              profilePhoto: string;
          };
      }
    | {
          type: 'SETPERSONALDATA_PHYSPAT_PLANNEDSTUDIES';
          personalDataPhysPat: {
              plannedStudies: IPlannedStudies[];
          };
      }
    | {
          type: 'SETPERSONALDATA_PHYSPAT';
          personalDataPhysPat: {
              insuranceNr: string;
              firstName: string;
              lastName: string;
              birthday: string;
              gender: string;
              email: string;
              telephone: string;
              street: string;
              housenr: string;
              city: string;
              zip: string;
              country: string;
              mobilePhone: string;
              academicTitle: string;
              eGK: string;
              kvBarcode: string;
              state: string;
              profilePhoto: string;
              plannedStudies: IPlannedStudies[];
              username: string;
              password: string;
              insuranceName: string;
              language: string;
          };
      }
    | {
          type: 'SETREMARK';
          remark: string;
      }
    | {
          type: 'SETEARLIERAPPOINTMENTWANTED';
          earlierAppointmentWanted: boolean;
      }
    | {
          type: 'SETENROLLDATA';
          enrollData: {
              ambId: string;
              enrollCode: string;
              maId: string;
              patNameFirst: string;
              patNameLast: string;
              scheduleTime: string;
              fhir: {
                  participant: IParticipant[];
                  resourceType: string;
                  start: Date;
                  end: Date;
                  status: string;
                  serviceType: any[];
              };
              waitNumber: string;
              requestFromDirectLink: boolean | undefined;
              remark: string;
              isToday: boolean | undefined;
          };
      }
    | {
          type: 'SETENROLLCODE';
          enrollData: {
              enrollCode: string;
              kvBarcode?: string;
              isToday?: boolean | undefined;
          };
      }
    | {
          type: 'SETJOURNEYDATA';
          journeyData: {
              site: string;
              hints: IHints[];
          };
      }
    | {
          type: 'RESETPERSONALDATA';
      }
    | {
          type: 'RESETPERSONALDATA_PHYSPAT';
      }
    | {
          type: 'RESETREMARK';
      }
    | {
          type: 'RESETALL';
      }
    | {
          type: 'RESETALL_WITHOUTPERSONALDATA';
      }
    | {
          type: 'RESETALL_FORPOSTPONEAPPOINTMENT';
      }
    | {
          type: 'CHANGEPERSONALDATA';
          changePersonalData: boolean;
      }
    | {
          type: 'CANCELEDAPPOINTMENT';
          canceledAppointment: boolean;
      }
    | {
          type: 'WORKONAPPOINTMENTNR';
          workOnAppointmentNr: number;
      }
    | {
          type: 'SETCANCELNEWAPPOINTMENTDATA';
          cancelNewAppointmentData: {
              showModal: boolean;
              data: any;
              reason: any;
          };
      }
    /*
    | {
          type: 'RESETAVAILABLETIMESLOTS';
          appointmentNr: number;
      }
      */
    /*
    | {
          type: 'RESETAVAILABLETIMESLOTS_2';
      }
      */
    /*
    | {
          type: 'RESETRESOURCE';
      }
      */
    /*
    | {
          type: 'RESETRESOURCE_2';
      }
      */
    /*
    | {
          type: 'RESETSELECTEDTIMESLOT';
      }
      */
    /*
    | {
          type: 'RESETSELECTEDTIMESLOT_2';
      }
      */
    | {
          type: 'RESETCANCELNEWAPPOINTMENTDATA';
      }
    | {
          type: 'DONTSHOWCANCELNEWAPPOINTMENTDATA';
      };

export const terminierungReducer = (state: InitialTerminierungType, action: Action) => {
    switch (action.type) {
        case 'SETWITHLOGIN':
            return {
                ...state,
                withLogin: action.withLogin,
            };
        case 'SETWITHCHECKIN':
            return {
                ...state,
                withCheckIn: action.withCheckIn,
            };
        case 'SELECTCATEGORY':
            return {
                ...state,
                selectedCategory: action.selectedCategory,
            };
        case 'SELECTLOCATION':
            return {
                ...state,
                selectedLocation: action.selectedLocation,
            };
        case 'SELECTDEPARTMENT':
            return {
                ...state,
                selectedDepartment: action.selectedDepartment,
            };
        case 'HDBOOKING':
            return {
                ...state,
                hdBooking: action.hdBooking,
            };
        case 'SETFILTEREDREASONS':
            return {
                ...state,
                filteredReasons: action.filteredReasons,
            };
        case 'SELECTREASON':
            return {
                ...state,
                selectedReason: action.selectedReason,
            };
        case 'SELECTREASONHINTS':
            return {
                ...state,
                selectedReasonHints: action.selectedReasonHints,
            };
        case 'SELECTINSURANCE':
            return {
                ...state,
                selectedInsurance: action.selectedInsurance,
            };
        case 'SELECTPHYSICIAN':
            return {
                ...state,
                selectedPhysician: action.selectedPhysician,
            };
        case 'SELECTAPPOINTMENTTYPE':
            return {
                ...state,
                selectedAppointmentType: action.selectedAppointmentType,
            };
        case 'RESETCATEGORY':
            return {
                ...state,
                selectedCategory: {
                    name: '',
                    id: 0,
                    countAPKs: 1,
                    oneMacro: false,
                    macroCode: '',
                    macroType: '',
                },
            };
        case 'SETAVAILABLETIMESLOTS':
            return {
                ...state,
                availableTimeslots: action.availableTimeslots,
            };
        /*
        case 'SETAVAILABLETIMESLOTS_2':
            return {
                ...state,
                availableTimeslots_2: action.availableTimeslots_2,
            };
            */
        case 'SELECTTIMESLOT':
            return {
                ...state,
                selectedTimeslots: action.selectedTimeslots,
            };
        case 'RESETTIMESLOT':
            return {
                ...state,
                selectedTimeslots: [],
            };
        case 'TIMESLOTCLICKED':
            return {
                ...state,
                timeslotClicked: action.timeslotClicked,
            };
        /*
        case 'SELECTTIMESLOT_2':
            return {
                ...state,
                selectedTimeslot_2: action.selectedTimeslot_2,
            };
            */
        case 'SETRESOURCE':
            return {
                ...state,
                selectedResources: action.selectedResources,
            };
        /*
        case 'SETRESOURCE_2':
            return {
                ...state,
                selectedResource_2: action.selectedResource_2,
            };
            */
        case 'SETRENEWAPPOINTMENT':
            return {
                ...state,
                renewAppointment: action.renewAppointment,
            };
        case 'SETPERSONALDATA_INSURANCENR':
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    insuranceNr: action.personalData.insuranceNr,
                },
            };
        case 'SETPERSONALDATA_INSURANCENAME':
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    insuranceName: action.personalData.insuranceName,
                },
            };
        case 'SETPERSONALDATA_LANGUAGE':
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    language: action.personalData.language,
                },
            };
        case 'SETPERSONALDATA_FIRSTNAME':
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    firstName: action.personalData.firstName,
                },
            };
        case 'SETPERSONALDATA_LASTNAME':
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    lastName: action.personalData.lastName,
                },
            };
        case 'SETPERSONALDATA_BIRTHDAY':
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    birthday: action.personalData.birthday,
                },
            };
        case 'SETPERSONALDATA_GENDER':
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    gender: action.personalData.gender,
                },
            };
        case 'SETPERSONALDATA_EMAIL':
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    email: action.personalData.email,
                },
            };
        case 'SETPERSONALDATA_TELEPHONE':
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    telephone: action.personalData.telephone,
                },
            };
        case 'SETPERSONALDATA_STREET':
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    street: action.personalData.street,
                },
            };
        case 'SETPERSONALDATA_HOUSENR':
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    housenr: action.personalData.housenr,
                },
            };
        case 'SETPERSONALDATA_CITY':
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    city: action.personalData.city,
                },
            };
        case 'SETPERSONALDATA_ZIP':
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    zip: action.personalData.zip,
                },
            };
        case 'SETPERSONALDATA_COUNTRY':
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    country: action.personalData.country,
                },
            };
        case 'SETPERSONALDATA_MOBILEPHONE':
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    mobilePhone: action.personalData.mobilePhone,
                },
            };
        case 'SETPERSONALDATA_ACADEMICTITLE':
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    academicTitle: action.personalData.academicTitle,
                },
            };
        case 'SETPERSONALDATA_USERNAME':
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    username: action.personalData.username,
                },
            };
        case 'SETPERSONALDATA_PASSWORD':
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    password: action.personalData.password,
                },
            };
        case 'SETPERSONALDATA_PROFILEPHOTO':
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    profilePhoto: action.personalData.profilePhoto,
                },
            };
        case 'SETPERSONALDATA_PLANNEDSTUDIES':
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    plannedStudies: action.personalData.plannedStudies,
                },
            };

        case 'SETPERSONALDATA':
            return {
                ...state,
                personalData: {
                    insuranceNr: action.personalData.insuranceNr,
                    firstName: action.personalData.firstName,
                    lastName: action.personalData.lastName,
                    birthday: action.personalData.birthday,
                    gender: action.personalData.gender,
                    email: action.personalData.email,
                    telephone: action.personalData.telephone,
                    street: action.personalData.street,
                    housenr: action.personalData.housenr,
                    city: action.personalData.city,
                    zip: action.personalData.zip,
                    country: action.personalData.country,
                    mobilePhone: action.personalData.mobilePhone,
                    academicTitle: action.personalData.academicTitle,
                    eGK: action.personalData.eGK ? action.personalData.eGK : '',
                    kvBarcode: action.personalData.kvBarcode
                        ? action.personalData.kvBarcode
                        : state.enrollData.kvBarcode
                        ? state.enrollData.kvBarcode
                        : '',
                    state: action.personalData.state,
                    profilePhoto: action.personalData.profilePhoto,
                    plannedStudies: action.personalData.plannedStudies,
                    username: action.personalData.username,
                    password: action.personalData.password,
                    insuranceName: action.personalData.insuranceName,
                    language: action.personalData.language,
                },
            };

        case 'SETPERSONALDATA_PHYSPAT_INSURANCENR':
            return {
                ...state,
                personalDataPhysPat: {
                    ...state.personalDataPhysPat,
                    insuranceNr: action.personalDataPhysPat.insuranceNr,
                },
            };
        case 'SETPERSONALDATA_PHYSPAT_INSURANCENAME':
            return {
                ...state,
                personalDataPhysPat: {
                    ...state.personalDataPhysPat,
                    insuranceName: action.personalDataPhysPat.insuranceName,
                },
            };
        case 'SETPERSONALDATA_PHYSPAT_LANGUAGE':
            return {
                ...state,
                personalDataPhysPat: {
                    ...state.personalDataPhysPat,
                    language: action.personalDataPhysPat.language,
                },
            };
        case 'SETPERSONALDATA_PHYSPAT_FIRSTNAME':
            return {
                ...state,
                personalDataPhysPat: {
                    ...state.personalDataPhysPat,
                    firstName: action.personalDataPhysPat.firstName,
                },
            };
        case 'SETPERSONALDATA_PHYSPAT_LASTNAME':
            return {
                ...state,
                personalDataPhysPat: {
                    ...state.personalDataPhysPat,
                    lastName: action.personalDataPhysPat.lastName,
                },
            };
        case 'SETPERSONALDATA_PHYSPAT_BIRTHDAY':
            return {
                ...state,
                personalDataPhysPat: {
                    ...state.personalDataPhysPat,
                    birthday: action.personalDataPhysPat.birthday,
                },
            };
        case 'SETPERSONALDATA_PHYSPAT_GENDER':
            return {
                ...state,
                personalDataPhysPat: {
                    ...state.personalDataPhysPat,
                    gender: action.personalDataPhysPat.gender,
                },
            };
        case 'SETPERSONALDATA_PHYSPAT_EMAIL':
            return {
                ...state,
                personalDataPhysPat: {
                    ...state.personalDataPhysPat,
                    email: action.personalDataPhysPat.email,
                },
            };
        case 'SETPERSONALDATA_PHYSPAT_TELEPHONE':
            return {
                ...state,
                personalDataPhysPat: {
                    ...state.personalDataPhysPat,
                    telephone: action.personalDataPhysPat.telephone,
                },
            };
        case 'SETPERSONALDATA_PHYSPAT_STREET':
            return {
                ...state,
                personalDataPhysPat: {
                    ...state.personalDataPhysPat,
                    street: action.personalDataPhysPat.street,
                },
            };
        case 'SETPERSONALDATA_PHYSPAT_HOUSENR':
            return {
                ...state,
                personalDataPhysPat: {
                    ...state.personalDataPhysPat,
                    housenr: action.personalDataPhysPat.housenr,
                },
            };
        case 'SETPERSONALDATA_PHYSPAT_CITY':
            return {
                ...state,
                personalDataPhysPat: {
                    ...state.personalDataPhysPat,
                    city: action.personalDataPhysPat.city,
                },
            };
        case 'SETPERSONALDATA_PHYSPAT_ZIP':
            return {
                ...state,
                personalDataPhysPat: {
                    ...state.personalDataPhysPat,
                    zip: action.personalDataPhysPat.zip,
                },
            };
        case 'SETPERSONALDATA_PHYSPAT_COUNTRY':
            return {
                ...state,
                personalDataPhysPat: {
                    ...state.personalDataPhysPat,
                    country: action.personalDataPhysPat.country,
                },
            };
        case 'SETPERSONALDATA_PHYSPAT_MOBILEPHONE':
            return {
                ...state,
                personalDataPhysPat: {
                    ...state.personalDataPhysPat,
                    mobilePhone: action.personalDataPhysPat.mobilePhone,
                },
            };
        case 'SETPERSONALDATA_PHYSPAT_ACADEMICTITLE':
            return {
                ...state,
                personalDataPhysPat: {
                    ...state.personalDataPhysPat,
                    academicTitle: action.personalDataPhysPat.academicTitle,
                },
            };
        case 'SETPERSONALDATA_PHYSPAT_USERNAME':
            return {
                ...state,
                personalDataPhysPat: {
                    ...state.personalDataPhysPat,
                    username: action.personalDataPhysPat.username,
                },
            };
        case 'SETPERSONALDATA_PHYSPAT_PASSWORD':
            return {
                ...state,
                personalDataPhysPat: {
                    ...state.personalDataPhysPat,
                    password: action.personalDataPhysPat.password,
                },
            };
        case 'SETPERSONALDATA_PHYSPAT_PROFILEPHOTO':
            return {
                ...state,
                personalDataPhysPat: {
                    ...state.personalDataPhysPat,
                    profilePhoto: action.personalDataPhysPat.profilePhoto,
                },
            };
        case 'SETPERSONALDATA_PHYSPAT_PLANNEDSTUDIES':
            return {
                ...state,
                personalDataPhysPat: {
                    ...state.personalDataPhysPat,
                    plannedStudies: action.personalDataPhysPat.plannedStudies,
                },
            };

        case 'SETPERSONALDATA_PHYSPAT':
            return {
                ...state,
                personalDataPhysPat: {
                    insuranceNr: action.personalDataPhysPat.insuranceNr,
                    firstName: action.personalDataPhysPat.firstName,
                    lastName: action.personalDataPhysPat.lastName,
                    birthday: action.personalDataPhysPat.birthday,
                    gender: action.personalDataPhysPat.gender,
                    email: action.personalDataPhysPat.email,
                    telephone: action.personalDataPhysPat.telephone,
                    street: action.personalDataPhysPat.street,
                    housenr: action.personalDataPhysPat.housenr,
                    city: action.personalDataPhysPat.city,
                    zip: action.personalDataPhysPat.zip,
                    country: action.personalDataPhysPat.country,
                    mobilePhone: action.personalDataPhysPat.mobilePhone,
                    academicTitle: action.personalDataPhysPat.academicTitle,
                    eGK: action.personalDataPhysPat.eGK ? action.personalDataPhysPat.eGK : '',
                    kvBarcode: action.personalDataPhysPat.kvBarcode
                        ? action.personalDataPhysPat.kvBarcode
                        : state.enrollData.kvBarcode
                        ? state.enrollData.kvBarcode
                        : '',
                    state: action.personalDataPhysPat.state,
                    profilePhoto: action.personalDataPhysPat.profilePhoto,
                    plannedStudies: action.personalDataPhysPat.plannedStudies,
                    username: action.personalDataPhysPat.username,
                    password: action.personalDataPhysPat.password,
                    insuranceName: action.personalDataPhysPat.insuranceName,
                    language: action.personalDataPhysPat.language,
                },
            };

        case 'SETREMARK':
            return {
                ...state,
                remark: action.remark,
            };
        case 'SETEARLIERAPPOINTMENTWANTED':
            return {
                ...state,
                earlierAppointmentWanted: action.earlierAppointmentWanted,
            };

        case 'SETENROLLDATA':
            return {
                ...state,
                enrollData: {
                    ambId: action.enrollData.ambId,
                    enrollCode: action.enrollData.enrollCode,
                    maId: action.enrollData.maId,
                    patNameFirst: action.enrollData.patNameFirst,
                    patNameLast: action.enrollData.patNameLast,
                    scheduleTime: action.enrollData.scheduleTime,
                    fhir: {
                        participant: action.enrollData.fhir.participant,
                        resourceType: action.enrollData.fhir.resourceType,
                        start: action.enrollData.fhir.start,
                        end: action.enrollData.fhir.end,
                        status: action.enrollData.fhir.status,
                        serviceType: action.enrollData.fhir.serviceType,
                    },
                    waitNumber: action.enrollData.waitNumber,
                    requestFromDirectLink: action.enrollData.requestFromDirectLink,
                    remark: action.enrollData.remark,
                    isToday: action.enrollData.isToday,
                },
            };
        case 'SETENROLLCODE':
            const res = {
                ...state,
                enrollData: {
                    ...state.enrollData,
                    enrollCode: action.enrollData.enrollCode,
                    kvBarcode: action.enrollData.kvBarcode,
                    isToday: action.enrollData.isToday,
                },
            };
            if (action.enrollData.kvBarcode) {
                res.personalData = {
                    ...state.personalData,
                    kvBarcode: action.enrollData.kvBarcode,
                };
            }
            return res;
        case 'CHANGEPERSONALDATA':
            return {
                ...state,
                changePersonalData: action.changePersonalData,
            };
        case 'CANCELEDAPPOINTMENT':
            return {
                ...state,
                canceledAppointment: action.canceledAppointment,
            };

        case 'SETJOURNEYDATA':
            return {
                ...state,
                journeyData: {
                    site: action.journeyData.site,
                    hints: action.journeyData.hints,
                },
            };

        case 'RESETPERSONALDATA':
            return {
                ...state,
                personalData: {
                    insuranceNr: '',
                    firstName: '',
                    lastName: '',
                    birthday: '',
                    gender: '',
                    email: '',
                    telephone: '',
                    street: '',
                    housenr: '',
                    city: '',
                    zip: '',
                    country: '',
                    mobilePhone: '',
                    academicTitle: '',
                    eGK: '',
                    kvBarcode: '',
                    state: '',
                    profilePhoto: '',
                    plannedStudies: [],
                    username: '',
                    password: '',
                    insuranceName: '',
                    language: '',
                },
                remark: '',
                earlierAppointmentWanted: false,
            };
        case 'RESETPERSONALDATA_PHYSPAT':
            return {
                ...state,
                personalDataPhysPat: {
                    insuranceNr: '',
                    firstName: '',
                    lastName: '',
                    birthday: '',
                    gender: '',
                    email: '',
                    telephone: '',
                    street: '',
                    housenr: '',
                    city: '',
                    zip: '',
                    country: '',
                    mobilePhone: '',
                    academicTitle: '',
                    eGK: '',
                    kvBarcode: '',
                    state: '',
                    profilePhoto: '',
                    plannedStudies: [],
                    username: '',
                    password: '',
                    insuranceName: '',
                    language: '',
                },
                remark: '',
                earlierAppointmentWanted: false,
            };
        case 'RESETREMARK':
            return {
                ...state,
                remark: '',
            };
        case 'RESETALL':
            return {
                ...state,
                selectedLocation: {
                    code: '',
                    name: '',
                },
                selectedCategory: {
                    name: '',
                    id: 0,
                    countAPKs: 1,
                    oneMacro: false,
                    macroCode: '',
                    macroType: '',
                },
                selectedDepartment: {
                    name: '',
                    reasons: [],
                },
                hdBooking: '',
                filteredReasons: [],
                selectedReason: {
                    makroCode: '',
                    makroId: 0,
                    makroName: '',
                    pFilter: '',
                    aFilter: '',
                    reasonCode: '',
                    reasonId: '',
                    reasonName: '',
                },
                selectedReasonHints: [],
                selectedInsurance: '',
                selectedPhysician: {
                    lines: [],
                    id: 0,
                    name: '',
                    pict: '',
                    makroId: 0,
                },
                selectedAppointmentType: '',
                availableTimeslots: [],
                // availableTimeslots_2: [],
                selectedTimeslots: [],
                timeslotClicked: false,
                /*
                selectedTimeslot_2: {
                    start: new Date(0),
                    end: new Date(0),
                },
                */
                selectedResources: [],
                // selectedResource_2: '',
                personalData: {
                    insuranceNr: '',
                    firstName: '',
                    lastName: '',
                    birthday: '',
                    gender: '',
                    email: '',
                    telephone: '',
                    street: '',
                    housenr: '',
                    city: '',
                    zip: '',
                    country: '',
                    mobilePhone: '',
                    academicTitle: '',
                    eGK: '',
                    kvBarcode: '',
                    state: '',
                    profilePhoto: '',
                    plannedStudies: [],
                    username: '',
                    password: '',
                    insuranceName: '',
                    language: '',
                },
                personalDataPhysPat: {
                    insuranceNr: '',
                    firstName: '',
                    lastName: '',
                    birthday: '',
                    gender: '',
                    email: '',
                    telephone: '',
                    street: '',
                    housenr: '',
                    city: '',
                    zip: '',
                    country: '',
                    mobilePhone: '',
                    academicTitle: '',
                    eGK: '',
                    kvBarcode: '',
                    state: '',
                    profilePhoto: '',
                    plannedStudies: [],
                    username: '',
                    password: '',
                    insuranceName: '',
                    language: '',
                },
                remark: '',
                earlierAppointmentWanted: false,
                enrollData: {
                    ambId: '',
                    enrollCode: '',
                    maId: '',
                    patNameFirst: '',
                    patNameLast: '',
                    scheduleTime: '',
                    fhir: {
                        participant: [],
                        resourceType: '',
                        start: new Date(0),
                        end: new Date(0),
                        status: '',
                        serviceType: [],
                    },
                    waitNumber: '',
                    requestFromDirectLink: undefined,
                    remark: '',
                    isToday: undefined,
                },
                changePersonalData: false,
                workOnAppointmentNr: 1,
                cancelNewAppointmentData: {
                    showModal: false,
                    data: '',
                    reason: '',
                },
                renewAppointment: false,
                withLogin: false,
                withCheckIn: false,
                journeyData: {
                    site: '',
                    hints: [],
                },
            };
        case 'RESETALL_WITHOUTPERSONALDATA':
            return {
                ...state,
                selectedLocation: {
                    code: '',
                    name: '',
                },
                selectedCategory: {
                    name: '',
                    id: 0,
                    countAPKs: 1,
                    oneMacro: false,
                    macroCode: '',
                    macroType: '',
                },
                selectedDeparment: {
                    name: '',
                    reasons: [],
                },
                hdBooking: '',
                filteredReasons: [],
                selectedReason: {
                    makroCode: '',
                    makroId: 0,
                    makroName: '',
                    pFilter: '',
                    aFilter: '',
                    reasonCode: '',
                    reasonId: '',
                    reasonName: '',
                },
                selectedReasonHints: [],
                selectedInsurance: '',
                selectedPhysician: {
                    lines: [],
                    id: 0,
                    name: '',
                    pict: '',
                    makroId: 0,
                },
                selectedAppointmentType: '',
                availableTimeslots: [],
                // availableTimeslots_2: [],
                selectedTimeslots: [],
                timeslotClicked: false,
                /*
                selectedTimeslot_2: {
                    start: new Date(0),
                    end: new Date(0),
                },
                */
                selectedResources: [],
                // selectedResource_2: '',
                remark: '',
                earlierAppointmentWanted: false,
                enrollData: {
                    ambId: '',
                    enrollCode: '',
                    maId: '',
                    patNameFirst: '',
                    patNameLast: '',
                    scheduleTime: '',
                    fhir: {
                        participant: [],
                        resourceType: '',
                        start: new Date(0),
                        end: new Date(0),
                        status: '',
                        serviceType: [],
                    },
                    waitNumber: '',
                    requestFromDirectLink: undefined,
                    remark: '',
                },
                changePersonalData: false,
                workOnAppointmentNr: 1,
                cancelNewAppointmentData: {
                    showModal: false,
                    data: '',
                    reason: '',
                },
                renewAppointment: false,
                journeyData: {
                    site: '',
                    hints: [],
                },
            };
        case 'RESETALL_FORPOSTPONEAPPOINTMENT':
            return {
                ...state,
                selectedLocation: {
                    code: '',
                    name: '',
                },
                selectedCategory: {
                    name: '',
                    id: 0,
                    countAPKs: 1,
                    oneMacro: false,
                    macroCode: '',
                    macroType: '',
                },
                selectedDepartment: {
                    name: '',
                    reasons: [],
                },
                hdBooking: '',
                filteredReasons: [],
                selectedReason: {
                    makroCode: '',
                    makroId: 0,
                    makroName: '',
                    pFilter: '',
                    aFilter: '',
                    reasonCode: '',
                    reasonId: '',
                    reasonName: '',
                },
                selectedReasonHints: [],
                selectedInsurance: '',
                selectedPhysician: {
                    lines: [],
                    id: 0,
                    name: '',
                    pict: '',
                    makroId: 0,
                },
                selectedAppointmentType: '',
                availableTimeslots: [],
                // availableTimeslots_2: [],
                selectedTimeslots: [],
                timeslotClicked: false,
                /*
                    selectedTimeslot_2: {
                        start: new Date(0),
                        end: new Date(0),
                    },
                    */
                selectedResources: [],
                // selectedResource_2: '',
                //remark: '',
                changePersonalData: false,
                workOnAppointmentNr: 1,
                renewAppointment: false,
                withLogin: false,
                withCheckIn: false,
                journeyData: {
                    site: '',
                    hints: [],
                },
            };
        case 'WORKONAPPOINTMENTNR':
            return {
                ...state,
                workOnAppointmentNr: action.workOnAppointmentNr,
            };
        case 'SETCANCELNEWAPPOINTMENTDATA':
            return {
                ...state,
                cancelNewAppointmentData: {
                    showModal: action.cancelNewAppointmentData.showModal,
                    data: action.cancelNewAppointmentData.data,
                    reason: action.cancelNewAppointmentData.reason,
                },
            };
        /*
        case 'RESETAVAILABLETIMESLOTS':
            const updatedResetAvailableTimeslots = resetAvailableTimeslots(
                state.availableTimeslots,
                action.appointmentNr,
            );

            return {
                ...state,
                availableTimeslots: updatedResetAvailableTimeslots,
            };
        /*
        case 'RESETAVAILABLETIMESLOTS_2':
            return {
                ...state,
                availableTimeslots_2: [],
            };
            */
        /*
        case 'RESETRESOURCE':
            return {
                ...state,
                selectedResource: resetedResource,
            };
            */
        /*
        case 'RESETRESOURCE_2':
            return {
                ...state,
                selectedResource_2: '',
            };
            */
        /*
        case 'RESETSELECTEDTIMESLOT':
            return {
                ...state,
                selectedTimeslot: {
                    start: new Date(0),
                    end: new Date(0),
                },
            };
            */
        /*
        case 'RESETSELECTEDTIMESLOT_2':
            return {
                ...state,
                selectedTimeslot_2: {
                    start: new Date(0),
                    end: new Date(0),
                },
            };
            */
        case 'RESETCANCELNEWAPPOINTMENTDATA':
            return {
                ...state,
                cancelNewAppointmentData: {
                    showModal: false,
                    data: '',
                    reason: '',
                },
            };
        case 'DONTSHOWCANCELNEWAPPOINTMENTDATA':
            return {
                ...state,
                cancelNewAppointmentData: {
                    ...state.cancelNewAppointmentData,
                    showModal: false,
                },
            };

        default:
            return state;
    }
};
