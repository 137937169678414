import { useContext, useEffect, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Nav from 'react-bootstrap/Nav';
import { BsChatDots } from 'react-icons/bs';
import VideochatContext from '../../context/videochatContext';
import { IAppointment } from '../../utils/appointmentUtils';
import { isDateToday } from '../../utils/dateUtils';
import Chat, { getAppointmentChatEntry, IAppointmentChat, IIncomingMessage, MessageIdType } from '../Chat/Chat';
import MyTooltip from '../Tooltip/MyTooltip';
import { StyledAppointmentCardFooter } from './StyledCard';

interface IAppointmentChatIcon extends IAppointment {
    chatOpen: boolean;
    numberUnreadMsg?: number;
}

export const AppointmentChatIcon = (props: IAppointmentChatIcon) => {
    const { vsstate, vsdispatch } = useContext(VideochatContext);
    const [numberUnread, setNumberUnread] = useState(0);
    const [showUnreadBadge, setShowUnreadBadge] = useState(false);

    useEffect(() => {
        const numberUnreadMsg = getNumberUnreadMessages();
        if (!props.chatOpen && numberUnreadMsg && numberUnreadMsg > 0) {
            setNumberUnread(numberUnreadMsg);
            setShowUnreadBadge(true);
        }
    }, [vsstate.appointmentChat, vsstate.numberUnreadMessages]);

    const toggleChatOpen = (currentChatOpenStatus: boolean) => {
        let appointmentChat;

        const appointmentChatEntry = getAppointmentChatEntry(vsstate.appointmentChat, props.id);
        if (appointmentChatEntry) {
            appointmentChat = vsstate.appointmentChat.map((item) => {
                if (item.id === props.id) {
                    item.numberUnreadMsg = 0;
                }
                return item;
            });
        }

        setNumberUnread(0);
        setShowUnreadBadge(false);

        vsdispatch({
            type: 'SETAPPOINTMENTCHAT',
            appointmentChat: appointmentChat,
        });

        return !currentChatOpenStatus;
    };

    const getNumberUnreadMessages = () => {
        const appointmentChatEntry = getAppointmentChatEntry(vsstate.appointmentChat, props.id);
        return appointmentChatEntry?.numberUnreadMsg;
    };

    const handleChatIconClick = () => {
        let appointmentChat;

        const appointmentChatEntry = getAppointmentChatEntry(vsstate.appointmentChat, props.id);
        if (appointmentChatEntry) {
            appointmentChat = vsstate.appointmentChat.map((item) => {
                if (item.id === props.id) {
                    if (!item.chatOpen) {
                        item.numberUnreadMsg = 0;
                        setNumberUnread(0);
                        setShowUnreadBadge(false);
                    }
                    item.chatOpen = toggleChatOpen(item.chatOpen);
                }
                return item;
            });
        } else {
            const appChatValue: IAppointmentChat = {
                id: props.id,
                chatOpen: true,
            };
            appointmentChat = vsstate.appointmentChat;
            appointmentChat.push(appChatValue);
        }

        vsdispatch({
            type: 'SETAPPOINTMENTCHAT',
            appointmentChat: appointmentChat,
        });
    };

    return (
        <div>
            <Nav.Link onClick={() => handleChatIconClick()}>
                <MyTooltip
                    id="AppointmentChatIcon"
                    text={props.chatOpen ? 'Chat schließen' : 'Chat öffnen'}
                    iconDiv={true}
                    divWidth="25px"
                    marginLeft="auto"
                    marginRight="0"
                    icon={<BsChatDots size={25} />}
                ></MyTooltip>
            </Nav.Link>
            {showUnreadBadge && (
                <Badge variant="secondary" style={{ position: 'absolute', top: '0px', right: '-20px' }}>
                    {numberUnread}
                </Badge>
            )}
        </div>
    );
};

export const AppointmentChat = (props: IAppointment) => {
    const [allIncomingMessages, setAllIncomingMessages] = useState<IIncomingMessage[]>([]);
    const { vsstate } = useContext(VideochatContext);
    useEffect(() => {
        getAllIncomingMessages(props);
    }, []);

    useEffect(() => {
        const appointmentChatEntry = getAppointmentChatEntry(vsstate.appointmentChat, props.id);
        if (appointmentChatEntry) {
            if (appointmentChatEntry.incomingMessages) {
                setAllIncomingMessages(appointmentChatEntry.incomingMessages);
            }
        }
        // return () => {
        //     cleanup
        // }
    }, [vsstate.appointmentChat]);

    const getAllIncomingMessages = (props: IAppointment) => {
        if (props.extension) {
            props.extension.forEach((extension: any) => {
                if (extension.url.endsWith('ChatEntries')) {
                    const incomingMessages = extension.valueString;
                    const allMsg: IIncomingMessage[] = JSON.parse(incomingMessages);
                    setAllIncomingMessages(allMsg);
                }
            });
        }
    };

    return (
        <div>
            <StyledAppointmentCardFooter>
                <Chat
                    idType={MessageIdType.AppId}
                    show={true}
                    asWidget={false}
                    showChatInput={isDateToday(props.start)}
                    id={props.id}
                    incomingMessages={allIncomingMessages}
                />
            </StyledAppointmentCardFooter>
        </div>
    );
};
