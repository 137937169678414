import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/esm/Row';
import Modal from 'react-bootstrap/Modal';
import StyledButton from '../../Buttons/StyledButton';
import StyledCol from '../../Cols/StyledCol';
import { StyledModal } from '../StyledModal/StyledModal';
import { useContext } from 'react';
import AppPropsContext from '../../../context/appPropsContext';

interface IDoDoubleOptInViaLinkModal {
    show: boolean;
    onHide: () => void;
}

const DoDoubleOptInViaLinkModal = (props: IDoDoubleOptInViaLinkModal) => {
    const { apstate } = useContext(AppPropsContext);
    return (
        <div>
            <StyledModal show={props.show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Aktivierung Ihres Accounts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Alert variant="danger">
                            <p>
                                Bitte überprüfen Sie Ihren E-Mail Posteingang auf eine E-Mail von {apstate.smtpUser} und
                                klicken Sie auf den enthaltenen Link, um Ihren Account zu aktivieren. Prüfen Sie ggf.
                                auch Ihren Spam-Ordner
                            </p>
                        </Alert>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <div style={{ float: 'left' }}>
                            <StyledCol textAlign="left">
                                <StyledButton variant="primary" onClick={props.onHide}>
                                    OK
                                </StyledButton>
                            </StyledCol>
                        </div>
                    </Row>
                </Modal.Footer>
            </StyledModal>
        </div>
    );
};

export default DoDoubleOptInViaLinkModal;
